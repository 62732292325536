import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { TaskTypeModel } from 'src/app/shared/entities/taskTypeModel';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSelect, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Subject } from 'rxjs';
import { CommonDropdownService } from 'src/app/shared/services/common.dropdown.service';
import { DialogDataForModalView, UIService } from 'src/app/shared/services/ui.service';
import { MaintenanceService } from '../../service/maintenance.service';
import { SnackBar, SnackBarType } from 'src/app/shared/components/snackbar/entities/snackbar';
import { TitleCasePipe } from '@angular/common';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { APIService } from '../../../shared/services/api.service';
import API from '../../../shared/services/api.routes.json';

@Component({
  selector: 'app-add-edit-task-type',
  templateUrl: './add-edit-task-type.component.html',
  styleUrls: ['./add-edit-task-type.component.scss']
})
export class AddEditTaskTypeComponent implements OnInit {

  taskTypeModel: TaskTypeModel;
  taskTypeFormGroup: FormGroup;
  isEdit: boolean = false;
  _taskTypeList: any = [];
  isDisabledTaskType = false;
  lengthTaskType: number = 0;

  account_validation_messages = {
    'taskTypeName': [
      { type: 'required', message: 'taskTypeName is required' },
      { type: 'maxlength', message: 'taskTypeName cannot be more than 50 characters long' }

    ],
  }
  @ViewChild('singleSelect', { static: false }) singleSelect: MatSelect;

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  constructor(
    private _apiService: APIService,
    private commonDropdownService: CommonDropdownService,
    private _formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public taskTypeData: DialogDataForModalView,
    private maintenanceService: MaintenanceService,
    private uIService: UIService,
    private validationService: ValidationService,
    public dialogRef: MatDialogRef<AddEditTaskTypeComponent>,
  ) {
    this.taskTypeModel = new TaskTypeModel();
  }

  ngOnInit() {
    this.initFormGroup();
    this.commonDropdownService.LoadReferralSourceList();
    this.setClientDataForEditView();
    this.LoadTaskTypeList();
  }

  initFormGroup() {
    this.taskTypeFormGroup = this._formBuilder.group({
      taskTypeName: ['', [Validators.minLength(1), Validators.maxLength(50), this.validationService.noWhitespaceValidator]],
      icon: ['', [Validators.required, this.validationService.noWhitespaceValidator]]
    });
  }

  setClientDataForEditView() {
    if (this.taskTypeData.data) {
      this.taskTypeModel = Object.assign({}, this.taskTypeData.data).modalData;
      this.isEdit = true;
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  async submit(model: { id: any; }) {    
    if (this.taskTypeFormGroup.invalid) {
      this.isDisabledTaskType = false;
      return;
    }

    if (this.isEdit) this.updateTaskType(model.id, model);
    else this.saveTaskType(model);
  }

  saveTaskType(obj: any) {
    obj.active = true;
    obj.isHeaderType = false;
    let taskTypes = this._taskTypeList.filter(x => x.isHeaderType == true);
    obj.order = taskTypes.length + 1;
    this.isDisabledTaskType = true;
    this.maintenanceService.SaveTaskType(obj).subscribe(
      res => {
        if (res.isSuccsess) {
          var snack = new SnackBar(SnackBarType.success, "Successfully saved", "", 2000);
          this.uIService.openSnackBar(snack);
          this.closeDialog();
          this.maintenanceService.TaskTypeRefersh(true);
        }
        else {
          snack = new SnackBar(SnackBarType.success, "Error on save", "", 2000);
          this.uIService.openSnackBar(snack);
        }
      },
      () => { console.error(); this.closeDialog(); });
  }

  updateTaskType(id: any, obj: any) {
    this.maintenanceService.updateTaskType(id, obj).subscribe(
      res => {
        if (res.isSuccsess) {
          var snack = new SnackBar(SnackBarType.success, "Successfully updated", "", 2000);
          this.uIService.openSnackBar(snack);
          this.closeDialog();
          this.maintenanceService.TaskTypeRefersh(true);
        }
        else {
          snack = new SnackBar(SnackBarType.success, "Error on update", "", 2000);
          this.uIService.openSnackBar(snack);
        }
      },
      () => { console.error(); this.closeDialog(); });
  }

  LoadTaskTypeList() {
    this._apiService.get(API.taskType.getAllTaskType).subscribe(response => {
      if (response) {
        if (response.data) {
          this._taskTypeList = response.data;
        }
      }
    },
      err => {
        console.error(err);
      }, () => {
      });
  }
}
