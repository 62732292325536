import { Component, OnInit } from '@angular/core';
import { UIService } from 'src/app/shared/services/ui.service';
import { ClientService } from 'src/app/client/services/client.service';
import API from '../../shared/services/api.routes.json';
import { APIService } from 'src/app/shared/services/api.service';
import { AlertModel } from 'src/app/shared/entities/alertModel';
import { Subscription, Subject } from 'rxjs';
import { SnackBar, SnackBarType } from 'src/app/shared/components/snackbar/entities/snackbar';
import { AlertService } from 'src/app/shared/services/alert.service';
import { SharedHeaderService } from '../../shared/services/shared.header.services';
import { LoginService } from '../../auth/login/login.service';
import { Users } from '../../auth/entities/user';
import { roleModel } from '../../auth/entities/role';
import { AddEditClientProductComponent } from './add-edit-client-product/add-edit-client-product.component';
import { ProductService } from '../service/product.service';
import { Product } from '../entities/product';
import { ProductPack } from '../entities/productPack';
import { AddEditClientPackComponent } from './add-edit-client-pack/add-edit-client-pack.component';
import { AddEditProductPackComponent } from './add-edit-product-pack/add-edit-product-pack.component';
import { AddEditProductComponent } from './add-edit-product/add-edit-product.component';

@Component({
  selector: 'view-client-product',
  templateUrl: './view-client-product.component.html',
  styleUrls: ['./view-client-product.component.scss']
})
export class ViewClientProductComponent implements OnInit {
  selectedProduct: Product;
  idSelectedProductPack: number;
  private subscriber: Subscription;


  constructor(private commonUIService: UIService,
    private loginServic: LoginService,
    public sharedHeaderService: SharedHeaderService,
    private _apiService: APIService,
    private uIService: UIService,
    public productService: ProductService,
    private alertService: AlertService) {
  }

  ngOnInit() {
    this.productService.isActiveProduct = true;
    this.loadProductList();
    this.subscribeRefreshProduct();
    this.subscribeRefreshProductPack();
    this.subscribeAlertConfirmEvents();
  }

  loadProductList() {  
    this.productService.getProductList().subscribe(res => {
      if (res && res.data) {
        this.productService.productList = [];
        this.productService.activeProductList = [];
        this.productService.inActiveProductList = [];
        if (this.productService.isActiveProduct) {        
          this.productService.activeProductList = res.data.filter(x => x.active != null && x.active == this.productService.isActiveProduct);
          this.productService.productList = this.productService.activeProductList;
        }
        if (!this.productService.isActiveProduct) {      
          this.productService.inActiveProductList = res.data.filter(x => x.active == null || x.active == this.productService.isActiveProduct);
          this.productService.productList = this.productService.inActiveProductList;
        }
      }
      else {
        const snack = new SnackBar(SnackBarType.error, 'Error on list', '', 2000);
        this.commonUIService.openSnackBar(snack);
        this.productService.productList = [];
        this.productService.activeProductList = [];
        this.productService.inActiveProductList = [];
      }
    },
      err => { console.error(err); }, () => { });
  }

  filterProducts(event) {
    if (event) {
      this.productService.isActiveProduct = event.checked;
      this.loadProductList();
    }
  }

  filterProductPacks(event) {    
    if (event) {
      this.productService.isActiveProductPack = event.checked;
      this.selectProduct(this.selectedProduct);
    }
  }

  selectProduct(product) {  
    this.productService.updatedProduct=null;
    this.loadProductList();
    if (product.id) {
      this.productService.getProductById(product.id).subscribe(
        res => {       
         this.selectedProduct = res.data;       
         if (this.productService.productList.length > 0) {
           let products = this.productService.productList.find(x => x.id == product.id);
           if (products.productPack) {
             this.productService.productPackList = products.productPack;
             if (this.productService.isActiveProductPack) {
               this.productService.activeProductPackList = this.productService.productPackList.filter(x => x.active != null && x.active == this.productService.isActiveProductPack);
               this.productService.productPackList = this.productService.activeProductPackList;
             }
             if (!this.productService.isActiveProductPack) {
               this.productService.inActiveProductPackList = this.productService.productPackList.filter(x => x.active == null || x.active == this.productService.isActiveProductPack);
               this.productService.productPackList = this.productService.inActiveProductPackList;
             }
           }
           else {
             this.productService.productPackList = [];
             this.productService.activeProductPackList = [];
             this.productService.inActiveProductPackList = [];
           }
         }
        },
        (error) => {
          console.log(error);
        });    
    }
  }  

  openAddProjectPackPopup(project) {
    const dialogViewData = this.commonUIService.setDataForModalDialog(null, null, null, project, null, null);
    this.commonUIService.openDialog(AddEditProductPackComponent, dialogViewData)
  }

  openEditProjectPackPopup(projectPack) {    
    const dialogViewData = this.commonUIService.setDataForModalDialog(projectPack.id, null, null, projectPack, null, null);
    this.commonUIService.openDialog(AddEditProductPackComponent, dialogViewData);
  }

  openAddProductPopup() {
    const dialogViewData = this.commonUIService.setDataForModalDialog(null, null, null, null, null, null);
    this.commonUIService.openDialog(AddEditProductComponent, dialogViewData)
  }

  openEditProductPopup(product) {
    const dialogViewData = this.commonUIService.setDataForModalDialog(product.id, null, null, product, null, null);
    this.commonUIService.openDialog(AddEditProductComponent, dialogViewData);
  }

  subscribeRefreshProduct() {
    this.productService.refreshProduct.subscribe(data => {
      if (!data) { return; }
      this.loadProductList();
    });
  }

  subscribeRefreshProductPack() {
    this.productService.refreshProductPack.subscribe(data => {
      if (!data) { return; }
      if (this.productService.productList.length > 0) {
        let products = this.productService.productList.find(x => x.id == data.id);
        if (products.productPack) {
          this.productService.productPackList = products.productPack;
          if (this.productService.isActiveProductPack) {
            this.productService.activeProductPackList = this.productService.productPackList.filter(x => x.active != null && x.active == this.productService.isActiveProductPack);
            this.productService.productPackList = this.productService.activeProductPackList;
          }
          if (!this.productService.isActiveProductPack) {
            this.productService.inActiveProductPackList = this.productService.productPackList.filter(x => x.active == null || x.active == this.productService.isActiveProductPack);
            this.productService.productPackList = this.productService.inActiveProductPackList;
          }
        }
        else {
          this.productService.productPackList = [];
          this.productService.activeProductPackList = [];
          this.productService.inActiveProductPackList = [];
        }
      }
    });
  }
  
  openDeleteProjectPackPopup(id) {
    let deleteData = new AlertModel();
    deleteData.title = "Delete";
    deleteData.msg = "Are you sure to delete this record?";
    deleteData.id = id;
    deleteData.reference = "Delete Product Pack";
    this.idSelectedProductPack = id;
    this.commonUIService.openAlert(deleteData);
  }

  subscribeAlertConfirmEvents() {
    this.subscriber = this.alertService.referenceDelete
      .subscribe(data => {
        if (data === "Delete Product Pack" && this.idSelectedProductPack) {
          this.deleteProductPack(this.idSelectedProductPack);
        }
      });
  }

  deleteProductPack(id) {
    this.productService.deleteProductPack(id).subscribe(
      res => {
        if (res.isSuccsess) {
          var snack = new SnackBar(SnackBarType.success, res.message, "", 2000);
          this.commonUIService.openSnackBar(snack);
          let index1 = this.productService.productPackList.findIndex(s => s.id == this.idSelectedProductPack);
          if (index1 !== -1) {
            this.productService.productPackList.splice(index1, 1);
          }
        }
      });
  }

 
  showSubscriptionPrice(ppObj:ProductPack)
  {   
    let result = false;

    if(ppObj.subscriptionPrice && ppObj.subscriptionPrice!=0 && ppObj.oneTimePrice && parseFloat(ppObj.oneTimePrice)!=0)
    {
      result = true;      
    }  
    else if(ppObj.subscriptionPrice && ppObj.subscriptionPrice!=0 && (ppObj.oneTimePrice==null||parseFloat(ppObj.oneTimePrice)==0))
    {
      result = true; 
    } 
    else if(ppObj.oneTimePrice  && parseFloat(ppObj.oneTimePrice)!=0)
    {
      result = false;    
    }
    return result;
  }

  showOneTimePrice(ppObj:ProductPack)
  {   
    let result = false;

    if(ppObj.subscriptionPrice && ppObj.subscriptionPrice!=0 && ppObj.oneTimePrice && parseFloat(ppObj.oneTimePrice)!=0)
    {
      result = true;      
    }  
    else if(ppObj.subscriptionPrice && ppObj.subscriptionPrice!=0 && (ppObj.oneTimePrice==null||parseFloat(ppObj.oneTimePrice)==0))
    {
      result = false; 
    } 
    else if(ppObj.oneTimePrice  && parseFloat(ppObj.oneTimePrice)!=0)
    {
      result = true;    
    }
    return result;
  }

  showCollectivePrice(ppObj:ProductPack){

    let result = false;

    if(ppObj.subscriptionPrice && ppObj.subscriptionPrice!=0 && ppObj.oneTimePrice && parseFloat(ppObj.oneTimePrice)!=0)
    {
      result =true;      
    }  
    else if(ppObj.subscriptionPrice && ppObj.subscriptionPrice!=0 && (ppObj.oneTimePrice==null||parseFloat(ppObj.oneTimePrice)==0))
    {
      result = false; //NOTE : '+' mark not added as prefix if one time price=0    
    } 
    else if(ppObj.oneTimePrice  && parseFloat(ppObj.oneTimePrice)!=0)
    {
      result = false;    
    }       
    return result;

  }

  showPPSubscriptionTypeName(ppObj:ProductPack){

    let showSubscriptionType :boolean= false;     
    
    if(ppObj.subscriptionPrice && ppObj.subscriptionPrice!=0)
    {
      showSubscriptionType = true;    
    }
    else if(ppObj.oneTimePrice  && parseFloat(ppObj.oneTimePrice)!=0)
    {
      showSubscriptionType = false;        
    }   
    else
    {
      showSubscriptionType = true;        
    }
    return showSubscriptionType;
  }

  /*NOTE:Refer the harcoded subscription types at :
   src/app/client-product/view-client-product/add-edit-client-pack/add-edit-client-pack.html file's drop down for 'Types of Subscription'*/
  getSubscriptionTypeNameById(ppObj:ProductPack)  
  {    
    console.log(ppObj.subscriptionType);
    let paramStr= ppObj.subscriptionType!=null?ppObj.subscriptionType.toString().toLowerCase().trim():'';
    let result = '';
    switch(paramStr)
    {
      case "0":
        result = 'One time';
        break;
      case "1":
        result = 'Monthly';
        break;
      case "3":
        result = 'Quarterly';
        break;
      case "6":
        result = 'Semi-yearly';
        break;
      case "12":
        result = 'Yearly';
        break;
    }
    return result;
  }
}
