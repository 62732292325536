import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { MatAutocomplete, MAT_DIALOG_DATA, MatDialogRef, MatAutocompleteSelectedEvent } from '@angular/material';
import { UIService, DialogDataForModalView } from 'src/app/shared/services/ui.service';
import { MaintenanceService } from 'src/app/maintenance/service/maintenance.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { CommonDropdownService } from 'src/app/shared/services/common.dropdown.service';
import { FormatMatDatepicker } from 'src/app/shared/format-datepicker';
import { LoginService } from 'src/app/auth/login/login.service';
import { FilterClientProjectTaskModel } from '../../entities/filterClientProjectTaskModel';
import { ClientService } from '../../../client/services/client.service';
import { SnackBar, SnackBarType } from '../../../shared/components/snackbar/entities/snackbar';

@Component({
  selector: 'filter-task',
  templateUrl: './filter-task.component.html',
  styleUrls: ['./filter-task.component.scss'],
})
export class FilterTaskComponent implements OnInit {

  filterClientProjectTaskModel: FilterClientProjectTaskModel;
  taskFilterFormGroup: FormGroup;

  constructor(
    private _formBuilder: FormBuilder,
    public clientService: ClientService,
    public commonUIService: UIService,
    public commonDropdownService: CommonDropdownService,
    private formatDatepicker: FormatMatDatepicker,
    public dialogRef: MatDialogRef<FilterTaskComponent>,
  ) {
    this.filterClientProjectTaskModel = new FilterClientProjectTaskModel;
  }

  ngOnInit() {
    this.commonDropdownService.LoadUserList();
    this.commonDropdownService.LoadTaskTypeList();
    this.formvalidate();
    this.setFilterModelValue();
  }

  formvalidate() {
    this.taskFilterFormGroup = this._formBuilder.group({
      taskTypeId: [''],
      userId: [''],
      toDate: [''],
      fromDate: [''],
    })
  }

  filterClientAndProjectTasks(event) {
    if (this.filterClientProjectTaskModel.fromDate == undefined &&
      this.filterClientProjectTaskModel.toDate == undefined &&
      this.filterClientProjectTaskModel.taskTypeId == undefined &&
      this.filterClientProjectTaskModel.userId == undefined) {
      this.clientService.isMoreFilterActiveForTask = false;
      const snack = new SnackBar(SnackBarType.error, 'Apply atleast one filter', '', 2000);
      this.commonUIService.openSnackBar(snack);
      return;
    }
    this.clientService.isMoreFilterActiveForTask = true;
    this.filterClientProjectTaskModel.isCheckLoggingUser = false;
    this.filterClientProjectTaskModel.taskDescription = null;
    this.filterClientProjectTaskModel.uptoToday = false;
    this.filterClientProjectTaskModel.fromDateStr = this.filterClientProjectTaskModel.fromDate ? this.formatDatepicker.ConvertDateFormatForSave(this.filterClientProjectTaskModel.fromDate) : null;
    this.filterClientProjectTaskModel.toDateStr = this.filterClientProjectTaskModel.toDate ? this.formatDatepicker.ConvertDateFormatForSave(this.filterClientProjectTaskModel.toDate) : null;
    localStorage.setItem('filterStorageModel', JSON.stringify(this.filterClientProjectTaskModel));

    this.clientService.filterClientAndProjectTasks(this.filterClientProjectTaskModel).subscribe(res => {
      if (res && res.data) {
        let clientAndProjectTaskList = res.data;
        clientAndProjectTaskList.forEach(r => {
          var myStr = r.taskUserName;
          var matches = myStr.match(/\b(\w)/g);
          var x = matches.join('');
          r.initials = x;
        });
        this.clientService.refreshClientAndProjectTaskList.emit(clientAndProjectTaskList);
        this.closeDialog();
      }
      else {
        const snack = new SnackBar(SnackBarType.error, 'Error on list', '', 2000);
        this.commonUIService.openSnackBar(snack);
      }
    },
      err => {
        console.error();
        const snack = new SnackBar(SnackBarType.error, 'Error on list', '', 2000);
        this.commonUIService.openSnackBar(snack);
      });
  }

  setFilterModelValue() {
    var value = localStorage.getItem('filterStorageModel');
    if (value) {
      var obj = JSON.parse(value);
      this.filterClientProjectTaskModel.userId = obj && obj.userId ? obj.userId : 0;
      this.filterClientProjectTaskModel.taskTypeId = obj && obj.taskTypeId ? obj.taskTypeId : 0;
      this.filterClientProjectTaskModel.toDate = obj && obj.toDate ? obj.toDate : null;
      this.filterClientProjectTaskModel.fromDate = obj && obj.fromDate ? obj.fromDate : null;
    }
  }

  filterClosed() {
    const value = localStorage.getItem('filterStorageModel');
    if (!value)
      this.closeDialog();
    else {
      localStorage.removeItem("filterStorageModel");
      this.clientService.clearTaskFilter.emit(true);
      this.clientService.isMoreFilterActiveForTask = false;
      this.closeDialog();
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }  
}
