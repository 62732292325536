import { Component, OnInit } from '@angular/core';
import { UIService } from '../../shared/services/ui.service';
import { ProjectStatus } from '../../shared/entities/projectStatus';
import { MaintenanceService } from '../service/maintenance.service';
import { APIService } from '../../shared/services/api.service';
import API from '../../shared/services/api.routes.json';
import { AlertModel } from '../../shared/entities/alertModel';
import { AlertService } from '../../shared/services/alert.service';
import { Subscription, Subject } from 'rxjs';
import { SnackBar, SnackBarType } from '../../shared/components/snackbar/entities/snackbar';
import { AddEditProjectstatusComponent } from './add-edit-projectstatus/add-edit-projectstatus.component';
import { MatDialog } from '@angular/material';
// import { AddEditProjectstatusComponent } from './add-edit-projectstatus/add-edit-projectstatus.component';

@Component({
  selector: 'app-project-status',
  templateUrl: './project-status.component.html',

})

export class ProjectStatusComponent implements OnInit {

  _projectStatusList: ProjectStatus[] = [];
  projectStatusList: ProjectStatus[] = [];
  _idSelectedProjectStatus: number;
  updateProjectStatus: ProjectStatus;
  private subscriber: Subscription;
  private _unsubscribeAll: Subject<any>;
  public dialog: MatDialog;
  isLoading: boolean = false;

  constructor(
    private uIService: UIService,
    private maintenanceService: MaintenanceService,
    private _apiService: APIService,
    private alertService: AlertService) {
    this._projectStatusList = [];
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this.updateProjectStatus = new ProjectStatus();
    this.LoadProjectStatusList();
    this.subscribeAlertConfirmEvents();
    this.maintenanceService.subjectprojectStatusRefresh.subscribe(isStatusRefesh => {
      if (isStatusRefesh) {
        this.LoadProjectStatusList();
      }
    });
  }

  subscribeAlertConfirmEvents() {

    this.subscriber = this.alertService.referenceDelete
      .subscribe(data => {
        if (data === "Delete Project Status" && this._idSelectedProjectStatus) {
          this.deleteProjectStatus(this._idSelectedProjectStatus);
        }
      });
  }

  addProject() {
    this.uIService.openDialog(AddEditProjectstatusComponent, null)
  }

  openAlertDeleteProjectStatus(id) {
    let deleteData = new AlertModel();
    deleteData.title = "Delete";
    deleteData.msg = "Are you sure to delete this record?";
    deleteData.id = id;
    deleteData.reference = "Delete Project Status";
    this._idSelectedProjectStatus = id;
    this.uIService.openAlert(deleteData);
  }

  private LoadProjectStatusList() {
    this.isLoading = true;
    this._apiService.get(API.projectStatus.listAll).subscribe(response => {
      if (response) {
        if (response.data) {
          this.projectStatusList = response.data;
          this._projectStatusList = this.projectStatusList.sort((a, b) => a.projectState.localeCompare(b.projectState));
        }
      }
      this.isLoading = false;
    },
      err => {
        this.isLoading = false;
        console.error(err);
      }, () => {
      });
  }

  editprojectStatus(state) {
    const projectState = Object.assign({}, state);
    if (projectState.categoryId != null || projectState.categoryId != undefined) {
      projectState.categoryId = projectState.categoryId.toString();
    }
    const dialogViewData = this.uIService.setDataForModalDialog(null, null, null, projectState, null, null);
    this.uIService.openDialog(AddEditProjectstatusComponent, dialogViewData);
  }

  async deleteProjectStatus(projectId) {
    this.maintenanceService.deleteProjectStatus(projectId).subscribe(
      res => {
        if (res.isSuccsess) {
          var snack = new SnackBar(SnackBarType.success, res.message, "", 2000);
          this.uIService.openSnackBar(snack);
          if (res.data) this.LoadProjectStatusList();
        }
        else {
          var snack = new SnackBar(SnackBarType.error, "Error", "", 2000);
          this.uIService.openSnackBar(snack);
        }
      });
  }
  activeDeactive(event, model) {
    model.active = event.checked
    this.maintenanceService.updateProjectStatus(model.id, model).subscribe(
      (res: { isSuccsess: any; }) => {
        if (res.isSuccsess) {
          var snack = new SnackBar(SnackBarType.success, "Successfully updated", "", 2000);
          this.uIService.openSnackBar(snack);
          this.maintenanceService.projectStatusRefresh(true);
        }
        else {
          snack = new SnackBar(SnackBarType.success, "Error on update", "", 2000);
          this.uIService.openSnackBar(snack);
        }
      },
      () => { console.error(); });
  }
  ngOnDestroy(): void {
  }
}
