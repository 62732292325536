import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { InventoryService } from 'src/app/inventory/service/inventoryService';
import { SimmModels } from 'src/app/inventory/entities/simm';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { SnackBar, SnackBarType } from 'src/app/shared/components/snackbar/entities/snackbar';
import { UIService } from 'src/app/shared/services/ui.service';
import { CommonDropdownService } from '../../../../shared/services/common.dropdown.service';
import { AlertModel } from '../../../../shared/entities/alertModel';
import { Subscription } from 'rxjs';
import { AlertService } from '../../../../shared/services/alert.service';
import { AddSimmUserComponent } from './add-simm-user/add-simm-user.component';
import { MatSelectChange } from '@angular/material';

@Component({
  selector: 'app-simm-detail',
  templateUrl: './simm-detail.component.html',
  styleUrls: ['./simm-detail.component.scss']
})
export class SimmDetailComponent implements OnInit, OnChanges {

  @Input() simmId: any; 
  simmListModel: SimmModels;
  inventorySimmForm: FormGroup;
  showEditSave: boolean = false;
  isEdit: boolean = false;
  lengthNumber: number = 9;
  isChangeUser: boolean = false;
  subscriberForAlertConfirm: Subscription;
  subscriberRefresh: Subscription;
  subscriberAlertService: Subscription;
  subscriberSIMStatusChangeService: Subscription;
  selectedUser:any;

  constructor(public inventoryService: InventoryService, public fb: FormBuilder, public commonUIService: UIService,
    public commonDropdownService: CommonDropdownService, public alertService: AlertService) {
    this.simmListModel = new SimmModels();
    if (this.inventorySimmForm) {
      this.inventorySimmForm.get('number').markAsUntouched();    
      this.subscribeRefreshSimm(); 
      this.subscribeAlertConfirmEvents();
      this.subscribeLoadSimmList();
    }
  }
  ngOnInit() {   
    this.initInventoryDetailForm(); 
    this.commonDropdownService.LoadUserList();  
    this.subscribeAlertConfirmEvents();
    this.subscribeLoadSimmList();
    this.subscribeRefreshSimm();    
  }

  ngOnChanges() {
    if (this.simmId != 0) {
      this.loadSimmList(this.simmId);
      this.isEdit = true;
      setTimeout(() => this.inventorySimmForm.disable(), 2000);
      this.showEditSave = false;
    } else {
      this.simmListModel = new SimmModels();
      this.isEdit = false;
      if (this.inventorySimmForm) {
        this.inventorySimmForm.enable();
      } else {
        this.inventorySimmForm = this.fb.group({
          user: [''],
          number: ['', [Validators.required, Validators.pattern(/^(\+\d{1,3}[- ]?)?\d{10}$/)]],
          status: [''],
          allocatedData: ['']
        })
        this.inventorySimmForm.enable();
      }
      this.showEditSave = true;
    }
    if (this.inventorySimmForm) {
      this.inventorySimmForm.get('number').markAsUntouched();
    }
  }

  ngOnDestroy() {
    this.subscriberForAlertConfirm.unsubscribe();
    this.subscriberRefresh.unsubscribe();
    this.subscriberAlertService.unsubscribe();
  }

  initInventoryDetailForm() {
    this.inventorySimmForm = this.fb.group({
      user: [''],
      number: ['', [Validators.required, Validators.pattern(/^(\+\d{1,3}[- ]?)?\d{10}$/)]],
      status: [''],
      allocatedData: ['']
    })
  }

  loadSimmList(id) {
    if (id) {
      this.commonDropdownService.LoadUserListAsObservable().subscribe(result=>{    
        this.inventoryService.getBySimmId(id).subscribe(res => {    
          this.commonDropdownService._userList= result;                    
          this.simmListModel = Object.assign({},res.data[0]);  
          this.selectedUser.value =this.simmListModel.userId;
          this.selectedUser.text = this.simmListModel.user;            
        });
      },(error)=>
      {
      console.log(error);
      });
     
    }
  }

  editSimm() {
    if (!this.showEditSave) {
      this.showEditSave = true;
    }

    if (this.inventorySimmForm.disabled) {
      this.inventorySimmForm.enable();
    }
  }

  saveSimm(model: SimmModels) {
    model.user = this.selectedUser.text;  
    if (model.user == undefined || model.user == null)
      model.status = false;
    else
      model.status = true;
    if (this.isEdit == false) {
      this.inventorySimmForm.get('number').markAsTouched();
      var numberValue = this.inventorySimmForm.get('number').value;
      if (this.inventorySimmForm.invalid || numberValue == undefined || numberValue == null) {
        this.inventorySimmForm.enable();
        return
      } else {
        if (model.number != undefined || model.user != undefined) {
          if (model.id) {
            this.inventoryService.updateSimm(model.id, model).subscribe(res => {
              if (res.isSuccsess) {
                var snack = new SnackBar(SnackBarType.success, res.message, '', 2000);
                this.commonUIService.openSnackBar(snack);
                if (res.message == "Sucessfully updated") {
                  this.inventorySimmForm.disable();
                  this.showEditSave = false;
                  this.loadSimmList(res.data.id);
                  this.inventoryService.refreshSimmUpdate.emit({ status: true, simmId: res.data.id });
                }
              }
            });
          } else {                        
            this.inventoryService.saveSimm(model).subscribe(
              (res => {
                if (res.isSuccsess) {
                  var snack = new SnackBar(SnackBarType.success, "Successfully saved", "", 2000);
                  this.commonUIService.openSnackBar(snack);
                  this.inventorySimmForm.disable();
                  this.showEditSave = false;
                  this.isEdit = true;
                  this.loadSimmList(res.data.id);
                  this.inventoryService.refreshSimmUpdate.emit({ status: true, simmId: res.data.id });
                }
                else {
                  snack = new SnackBar(SnackBarType.success, "Error on save", "", 2000);
                  this.commonUIService.openSnackBar(snack);
                }
              }),
              () => { console.error(); });
          }
        } else {
          var snack = new SnackBar(SnackBarType.success, "No data entered for save", "", 2000);
          this.commonUIService.openSnackBar(snack);
          return;
        }
      }
    } else {
      if (!!this.inventorySimmForm && this.inventorySimmForm.dirty) {
        this.inventorySimmForm.get('number').markAsTouched();
        var numberValue = this.inventorySimmForm.get('number').value;
        if (this.inventorySimmForm.invalid || numberValue == undefined || numberValue == null) {
          this.inventorySimmForm.enable();
          return
        } else {
          if (model.number != undefined || model.user != undefined) {
            if (model.id) {
              this.inventoryService.updateSimm(model.id, model).subscribe(res => {
                if (res.isSuccsess) {
                  var snack = new SnackBar(SnackBarType.success, res.message, '', 2000);
                  this.commonUIService.openSnackBar(snack);
                  if (res.message == "Sucessfully updated") {
                    this.inventorySimmForm.disable();
                    this.showEditSave = false;
                    this.inventoryService.refreshSimmUpdate.emit({ status: true, simmId: res.data.id });
                  }
                }
                else 
                {
                  snack = new SnackBar(SnackBarType.success, "Error on update", "", 2000);
                  this.commonUIService.openSnackBar(snack);
                }
              });
            } else {
              this.inventoryService.saveSimm(model).subscribe(
                (res => {
                  if (res.isSuccsess) {
                    var snack = new SnackBar(SnackBarType.success, "Successfully saved", "", 2000);
                    this.commonUIService.openSnackBar(snack);
                    this.inventorySimmForm.disable();
                    this.showEditSave = false;
                    this.isEdit = true;
                    this.inventoryService.refreshSimmUpdate.emit({ status: true, simmId: res.data.id });
                  }
                  else {
                    snack = new SnackBar(SnackBarType.success, "Error on save", "", 2000);
                    this.commonUIService.openSnackBar(snack);
                  }
                }),
                () => { console.error(); });
            }
          } else {
            var snack = new SnackBar(SnackBarType.success, "No data entered for save", "", 2000);
            this.commonUIService.openSnackBar(snack);
            return;
          }
        }
      } else {
        const snack = new SnackBar(SnackBarType.success, 'No data entered for update', '', 4000);
        this.commonUIService.openSnackBar(snack);
        return;
      }
    }
  }

  onKey(numb) {
    if (numb.charAt(0) == "+") {
      this.lengthNumber = 12
    }
    else {
      this.lengthNumber = 10;
    }
  }

  changeStatusCheckEvent(event, simmListModel) {   
    if (event && event.value != null || event.value != undefined) {
      if (event.value == 0) {
        let alterData = new AlertModel();
        alterData.title = 'Warning';
        alterData.msg = 'Are you sure you want to change the status?';
        alterData.reference = 'DontChangeStatusOfSimm';
        alterData.alertType = 'Simm';
        alterData.id = simmListModel.id;
        this.commonUIService.openAlert(alterData);
      } else {
        let alterData = new AlertModel();
        alterData.title = 'Warning';
        alterData.msg = 'Are you sure you want to change the status?';
        alterData.id = simmListModel.id;
        alterData.reference = 'ChangeStatusOfSimm';
        alterData.alertType = 'Simm';
        this.commonUIService.openAlert(alterData);
      }
    }
  }

  subscribeAlertConfirmEvents() {
    this.subscriberForAlertConfirm = this.alertService.referenceConfirmForSimm.subscribe(data => {   
      if (data.reference == 'ChangeStatusOfSimm') {
        this.simmListModel.status = true;       
        this.onOpenSimmUserPopup();
      } else if (data.reference == 'DontChangeStatusOfSimm') {
        this.simmListModel.status = false;
        this.simmListModel.user = null;
        this.simmListModel.userId = null;
        if (this.simmListModel.id > 0) {
          this.inventoryService.updateSimm(this.simmListModel.id, this.simmListModel).subscribe(res => {
            if (res.isSuccsess) {
              var snack = new SnackBar(SnackBarType.success, res.message, '', 2000);
              this.commonUIService.openSnackBar(snack);
              if (res.message == "Sucessfully updated") {
                this.inventorySimmForm.disable();
                this.showEditSave = false;
                this.commonDropdownService.LoadUserList();
                this.loadSimmList(res.data.id);
                this.inventoryService.refreshSimmUpdate.emit({ status: true, simmId: res.data.id });
              }
            }
          });
        }
      }
    });
  }

  subscribeLoadSimmList() {
    this.subscriberRefresh = this.inventoryService.refreshLoadSimmList.subscribe(data => {
      if (data != null) {
        this.loadSimmList(data.id);
      }
    });
  }

  onOpenSimmUserPopup() {
    this.simmListModel.status = true;
    const dialogViewData = this.commonUIService.setDataForModalDialog(null, null, null, this.simmListModel, null, null);
    this.commonUIService.openDialog(AddSimmUserComponent, dialogViewData)
  }

  subscribeRefreshSimm() {
    this.subscriberAlertService = this.alertService.referenceRefreshSimm.subscribe(data => {
      if (data != null) {
        this.loadSimmList(data);
      }
    });
    this.subscriberAlertService = this.inventoryService.refreshSimmUpdate.subscribe(data => {
      if (data != null) {
        this.loadSimmList(data.simmId);
      }
    });
  }  

  selectedValue(event: MatSelectChange) {  
    this.selectedUser = {
      value: event.value,
      text: event.source.triggerValue
    };   
  }
}
