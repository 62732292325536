import { Component, Input, ViewChild, ChangeDetectorRef, ElementRef, Output, EventEmitter } from '@angular/core';
import { Client } from '../../entities/client';
import { Subscription } from 'rxjs';
import { ClientService } from '../../services/client.service';
import { CommonDropdownService } from 'src/app/shared/services/common.dropdown.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { UIService } from 'src/app/shared/services/ui.service';
import { ClientContact } from '../../entities/clientContact';
import { isNumber } from 'util';
import { SnackBar, SnackBarType } from 'src/app/shared/components/snackbar/entities/snackbar';
import { AddclientComponent } from '../../add-client/add-client.component';
import { MatDialog } from '@angular/material';
import { AddEditTaskComponent } from './add-edit-task/add-edit-task.component';
import { AlertModel } from 'src/app/shared/entities/alertModel';
import { AddEditContactComponent } from './add-edit-contact/add-edit-contact.component';
import { AddEditActionComponent } from './add-edit-action/add-edit-action.component';
import { ActionClient } from '../../entities/actionClient';
import { regionModel } from 'src/app/shared/entities/regionModel';
import { AddEditClientDocumentComponent } from 'src/app/client/client-detail/client-view-data/add-edit-client-document/add-edit-client-document.component';
import API from '../../../shared/services/api.routes.json';
import { ClientDocument } from 'src/app/client/entities/clientDocument';
import { Users } from 'src/app/auth/entities/user';
import { ClientTask } from '../../entities/clientTask';
import { FormatTimeForDisplay, FormatMatDatepicker } from 'src/app/shared/format-datepicker';
import moment from 'moment';
import { AuthAppService } from 'src/app/shared/services/auth-app.service';
import { SharedHeaderService } from '../../../shared/services/shared.header.services';
import { ClientViewService } from '../../services/client-view.service';
import { DocumentService } from '../../services/document.service';
import { FilterTaskModel } from '../../../task/entities/filterTaskModel';
import { Router } from '@angular/router';
import { SharedClientService } from '../../services/shared.client.service';
import { Product } from '../../../client-product/entities/product';
import { ProductService } from '../../../client-product/service/product.service';
import { AddEditProductComponent } from '../../../client-product/view-client-product/add-edit-product/add-edit-product.component';
import { AddEditClientProductComponent } from '../../../client-product/view-client-product/add-edit-client-product/add-edit-client-product.component';
import { AddEditClientPackComponent } from '../../../client-product/view-client-product/add-edit-client-pack/add-edit-client-pack.component';
import { ViewProductListComponent } from '../../../client-product/view-client-product/view-product-list/view-product-list.component';
import { SlickCarouselComponent } from "ngx-slick-carousel";

@Component({
  selector: 'app-client-view-data',
  templateUrl: './client-view-data.component.html',
  styleUrls: ['./client-view-data.component.scss']
})
export class ClientViewDataComponent {
  //region Variables
  @Input() currentClientId: any;
  @Input() detectChange: any;

  @Output() menuClosed: EventEmitter<void>;
  @Output() menuOpened: EventEmitter<void>;

  @ViewChild('commentElement', { read: ElementRef, static: true }) commentElement: ElementRef<HTMLInputElement>;
  @ViewChild('trigger', { read: null, static: false }) trigger: any;
  @ViewChild('matTabGroup', { static: false }) matTabGroup: any;
  @ViewChild('slickModal',{ static: false }) slickModalProduct: SlickCarouselComponent;
  @ViewChild('slickModal',{ static: false }) slickModalDocType: SlickCarouselComponent;

  // config
  public PDF = '../../assets/images/config/pdf.png';
  public PNG = '../../assets/images/config/png.png';
  public JPG = '../../assets/images/config/jpg.png';
  public JPEG = '../../assets/images/config/jpg.png';
  public XLS = '../../assets/images/config/xls.png';
  public DOC = '../../assets/images/config/doc.png';

  typeGlobal: string;
  idCurrentClientTask: number = 0;
  addressString: string;

  // #loading
  isLoading_client: boolean = true;
  isLoading_contact: boolean = false;
  isLoading_task: boolean = false;
  isLoading_action: boolean = false;
  isLoading_clientDocument: boolean = false;

  subscriber: Subscription;
  isSelected: boolean = false;
  client: Client;
  idCurrentClientContact: number = 0;
  idCurrentClientAction: number = 0;
  idCurrentClientDocument: number = 0;
  selectedDocumentTypeId: number = 0;
  clientStatusIdModel: number;
  selectedClientStatusStyle: any;
  _clientStatusGlobal: any;
  _clientContactList: ClientContact[] = [];
  clientLogoFamilyGlobal: string;
  selected = 'option2';
  _taskList: any = [];
  clientDocumentViewModel: ClientDocument;
  pickedDate: Date;
  minDate: Date = new Date();
  closeReasonIdForModel: any = null;
  filterTaskModel: FilterTaskModel;
  //endregion

  //region actionClient
  _actionList: any = [];
  regionName: regionModel;
  documentList: any = [];
  name;
  selectedTaskId: number = 0;
  eventValue: any;

  _taskTypeListForHeader: any = [];
  _actionTypeListForHeader: any = [];
  _taskTypeIdForAutomatic: number = 0;
  _actionTypeIdForAutomatic: number = 0;
  _loggedinUser: Users = new Users();
  isClosedReasonVisible: boolean = false;
  isCloseReasonRequired: boolean = false;
  currentCompanyId: number;
  currentCompanyName: string;
  selectedDocumentTabIndex: number = 0;
  _activeProjectTaskList: any = [];
  _inactiveProjectTaskList: any = [];
  characterLength: any = null;
  yesterdayDate: any;
  _activeProjectActionList: any = [];
  _inactiveProjectActionList: any = [];
  typeOfDocumentList: any = [];
  clientDocumentForSave: ClientDocument;
  clientDocumentModal: ClientDocument;
  _roleIds: any[] = [];

  //region client product
  _productList: Product[] = [];

  //end region

  Followup = [
    { value: `${this.commonUIService.getDateValueForTask('One week')}`, label: 'One week', parent: 'Followup' },
    { value: `${this.commonUIService.getDateValueForTask('Two weeks')}`, label: 'Two weeks', parent: 'Followup' },
    { value: `${this.commonUIService.getDateValueForTask('Three weeks')}`, label: 'Three weeks', parent: 'Followup' },
    { value: `${this.commonUIService.getDateValueForTask('One month')}`, label: 'One month', parent: 'Followup' },
    { value: 'Other', label: 'Other', parent: 'Followup' },
  ];

  Reminder = [
    { value: `${this.commonUIService.getDateValueForTask('One month')}`, label: 'One month', parent: 'Reminder' },
    { value: `${this.commonUIService.getDateValueForTask('Two months')}`, label: 'Two months', parent: 'Reminder' },
    { value: `${this.commonUIService.getDateValueForTask('Three months')}`, label: 'Three months', parent: 'Reminder' },
    { value: `${this.commonUIService.getDateValueForTask('Six months')}`, label: 'Six months', parent: 'Reminder' },
    { value: 'Other', label: 'Other', parent: 'Reminder' },
  ];

  Claim = [
    { value: `${this.commonUIService.getDateValueForTask('Tomorrow')}`, label: 'Tomorrow', parent: 'Claim' },
    { value: `${this.commonUIService.getDateValueForTask('Day after tomorrow')}`, label: 'Day after tomorrow', parent: 'Claim' },
    { value: `${this.commonUIService.getDateValueForTask('One week')}`, label: 'One week', parent: 'Claim' },
    { value: `${this.commonUIService.getDateValueForTask('Two weeks')}`, label: 'Two weeks', parent: 'Claim' },
    { value: `${this.commonUIService.getDateValueForTask('Three weeks')}`, label: 'Three weeks', parent: 'Claim' },
    { value: `${this.commonUIService.getDateValueForTask('One month')}`, label: 'One month', parent: 'Claim' },
    { value: 'Other', label: 'Other', parent: 'Claim' },
  ];

  
  slideConfigProductHeader = {
    "slidesToShow": 4,
    "slidesToScroll": 1,
    "nextArrow": "<div class='nav-btn next-slide'>&gt;</div>",
    "prevArrow": "<div class='nav-btn prev-slide'>&lt;</div>",
    "dots": false,
    "infinite": false
  };

  slideConfigDocumentTypeHeader = {
    "slidesToShow": 3,
    "slidesToScroll": 1,
    "nextArrow": "<div class='nav-btn next-slide'>&gt;</div>",
    "prevArrow": "<div class='nav-btn prev-slide'>&lt;</div>",
    "dots": false,
    "infinite": false
  };

  showProductCard :boolean = false; //Product card is still under testing=>Use this value to hide Product card

  constructor(
    public service: ClientViewService,
    public clientService: ClientService,
    private sharedClientService: SharedClientService,
    private documentService: DocumentService,
    public commonUIService: UIService,
    public dialog: MatDialog,
    public commonDropdownService: CommonDropdownService,
    public alertService: AlertService,
    public cdRef: ChangeDetectorRef,
    private formatTimeForDisplay: FormatTimeForDisplay,
    private formatDatepicker: FormatMatDatepicker,
    private authAppService: AuthAppService,
    private sharedHeaderService: SharedHeaderService,
    private clientViewService: ClientViewService,
    private productService: ProductService,
    public router: Router) {    
    this.service.selectedClient = new Client();
    this._actionList = [];
    this.regionName = new regionModel();
    this.currentCompanyId = this.sharedHeaderService.selectedCompanyId;
    this.currentCompanyName = this.sharedHeaderService.selectedCompanyName;
    this.filterTaskModel = new FilterTaskModel();
    this.filterTaskModel.clientId = this.currentClientId;
    this._activeProjectTaskList = [];
    this._inactiveProjectTaskList = [];
    this._activeProjectActionList = [];
    this._inactiveProjectActionList = [];
    this.clientDocumentForSave = new ClientDocument();
    this.clientDocumentModal = new ClientDocument();
  }

  ngOnInit() {
    let today = new Date();
    this.yesterdayDate = new Date(today.setDate(today.getDate() - 1)).toISOString();
    this.commonDropdownService.LoadClientActiveStatusList().subscribe(res => {
      this.commonDropdownService._activeClientStatusList = res;
    });
    this.clientDocumentViewModel = new ClientDocument();
    this.newDocumentCreate();
    this.subscribeEvents();
    this.commonDropdownService.LoadPositionList();
    this.LoadTaskTypeListForHeader();
    this.LoadActionTypeListForHeader();
    this._loggedinUser = this.authAppService.getLoggedInUser();
    this.commonUIService.loadPostponeDateList();
    this.loadTypeOfDocumentList();
    this.commonDropdownService.LoadCloseReasonList();
    this.subscribeCancelDocument();
    this.clientService.showMore = false;
    this.loadProductList();
    this.subscribeRefreshClientProduct();
  }

  onDateChanged(id, event) {
    this.pickedDate = event.value;
    let pickedDateStr = this.pickedDate ? this.formatDatepicker.ConvertDateFormatForSave(this.pickedDate) : this.pickedDate;
    this.changeClientTaskDate(id, pickedDateStr);
  }

  ngOnChanges() {
    let today = new Date();
    this.yesterdayDate = new Date(today.setDate(today.getDate() - 1)).toISOString();
    this.clientService.clientActiveProjectList;
    this.filterClientAndProjectTasks(null);
    this.filterTaskModel.clientId = this.currentClientId;
    this._activeProjectTaskList = [];
    this._inactiveProjectTaskList = [];
    this._activeProjectActionList = [];
    this._inactiveProjectActionList = [];
    this.getClientById(this.currentClientId);
    this.subscribeRefreshFilterClientAndProjectTasks();
    this.clientService.showMore = false;
    this.loadTypeOfDocumentList();
    this.loadProductList();
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  subscribeEvents() {
    this.clientService.refreshClientContact.subscribe(data => {
      if (!data) { return; }
      this.loadClientContactList(this.currentClientId).then();
    });

    this.clientService.refreshClientTask.subscribe(data => {
      if (!data) { return; }
      this.loadToDoClientTaskList(this.currentClientId).then();
    });

    this.clientService.refreshClientAction.subscribe(data => {
      if (!data) { return; }
      this.loadClientActionList(this.currentClientId).then();
      this.cdRef.detectChanges();
    });

    this.subscriber = this.alertService.referenceDelete.subscribe(data => {
      if (data === 'Delete Contact') {
        this.deleteClientContact(this.idCurrentClientContact);
      }
      else if (data === 'Delete client Task') {
        this.deleteClientTask(this.idCurrentClientTask);
      }
      else if (data === 'Delete Action') {
        this.deleteClientAction(this.idCurrentClientAction);
        this.isSelected = false;
      }
      else if (data === 'Delete Client') {
        this.deleteClient(this.currentClientId);
      }
      else if (data === 'Delete Document') {
        this.deleteClientDocument(this.idCurrentClientDocument);
      }
    });

    this.clientService.selectedClient.subscribe(() => {
      if (this.matTabGroup)
        this.matTabGroup.selectedIndex = 0;
    });

    this.documentService.cancelDocument.subscribe(() => {
      if (this.eventValue) {
        this.matTabGroup.selectedIndex = this.eventValue.index;
        this.selectedDocumentTypeId = this.eventValue.tab._viewContainerRef.element.nativeElement;
      }
      else {
        if (this.matTabGroup && this.matTabGroup.selectedIndex != undefined) {
          this.matTabGroup.selectedIndex = 0;
        }
      }
    });

    this.documentService.newDocument.subscribe(res => {
      if (res) {
        let x = res.documentTypeId;
        for (let i = 0; i < this.documentList.length; i++) {

          if (this.documentList[i].documentTypeId == x) {
            if (this.matTabGroup && this.matTabGroup.selectedIndex != undefined)
              this.matTabGroup.selectedIndex = i;
          }
        }
      }
    });
  }

  clientStatusChange(event, statusId) {   
    statusId = parseInt(statusId);
    this.setClientStatusStyle(statusId);
    if (this._clientStatusGlobal == 0) {
      this.isClosedReasonVisible = true;
      this.service.selectedClient.clientStatusId = statusId > 0 ? statusId : this.service.selectedClient.clientStatusId;
      this.isCloseReasonRequired = this.closeReasonIdForModel === null;
      if (this.isCloseReasonRequired) return;
    }
    else {
      this.isClosedReasonVisible = false;
      this.isCloseReasonRequired = false;
      this.service.selectedClient.clientStatusId = statusId > 0 ? statusId : this.service.selectedClient.clientStatusId;
      this.service.selectedClient.closeReasonId = null;
      this.service.selectedClient.closeReasonText = null;
      this.updateClientStatus(this.currentClientId, this.service.selectedClient);
    }
  }

  setCloseReasonForUpdate(event) {
    if (event) {
      this.closeReasonIdForModel = event.value ? event.value : null;
      this.isCloseReasonRequired = this.closeReasonIdForModel === null;
      if (this.isCloseReasonRequired) { return; }
      else {
        this.service.selectedClient.closeReasonId = Number(this.closeReasonIdForModel);
        this.updateClientStatus(this.currentClientId, this.service.selectedClient);
      }
    }
  }

  async loadClientActionList(clientId) {
    this.isLoading_action = true;
    this.clientService.loadClientActionList(clientId).subscribe(res => {
      if (res) {
        this._actionList = res.data;
        if (this._actionList) {
          this._actionList.forEach(act => {
            if (act.author && act.author != null) {
              let myStr = act.author;
              let matches = myStr.match(/\b(\w)/g);
              act.initials = matches.join('');
              act.initials = act.initials.substring(0, 2);
            }
            act.showMore = false;
            if (act.comment)
              act.characterLength = act.comment.length;
            else
              act.characterLength = null;
          });
        }
        this.service.selectedClient.isActionList = this._actionList.length > 0 ? true : false;
        this.isLoading_action = false;
      }
      else this.isLoading_action = false;
    }, () => { console.error(); this.isLoading_action = false; });

  }

  async getClientById(clientId) {
    this.isLoading_client = true;
    this.clientService.getClientById(clientId).subscribe(res => {
      if (res.isSuccsess) {
        this.service.selectedClient = res.data;
        if (this.service.selectedClient.businessDescription)
          this.characterLength = this.service.selectedClient.businessDescription.length;
        else
          this.characterLength = null;
        this.addressString = this.getAddressString(res);
        // Client Logo
        if (this.service.selectedClient.logoUrl) {
          this.service.selectedClient.logoFamily = this.service.selectedClient.logoUrl;
          this.clientLogoFamilyGlobal = this.service.selectedClient.logoUrl;
        } else {
          this.clientLogoFamilyGlobal = this.service.selectedClient.logoFamily;
        }

        this._clientContactList = this.service.selectedClient.clientContacts;
        if (this.service.selectedClient.clientProduct.length > 0) {
          this.clientService._clientProductList = this.service.selectedClient.clientProduct.sort((a, b) => a.productName.localeCompare(b.productName));
          if (this.clientService._clientProductList) {
            this.clientService._clientProductList.forEach(cp => {
              if (cp.clientPack.length > 0) {
                cp.clientPack = cp.clientPack.sort((a, b) => new Date(b.contractDate).getTime() - new Date(a.contractDate).getTime());
              }
            });
          }
        }
        this.clientService._clientProductList;

        let positionList = this.commonDropdownService._positionlist;

        for (let i = 0; i < this.service.selectedClient.clientContacts.length; i++) {
          for (let j = 0; j < positionList.length; j++) {
            this._clientContactList[i].position = positionList[j].id == this._clientContactList[i].positionId ? positionList[j].description : this._clientContactList[i].position;
            const phone1 = this._clientContactList[i].phoneNumber;
            const phone2 = this._clientContactList[i].additionalPhoneNumber;
            this._clientContactList[i].phoneString = phone1 && phone2 ? phone1 + " / " + phone2 : (phone1 && !phone2 ? phone1 : null);
          }
        }
        let _docList = this.service.selectedClient.clientDocument;
        if (_docList.length > 0) {
          _docList = _docList.filter(x => x.generatedFileName != null)
          _docList.forEach(document => {
            if (document.fileName) {
              document.extension = document.fileName.split('.')[(document.fileName.split('.')).length - 1];
              document.link = API.baseUrl + API.clientDocument.getClientDocument + document.id + '/' + document.generatedFileName;
              if (document.url) {
                if (document.extension === 'pdf')
                  document.url = document.url.toString().includes('data:application/pdf;base64,') ? document.url : 'data:application/pdf;base64,' + document.url;
                if (document.extension === 'png')
                  document.url = document.url.toString().includes('data:image/png;base64,') ? document.url : 'data:image/png;base64,' + document.url;
                if (document.extension === 'jpg')
                  document.url = document.url.toString().includes('data:image/jpg;base64,') ? document.url : 'data:image/jpg;base64,' + document.url;
                if (document.extension === 'jpeg')
                  document.url = document.url.toString().includes('data:image/jpeg;base64,') ? document.url : 'data:image/jpeg;base64,' + document.url;
                if (document.extension === 'docx')
                  document.url = document.url.toString().includes('https://docs.google.com/gview?url=') ? document.url : 'https://docs.google.com/gview?url=' + document.link + '&embedded=true';
                if (document.extension === 'xlsx' || document.extension === 'xls')
                  document.url = document.url.toString().includes('https://docs.google.com/gview?url=') ? document.url : 'https://docs.google.com/gview?url=' + document.link + '&embedded=true';
              }
            }
          });

          this.clientService._clientDocumentList = _docList;
          let x = this.clientService._clientDocumentList.length;
          for (let i = 0; i < x; i++) {
            this.documentList[i] = this.clientService._clientDocumentList[i];
          }

          this.service.showAddDocumentOut = true;
          this.service.isLoading_clientDocument = false;
          let selectedDocument = this.clientService._clientDocumentList[0];
          if (selectedDocument) {
            this.selectedDocumentTypeId = selectedDocument.documentTypeId;
            const tabIndex = this.clientService._clientDocumentList.findIndex(m => m.documentTypeId === this.selectedDocumentTypeId);
            if (tabIndex != 0)
              this.matTabGroup.selectedIndex = tabIndex < 0 ? 0 : tabIndex + 1;
          }
        } else {
          this.clientService._clientDocumentList = [];
          this.service.showAddDocumentOut = false;
        }
        this._actionList = this.service.selectedClient.actionClients;
        if (this._actionList) {
          this._actionList.forEach(act => {
            if (act.author && act.author != null) {
              let myStr = act.author;
              let matches = myStr.match(/\b(\w)/g);
              act.initials = matches.join('');
              act.initials = act.initials.substring(0, 2);
            }

          });
        }

        let cts = this.service.selectedClient.clientTasks;
        if (cts.length > 0) {
          this._taskList = cts.filter(x => x.status == 0).sort((a, b) => new Date(b.taskDate).getTime() - new Date(a.taskDate).getTime());
        } else {
          this._taskList = cts;
        }
        if (this._taskList && this._taskList.length > 0) {
          this.clientService.clientTaskCount = this._taskList.length;
        } else {
          this.clientService.clientTaskCount = null;
        }
        this._taskList.forEach(r => {
          let myStr = r.taskUserName;
          let matches = myStr.match(/\b(\w)/g);
          r.initials = matches.join('');
          r.initials = r.initials.substring(0, 2);
        });
        this.formatTaskTimeForDisplay(this._taskList);
        this.service.selectedClient.isContactList = this._clientContactList.length > 0;
        this.service.selectedClient.isTaskList = this._taskList.length > 0;
        this.service.selectedClient.isActionList = this._actionList.length > 0;
        this.service.selectedClient.isClientDocumentList = this.clientService._clientDocumentList.length > 0;
        this.setClientStatusForDropDown(this.service.selectedClient);
        this.setCloseReasonForModel(this.service.selectedClient);
        this.isLoading_client = false;
        this.commonUIService.closeMask();
      }
      else {
        this.commonUIService.closeMask();
      }

    }, () => { console.error(); this.commonUIService.closeMask() });

    this.isLoading_client = false;
  }

  private formatTaskTimeForDisplay(list) {
    if (list.length > 0)
    {
      list.forEach(ele => {
        try {          
          ele.taskTimeStr = ele.taskTime ? this.formatTimeForDisplay.ConvertTimeFormatForDisplay(ele.taskTime) : null;
        }
        catch(error){
        console.log("formatTaskTimeForDisplay:"+error);
        }
       
      });
    }    
  }

  private getAddressString(res) {
    this.addressString = '';
    if (res.data.province != null && res.data.province != '') res.data.province = res.data.province.trim();
    if (res.data.city != null && res.data.city != '') res.data.city = res.data.city.trim();

    let province = res.data.province === res.data.city ? '' : res.data.province;
    let addLine2 = '';
    if (res.data.postalCode != null && res.data.city != null) {
      addLine2 = res.data.postalCode + " " + res.data.city;
    }
    else if (res.data.postalCode != null && res.data.city == null) {
      addLine2 = res.data.postalCode;
    }
    else if (res.data.postalCode == null && res.data.city != null) {
      addLine2 = res.data.city;
    }
    let da = [res.data.addressLine1, addLine2, province];

    for (let i = 0; i < 3; i++) {
      if (da[i] && da[i] != '' && da[i] != 0 && da[i] != null) {
        this.addressString += da[i] + '\n';
      }
    }
    return this.addressString;
  }
  // Region Contact start

  openAddContactPopup(clientId) {
    const dialogViewData = this.commonUIService.setDataForModalDialog(clientId, null, null, null, null, null);
    this.commonUIService.openDialog(AddEditContactComponent, dialogViewData);
  }

  openEditContactPopup(contact) {
    const dialogViewData = this.commonUIService.setDataForModalDialog(null, null, null, contact, null, null);
    this.commonUIService.openDialog(AddEditContactComponent, dialogViewData);
  }

  async loadClientContactList(clientId) {

    this.isLoading_contact = true;
    this.clientService.loadClientContactList(clientId).subscribe(
      res => {
        if (res) {
          this._clientContactList = res.data;
          let positionList = this.commonDropdownService._positionlist;
          for (let i = 0; i < this._clientContactList.length; i++) {
            for (let j = 0; j < positionList.length; j++) {
              this._clientContactList[i].position = positionList[j].id == this._clientContactList[i].positionId ? positionList[j].description : this._clientContactList[i].position;
              const phone1 = this._clientContactList[i].phoneNumber;
              const phone2 = this._clientContactList[i].additionalPhoneNumber;
              this._clientContactList[i].phoneString = phone1 && phone2 ? phone1 + " / " + phone2 : (phone1 && !phone2 ? phone1 : null);
            }
          }
          this.service.selectedClient.isContactList = this._clientContactList.length > 0 ? true : false;
          this.isLoading_contact = false;
        }
        else this.isLoading_contact = false;
      },
      () => { console.error(); this.isLoading_contact = false; });
  }

  openDeleteContactAlert(id) {
    const deleteData = new AlertModel();
    deleteData.title = 'Delete';
    deleteData.msg = 'Are you sure to delete this record?';
    deleteData.id = id;
    deleteData.reference = 'Delete Contact';
    this.idCurrentClientContact = id;
    this.commonUIService.openAlert(deleteData);
  }

  deleteClientContact(clientContactId) {
    this.clientService.deleteClientContact(clientContactId).subscribe(
      res => {
        if (res.isSuccsess) {
          const snack = new SnackBar(SnackBarType.success, 'Client Contact successfully deleted', '', 2000);
          this.commonUIService.openSnackBar(snack);
          this.clientService.refreshClientContact.emit(res.data);
        }
      }
    )
  }
  //  Region Contact End

  //  Region Task Start
  openAddTaskPopup(client) {
    this.typeGlobal = client.type;
    let task = {
      clientId: client.id,
      userId: this._loggedinUser ? this._loggedinUser.id : null
    };
    const dialogViewData = this.commonUIService.setDataForModalDialog(client.id, null, 'clientTask', task, null, null);
    this.commonUIService.openDialog(AddEditTaskComponent, dialogViewData);
  }

  OpenEditTaskPopup(task) {
    const dialogViewData = this.commonUIService.setDataForModalDialog(null, null, 'clientTask', task, null, null);
    this.commonUIService.openDialog(AddEditTaskComponent, dialogViewData);
  }

  deleteClientTask(taskId) {
    this.clientService.isLoading_task = true;
    this.clientService.setValuesForRefresh(true, null, null, true, 'deleteTask', null, null);
    this.clientService.deleteClientTask(taskId).subscribe(
      res => {
        if (res.isSuccsess) {
          const snack = new SnackBar(SnackBarType.success, 'Client task successfully deleted', '', 2000);
          this.commonUIService.openSnackBar(snack);
          this.checkLastTask(true);
          let urlData = this.router.url;
          if (urlData == '/task') {
            this.clientService.clientProjectTaskUpdate.emit(null);
            this.loadToDoClientTaskList(this.currentClientId).then();           
          }
          if (urlData == '/client') {
            this.clientService.isLoading_task = true;
            let tasks = this._taskList.filter(t => t.id !== taskId);
            this.sharedClientService.taskUpdatedEvent.emit(tasks);
            //Don't remove this comments. Need for future developments
            // this.clientService.refreshClient.emit(this.service.selectedClient);
            this.loadToDoClientTaskList(this.currentClientId).then();
          }
          if (urlData == '/estimation') {
            //Don't remove this comments. Need for future developments
            //this.clientService.clientProjectTaskUpdate.emit(null);
            this.loadToDoClientTaskList(this.currentClientId).then();           
          }
        }        
      }
    )
  }

  changeClientTaskStatus(task) {
    this.commonUIService.openMask();
    this.clientService.changeClientTaskStatus(task.id, task.status).subscribe(
      res => {
        if (res.data === true) {
          const snack = new SnackBar(SnackBarType.success, 'Task done', '', 2000);
          this.commonUIService.openSnackBar(snack);
          this.OpenActionAfterMarkedTask(task);
          let urlData = this.router.url;
          if (urlData == '/task') {
            this.clientService.clientProjectTaskUpdate.emit(null);
            this.loadToDoClientTaskList(this.currentClientId).then();
          }
          if (urlData == '/client') {
            let tasks = this._taskList.filter(t => t.id !== task.id);
            this.sharedClientService.taskUpdatedEvent.emit(tasks);
            this.loadToDoClientTaskList(this.currentClientId).then();
          }
        } else {
          const snack = new SnackBar(SnackBarType.error, 'Error on state update', '', 2000);
          this.commonUIService.openSnackBar(snack);
        }
        this.commonUIService.closeMask();
      });
  }

  OpenActionAfterMarkedTask(task) {
    let actionSelected = this._actionTypeListForHeader.filter(m => m.actionType1.trim().toLowerCase() === task.taskType.taskTypeName.trim().toLowerCase()).length > 0
      ? this._actionTypeListForHeader.filter(m => m.actionType1.trim().toLowerCase() === task.taskType.taskTypeName.trim().toLowerCase())
      : this._actionTypeListForHeader.filter(m => m.actionType1.trim().toLowerCase() === "contract");
    if (actionSelected.length > 0) {
      let action = {
        id: actionSelected[0].id,
        actionType1: task.taskType.taskTypeName
      };
      let taskType = {
        id: task.taskTypeId,
        taskTypeName: task.taskType.taskTypeName
      };
      this.OpenAutomaticActionPopupForPopup(task.clientId, action, taskType, true, task.id);
    }
    else {
      const snack = new SnackBar(SnackBarType.success, 'Action type not exist for marked task', '', 4000);
      this.commonUIService.openSnackBar(snack);
    }
  }

  changeClientTaskDate(id, date) {
    let re = /\//gi;
    let result = date.replace(re, "-");
    this.clientService.changeClientTaskDate(id, result).subscribe(
      res => {
        if (res.data === true) {
          const snack = new SnackBar(SnackBarType.success, 'Task date updated', '', 4000);
          this.commonUIService.openSnackBar(snack);
          this.checkLastTask(true);
          let urlData = this.router.url;
          if (urlData == '/task') {
            this.clientService.clientProjectTaskUpdate.emit(null);
            this.loadToDoClientTaskList(this.currentClientId).then();
          }
          if (urlData == '/client') {
            this.loadToDoClientTaskList(this.currentClientId).then();
          }
        }
      });
  }

  async loadToDoClientTaskList(id) {
    this.clientService.isLoading_task  = true;
    this.clientService.loadToDoClientTaskList(id).subscribe(
      res => {
        if (res) {
          this._taskList = res.data;
          this.formatTaskTimeForDisplay(this._taskList);
          this._taskList.forEach(r => {
            let myStr = r.taskUserName;
            let matches = myStr.match(/\b(\w)/g);
            r.initials = matches.join('');
            r.initials = r.initials.substring(0, 2);
          });
          if (this._taskList && this._taskList.length > 0) {
            this.clientService.clientTaskCount = this._taskList.length;
          } else {
            this.clientService.clientTaskCount = null;
          }

          this.service.selectedClient.isTaskList = this._taskList.length > 0 ? true : false;
          this.sharedClientService.taskUpdatedEvent.emit(this._taskList);
          this.clientService.isLoading_task  = false;
          this.checkLastTask(true);
        }
        this.checkLastTask(true);
        this.clientService.isLoading_task  = false;
      },
      () => { console.error(); this.isLoading_task = false; });
  }
  //  Region Task End

  //Region Action Start
  OpenEditActionPopup(action) {
    const dialogViewData = this.commonUIService.setDataForModalDialog(null, null, "actionClient", action, null, null);
    this.commonUIService.openDialog(AddEditActionComponent, dialogViewData);
  }

  openDeleteClientActionAlert(id) {
    const deleteData = new AlertModel();
    deleteData.title = 'Delete';
    deleteData.msg = 'Are you sure to delete this record?';
    deleteData.id = id;
    deleteData.reference = 'Delete Action';
    this.idCurrentClientAction = id;
    this.commonUIService.openAlert(deleteData);
  }

  deleteClientAction(clientActionId) {
    this.clientService.setValuesForRefresh(true, null, null, true, 'action', null, null);

    this.clientService.deleteClientAction(clientActionId).subscribe(
      res => {
        if (res.isSuccsess) {
          const snack = new SnackBar(SnackBarType.success, 'Client Action successfully deleted', '', 2000);
          this.commonUIService.openSnackBar(snack);
          this.clientService.refreshClientAction.emit(res.data);
        }
      }
    )
  }

  OpenAutomaticActionPopupForPopup(clientId, actionType, taskType, nextAfterDone: boolean, doneTaskId: number) {
    this.showNoTaskPopup();
    let today = moment();
    let date = moment(today);
    let action = {
      clientId: clientId,
      actionTypeId: actionType.id,
      actionDate: date,
      text: "Automatic",
      userId: this._loggedinUser ? this._loggedinUser.id : null,
      author: this._loggedinUser ? this._loggedinUser.name : null,
      actionTypeName: actionType.actionType1,
      doneTaskId: doneTaskId,
      nextTaskTypeId: taskType && !nextAfterDone ? taskType.id : 0,
      nextTaskTypeName: taskType ? taskType.taskTypeName : null,
      loggedUserId: this._loggedinUser ? this._loggedinUser.id : null
    };
    if ((actionType.actionType1 != "Follow-up" && actionType.actionType1 != "Reminder" && actionType.actionType1 != "Claim" && actionType.actionType1 != "Contract" && !nextAfterDone) || nextAfterDone) {
      this._taskTypeIdForAutomatic = 0;
      const dialogViewData = this.commonUIService.setDataForModalDialog(clientId, null, 'actionClient', action, null, null);
      this.commonUIService.openDialog(AddEditActionComponent, dialogViewData);
    }
    else {
      this._actionTypeIdForAutomatic = actionType.id;
      this.SaveAutomaticClientAction(clientId, date, actionType);
    }
  }

  OpenAddActionPopup(client) {
    this.showNoTaskPopup();
    this.typeGlobal = client.type;
    let today = moment();
    let date = moment(today);
    let action = {
      clientId: client.id,
      actionDate: date,
      userId: this._loggedinUser ? this._loggedinUser.id : null,
      author: this._loggedinUser ? this._loggedinUser.name : null,
      loggedUserId: this._loggedinUser ? this._loggedinUser.id : null
    };
    const dialogViewData = this.commonUIService.setDataForModalDialog(client.id, null, 'actionClient', action, null, null);
    this.commonUIService.openDialog(AddEditActionComponent, dialogViewData);
  }

  LoadActionTypeListForHeader() {
    this.commonDropdownService.LoadActionTypeListForHeader().subscribe(data => {
      if (data) {
        this._actionTypeListForHeader = data;
        if (this._actionTypeListForHeader.length > 0) {
          this._actionTypeListForHeader.forEach(ele => {
            ele.actionType1 = ele.actionType1.trim();
          });
        }
      }
    });
  }

  SaveAutomaticClientAction(clientId, date, actionType) {
    let actionModalForSave = new ActionClient();
    actionModalForSave.clientId = clientId;
    actionModalForSave.actionTypeId = this._actionTypeIdForAutomatic;
    actionModalForSave.userId = this._loggedinUser ? this._loggedinUser.id : null;
    actionModalForSave.status = 0;
    actionModalForSave.actionName = null;
    actionModalForSave.type = actionType.actionType1;
    actionModalForSave.who = this._loggedinUser ? this._loggedinUser.name : null;
    actionModalForSave.actionDate = date;
    actionModalForSave.comment = null;
    actionModalForSave.answer = null;
    actionModalForSave.author = this._loggedinUser ? this._loggedinUser.name : null;

    this.clientService.SaveClientAction(actionModalForSave).subscribe(
      res => {
        if (res.isSuccsess) {
          const snack = new SnackBar(SnackBarType.success, 'Successfully saved', '', 4000);
          this.commonUIService.openSnackBar(snack);
          this.clientService.refreshClientAction.emit(res.data);
        }
        else {
          const snack = new SnackBar(SnackBarType.error, 'Error on save', '', 4000);
          this.commonUIService.openSnackBar(snack);
        }
      },
      () => { console.error(); });
  }
  // Region Action End

  setClientStatusForDropDown(client) {
    this.clientStatusIdModel = client.clientStatusId.toString();
    if (client.clientStatus)
      this.setClientStatusStyle(client.clientStatus.id);
    //Don't remove this comment. Need for future developments.
    //else {
    //  this.setClientStatusStyle(client.clientStatus.id);
    //}
  }

  setCloseReasonForModel(client) {
    this.isClosedReasonVisible = client.clientStatusValue === '0' && client.closeReason && client.closeReasonId;
    this.closeReasonIdForModel = client.closeReasonId ? client.closeReasonId.toString() : null;
    this.isCloseReasonRequired = this.closeReasonIdForModel === null;
  }

  setClientStatusStyle(id) {
    let clientStatusList = this.commonDropdownService._activeClientStatusList;
    clientStatusList.forEach(ele => { 
      if (!isNumber(id)) id = Number(id);
      if (ele.id === id) {
        this.selectedClientStatusStyle = {
          'background': ele.color,
          'color': '#ffffff !important'
        };
        this._clientStatusGlobal = ele.status;
      }
    });
  }

  updateClientStatus(clientId, clientObj) {
    clientObj.clientStatus = null;
    this.clientService.updateClient(clientId, clientObj).subscribe(
      res => {
        let snack: any;
        if (res.isSuccsess) {
          snack = new SnackBar(SnackBarType.success, 'Updated successfully', '', 4000);
          this.commonUIService.openSnackBar(snack);
          let savedClient: any = res.data;
          savedClient.clientStatusGlobal = this._clientStatusGlobal;
          savedClient.clientStatusValue = this._clientStatusGlobal;
          savedClient.isStatusChange = true;
          this.sharedClientService.clientStatusChangeEvent.emit(savedClient);
          //Need for future development
          //this.clientService.refreshClient.emit(savedClient);
        }
        else {
          snack = new SnackBar(SnackBarType.success, 'Errors on update', '', 4000);
          this.commonUIService.openSnackBar(snack);
        }
      },
      () => { console.error(); });
  }

  openAddEditClientPopup(clientModel) {
    this.clientService.isClientEdit = true;
    let clientForEdit = Object.assign({}, clientModel);
    clientForEdit.logoFamily = this.clientLogoFamilyGlobal;
    clientForEdit.clientStatusValue = clientModel.clientStatusValue;
    const dialogViewData = this.commonUIService.setDataForModalDialog(null, null, this.currentCompanyName, clientForEdit, null, this.currentCompanyId);
    this.commonUIService.openDialog(AddclientComponent, dialogViewData);
  }

  //#endregion

  openClientTaskDeleteAlert(id) {   
    let deleteData = new AlertModel();
    deleteData.title = 'Delete';
    deleteData.msg = 'Are you sure to delete this task?';
    deleteData.id = id;
    deleteData.reference = 'Delete client Task';
    this.idCurrentClientTask = id;
    this.commonUIService.openAlert(deleteData);
  }

  openClientDeleteAlert(id) {
    let deleteData = new AlertModel();
    deleteData.title = 'Delete';
    deleteData.msg = 'Are you sure to delete this client?';
    deleteData.id = id;
    deleteData.reference = 'Delete Client';
    this.commonUIService.openAlert(deleteData);
  }

  deleteClient(clientId) {
    this.commonUIService.openMask();
    this._clientStatusGlobal = 1;
    this.clientService.deleteClient(clientId).subscribe(
      res => {
        let snack: any;
        if (res.isSuccsess) {
          snack = new SnackBar(SnackBarType.success, res.message, '', 2000);
          this.commonUIService.openSnackBar(snack);
          this.commonUIService.closeMask();
          if (res.data) this.clientService.refreshDeletedClient.emit(true);
          else return;
        }
        else {
          this.commonUIService.closeMask();
          snack = new SnackBar(SnackBarType.success, res.message, '', 2000);
          this.commonUIService.openSnackBar(snack);
        }
      }
    )
  }

  ngOnDestroy() {
    this.subscriber.unsubscribe();
    this.cdRef.detach();
  }

  //#region document
  openAddDocumentPopup(clientId, documentType) {
    let docId: number = 0;
    if (documentType != null)
      docId = documentType.id

    const dialogViewData = this.commonUIService.setDataForModalDialog(clientId, null, this.selectedDocumentTabIndex, docId, null, null);
    this.commonUIService.openDialog(AddEditClientDocumentComponent, dialogViewData);
  }

  onChooseClientDocument(event: any): void {
    if (event) {
      let files: any = event.target.files;
      this.clientDocumentViewModel.fileList = files;
    }
  }

  selectClient() {
    this.clientService.selectedClient.subscribe(res => {
      if (this.matTabGroup)
        this.matTabGroup.selectedIndex = 0;
    })
  }

  subscribeCancelDocument() {
    this.documentService.cancelDocument.subscribe(data => {
      this.service.isLoading_clientDocument = false;
      if (this.matTabGroup && this.matTabGroup.selectedIndex != undefined)
        this.matTabGroup.selectedIndex = data;
      this.selectedDocumentTypeId = data;

    })
  }

  newDocumentCreate() {
    this.documentService.newDocument.subscribe(res => {
      var p;
      let x = res.documentTypeId;
      for (let i = 0; i < this.documentList.length; i++) {
        if (this.documentList[i].documentTypeId == x) {
          p = i;
          if (this.matTabGroup && this.matTabGroup.selectedIndex != undefined)
            this.matTabGroup.selectedIndex = i;
        }
      }
    })
  }

  documentSelect(event) {
    if (event) {
      const matTabGroupLength = this.matTabGroup._tabs.length;
      const _lengthForGetLastIndex = (matTabGroupLength - 1);
      const _selectedIndex = event.index;
      this.selectedDocumentTabIndex = _selectedIndex;
      const _selectedTab = event.tab._viewContainerRef.element.nativeElement;
      if (_selectedIndex === _lengthForGetLastIndex) {
        this.matTabGroup._tabHeader._inkBar.hide();
      }
      else {
        this.matTabGroup._tabHeader._inkBar.show();
        this.eventValue = event;
      }
      this.selectedDocumentTypeId = _selectedTab.id;
    }
  }

  openDeleteDocumentAlert(clientDocumentId) {
    this.idCurrentClientDocument = clientDocumentId;
    const deleteData = new AlertModel();
    deleteData.title = 'Delete';
    deleteData.msg = 'Are you sure to delete this record?';
    deleteData.id = clientDocumentId;
    deleteData.type = 'deleteDocument';
    deleteData.reference = 'Delete Document';
    this.commonUIService.openAlert(deleteData);
  }

  deleteClientDocument(clientDocumentId) {
    this.clientService.setValuesForRefresh(true, null, null, true, 'delete' + 'Document', null, null);
    this.clientService.deleteClientDocument(clientDocumentId).subscribe(
      res => {
        if (res.isSuccsess) {
          const snack = new SnackBar(SnackBarType.success, 'Client document successfully deleted', '', 2000);
          this.commonUIService.openSnackBar(snack);
          this.service.loadClientDocumentList(this.currentClientId).then();

        }
      }
    )
  }

  //#endregion

  copyInputMessage(val: any) {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  //#region automatic task

  LoadTaskTypeListForHeader() {
    this.commonDropdownService.LoadTaskTypeListForHeader().subscribe(data => {
      if (data) {
        this._taskTypeListForHeader = data;
        if (this._taskTypeListForHeader.length > 0) {
          this._taskTypeListForHeader.forEach(ele => {
            ele.taskTypeName = ele.taskTypeName.trim();
          });
        }
      }
    });
  }

  openAutomaticClientTaskForDropdown(clientId, item) {
    let task = {
      clientId: clientId,
      taskTypeId: this._taskTypeIdForAutomatic,
      text: "Automatic",
      userId: this._loggedinUser ? this._loggedinUser.id : null,
      taskTypeName: item.parent
    };
    if (item.label === 'Other') {
      const dialogViewData = this.commonUIService.setDataForModalDialog(clientId, null, 'clientTask', task, null, null);
      this.commonUIService.openDialog(AddEditTaskComponent, dialogViewData);
    }
    else {
      let date = item.value;
      this.SaveAutomaticClientTask(clientId, date);
    }
  }

  SaveAutomaticClientTask(clientId, date) {
    let taskModalForSave = new ClientTask();
    taskModalForSave.clientId = clientId;
    taskModalForSave.taskTypeId = this._taskTypeIdForAutomatic;
    taskModalForSave.userId = this._loggedinUser ? this._loggedinUser.id : null;
    taskModalForSave.status = 0;
    taskModalForSave.taskName = null;
    taskModalForSave.taskDate = null;
    taskModalForSave.taskDateStr = date;
    taskModalForSave.taskType = null;
    taskModalForSave.description = null;
    taskModalForSave.taskTime = null;
    this.clientService.SaveTask(taskModalForSave).subscribe(
      res => {
        if (res.isSuccsess) {
          const snack = new SnackBar(SnackBarType.success, 'Successfully saved', '', 4000);
          this.commonUIService.openSnackBar(snack);
          this.loadToDoClientTaskList(this.currentClientId).then();
        }
        else {
          const snack = new SnackBar(SnackBarType.error, 'Error on save', '', 4000);
          this.commonUIService.openSnackBar(snack);
        }
      },
      () => { console.error(); });
  }

  menuOpenedPostpone(task) {
    this.selectedTaskId = task.id;
  }

  menuClosedPostpone() {
    this.selectedTaskId = 0;
  }

  //#endregion

  showNoTaskPopup() {
    if (!this.service.selectedClient.isTaskList) {
      const snack = new SnackBar(SnackBarType.info, 'No tasks for this client', '', 5000);
      this.commonUIService.openSnackBar(snack);
    }
  }

  filterClientAndProjectTasks(event) {
    this.clientService.isLoading_task  = true;
    if (event > "0") {
      this.filterTaskModel.filterId = event;
      this.clientService.filterId = this.filterTaskModel.filterId;
    }
    else {
      if (!this.clientService.filterId) {
        this.clientService.filterId = "2";
        this.filterTaskModel.filterId = this.clientService.filterId;
      }
      else
        this.filterTaskModel.filterId = this.clientService.filterId;
    }

    this.filterTaskModel.clientId = this.currentClientId;
    this.clientService.filterTasks(this.filterTaskModel).subscribe(res => {
      if (res && res.data) {
        this._activeProjectTaskList = [];
        this._inactiveProjectTaskList = [];
        res.data.forEach(r => {
          var myStr = r.taskUserName;
          var matches = myStr.match(/\b(\w)/g);
          var x = matches.join('');
          r.initials = x;
          r.initials = r.initials.substring(0, 2);
        });
        res.data.forEach(r => {
          if (r.projectName) {
            var myStr = r.projectName;
            var matches = myStr.match(/\b(\w)/g);
            var x = matches.join('');
            r.projectInitials = x;
            r.projectInitials = r.projectInitials.substring(0, 2);
          }
        });

        if (res.data.length > 0) {
          res.data.forEach(ele => {
            let ps = ele.projectStatus;
            if (ps === 1 || ps === 2 || ps === 3 || ps === 4 || ps === 5 || ps === 6) {
              this._activeProjectTaskList.push(ele);
            }
            else {
              this._inactiveProjectTaskList.push(ele);
            }
          });
          if (this._activeProjectTaskList.length > 0)
            this._activeProjectTaskList = this._activeProjectTaskList.sort((a, b) => new Date(b.taskDate).getTime() - new Date(a.taskDate).getTime());
          if (this._inactiveProjectTaskList.length > 0)
            this._inactiveProjectTaskList = this._inactiveProjectTaskList.sort((a, b) => new Date(b.taskDate).getTime() - new Date(a.taskDate).getTime());
        }
        this.clientService.getProjectListByClientId(this.filterTaskModel.clientId).subscribe(res => {
          if (res && res.data) {
            let activeProjectList = [];
            let initialProjectList = res.data;
            initialProjectList.forEach(ele => {
              var pname = ele.projectName;
              if (pname.length > 30) {
                pname = pname.substring(0, 15);
                ele.projectName = pname + "...";
              }
              let ps = ele.projectStatus.status;
              if (ps === 1 || ps === 2 || ps === 3 || ps === 4 || ps === 5 || ps === 6) {
                activeProjectList.push(ele);
                activeProjectList.forEach(ele => {
                  if (ele.tasks && ele.tasks.length > 0) {
                    ele.projectTaskCount = ele.tasks.length
                    if (ele.projectTaskCount == 0) {
                      ele.projectTaskCount = null;
                    }
                  }
                  else
                    ele.projectTaskCount = null;
                });
              }
            });
            if (activeProjectList && activeProjectList.length > 0) {
              if (activeProjectList[0])
                activeProjectList[0].colorStyles = { 'background-color': '#0e3d93' };
              if (activeProjectList[1])
                activeProjectList[1].colorStyles = { 'background-color': '#dd8f00' };
              if (activeProjectList[2])
                activeProjectList[2].colorStyles = { 'background-color': '#b10064' };
              if (activeProjectList[3])
                activeProjectList[3].colorStyles = { 'background-color': '#760492' };
              if (activeProjectList[4])
                activeProjectList[4].colorStyles = { 'background-color': '#ddca00' };
              if (activeProjectList[5])
                activeProjectList[5].colorStyles = { 'background-color': '#dd3500' };
              activeProjectList = activeProjectList.map((item, i) => {
                if (i >= 6) {
                  item.colorStyles = { 'background-color': '#dd3500' };
                }
                return item;
              })
            }
            //set active list colors
            let ap = activeProjectList;
            if (this._activeProjectTaskList.length > 0 && ap.length > 0) {
              for (var i = 0; i < this._activeProjectTaskList.length; i++) {
                try{
                  this._activeProjectTaskList[i].colorStyles = ap.filter((k) => k.id == this._activeProjectTaskList[i].projectId)[0].colorStyles;
                }
                catch(error)
                {
                  console.log("client-view-data component=>filterClientAndProjectTasks()"+ error);
                }              
              }
            }
          }
        });
        if (this._inactiveProjectTaskList.length > 0) {
          this._inactiveProjectTaskList = this._inactiveProjectTaskList.map((item, i) => {
            item.colorStyles = { 'background-color': '#cfcfcf' };
            return item;
          })
        }
        this.clientService.isLoading_task = false;
      }
      else {
        const snack = new SnackBar(SnackBarType.error, 'Error on list', '', 2000);
        this.commonUIService.openSnackBar(snack);
        this.clientService.isLoading_task = false;
      }
    },
      err => {
        console.error();
        const snack = new SnackBar(SnackBarType.error, 'Error on list', '', 2000);
        this.commonUIService.openSnackBar(snack);
        this.clientService.isLoading_task = false;
      });

    //Filter actions
    this.clientService.filterActions(this.filterTaskModel).subscribe(res => {
      if (res && res.data) {
        this._activeProjectActionList = [];
        this._inactiveProjectActionList = [];
        res.data.forEach(r => {
          if (r.author && r.author != null) {
            const myStr = r.author;
            const matches = myStr.match(/\b(\w)/g);
            r.initials = matches.join('');
            r.initials = r.initials.substring(0, 2);
          }
        });
        res.data.forEach(r => {
          if (r.projectName) {
            var myStr = r.projectName;
            var matches = myStr.match(/\b(\w)/g);
            var x = matches.join('');
            r.projectInitials = x;
            r.projectInitials = r.projectInitials.substring(0, 2);
          }
        });

        if (res.data.length > 0) {
          res.data.forEach(ele => {
            let ps = ele.projectStatus;
            if (ps === 1 || ps === 2 || ps === 3 || ps === 4 || ps === 5 || ps === 6) {
              this._activeProjectActionList.push(ele);
            }
            else {
              this._inactiveProjectActionList.push(ele);
            }
          });
          if (this._activeProjectActionList.length > 0)
            this._activeProjectActionList = this._activeProjectActionList.sort((a, b) => new Date(b.actionDate).getTime() - new Date(a.actionDate).getTime());
          if (this._inactiveProjectActionList.length > 0)
            this._inactiveProjectActionList = this._inactiveProjectActionList.sort((a, b) => new Date(b.actionDate).getTime() - new Date(a.actionDate).getTime());
        }
        this.clientService.getProjectListByClientId(this.filterTaskModel.clientId).subscribe(res => {
          if (res && res.data) {
            let activeProjectList = [];
            let initialProjectList = res.data;
            initialProjectList.forEach(ele => {
              var pname = ele.projectName;
              if (pname.length > 30) {
                pname = pname.substring(0, 15);
                ele.projectName = pname + "...";
              }
              let ps = ele.projectStatus.status;
              if (ps === 1 || ps === 2 || ps === 3 || ps === 4 || ps === 5 || ps === 6) {
                activeProjectList.push(ele);
                activeProjectList.forEach(ele => {
                  if (ele.tasks && ele.tasks.length > 0) {
                    ele.projectTaskCount = ele.tasks.length
                    if (ele.projectTaskCount == 0) {
                      ele.projectTaskCount = null;
                    }
                  }
                  else
                    ele.projectTaskCount = null;
                });
              }
            });
            if (activeProjectList && activeProjectList.length > 0) {
              if (activeProjectList[0])
                activeProjectList[0].colorStyles = { 'background-color': '#0e3d93' };
              if (activeProjectList[1])
                activeProjectList[1].colorStyles = { 'background-color': '#dd8f00' };
              if (activeProjectList[2])
                activeProjectList[2].colorStyles = { 'background-color': '#b10064' };
              if (activeProjectList[3])
                activeProjectList[3].colorStyles = { 'background-color': '#760492' };
              if (activeProjectList[4])
                activeProjectList[4].colorStyles = { 'background-color': '#ddca00' };
              if (activeProjectList[5])
                activeProjectList[5].colorStyles = { 'background-color': '#dd3500' };
              activeProjectList = activeProjectList.map((item, i) => {
                if (i >= 6) {
                  item.colorStyles = { 'background-color': '#dd3500' };
                }
                return item;
              })
            }
            //set active list colors
            let ap = activeProjectList;
            if (this._activeProjectActionList.length > 0 && ap.length > 0) {
              for (var i = 0; i < this._activeProjectActionList.length; i++) {   
                try{
                  this._activeProjectActionList[i].colorStyles = ap.filter((k) => k.id == this._activeProjectActionList[i].projectId)[0].colorStyles;
                }      
                catch(error)     
                {
                  console.log("client-view-data.component.ts=>filterClientAndProjectTasks: "+error);//Required to track any colorStyles not loading issues
                }
               
              }
            }
          }
        });
        if (this._inactiveProjectActionList.length > 0) {
          this._inactiveProjectActionList = this._inactiveProjectActionList.map((item, i) => {
            item.colorStyles = { 'background-color': '#cfcfcf' };
            return item;
          })
        }
        this.isLoading_action = false;
      }
      else {
        const snack = new SnackBar(SnackBarType.error, 'Error on list', '', 2000);
        this.commonUIService.openSnackBar(snack);
        this.isLoading_action = false;
      }
    },
      err => {
        console.error();
        const snack = new SnackBar(SnackBarType.error, 'Error on list', '', 2000);
        this.commonUIService.openSnackBar(snack);
        this.isLoading_action = false;
      });
  }

  subscribeRefreshFilterClientAndProjectTasks() {
    this.clientService.refreshFilterClientAndProjectTasks.subscribe(data => {
      if (data == true) {
        this.filterClientAndProjectTasks(null)
      }
    })
  }

  checkLastTask(status) {
    if (status) {
      if (this._taskList && this._taskList.length == 0) {
        const snack = new SnackBar(SnackBarType.error, 'There are no more To-Do tasks', '', 2000);
        // this.commonUIService.openSnackBar(snack); // keep this line
      }
    }
  }

  //Client Documentation start
  loadTypeOfDocumentList() {
    this._loggedinUser = this.authAppService.getLoggedInUser();
    if (this._loggedinUser.userRole.length > 0)
      this._roleIds = this._loggedinUser.userRole.map(m => m.roleId);

    if (this._loggedinUser.roleId != null)
      this._roleIds.push(this._loggedinUser.roleId);

    this.clientService.getMainDocumentByClient(this.currentClientId, this._roleIds, this._loggedinUser.id).subscribe(res => {
      if (res && res.data) {
        this.typeOfDocumentList = res.data;
      }
    });
  }

  async saveClientDocumentRequest(documentType) {
    let today = moment();
    let date = moment(today);
    this.clientDocumentForSave.date = date;
    this.clientDocumentForSave.dateStr = this.clientDocumentForSave.date ? this.formatDatepicker.ConvertDateFormatForSave(this.clientDocumentForSave.date) : this.clientDocumentForSave.date;
    this.clientDocumentForSave.insertUser = this._loggedinUser.name;
    this.clientDocumentForSave.updateUser = this._loggedinUser.name;
    this.clientDocumentForSave.clientId = this.currentClientId;
    this.clientDocumentForSave.requested = true;
    this.clientDocumentForSave.main = true;
    this.clientDocumentForSave.typeOfDocumentId = documentType.id;

    this.clientService.saveClientDocumentRequest(this.clientDocumentForSave).subscribe(res => {
      if (res) {
        if (res.isSuccsess) {
          this.loadTypeOfDocumentList();
          this.clientViewService.loadClientDocumentList(this.currentClientId).then();
          const snack = new SnackBar(SnackBarType.success, 'Document request successfully saved', '', 2000);
          this.commonUIService.openSnackBar(snack);
        }
      }
    });
  }

  onChooseClientDocumentForAttach(documentType, event: any): void {
    if (event) {
      let files: any = event.target.files;
      this.clientDocumentModal.fileList = files;

      this.saveClientDocumentAttachment(documentType);
    }
  }

  async saveClientDocumentAttachment(documentType) {
    let today = moment();
    let date = moment(today);
    this.clientDocumentModal.date = date;
    this.clientDocumentModal.dateStr = this.clientDocumentModal.date ? this.formatDatepicker.ConvertDateFormatForSave(this.clientDocumentModal.date) : this.clientDocumentModal.date;
    this.clientDocumentModal.insertUser = this._loggedinUser.name;
    this.clientDocumentModal.updateUser = this._loggedinUser.name;
    this.clientDocumentModal.clientId = this.currentClientId;
    this.clientDocumentModal.requested = false;
    this.clientDocumentModal.main = true;
    this.clientDocumentModal.typeOfDocumentId = documentType.id;
    if (this.clientDocumentModal.fileList.length > 0) {
      this.clientDocumentModal.fileName = (this.clientDocumentModal.fileList[0]).name;
      this.clientDocumentModal.extension = ((this.clientDocumentModal.fileList[0]).name).split('.')[(((this.clientDocumentModal.fileList[0]).name).split('.')).length - 1];

      this.clientService.saveClientDocument(this.clientDocumentModal).subscribe(res => {
        if (res) {
          const formData: FormData = new FormData();
          formData.append('file', this.clientDocumentModal.fileList[0], res.data.generatedFileName);
          formData.append('clientId', res.data.clientId.toString());
          formData.append('fileId', res.data.id.toString());
          this.uploadClientDocument(formData, res.data.clientId);
        }
      });
    }
  }

  async updateClientDocument(documentType) {
    let today = moment();
    let date = moment(today);
    this.clientDocumentModal.updateUser = this._loggedinUser.name;
    this.clientDocumentModal.clientId = this.currentClientId;
    this.clientDocumentModal.main = false;
    this.clientDocumentModal.googleDriveId = documentType.googleDriveId;
    this.clientDocumentModal.date = documentType.date;
    this.clientDocumentModal.generatedFileName = documentType.generatedFileName;
    this.clientDocumentModal.fileName = documentType.fileName;
    this.clientDocumentModal.insertDate = documentType.insertDate;
    this.clientDocumentModal.insertUser = documentType.insertUser;
    this.clientDocumentModal.updateDate = date;
    this.clientDocumentModal.status = documentType.status;
    this.clientDocumentModal.googleDriveUrl = documentType.googleDriveUrl;
    this.clientDocumentModal.id = documentType.clientDocumentId;
    this.clientDocumentModal.typeOfDocumentId = documentType.id;
    this.clientDocumentModal.requested = documentType.requested;

    this.clientService.updateClientDocument(documentType.clientDocumentId, this.clientDocumentModal).subscribe(res => {
      if (res) {
        if (res.isSuccsess) {
          this.loadTypeOfDocumentList();
          this.clientViewService.loadClientDocumentList(this.currentClientId).then();
          const snack = new SnackBar(SnackBarType.success, 'Document request successfully updated', '', 2000);
          this.commonUIService.openSnackBar(snack);
        }
      }
    });
  }

  uploadClientDocument(formData: FormData, clientId) {
    this.commonUIService.openMask();
    this.clientService.uploadClientDocument(formData).subscribe(
      res => {
        if (+res == 200 || +(res.statusCode) == 200) {
          this.loadTypeOfDocumentList();
          this.clientViewService.loadClientDocumentList(this.currentClientId).then();
          this.documentService.newDocument.emit(this.clientDocumentModal);
          const snack = new SnackBar(SnackBarType.success, 'Client document successfully saved', '', 4000);
          this.commonUIService.openSnackBar(snack);
          this.commonUIService.closeMask();
        } else {
          const snack = new SnackBar(SnackBarType.success, 'Error on save document attachment', '', 4000);
          this.commonUIService.openSnackBar(snack);
          this.commonUIService.closeMask();
        }
      },
      () => { console.error(); this.commonUIService.closeMask(); });
  }

  deleteClientsDocument(documentType) {
    if (documentType.clientDocumentId) {
      this.clientService.deleteClientDocument(documentType.clientDocumentId).subscribe(
        res => {
          if (res.isSuccsess) {
            this.loadTypeOfDocumentList();
            this.clientViewService.loadClientDocumentList(this.currentClientId).then();
            const snack = new SnackBar(SnackBarType.success, 'Client document successfully deleted', '', 2000);
            this.commonUIService.openSnackBar(snack);
          }
        }
      )
    }
  }

  public onDownloadDocument(document): void {
    if (document.googleDriveId && document.write == false) {
      var webcontentlink = ' https://docs.google.com/uc?id=' + document.googleDriveId + '&export=download'
      window.open(webcontentlink, 'image/png');
      this.loadTypeOfDocumentList();
    } else {
      const snack = new SnackBar(SnackBarType.success, 'Permit should not editable to download the document', '', 2000);
      this.commonUIService.openSnackBar(snack);
    }
  }
  //Client Documentation end

  //Client product
  loadProductList() {
    this.productService.getProductList().subscribe(res => {
      if (res && res.data) {
        this._productList = [];
        this._productList = res.data.filter(x => x.main != null && x.main == true);
      }
      else {
        const snack = new SnackBar(SnackBarType.error, 'Error on list', '', 2000);
        this.commonUIService.openSnackBar(snack);
        this._productList = [];
      }
    },
      err => { console.error(err); }, () => { });
  } 

  openEditClientProductPopup(clientProduct) {
    const dialogViewData = this.commonUIService.setDataForModalDialog(clientProduct.id, null, null, clientProduct, null, null);
    this.commonUIService.openDialog(AddEditClientProductComponent, dialogViewData)
  }

  openAddClientPackPopup(clientProduct) {
    let clientPack = {
      clientProductId: clientProduct
    };
    const dialogViewData = this.commonUIService.setDataForModalDialog(null, null, null, clientPack, null, null);
    this.commonUIService.openDialog(AddEditClientPackComponent, dialogViewData)
  }

  openAddProductListPopup() {  
    let modalData = {'clientId':this.currentClientId};
    const dialogViewData = this.commonUIService.setDataForModalDialog(null, null, null, modalData, null, null);
    this.commonUIService.openDialog(ViewProductListComponent, dialogViewData)
  }

  openEditClientPackPopup(clientPack) {
    const dialogViewData = this.commonUIService.setDataForModalDialog(clientPack.id, null, null, clientPack, null, null);
    this.commonUIService.openDialog(AddEditClientPackComponent, dialogViewData)
  }

  subscribeRefreshClientProduct() {
    this.productService.refreshProduct.subscribe(data => {   
      if (!data) { return; }
      this.loadProductList();
    });
  } 

  //End Client product

//#region "Product vertical slide"

  slickInitProduct(e) {
    console.log('slick initialized for product header');
  }

  breakpointProduct(e) {
    console.log('breakpoint product header');
  }

  afterChangeProduct(e) {
    console.log('afterChange product header');
  }

  beforeChangeProduct(e) {
    console.log('beforeChange product header');
  }


//#endregion "Product vertical slide"

//#region "Document type vertical slide"

slickInitDocType(e) {
  console.log('slick initialized for Document type');
}

breakpointDocType(e) {
  console.log('breakpoint Documents type');
}

afterChangeDocType(e) {
  console.log('afterChange Documents type');
}

beforeChangeDocType(e) {
  console.log('beforeChange Documents type');
}


//#endregion "My Region"
}
