import { Component, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { UIService } from 'src/app/shared/services/ui.service';
import { APIService } from 'src/app/shared/services/api.service';
import { SnackBar, SnackBarType } from 'src/app/shared/components/snackbar/entities/snackbar';
import { AlertModel } from 'src/app/shared/entities/alertModel';
import { AlertService } from 'src/app/shared/services/alert.service';
import { HrmMetaDataEnum } from 'src/app/shared/enum/hrmMetadataType';
import { HrmMetaData } from 'src/app/shared/entities/hrmMetadataModel';
import { HrmMaintenanceService } from '../service/hrm-maintenance.service';
import { AddEditInsuranceComponent } from './add-edit-insurance/add-edit-insurance.component';

@Component({
  selector: 'app-insurance',
  templateUrl: './insurance.component.html',
  styleUrls: ['./insurance.component.scss']
})
export class InsuranceComponent implements OnInit {

  dataList: HrmMetaData[] = [];
  updateHrmMetadata: HrmMetaData;
  private subscriber: Subscription;
  isLoading: boolean = false;
  _idSelectedHrmMetadata: number;
  hrmMetaDataEnum = HrmMetaDataEnum;

  constructor(private uIService: UIService, private _apiService: APIService, private _hrmMaintenanceService: HrmMaintenanceService,
    private alertService: AlertService) { }

  ngOnInit() {
    this.updateHrmMetadata = new HrmMetaData();
    this.LoadDataList();
    this.subscribeAlertConfirmEvents()
    this._hrmMaintenanceService.subjectOPDRefresh.subscribe(isStatusRefesh => {
      if (isStatusRefesh) {
        this.LoadDataList();
      }
    });
  }

  subscribeAlertConfirmEvents() {
    this.subscriber = this.alertService.referenceDelete
      .subscribe(data => {
        if (data === "Delete ActionType" && this._idSelectedHrmMetadata) {
          this.deleteActionType(this._idSelectedHrmMetadata);
          console.log(this._idSelectedHrmMetadata);
        }
      });
  }

  deleteActionType(conditionId) {
    this._hrmMaintenanceService.deleteHrmMetadata(conditionId).subscribe(
      res => {
        if (res.isSuccsess) {
          var snack = new SnackBar(SnackBarType.success, res.message, "", 2000);
          this.uIService.openSnackBar(snack);
          if (res.data) this.LoadDataList();
        }
      });
  }

  addInsurance() {
    this.uIService.openDialog(AddEditInsuranceComponent, null);
  }

  editHrmMetadata(type) {
    const hrmData = Object.assign({}, type);
    const dialogViewData = this.uIService.setDataForModalDialog(null, null, null, hrmData, null, null);
    this.uIService.openDialog(AddEditInsuranceComponent, dialogViewData);
  }

  private LoadDataList() {
    //this method will change with requirement
    this.isLoading = true;
    let url = this._apiService.getEndPoint("hrmMetaData.getHrmMetadataByType", [["{type}", HrmMetaDataEnum.Insurance], ["{isOnlyActive}", false]]);
    this._apiService.get(url).subscribe(response => {
      if (response) {
        if (response.data) {
          this.dataList = response.data;
        }
      }
      this.isLoading = false;
    },
      err => {
        console.error(err);
      }, () => {
      });
  }

  activeDeactive(event, hrmMetadata: HrmMetaData) {
    hrmMetadata.status = event.checked;
    this._hrmMaintenanceService.updateHrmMetadata(hrmMetadata.id, hrmMetadata).subscribe(
      (res: { isSuccsess: any; }) => {
        if (res.isSuccsess) {
          this._hrmMaintenanceService.OPDRefresh(true);
          var snack = new SnackBar(SnackBarType.success, "Successfully updated", "", 2000);
          this.uIService.openSnackBar(snack);
        }
        else {
          snack = new SnackBar(SnackBarType.success, "Error on update", "", 2000);
          this.uIService.openSnackBar(snack);
        }
      },
      () => { console.error(); });
  }

  openAlertDelete(id) {
    let deleteData = new AlertModel();
    deleteData.title = "Delete";
    deleteData.msg = "Are you sure to delete this record?";
    deleteData.id = id;
    deleteData.reference = "Delete ActionType";
    this._idSelectedHrmMetadata = id;
    this.uIService.openAlert(deleteData);
  }

  ngOnDestroy(): void {
    this.subscriber.unsubscribe();
  }

}
