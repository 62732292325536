import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { UIService, DialogDataForModalView } from 'src/app/shared/services/ui.service';
import { ProjectDocuments } from 'src/app/client/entities/projectDocument';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CommonDropdownService } from 'src/app/shared/services/common.dropdown.service';
import { FormatMatDatepicker } from 'src/app/shared/format-datepicker';
import { ClientService } from 'src/app/client/services/client.service';
import { SnackBar, SnackBarType } from 'src/app/shared/components/snackbar/entities/snackbar';
import { Subject } from 'rxjs';
import {DocumentService} from '../../../services/document.service';
import { ProjectViewService } from '../../../services/project-view.service';
import moment from 'moment';
import { Users } from 'src/app/auth/entities/user';
import { AuthAppService } from 'src/app/shared/services/auth-app.service';

@Component({
  selector: 'app-add-edit-project-document',
  templateUrl: './add-edit-project-document.component.html',
  styleUrls: ['./add-edit-project-document.component.scss']
})
export class AddEditProjectDocumentComponent implements OnInit {

  projectDocumentFormGroup: FormGroup;
  _projectDocumentModal: ProjectDocuments;
  _projectDocumentForSave: ProjectDocuments;
  toggle = true;
  private _unsubscribeAll: Subject<any>;
  _loggedinUser: Users = new Users();

  constructor(
    private _formBuilder: FormBuilder,
    private commonUIService: UIService,
    private validationService: ValidationService,
    public dialogRef: MatDialogRef<AddEditProjectDocumentComponent>,
    @Inject(MAT_DIALOG_DATA) public modalViewData: DialogDataForModalView,
    private formatDatepicker: FormatMatDatepicker,
    public commonDropdownService: CommonDropdownService,
    private clientService: ClientService,
    private projectViewService: ProjectViewService,
    private documentService:DocumentService,
    private authAppService: AuthAppService
  ) {
    this._projectDocumentModal = new ProjectDocuments();
    this._projectDocumentForSave = new ProjectDocuments();
    this._unsubscribeAll = new Subject();

  }

  ngOnInit() {
    this.initProjectDocumezFormGroup();
    this.commonDropdownService.LoadDocumentTypeListForCRM();
    this.getLoggedinUser();
  }
  closeDialog(): void {
    this.dialogRef.close();
  }
  initProjectDocumezFormGroup() {
    this.projectDocumentFormGroup = this._formBuilder.group({
      documentName: ['', [Validators.required, this.validationService.noWhitespaceValidator]],
      date: ['', [Validators.required]],
      typeOfDocumentId: ['', [Validators.required]],
      file: [null, [Validators.required]]
    });
    let today = moment();
    let date = moment(today);
    this._projectDocumentModal.initialDate = date;
    if (this.modalViewData.data.projectId != 0)
      this._projectDocumentModal.typeOfDocumentId = this.modalViewData.data.projectId;
  }
  onChooseProjectDocument(event: any): void {
    if (event) {
      let files: any = event.target.files;
      this._projectDocumentModal.fileList = files;
      this.toggle = true;
    }
  }
  async submit(ProjectDocument: ProjectDocuments) {
    let valid = this.projectDocumentFormGroup.get('file').touched && this.projectDocumentFormGroup.get('file').valid;
    this.toggle = valid;   

    this.projectDocumentFormGroup.get('file').markAsTouched();
    if (this.projectDocumentFormGroup.invalid) {
      return;
    }
    this._projectDocumentForSave = Object.assign({},ProjectDocument);
    this._projectDocumentForSave.dateStr = ProjectDocument.initialDate
      ? this.formatDatepicker.ConvertDateFormatForSave(ProjectDocument.initialDate) : ProjectDocument.initialDate;
    this.onSaveClientDocument().then();
  }

  async onSaveClientDocument() {
    if (this._projectDocumentForSave) {
      if (this.modalViewData.data.id) {
        this._projectDocumentForSave.projectId = this.modalViewData.data.id;
        if (this._projectDocumentForSave.fileList.length > 0) {
          this._projectDocumentForSave.fileName = (this._projectDocumentForSave.fileList[0]).name;
          this._projectDocumentForSave.extension = ((this._projectDocumentForSave.fileList[0]).name).split('.')[(((this._projectDocumentForSave.fileList[0]).name).split('.')).length - 1];
          this._projectDocumentForSave.insertUser = this._loggedinUser.id.toString();
          await this.clientService.saveProjectDocument(this._projectDocumentForSave).subscribe(res => {
            if (res) {
              const formData: FormData = new FormData();
              formData.append('file', this._projectDocumentModal.fileList[0], res.data.generatedFileName);
              formData.append('projectId', res.data.projectId.toString());
              formData.append('fileId', res.data.id.toString());
              this.uploadClientDocument(formData);
            }
          });
          this.closeDialog();
        }
      }
    }
  }

  uploadClientDocument(formData: FormData) {
    this.clientService.uploadClientDocument(formData).subscribe(
      res => {
        if (+res == 200 || +(res.statusCode) == 200) {
          this.clientService.newProjectDocument.emit(this._projectDocumentForSave);
          this.documentService.refreshProjectDocument.emit(null);
          const snack = new SnackBar(SnackBarType.success, 'Successfully saved', '', 4000);
          this.commonUIService.openSnackBar(snack);
          this.closeDialog();
        } else {
          const snack = new SnackBar(SnackBarType.success, 'Error on save', '', 4000);
          this.commonUIService.openSnackBar(snack);
        }
      },
      () => { console.error(); this.closeDialog(); });
  }

  closeDialogBox() {
    if (this.modalViewData && this.modalViewData.data.type == undefined) {
      this.modalViewData.data.type = 0;
    }
    this.clientService.cancelProjectDocument.emit(this.modalViewData.data.type);
  }

  getLoggedinUser() {
    this._loggedinUser = this.authAppService.getLoggedInUser();
  }
}
