export class FurnitureModel{
  floorId: number;
  categoryId: number;
  name: string;
  furnitureCount: number;
}

export class FloorFurnitureModel {
  floorId: number;
  furnitureId: number;
  furnitureCount: number;
}
