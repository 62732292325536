import { Component, OnInit } from '@angular/core';
import { CommonDropdownService } from '../../shared/services/common.dropdown.service';
import { TechnologiesModel } from '../../shared/entities/technologiesModel';
import { APIService } from 'src/app/shared/services/api.service';
import API from '../../shared/services/api.routes.json';
import { UIService } from '../../shared/services/ui.service';
import { DialogPopupModel } from '../../shared/entities/dialogPopupModel';
import { MaintenanceService } from '../service/maintenance.service';
import { AlertModel } from '../../shared/entities/alertModel';
import { Subscription } from 'rxjs';
import { AlertService } from '../../shared/services/alert.service';
import { AddEditTechnologiesComponent } from './add-edit-technologies/add-edit-technologies.component';
import { MatDialog, MatTabGroup } from '@angular/material';
import { SnackBarType, SnackBar } from 'src/app/shared/components/snackbar/entities/snackbar';

@Component({
  selector: 'app-technologies',
  templateUrl: './technologies.component.html',
  styleUrls: ['./technologies.component.scss']
})

export class TechnologiesComponent implements OnInit {
  _technologiesList: TechnologiesModel[] = [];
  technologiesList: TechnologiesModel[] = [];
  updateTechnlogy: TechnologiesModel;
  private subscriber: Subscription;
  _idSelectedTechnology: number;
  isLoading: boolean = false;

  constructor(private _apiService: APIService,
    private uIService: UIService,
    public dialog: MatDialog,
    private commonUIService: UIService,
    private maintananceService: MaintenanceService,
    private alertService: AlertService) {
    this._technologiesList = [];
    this.technologiesList = [];

  }

  ngOnInit() {
    this.updateTechnlogy = new TechnologiesModel();
    this.LoadTechnologyList();
    this.maintananceService.subjectTechnolgyRefersh.subscribe(isRefesh => {
      if (isRefesh) {
        this.LoadTechnologyList();
      }
    });
    this.subscribeAlertConfirmEvents();
  }

  subscribeAlertConfirmEvents() {
    this.subscriber = this.alertService.referenceDelete.subscribe(data => {
      if (data && data === "Delete Technology") {
        this.deleteTechnology(this._idSelectedTechnology);
      }
    });
  }

  private LoadTechnologyList() {
    this.isLoading = true;
    this._apiService.get(API.technologies.listAll).subscribe(response => {
      if (response) {
        if (response.data) {
          this.technologiesList = response.data;
          this._technologiesList = this.technologiesList.sort((a, b) => a.name.localeCompare(b.name));
        }
      }
      this.isLoading = false;
    }, err => {
      this.isLoading = false;
      console.error(err);
    }, () => {
    });
  }

  addTechnology() {
    this.commonUIService.openDialog(AddEditTechnologiesComponent, null)
  }

  editTechnology(tech: any) {
    const technology = Object.assign({}, tech);
    const dialogViewData = this.commonUIService.setDataForModalDialog(null, null, null, technology, null, null);
    this.commonUIService.openDialog(AddEditTechnologiesComponent, dialogViewData);
  }

  openAlertDeleteTechnology(id: number) {
    let deleteData = new AlertModel();
    deleteData.title = "Delete";
    deleteData.msg = "Are you sure to delete this record?";
    deleteData.id = id;
    deleteData.reference = "Delete Technology";
    this._idSelectedTechnology = id;
    this.commonUIService.openAlert(deleteData);
  }

  deleteTechnology(id) {
    this.maintananceService.deleteTechnology(id).subscribe(
      res => {
        if (res.isSuccsess) {
          var snack = new SnackBar(SnackBarType.success, res.message, "", 2000);
          this.uIService.openSnackBar(snack)
          if (res.data) this.LoadTechnologyList();
        }
        else {
          var snack = new SnackBar(SnackBarType.error, "Error", "", 2000);
          this.uIService.openSnackBar(snack);
        }
      }
    )
  }
  activeDeactive(event, model) {
    model.active = event.checked;
    this.maintananceService.updateTechnology(model.id, model).subscribe(
      (res: { isSuccsess: any; }) => {
        if (res.isSuccsess) {
          var snack = new SnackBar(SnackBarType.success, "Successfully updated", "", 2000);
          this.uIService.openSnackBar(snack);
          this.maintananceService.technologyRefersh(true);
        }
        else {
          snack = new SnackBar(SnackBarType.success, "Error on update", "", 2000);
          this.uIService.openSnackBar(snack);
        }
      },
      () => { console.error(); });
  }
}
