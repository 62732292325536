import { Component, OnInit } from '@angular/core';
import { countryModel } from 'src/app/shared/entities/countryModel';
import { UIService } from 'src/app/shared/services/ui.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { MaintenanceService } from '../service/maintenance.service';
import { APIService } from 'src/app/shared/services/api.service';
import API from '../../shared/services/api.routes.json';
import { AlertModel } from 'src/app/shared/entities/alertModel';
import { SnackBarType, SnackBar } from 'src/app/shared/components/snackbar/entities/snackbar';
import { AddEditCountryComponent } from './add-edit-country/add-edit-country.component';

@Component({
  selector: 'app-country',
  templateUrl: './country.component.html',
  styleUrls: ['./country.component.scss']
})

export class CountryComponent implements OnInit {
  _CountryList: countryModel[] = [];
  countryList: countryModel[] = [];
  updateCountry: countryModel;
  _idSelectedCountry;
  isLoading: boolean = false;

  constructor(private uIService: UIService,
    private _apiService: APIService,
    private alertService: AlertService,
    private maintananceService: MaintenanceService) {
    this._CountryList = [];
  }

  ngOnInit() {
    this.updateCountry = new countryModel();
    this.LoadCountry();
    this.subscribeCountryList();
    this.maintananceService.subjectCountryRefresh.subscribe(isRefresh => {
      if (isRefresh) {
        this.LoadCountry();
      }
    });
  }

  subscribeCountryList() {
    this.alertService.referenceDelete.subscribe(data => {
      if (data == "Delete Country") {
        this.deleteCountryConfirm(this._idSelectedCountry);
      }
    });
  }

  addCountry() {
    this.uIService.openDialog(AddEditCountryComponent, null);
  }

  deleteCountry(id) {
    let deleteData = new AlertModel();
    deleteData.title = "Delete";
    deleteData.msg = "Are you sure to delete this record?";
    deleteData.id = id;
    deleteData.reference = "Delete Country";
    this._idSelectedCountry = id;
    this.uIService.openAlert(deleteData);
  }

  editCountry(data) {
    const setdataCountry = Object.assign({}, data);
    const dialogViewData = this.uIService.setDataForModalDialog(null, null, null, setdataCountry, null, null);
    this.uIService.openDialog(AddEditCountryComponent, dialogViewData);
  }

  LoadCountry() {
    this.isLoading = true;
    this._apiService.get(API.country.listAll).subscribe(countrydata => {
      if (countrydata) {
        if (countrydata.data) {
          this.countryList = countrydata.data;
          this._CountryList = this.countryList.sort((a, b) => a.name.localeCompare(b.name));
        }
      }
      this.isLoading = false;
    });
  }

  deleteCountryConfirm(id) {
    this.maintananceService.deleteCountry(id).subscribe(data => {
      if (data.isSuccsess) {
        var snack = new SnackBar(SnackBarType.success, data.message, "", 2000);
        this.uIService.openSnackBar(snack);
        if (data.data) this.LoadCountry()
      }
      else {
        var snack = new SnackBar(SnackBarType.error, "Error", "", 2000);
        this.uIService.openSnackBar(snack);
      }
    });
  }

  activeDeactive(event, model) {
    model.status = event.checked;
    this.maintananceService.UpdateCountry(model).subscribe(
      (res: { isSuccsess: any; }) => {
        if (res.isSuccsess) {
          this.maintananceService.CountryRefresh(true);
          var snack = new SnackBar(SnackBarType.success, "Successfully updated", "", 2000);
          this.uIService.openSnackBar(snack);
        }
        else {
          snack = new SnackBar(SnackBarType.success, "Error on update", "", 2000);
          this.uIService.openSnackBar(snack);
        }
      },
      () => { console.error(); });
  }
}
