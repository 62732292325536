import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SnackBar, SnackBarType } from 'src/app/shared/components/snackbar/entities/snackbar';
import { InventoryMetaData } from 'src/app/shared/entities/inventoryMetadata';
import { InventoryMetadataType } from 'src/app/shared/enum/inventoryMetadataType';
import { DialogDataForModalView, UIService } from 'src/app/shared/services/ui.service';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { InventoryMaintenanceService } from '../../service/inventory-maintenance.service';

@Component({
  selector: 'app-add-edit-monitoros',
  templateUrl: './add-edit-monitoros.component.html',
  styleUrls: ['./add-edit-monitoros.component.scss']
})
export class AddEditMonitorosComponent implements OnInit {

  isEdit: boolean = false;
  inventoryMetadataFormGroup: FormGroup;
  inventoryMetadata: InventoryMetaData;
  isDisabledSave = false;


  constructor(private uIService: UIService,
    private _formBuilder: FormBuilder,
    private validationService: ValidationService,
    public dialogRef: MatDialogRef<AddEditMonitorosComponent>,
    private _inventoryMaintenanceService: InventoryMaintenanceService,
    @Inject(MAT_DIALOG_DATA) public conditionData: DialogDataForModalView) { }

  ngOnInit() {
    this.initFormGroup();
    this.inventoryMetadata = new InventoryMetaData();
    this.setActionTypeDataForEditView();
  }

  initFormGroup() {
    this.inventoryMetadataFormGroup = this._formBuilder.group({
      name: ['', [Validators.required, this.validationService.noWhitespaceValidator]],
    })
  }

  setActionTypeDataForEditView() {
    if (this.conditionData.data) {
      this.inventoryMetadata = Object.assign({}, this.conditionData.data).modalData;
      this.isEdit = true;
    } else {
      this.inventoryMetadata.type = InventoryMetadataType.monitorOS;
    }
  }

  async submit(condition: { id: any; }) {
    if (this.inventoryMetadataFormGroup.invalid) {
      this.isDisabledSave = false;
      return;
    }
    if (this.isEdit) this.updateActionType(condition.id, condition);
    else
      this.saveActionType(condition);
  }

  saveActionType(actionType: { id: any; }) {
    console.log(actionType);
    this.isDisabledSave = true;
    this._inventoryMaintenanceService.saveInventoryMetadata(actionType).subscribe(
      (res: { isSuccsess: any; }) => {
        if (res.isSuccsess) {
          var snack = new SnackBar(SnackBarType.success, "Successfully saved", "", 2000);
          this.uIService.openSnackBar(snack);
          this.closeDialog();
          this._inventoryMaintenanceService.monitorOsRefresh(true);
        }
        else {
          snack = new SnackBar(SnackBarType.success, "Error on save", "", 2000);
          this.uIService.openSnackBar(snack);
        }
      },
      () => { console.error(); this.closeDialog(); });
  }

  updateActionType(id: any, obj: { id: any; }) {
    this._inventoryMaintenanceService.updateInventoryMetadata(id, obj).subscribe(
      (res: { isSuccsess: any; }) => {
        if (res.isSuccsess) {
          var snack = new SnackBar(SnackBarType.success, "Successfully updated", "", 2000);
          this.uIService.openSnackBar(snack);
          this.closeDialog();
          this._inventoryMaintenanceService.monitorOsRefresh(true);
        }
        else {
          snack = new SnackBar(SnackBarType.success, "Error on update", "", 2000);
          this.uIService.openSnackBar(snack);
        }
      },
      () => { console.error(); this.closeDialog(); });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

}
