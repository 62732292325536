import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SnackBar, SnackBarType } from 'src/app/shared/components/snackbar/entities/snackbar';
import { HrmMetaData } from 'src/app/shared/entities/hrmMetadataModel';
import { HrmMetaDataEnum, MainLeaveTypes } from 'src/app/shared/enum/hrmMetadataType';
import { DialogDataForModalView, UIService } from 'src/app/shared/services/ui.service';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { HrmMaintenanceService } from '../../service/hrm-maintenance.service';

@Component({
  selector: 'app-add-edit-leave',
  templateUrl: './add-edit-leave.component.html',
  styleUrls: ['./add-edit-leave.component.scss']
})
export class AddEditLeaveComponent implements OnInit {

  isEdit: boolean = false;
  hrmMetadataFormGroup: FormGroup;
  hrmMetadata: HrmMetaData;
  isDisabledSave = false;
  hrmMetaDataEnum: HrmMetaDataEnum;
  isDescriptionEditable: boolean = true;

  constructor(private uIService: UIService,
    private _formBuilder: FormBuilder,
    private validationService: ValidationService,
    public dialogRef: MatDialogRef<AddEditLeaveComponent>,
    private _hrmMaintenanceService: HrmMaintenanceService,
    @Inject(MAT_DIALOG_DATA) public conditionData: DialogDataForModalView) { }

  ngOnInit() {
    this.initFormGroup();
    this.hrmMetadata = new HrmMetaData();
    this.setActionTypeDataForEditView();
  }

  initFormGroup() {
    this.hrmMetadataFormGroup = this._formBuilder.group({
      value: ['', [Validators.required, this.validationService.noWhitespaceValidator]],
      description: ['', [Validators.required, this.validationService.noWhitespaceValidator]],
      color:  ['', [Validators.required]],
    })
  }

  setActionTypeDataForEditView() {
    if (this.conditionData.data) {
      this.hrmMetadata = Object.assign({}, this.conditionData.data).modalData;
      this.isEdit = true;
      this.checkForDeleteNEdit(this.hrmMetadata.description)
    } else {
      this.hrmMetadata.type = HrmMetaDataEnum.Leave;
    }
  }

  async submit(condition) {
    if ((condition.description.toLowerCase().replace(/\s/g, "") === MainLeaveTypes.AnnualLeave
      || condition.description.toLowerCase().replace(/\s/g, "") === MainLeaveTypes.CasualLeave
      || condition.description.toLowerCase().replace(/\s/g, "") === MainLeaveTypes.SickLeave) && !this.isEdit) {
      var snack = new SnackBar(SnackBarType.success, "Cannot duplicate Main Leave type name", "", 2000);
      this.uIService.openSnackBar(snack);
    } else {
      if (this.hrmMetadataFormGroup.invalid) {
        this.isDisabledSave = false;
        return;
      }
      if (this.isEdit) this.updateActionType(condition.id, condition);
      else
        this.saveActionType(condition);

    }

  }

  saveActionType(actionType: { id: any; }) {
    console.log(actionType);
    this.isDisabledSave = true;
    this._hrmMaintenanceService.saveHrmMetadata(actionType).subscribe(
      (res: { isSuccsess: any; }) => {
        if (res.isSuccsess) {
          var snack = new SnackBar(SnackBarType.success, "Successfully saved", "", 2000);
          this.uIService.openSnackBar(snack);
          this.closeDialog();
          this._hrmMaintenanceService.AnnualRefresh(true);
        }
        else {
          snack = new SnackBar(SnackBarType.success, "Error on save", "", 2000);
          this.uIService.openSnackBar(snack);
        }
      },
      () => { console.error(); this.closeDialog(); });
  }

  public checkForDeleteNEdit(description: string): void {
    switch (description.toLowerCase().replace(/\s/g, "")) {
      case MainLeaveTypes.AnnualLeave:
        this.isDescriptionEditable = false;
        break;
      case MainLeaveTypes.CasualLeave:
        this.isDescriptionEditable = false;
        break;
      case MainLeaveTypes.SickLeave:
        this.isDescriptionEditable = false;
        break;
      default:
        this.isDescriptionEditable = true;
        break;
    }
  }

  updateActionType(id: any, obj: { id: any; }) {
    this._hrmMaintenanceService.updateHrmMetadata(id, obj).subscribe(
      (res: { isSuccsess: any; }) => {
        if (res.isSuccsess) {
          var snack = new SnackBar(SnackBarType.success, "Successfully updated", "", 2000);
          this.uIService.openSnackBar(snack);
          this.closeDialog();
          this._hrmMaintenanceService.AnnualRefresh(true);
        }
        else {
          snack = new SnackBar(SnackBarType.success, "Error on update", "", 2000);
          this.uIService.openSnackBar(snack);
        }
      },
      () => { console.error(); this.closeDialog(); });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

}
