import {Component, OnDestroy, OnInit} from '@angular/core';
import { PermissionModel } from '../entities/permission';
import { roleModel } from '../entities/role';
import { MaintenanceService } from 'src/app/maintenance/service/maintenance.service';
import { APIService } from 'src/app/shared/services/api.service';
import API from '../../shared/services/api.routes.json';
import { RolePermissionModel } from '../entities/rolePermission';
import { ClientService } from 'src/app/client/services/client.service';
import { SnackBar, SnackBarType } from 'src/app/shared/components/snackbar/entities/snackbar';
import { UIService } from 'src/app/shared/services/ui.service';
import * as $ from "jquery";

@Component({
  selector: 'app-permits',
  templateUrl: './permits.component.html',
  styleUrls: ['./permits.component.scss']
})
export class PermitsComponent implements OnInit, OnDestroy {

  permitsList: PermissionModel[] = [];
  roleList: roleModel[] = [];
  rolePermissionList: RolePermissionModel[] = [];
  rolePermission: RolePermissionModel;
  permitsGroupList: any[] = [];
  constructor(
    private maintenanceService: MaintenanceService,
    private _apiService: APIService,
    private _clientService: ClientService,
    private uIService: UIService
  ) {
    this.permitsList = [];
    this.roleList = [];
    this.rolePermissionList = [];
    this.rolePermission = new RolePermissionModel();
  }

  ngOnInit() {
    this.loadPermission();
    // $('app-root').css('overflow-x', 'scroll');
  }

  ngOnDestroy(){
    // $('app-root').css('overflow-x', 'unset');
  }

  loadPermission() {
    this._apiService.get(API.permission.listAll).subscribe(response => {
      if (response) {
        if (response.data) {
          this.permitsList = response.data;
          this.permitsGroupList = this.loadPermissionGroupList();
          this.loadRoleList();
        }
      }
    });
  }

  loadPermissionGroupList() {
    let groupedPermissionList = [];
    let groupList : any = [];
    if(this.permitsList.length > 0){
      groupList = this.permitsList.reduce((gro, current) => {
        const x = gro.find(item => item.group.trim() === current.group.trim());
        if (!x) {
          return gro.concat([current]);
        } else {
          return gro;
        }
      }, []);
    }
    if(groupList.length > 0){
      groupList.forEach(ele => {
        let groupedPermission = {
          groupName: ele.group,
          permitsList : this.permitsList.filter(x => x.group.trim() === ele.group.trim())
        };
        groupedPermissionList.push(groupedPermission);
      });
    }
    return(groupedPermissionList);
  }

  loadRoleList() {
    this._apiService.get(API.role.listAll).subscribe(response => {
      if (response && response.data) {
        this.roleList = response.data;
        this.loadRolePermission();
      }
    });
  }

  loadRolePermission() {
    this._apiService.get(API.rolePermission.listAll).subscribe(response => {
      if (response && response.data) {
        this.loadPermissionToID(response.data);
      }
    });
  }

  loadPermissionToID(data) {
    let arr = [];
    data.forEach(element => {
      arr[element.permissionID] = element.roles;
    });
    this.rolePermissionList = arr;
  }

  checkPermit(permissionId, rolesId) {
    return this.rolePermissionList.length > 0 ? (this.rolePermissionList[permissionId] as any).includes(rolesId) : false;
  }

  changePermissions(permissionId, rolesId, event) {
    this.rolePermission.permissionId = permissionId;
    this.rolePermission.roleId = rolesId;
    if (event.checked) {
      this._clientService.saveRolePermission(this.rolePermission).subscribe(res => {
        if (res.isSuccsess) {
          let snack = new SnackBar(SnackBarType.success, res.message, "", 2000);
          this.uIService.openSnackBar(snack);
        }
        else {
          let snack = new SnackBar(SnackBarType.success, "Error on update", "", 2000);
          this.uIService.openSnackBar(snack);
        }
      })
    }
    else {
      this._clientService.deleteRolePermission(null, rolesId, permissionId).subscribe(res => {
        if (res.text = "Successfully deleted") {
          let snack = new SnackBar(SnackBarType.success, "Successfully updated", "", 2000);
          this.uIService.openSnackBar(snack);
        }
        else {
          let snack = new SnackBar(SnackBarType.success, "Error on update", "", 2000);
          this.uIService.openSnackBar(snack);
        }
      })
    }
  }
}


