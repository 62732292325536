import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UIService, DialogDataForModalView } from 'src/app/shared/services/ui.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SnackBar, SnackBarType } from 'src/app/shared/components/snackbar/entities/snackbar';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/auth/login/login.service';
import { Users } from 'src/app/auth/entities/user';
import { CommonDropdownService } from '../../../shared/services/common.dropdown.service';
import moment from 'moment';
import { SharedHeaderService } from '../../../shared/services/shared.header.services';
import { ClientProduct } from '../../entities/clientProduct';
import { isNumber } from 'util';
import { FormatMatDatepicker } from '../../../shared/format-datepicker';
import { ProductService } from '../../service/product.service';
import { ClientService } from 'src/app/client/services/client.service';

@Component({
  selector: 'app-add-edit-client-product',
  templateUrl: './add-edit-client-product.component.html',
  styleUrls: ['./add-edit-client-product.component.scss']
})

export class AddEditClientProductComponent implements OnInit {

  isEdit: boolean = false;
  clientProductFormGroup: FormGroup;
  _clientProductModal: ClientProduct;
  isDisabledClientProduct: boolean = false;

  constructor(
    private _formBuilder: FormBuilder,
    public commonUIService: UIService,
    private uIService: UIService,
    private route: Router,
    private loginServic: LoginService,
    private validationSerivce: ValidationService,
    public sharedHeaderService: SharedHeaderService,
    private formatDatepicker: FormatMatDatepicker,
    public productService: ProductService,
    private commonDropdownService: CommonDropdownService,
    public dialogRef: MatDialogRef<AddEditClientProductComponent>,
    private clientService: ClientService,
    @Inject(MAT_DIALOG_DATA) public modalViewData: DialogDataForModalView) {
  }

  ngOnInit() {
    this.initFormGroup();
    this.setClientProductDataForEditView();
  }

  initFormGroup() {
    this.clientProductFormGroup = this._formBuilder.group({
      state: ['', [Validators.required]],
      proposedDate: [''],
      rejectDate:[''],
      confirmDate:[''],
      comments:[''],
      active:[''],
    });
  }

  setClientProductDataForEditView(): void {   
    if (this.modalViewData.data) {
      this._clientProductModal = Object.assign({}, this.modalViewData.data.modalData);
      if (isNumber(this.modalViewData.data.id)) {
        this.isEdit = true;
      } else {
        this._clientProductModal.productName = this.modalViewData.data.modalData.productName;
        this._clientProductModal.productId = this.modalViewData.data.modalData.id;
        this.isEdit = false;
      }
    }
  }

  async saveClientProduct(state) { 
    if (this.clientProductFormGroup.errors!=null) {
      this.isDisabledClientProduct = false;
      return;
    }
    let clientProductObj = new ClientProduct();

    if(this._clientProductModal!=null)
    {
      clientProductObj = Object.assign({},this._clientProductModal);
    }

    clientProductObj.proposedDateStr = clientProductObj.proposedDate ? this.formatDatepicker.ConvertDateFormatForSave(clientProductObj.proposedDate) : clientProductObj.proposedDate;
    clientProductObj.rejectDateStr = clientProductObj.rejectDate ? this.formatDatepicker.ConvertDateFormatForSave(clientProductObj.rejectDate) : clientProductObj.rejectDate;
    clientProductObj.confirmDateStr = clientProductObj.confirmDate ? this.formatDatepicker.ConvertDateFormatForSave(clientProductObj.confirmDate) : clientProductObj.confirmDate;
    clientProductObj.state = this.getStateIdByName(state);
    clientProductObj.clientId = this.clientService._clientId;
    if (this.isEdit) {
      this.updateClientProduct(this._clientProductModal.id, clientProductObj);
    } else {
      clientProductObj.id= 0; //If it is a new row, the PK id should be set as '0'(Id is auto incremented)
      this.addClientProduct(clientProductObj);
    }
  }

  addClientProduct(clientProductObj) {
    this.isDisabledClientProduct = true;
    this.productService.saveClientProductData(clientProductObj)
    .subscribe(
      res => {      
        if (res.isSuccsess) {
          this.closeDialog();
          this.clientService.refreshMappedProducts.emit(res.data);
          this.productService.refreshProduct.emit(res.data);
          const snack = new SnackBar(SnackBarType.success, 'Successfully saved', '', 2000);
          this.commonUIService.openSnackBar(snack);
        }
        else{
          const snack = new SnackBar(SnackBarType.error, res.message, '', 2000);
          this.commonUIService.openSnackBar(snack);
        }
      },error => {        
        console.error(); 
        this.closeDialog();
        const snack = new SnackBar(SnackBarType.error, 'An unexpected error occurred', '', 2000);
        this.commonUIService.openSnackBar(snack);
      });
  }

  updateClientProduct(clientProductId, clientProductObj) {
    this.productService.updateClientProduct(clientProductId, clientProductObj).subscribe(
      res => {
        if (res.isSuccsess) {
          this.clientService.refreshMappedProducts.emit(res.data);
          this.productService.refreshProduct.emit(res.data);
          const snack = new SnackBar(SnackBarType.success, 'Successfully updated', '', 2000);
          this.commonUIService.openSnackBar(snack);
          this.closeDialog();
        }
      },error => {
        console.error(); 
        this.closeDialog();
        const snack = new SnackBar(SnackBarType.error, 'An unexpected error occurred', '', 2000);
        this.commonUIService.openSnackBar(snack);
      });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  getStateIdByName(stateName):number{
    let result = null;
    switch(stateName.toLowerCase()){
    case "proposed":
      result=0;
      break;
    case "contracted":
        result=1;
        break;
    case "finished":
          result=2;
          break;
    case "rejected":
          result=-1;
          break;      
    }
    return result;

  }
}
