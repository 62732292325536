import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ProjectAction } from 'src/app/client/entities/projectAction';
import { ClientService } from 'src/app/client/services/client.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatDatepicker } from '@angular/material';
import { DialogDataForModalView, UIService } from 'src/app/shared/services/ui.service';
import { FormatMatDatepicker } from 'src/app/shared/format-datepicker';
import { CommonDropdownService } from 'src/app/shared/services/common.dropdown.service';
import { isNumber } from 'util';
import { SnackBarType, SnackBar } from 'src/app/shared/components/snackbar/entities/snackbar';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { TaskService } from 'src/app/client/services/task.service';
import { AlertModel } from 'src/app/shared/entities/alertModel';
import { AlertService } from 'src/app/shared/services/alert.service';
import { Subscription } from 'rxjs';
import {ProjectViewService} from '../../../services/project-view.service';

@Component({
  selector: 'app-add-edit-project-action',
  templateUrl: './add-edit-project-action.component.html',
  styleUrls: ['./add-edit-project-action.component.scss']
})
export class AddEditProjectActionComponent implements OnInit {
  projectActionFormGroup: FormGroup;
  _projectActionModal: ProjectAction;
  _projectActionModalForSave: any;
  isEdit: boolean = false;
  isDisabledAction = false;
  _taskCreationType: string = "";
  maxDate: Date = new Date();
  _actionTypeListForIcon : any = [];
  _selectedActionTypeId : any;
  _selectedActionTypeName: string;
  isTypeRequired : boolean = false;
  minDate: Date = new Date();
  _selectedTaskTypeId = 0;
  _selectedTaskTypeName = "";
  @ViewChild('pickerTaskDate', {read: null, static : true}) pickerTaskDate: MatDatepicker<Date>;
  doneTaskId: number = 0;
  isNextTaskUserRequired: boolean = false;
  isNextTaskDateRequired: boolean = false;
  loggedUserId: any;
  subscriber: Subscription;
  @ViewChild('description', { static: false }) descriptionField: ElementRef;
  @ViewChild('comment', { static: false }) commentField: ElementRef;

  constructor(private _formBuilder: FormBuilder,
    private clientService: ClientService,
    private projectViewService: ProjectViewService,
    @Inject(MAT_DIALOG_DATA) public modalViewData: DialogDataForModalView,
    private formatDatepicker: FormatMatDatepicker,
    public commonDropdownService: CommonDropdownService,
    private validationService:ValidationService,
    public dialogRef: MatDialogRef<AddEditProjectActionComponent>,
    public commonUIService: UIService,
    public taskService: TaskService,
    private alertService: AlertService) {
    this._projectActionModal = new ProjectAction();
  }

  ngOnInit() {
    this.LoadActionTypeListForIcon();
    this.initProjectActionFormGroup();
    this.commonDropdownService.LoadUserList();
    this.setProjectActionDataForEditView();
    this.commonUIService.loadTaskDateLabels();
    this.taskService.LoadTaskTypeListForIcon();
    this.subscribeTaskDoneConfirmEvents();
  }

  // Reactive Form
  initProjectActionFormGroup() {
    this.projectActionFormGroup = this._formBuilder.group({
      actionTypeId: ['', [Validators.required]],
      actionDate: ['', [Validators.required]],
      comment: [''],
      userId: [''],
      actionTypeName:['',  [Validators.required]],
      doneTaskId:[''],
      taskDate: [''],
      taskUserId: [''],
      status: [''],
      taskTypeId: [''],
      taskTime: [''],
      description: [''],
      taskTypeName:[''],
      author:['']
    });
  }

  setProjectActionDataForEditView(): void {
    if (this.modalViewData.data) {
      this._projectActionModal = Object.assign({}, this.modalViewData.data.modalData);
      this._selectedActionTypeId = this._projectActionModal.actionTypeId;
      this.loggedUserId = this._projectActionModal && this._projectActionModal.loggedUserId ? this._projectActionModal.loggedUserId : null;
      this.taskService.selectedDateId = 0;
      this.doneTaskId = this._projectActionModal && this._projectActionModal.doneTaskId ? this._projectActionModal.doneTaskId : null;
      this._projectActionModal.nextTaskUserId = !this._projectActionModal.nextTaskUserId ? this.loggedUserId : this._projectActionModal.nextTaskUserId;
      if (isNumber(this.modalViewData.data.id)) {
        this.isEdit = false;
        this._taskCreationType = this._projectActionModal ? this._projectActionModal.text : null;
        this._selectedActionTypeName = this._projectActionModal.actionTypeName ? this._projectActionModal.actionTypeName: null;
      } else {
        this.isEdit = true;
        this._selectedActionTypeName = this._projectActionModal.actionType.actionType1;
      }
    }
  }

  async submit(projectActionModal) {
    if (this.projectActionFormGroup.invalid) {
      this.isDisabledAction = false;
      this.isTypeRequired  = !!(this.projectActionFormGroup.get('actionTypeId').errors && this.projectActionFormGroup.get('actionTypeId').errors.required && this.projectActionFormGroup.get('actionTypeName').errors.required);
      return;
    }
    this.ValidateNextTask(projectActionModal);
    if(this.isNextTaskUserRequired || this.isNextTaskDateRequired)  return;

    const projectActionModalObj = Object.assign({}, projectActionModal);
    projectActionModalObj.actionDateStr = projectActionModalObj.actionDate ? this.formatDatepicker.ConvertDateFormatForSave(projectActionModalObj.actionDate) : projectActionModalObj.actionDate;
    projectActionModalObj.nextTaskDateStr = projectActionModalObj.nextTaskDate ? this.formatDatepicker.ConvertDateFormatForSave(projectActionModalObj.nextTaskDate) : projectActionModalObj.nextTaskDate;
    if (this.isEdit) {
      this.updateProjectAction(projectActionModal.id, projectActionModalObj);
    } else {
      this.SaveProjectAction(projectActionModalObj);
    }
  }

  ValidateNextTask(actionModal){
    if(actionModal.nextTaskTypeId && actionModal.nextTaskTypeId > 0){
      this.isNextTaskUserRequired = !actionModal.nextTaskUserId;
      this.isNextTaskDateRequired = !actionModal.nextTaskDate;
    }
  }
  SaveProjectAction(projectActionModalObj) {
    this.isDisabledAction = true;
    this.BindToModel(projectActionModalObj);
    this.clientService.SaveProjectAction(this._projectActionModalForSave).subscribe(
      res => {
        if (res.isSuccsess) {
          const snack = new SnackBar(SnackBarType.success, 'Successfully saved', '', 2000);
          this.commonUIService.openSnackBar(snack);
          this.closeDialog();
          this.projectViewService.refreshProjectAction.emit(res.data);
          this.projectViewService.refreshProjectTask.emit('AfterAction');
        }
      },
      () => {
        console.error(); this.closeDialog();
      });
  }

  updateProjectAction(actionModalId, actionModalObj) {
    this.BindToModel(actionModalObj);
    this.clientService.updateProjectAction(actionModalId, this._projectActionModalForSave).subscribe(
      res => {
        if (res.isSuccsess) {
          let snack = new SnackBar(SnackBarType.success, 'Successfully updated', '', 2000);
          this.commonUIService.openSnackBar(snack);
          this.closeDialog();
          this.projectViewService.refreshProjectAction.emit(res.data);
        }
      },
      () => {
        console.error();
        this.closeDialog();
      });
  }
  BindToModel(projectActionModalObj) {
    this._projectActionModalForSave = Object.assign({}, null);
    this._projectActionModalForSave.projectId = this.isEdit ? projectActionModalObj.projectId : this.modalViewData.data.id;
    this._projectActionModalForSave.actionName = projectActionModalObj.actionName;
    this._projectActionModalForSave.type = projectActionModalObj.type;
    this._projectActionModalForSave.userId = projectActionModalObj.userId;
    this._projectActionModalForSave.comment = projectActionModalObj.comment;
    this._projectActionModalForSave.actionDate = projectActionModalObj.actionDate;
    this._projectActionModalForSave.actionDateStr = projectActionModalObj.actionDateStr;
    this._projectActionModalForSave.status = true;
    this._projectActionModalForSave.actionTypeId = projectActionModalObj.actionTypeId;
    this._projectActionModalForSave.id = this.isEdit ? projectActionModalObj.id : 0;
    this._projectActionModalForSave.author = projectActionModalObj.author;
    this._projectActionModalForSave.doneTaskId = projectActionModalObj.doneTaskId;
    this._projectActionModalForSave.nextTaskTypeId = projectActionModalObj.nextTaskTypeId;
    this._projectActionModalForSave.nextTaskDate = projectActionModalObj.nextTaskDate;
    this._projectActionModalForSave.nextTaskUserId = projectActionModalObj.nextTaskUserId;
    this._projectActionModalForSave.nextTaskTime = projectActionModalObj.nextTaskTime;
    this._projectActionModalForSave.nextTaskDescription = projectActionModalObj.nextTaskDescription;
    this._projectActionModalForSave.nextTaskDateStr = projectActionModalObj.nextTaskDateStr;
    this._projectActionModalForSave.nextTaskTimeStr = projectActionModalObj.nextTaskTimeStr;
  }
  closeDialog(): void {
    this.dialogRef.close();
  }

  getPreviousDateForProjectAction() {
    let yesterday = new Date(this._projectActionModal.actionDate);
    yesterday.setDate(yesterday.getDate() - 1);
    this._projectActionModal.actionDate = yesterday;
  }

  LoadActionTypeListForIcon() {
    this.commonDropdownService.LoadActionTypeListForHeader().subscribe(data => {
      if (data) {
        this._actionTypeListForIcon = data;
        if (this._actionTypeListForIcon.length > 0) {
          this._actionTypeListForIcon.forEach(ele => {
            ele.actionType1 = ele.actionType1.trim();
          });
        }
      }
    });
  }

  selectActionType(actionType){
    this._projectActionModal.actionTypeId = actionType.id;
    this._selectedActionTypeId = actionType.id;
    this._selectedActionTypeName = actionType.actionType1;
    this.isTypeRequired = false;
    setTimeout(() => {
      this.commentField.nativeElement.focus();
    }, 100);
  }

  setDateValueToPicker(item){
    this._projectActionModal.nextTaskDate = this.taskService.setDateValueToPicker(item);
    this.isNextTaskDateRequired = !(this._projectActionModal.nextTaskDate && this._projectActionModal.nextTaskDate != null);
  }

  selectTaskType(taskType){
    this._projectActionModal.nextTaskTypeId = taskType.id;
    this._projectActionModal.nextTaskUserId = !this._projectActionModal.nextTaskUserId ? this.loggedUserId : this._projectActionModal.nextTaskUserId;
    this._selectedTaskTypeId = taskType.id;
    this._selectedTaskTypeName = taskType.taskTypeName;
    this.isTypeRequired = false;
    setTimeout(() => {
      this.descriptionField.nativeElement.focus();
    }, 100);
  }

  clearSelectedTaskType(){
    this._projectActionModal.nextTaskTypeId = 0;
    this._projectActionModal.nextTaskUserId = 0;
    this._projectActionModal.nextTaskDescription = null;
    this._projectActionModal.nextTaskDate = null;
    this._projectActionModal.nextTaskTimeStr = null;
    this._selectedTaskTypeId = 0;
    this._selectedTaskTypeName = "";
    this.isNextTaskDateRequired = false;
    this.isNextTaskUserRequired = false;
    this.taskService.selectedDateId = null;
  }

  OnAuthorChange(event){
    if (!event) { return; }
    this._projectActionModal.author = event.source.selected ? event.source.selected.viewValue : null;
  }

  cancelAction(){
    if(this.doneTaskId && this.doneTaskId > 0) this.openConfirmationAlert();
    else this.closeDialog();
  }

  openConfirmationAlert() {
    const data = new AlertModel();
    data.title = '';
    data.msg = 'Task is done?';
    data.id = this.doneTaskId;
    data.reference = 'TaskDone';
    data.alertType = 'Confirm';
    this.commonUIService.openAlert(data);
  }
  subscribeTaskDoneConfirmEvents() {
    this.subscriber =  this.alertService.referenceConfirm.subscribe(data => {
      if(data && this.doneTaskId && data.id && data.id > 0 && data.isCancel) this.revertClientTaskStatus(this.doneTaskId);
      else this.closeDialog();
    })
  }
  ngOnDestroy(){
    this.subscriber.unsubscribe();
    this.doneTaskId = null;
  }

  revertClientTaskStatus(doneTaskId) {
    this.commonUIService.openMask();
    this.clientService.changeProjectTaskStatus(doneTaskId, true).subscribe(
      res => {
        if (res.data === true) {
          const snack = new SnackBar(SnackBarType.success, 'Task done reverted', '', 2000);
          this.commonUIService.openSnackBar(snack);
          this.closeDialog();
          this.projectViewService.refreshProjectTask.emit(true);
          this.projectViewService.refreshDeleteProjectTask.emit(true);
          this.clientService.clientProjectTaskUpdate.emit(null);
        }else{
          const snack = new SnackBar(SnackBarType.error, 'Error on task revert', '', 2000);
          this.commonUIService.openSnackBar(snack);
        }
        this.commonUIService.closeMask();
      });
  }
}
