import { Component, OnInit, Input, Inject } from '@angular/core';
import { CommonDropdownService } from 'src/app/shared/services/common.dropdown.service';
import { UIService, DialogDataForModalView } from 'src/app/shared/services/ui.service';
import { APIService } from 'src/app/shared/services/api.service';
import { Employee } from '../../../entities/empoyee';
import { HrmService } from '../../../service/hrm.service';
import API from '../../../../shared/services/api.routes.json';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { AlertModel } from '../../../../shared/entities/alertModel';
import { SnackBar, SnackBarType } from '../../../../shared/components/snackbar/entities/snackbar';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ValidationService } from '../../../../shared/services/validation.service';
import { FormatMatDatepicker } from '../../../../shared/format-datepicker';
import { PersonalDocumentViewComponent } from './personal-document-view/personal-document-view.component';
import { CompanyDocumentViewComponent } from './company-document-view/company-document-view.component';
import { LetterDocumentViewComponent } from './letter-document-view/letter-document-view.component';
import { SalaryDocumentViewComponent } from './salary-document-view/salary-document-view.component';
import { BankAccountDocumentViewComponent } from './bank-account-document-view/bank-account-document-view.component';
import { AddEditEmployeeDocumentComponent } from './company-document-view/add-edit-employee-document/add-edit-employee-document.component';
import { AddEmployeeComponent } from '../../add-employee/add-employee.component';

@Component({
  selector: 'app-hrm-view',
  templateUrl: './hrm-view.component.html',
  styleUrls: ['./hrm-view.component.scss'],
  host: {
    "(window:paste)": "handlePaste( $event )"
  },
})
export class HRMViewComponent implements OnInit {

  @Input() employeeModel: Employee;
  empTypeDoc: any[];
  employeeDocumentList: any[];
  idCurrentEmployeeDocument: number = 0;

  //Employee view 
  employeeFormGroup: FormGroup;
  lengthNumber: number;
  employeeForSave: Employee;
  viewExpandEmployeeDetails: boolean = false;

  // region image
  imageDataViewForEdit: any;
  imageFront: any;
  imageFileNameFront: any;
  imageName: any;
  firstOpen: boolean = true;
  //Employee view end

  constructor(public commonDropdownService: CommonDropdownService,
    public commonUIService: UIService,
    public _apiService: APIService,
    public hrmService: HrmService,
    public dialog: MatDialog,
    public fb: FormBuilder,
    public formatDatepicker: FormatMatDatepicker,
    private validationService: ValidationService) {
    this.employeeForSave = new Employee();
  }

  ngOnInit() {
    this.initEmployeeDetailForm();
    this.commonDropdownService.LoadDesignationList();
    this.commonDropdownService.LoadDepartmentList();
  }

  //region Employee
  ngOnChanges() {
    this.initEmployeeDetailForm();
  }

  initEmployeeDetailForm() {
    this.employeeFormGroup = this.fb.group({
      id: [''],
      firstName: ['', [Validators.required, this.validationService.noWhitespaceValidator]],
      lastName: ['', [Validators.required, this.validationService.noWhitespaceValidator]],
      employeeNumber: ['', [Validators.required, this.validationService.noWhitespaceValidator]],
      nic: [''],
      dob: [''],
      designation: [''],
      department: [''],
      jointDate: ['', [Validators.required]],
      email: ['', [Validators.email]],
      phone1: ['', [Validators.required, Validators.pattern(/^(\+\d{1,3}[- ]?)?\d{9}$/)]],
      address: [''],
      phone2: ['', [Validators.pattern(/^(\+\d{1,3}[- ]?)?\d{9}$/)]],
      relationShip: [''],
      emergencyContactPersonName: ['']
    })
    this.employeeFormGroup.disable();
  }

  onKey(numb) {
    if (numb.charAt(0) == "+") {

      this.lengthNumber = 12
    }
    else {
      this.lengthNumber = 9;
    }
  }

  changeListenerFront(event: any): void {
    if (!this.employeeModel.showEditSave) {
      this.employeeModel.showEditSave = true;
    }
    let files: any = event.target.files;
    this.employeeModel.fileList = files;
    this.readThisFront(event.target);
  }

  readThisFront(inputValue: any): void {
    const file: File = inputValue.files[0];
    const myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      this.imageFront = myReader.result;
      this.imageFileNameFront = file.name;
      this.employeeModel.prolfileImage = this.imageFront;
    }
    myReader.readAsDataURL(file);
  }

  // PASTE IMAGE REGION start
  public handlePaste(event: ClipboardEvent): void {
    if (!this.employeeModel.showEditSave) {
      this.employeeModel.showEditSave = true;
    }
    var pastedImage = this.getPastedImage(event);
    if (!pastedImage) {
      return;
    }
    var reader = new FileReader();
    reader.readAsDataURL(pastedImage);
    this.imageName = pastedImage;
    reader.onloadend = (e) => {
      this.imageFront = reader.result;
      this.imageFileNameFront = this.imageName.name;
      this.employeeModel.prolfileImage = this.imageFront;
    }
  }

  private getPastedImage(event: ClipboardEvent): File | void {
    if (
      event.clipboardData &&
      event.clipboardData.files &&
      event.clipboardData.files.length &&
      this.isImageFile(event.clipboardData.files[0])
    ) {
      let files: any = event.clipboardData.files;
      this.employeeModel.fileList = files;
      return (event.clipboardData.files[0]);
    }
    return (null);
  }

  private isImageFile(file: File): boolean {
    return (file.type.search(/^image\//i) === 0);
  }

  // PASTE IMAGE REGION END
  public editEmployee() {
    if (!this.employeeModel.showEditSave) {
      this.employeeModel.showEditSave = true;
    }
    if (this.employeeFormGroup.disabled) {
      this.employeeFormGroup.enable();
    }
  }

  toggleExpandMoreInfo(): void {
    if (this.viewExpandEmployeeDetails) { this.viewExpandEmployeeDetails = false; } else { this.viewExpandEmployeeDetails = true; }
  }

  openEditEmployeePopup(employeeModel) {
    if (employeeModel.id > 0) {
      let empObj = Object.assign({}, employeeModel);
      this.hrmService.isEmployeeEdit = true;
      const dialogViewData = this.commonUIService.setDataForModalDialog(employeeModel.id, null, null, empObj, null, null);
      this.commonUIService.openDialog(AddEmployeeComponent, dialogViewData)
    }
  }
  //endregion Employee  

  //region PersonalDocumentView
  openPersonalDocumentViewPopup(employeeId) {
    const dialogViewData = this.commonUIService.setDataForModalDialog(employeeId, null, 8, null, null, null);
    this.commonUIService.openDialog(PersonalDocumentViewComponent, dialogViewData);
  }
  //endregion PersonalDocumentView

  //region CompanyDocumentView
  openCompanyDocumentViewPopup() {
    const dialogViewData = this.commonUIService.setDataForModalDialog(this.employeeModel.id, null, null, null, null, null);
    setTimeout(() => {
      this.commonUIService.openDialog(CompanyDocumentViewComponent, dialogViewData);
    }, 0);
  }
  //endregion CompanyDocumentView

  //region LetterDocumentView
  openLetterDocumentViewPopup() {
    const dialogViewData = this.commonUIService.setDataForModalDialog(this.employeeModel.id, null, null, this.employeeModel, null, null);
    this.commonUIService.openDialog(LetterDocumentViewComponent, dialogViewData);
  }
  //endregion LetterDocumentView

  //region SalaryDocumentView
  openSalaryDocumentViewPopup() {
    const dialogViewData = this.commonUIService.setDataForModalDialog(this.employeeModel.id, null, null, null, null, null);
    this.commonUIService.openDialog(SalaryDocumentViewComponent, dialogViewData);
  }
  //endregion SalaryDocumentView

  //region BankAccountDocumentView
  openBankAccountDocumentViewPopup(employeeId) {
    const dialogViewData = this.commonUIService.setDataForModalDialog(employeeId, null, null, null, null, null);
    this.commonUIService.openDialog(BankAccountDocumentViewComponent, dialogViewData);
  }
  //endregion BankAccountDocumentView
}
