import { NgModule } from '@angular/core';
import { AdminComponent } from './admin.component';
import { AdminRoutingModule } from './admin-routing.modules';
import { AngularMaterialModule } from '../angular-material.module';
import { SharedModule } from '../shared/shared.module';
import { ClientModule } from '../client/client.module';
import { MaintenanceModule } from '../maintenance/maintenance.module';
import { LinkedInModule } from 'src/app/linkedin/linkedin.module';
import { InventoryModule } from '../inventory/inventory.module';
import { CompanyModule } from '../company/company.module';
import { HRMModule } from '../hrm/hrm.module';
import { TaskModule } from '../task/task.module';
import { EstimationModule } from '../estimation/estimation.module';
import { CRMDocumentTypeModule } from '../crm-document-type/crm-document-type.module';
import { LinkedInTempleteModule } from '../linkedin-templete/linkedin-templete.module';
import { ClientProductModule } from '../client-product/client-product.module';
import { InventoryMaintenanceModule } from '../inventory-maintenance/inventory-maintenance.module';
import { HrmMaintenanceModule } from '../hrm-maintenance/hrm-maintenance.module';

@NgModule({
  declarations: [
    AdminComponent
  ],
  imports: [
    AdminRoutingModule,
    AngularMaterialModule,
    SharedModule,
    ClientModule,
    MaintenanceModule,
    HrmMaintenanceModule,
    InventoryMaintenanceModule,
    LinkedInModule,
    InventoryModule,
    CompanyModule,
    HRMModule,
    TaskModule,
    EstimationModule,
    CRMDocumentTypeModule,
    LinkedInTempleteModule,
    ClientProductModule
  ],
  exports: [],
})
export class AdminModule { }
