import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { DialogDataForModalView, UIService } from 'src/app/shared/services/ui.service';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { ServiceTypeModel } from 'src/app/shared/entities/service-type';
import { MaintenanceService } from '../../service/maintenance.service';
import { SnackBar, SnackBarType } from 'src/app/shared/components/snackbar/entities/snackbar';

@Component({
  selector: 'app-add-edit-service-type',
  templateUrl: './add-edit-service-type.component.html',
  styleUrls: ['./add-edit-service-type.component.scss']
})
export class AddEditServiceTypeComponent implements OnInit {


  serviceTypeModel: ServiceTypeModel;
  serviceTypeFormGroup: FormGroup;
  isEdit: boolean = false;
  isDisabledTaskType = false;

  constructor(private _formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public serviceTypeData: DialogDataForModalView,
    public dialogRef: MatDialogRef<AddEditServiceTypeComponent>,
    private validationService: ValidationService,
    private maintenanceService: MaintenanceService,
    private uiService: UIService) {
    this.serviceTypeModel = new ServiceTypeModel();
  }

  ngOnInit() {
    this.initFormGroup();
    this.setServiceTypeDataForEditView();
  }

  initFormGroup() {
    this.serviceTypeFormGroup = this._formBuilder.group({
      serviceTypeName: ['', [Validators.required, this.validationService.noWhitespaceValidator]],
    });
  }

  setServiceTypeDataForEditView() {
    if (this.serviceTypeData.data) {
      this.serviceTypeModel = Object.assign({}, this.serviceTypeData.data).modalData;
      this.isEdit = true;
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  async submit(model: { id: any; }) {
    if (this.serviceTypeFormGroup.invalid) {
      this.isDisabledTaskType = false;
      return;
    }
    if (this.isEdit) this.updateServiceType(model.id, model);
    else this.saveTaskType(model);
  }

  private saveTaskType(obj: any) {
    obj.active = true;
    this.isDisabledTaskType = true;
    this.maintenanceService.SaveServicetype(obj).subscribe(
      res => {
        if (res.isSuccsess) {
          var snack = new SnackBar(SnackBarType.success, "Successfully saved", "", 2000);
          this.uiService.openSnackBar(snack);
          this.closeDialog();
          this.maintenanceService.refreshServiceType.emit(true);
        }
        else {
          snack = new SnackBar(SnackBarType.success, "Error on save", "", 2000);
          this.uiService.openSnackBar(snack);
        }
      },
      () => { console.error(); this.closeDialog(); });
  }

  private updateServiceType(id: any, obj: any) {
    this.maintenanceService.UpdateServicetype(id, obj).subscribe(
      (res) => {
        if (res.isSuccsess) {
          var snack = new SnackBar(SnackBarType.success, "Successfully updated", "", 2000);
          this.uiService.openSnackBar(snack);
          this.closeDialog();
          this.maintenanceService.refreshServiceType.emit(true);
        }
        else {
          snack = new SnackBar(SnackBarType.success, "Error on update", "", 2000);
          this.uiService.openSnackBar(snack);
        }
      },
      () => { console.error(); this.closeDialog(); });
  }
}
