import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { TaskTypeModel } from '../../shared/entities/taskTypeModel';
import { APIService } from '../../shared/services/api.service';
import { MaintenanceService } from '../service/maintenance.service';
import { UIService } from '../../shared/services/ui.service';
import { AlertService } from '../../shared/services/alert.service';
import { AlertModel } from '../../shared/entities/alertModel';
import API from '../../shared/services/api.routes.json';
import { SnackBar, SnackBarType } from '../../shared/components/snackbar/entities/snackbar';
import { MatDialog } from '@angular/material';
import { AddEditTaskTypeComponent } from './add-edit-task-type/add-edit-task-type.component';
import { TitleCasePipe } from '@angular/common';

@Component({
  selector: 'app-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.scss']
})

export class TaskComponent implements OnInit {

  TaskTypeList: TaskTypeModel[] = [];
  _taskTypeList: TaskTypeModel[] = [];
  updateTaskType: TaskTypeModel;
  private subscriber: Subscription;
  _idSelectedTaskType: number;
  isLoading: boolean = false;

  constructor(private _apiService: APIService,
    private uIService: UIService,
    public dialog: MatDialog,
    private maintananceService: MaintenanceService,
    private alertService: AlertService) {

    this.TaskTypeList = [];
  }

  ngOnInit() {
    this.updateTaskType = new TaskTypeModel();
    this.LoadTaskTypeList();
    this.maintananceService.subjectTaskTypeRefersh.subscribe(isRefesh => {
      if (isRefesh) {
        this.LoadTaskTypeList();
      }
    });
    this.subscribeAlertConfirmEvents();
  }

  subscribeAlertConfirmEvents() {
    this.subscriber = this.alertService.referenceDelete.subscribe(data => {
      if (data && data === "Delete TaskType") {
        this.deleteTaskType(this._idSelectedTaskType);
      }
    });
  }

  private LoadTaskTypeList() {
    this.isLoading = true;
    this._apiService.get(API.taskType.getAllTaskType).subscribe(response => {
      if (response) {
        if (response.data) {
          this._taskTypeList = response.data;
          this.TaskTypeList = this._taskTypeList.sort((a, b) => a.taskTypeName.localeCompare(b.taskTypeName));
        }
      }
      this.isLoading = false;
    },
      err => {
        this.isLoading = false;
        console.error(err);
      }, () => {
      });

  }

  addTaskType() {
    this.uIService.openDialog(AddEditTaskTypeComponent, null)
  }

  editTasktype(tech) {
    const tasktype = Object.assign({}, tech);
    const dialogViewData = this.uIService.setDataForModalDialog(null, null, null, tasktype, null, null);
    this.uIService.openDialog(AddEditTaskTypeComponent, dialogViewData);
  }

  openAlertTaskTypeStatus(id) {
    let deleteData = new AlertModel();
    deleteData.title = "Delete";
    deleteData.msg = "Are you sure to delete this record?";
    deleteData.id = id;
    deleteData.reference = "Delete TaskType";
    this._idSelectedTaskType = id;
    this.uIService.openAlert(deleteData);
  }

  deleteTaskType(id) {
    this.maintananceService.deleteTaskType(id).subscribe(res => {
      if (res.isSuccsess) {
        var snack = new SnackBar(SnackBarType.success, res.message, "", 2000);
        this.uIService.openSnackBar(snack);
        if (res.data) this.LoadTaskTypeList();
      }
      else {
        var snack = new SnackBar(SnackBarType.error, "Error", "", 2000);
        this.uIService.openSnackBar(snack);
      }
    })
  }
  activeDeactive(event, model) {
    model.active = event.checked;
    this.maintananceService.updateTaskType(model.id, model).subscribe(
      (res: { isSuccsess: any; }) => {
        if (res.isSuccsess) {
          var snack = new SnackBar(SnackBarType.success, "Successfully updated", "", 2000);
          this.uIService.openSnackBar(snack);
          this.maintananceService.TaskTypeRefersh(true);
        }
        else {
          snack = new SnackBar(SnackBarType.success, "Error on update", "", 2000);
          this.uIService.openSnackBar(snack);
        }
      },
      () => { console.error(); });
  }
  addTaskandType() {
    this.uIService.openDialog(AddEditTaskTypeComponent, null)
  }
}
