import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UIService, DialogDataForModalView } from 'src/app/shared/services/ui.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SnackBar, SnackBarType } from 'src/app/shared/components/snackbar/entities/snackbar';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/auth/login/login.service';
import { Users } from 'src/app/auth/entities/user';
import { UserService } from '../../service/user.service';
import { CommonDropdownService } from '../../../../shared/services/common.dropdown.service';
import { SharedHeaderService } from '../../../../shared/services/shared.header.services';
import { APIService } from '../../../../shared/services/api.service';
import API from '../../../../shared/services/api.routes.json';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-add-edit-user',
  templateUrl: './add-edit-user.component.html',
  styleUrls: ['./add-edit-user.component.scss']
})

export class AddEditUserComponent implements OnInit {
  userFormGroup: FormGroup;
  _userModal: Users;
  isEdit: boolean = false;
  _userForSave: Users;
  currentusers: Users;
  isDisabledUser = false;
  _loggedinUser: Users = new Users();
  emailDomainValidation: boolean = false;
  companyMultiSelectList: string[];
  userRoleMultiSelectList: string[];
  companyIds: any[] = [];
  userRoleIds: any[] = [];
  public _subCompanyList: any = [];

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '12',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['subscript', 'superscript'],
      ['fontSize', 'customClasses', 'insertImage', 'insertVideo',]
    ]
  };
  
  constructor(
    private _formBuilder: FormBuilder,
    private userService: UserService,
    public commonUIService: UIService,
    private uIService: UIService,
    private route: Router,
    private loginServic: LoginService,
    private validationService: ValidationService,
    private commonDropdownService: CommonDropdownService,
    public sharedHeaderService: SharedHeaderService,
    private _apiService: APIService,
    public dialogRef: MatDialogRef<AddEditUserComponent>,
    @Inject(MAT_DIALOG_DATA) public userData: DialogDataForModalView) { this.currentusers = new Users(); }

  ngOnInit() {
    this.initFormGroup();
    this._userModal = new Users();
    this._userForSave = new Users();
    this.commonDropdownService.LoadRolesList();
    this.commonDropdownService.LoadCompantList();
    this.setUserDataForEditView();
    this.loadSubCompanyList();
  }

  initFormGroup() {
    this.userFormGroup = this._formBuilder.group({
      firstName: ['', [Validators.required, this.validationService.noWhitespaceValidator]],
      email: ['', [Validators.required, Validators.email, this.validationService.noWhitespaceValidator]],
      companyId: ['', [Validators.required]],
      seeAllBranches: [''],
      companyMultiSelectList: [''],
      userRoleMultiSelectList: ['', [Validators.required]],
      emailSignature: ['']
    })
  }

  setUserDataForEditView() {
    if (this.userData.data) {
      this._userModal = Object.assign({}, this.userData.data).modalData;
      this.companyMultiSelectList = this._userModal.companyUser.map(m => m.companyId);
      this.userRoleMultiSelectList = this._userModal.userRole.map(m => m.roleId);
      let _role = [];
      if (this._userModal.role)
        _role.push(this._userModal.role);
      if (_role.length > 0)
        this.userRoleMultiSelectList = _role.map(c => c.id);
      this.isEdit = true;
      if (this.userData.data.modalData.companyId) {
        this._apiService.get(API.company.listAll)
          .subscribe(response => {
            if (response && response.data) {
              this._subCompanyList = response.data;
              let index1 = this._subCompanyList.findIndex(s => s.id == this.userData.data.modalData.companyId);
              if (index1 !== -1) {
                this._subCompanyList.splice(index1, 1);
              }
            }
          }, err => {
            console.error(err)
          }, () => { });
      }
    }
  }


  public loadSubCompanyList() {
    this._apiService.get(API.company.listAll)
      .subscribe(response => {
        if (response && response.data) {
          this._subCompanyList = response.data;
        }
      }, err => {
        console.error(err)
      }, () => { });
  }

  seelAllBranchesCheckEvent(event) {
    this._userModal.seeAllBranches = event.checked;
  }

  async submit(user) {
    if (this.companyMultiSelectList != null) {
      for (let c of this.companyMultiSelectList) {
        this.companyIds.push(c);
      }
    }

    if (this.userRoleMultiSelectList != null) {
      for (let u of this.userRoleMultiSelectList) {
        this.userRoleIds.push(u);
      }
    }

    if (this._userModal.seeAllBranches == true) {
      this.companyIds = [];
      for (let c of this.commonDropdownService._companyList) {
        this.companyIds.push(c.id);
      }
    }

    if (this.userFormGroup.invalid) {
      this.isDisabledUser = false;
      return;
    }

    if (this.isEdit) {
      if (this.userRoleIds.length > 0)
        user.roleId = null;
      this.updateUser(user.id, user);
    }
    else {
      this.saveUserData(user);
    }
  }

  saveUserData(userObj) {
    this.isDisabledUser = true;
    if (userObj.salesNavigator == undefined) {
      userObj.salesNavigator = true;
    }
    this.userService.saveUserData(userObj).subscribe(
      res => {
        if (res.isSuccsess) {
          var snack = new SnackBar(SnackBarType.success, "Successfully saved", "", 2000);
          this.uIService.openSnackBar(snack);
          this.closeDialog();
          //save user branches
          if (this.companyIds.length > 0) {
            let index = this.companyIds.findIndex(r => r === res.data.companyId);
            if (index !== -1)
              this.companyIds.splice(index, 1);

            let _companyUserList: any = [];
            if (this.companyIds.length > 0) {
              this.companyIds.forEach(e => {
                let cu = {
                  userId: res.data.id,
                  companyId: e
                };
                _companyUserList.push(cu);
              });
            }
            if (_companyUserList.length > 0)
              this.addUserBranches(_companyUserList);
          }

          //save user roles
          if (this.userRoleIds.length > 0) {
            let _userRoleList: any = [];
            if (this.userRoleIds.length > 0) {
              this.userRoleIds.forEach(e => {
                let ur = {
                  userId: res.data.id,
                  roleId: e
                };
                _userRoleList.push(ur);
              });
            }
            if (_userRoleList.length > 0)
              this.addUserRoles(_userRoleList);
          }
          this.userService.userRefresh(true);
          this.sharedHeaderService.refreshCompanyDropdownList.emit(true);
        }
        else {
          snack = new SnackBar(SnackBarType.success, "Error on save", "", 2000);
          this.uIService.openSnackBar(snack);
        }
      },
      () => { console.error(); this.closeDialog(); });
  }

  updateUser(id, obj) {
    this.userService.updateUser(id, obj).subscribe(
      res => {
        if (res.isSuccsess) {
          var snack = new SnackBar(SnackBarType.success, "Successfully updated", "", 2000);
          this.uIService.openSnackBar(snack);
          this.closeDialog();
          //update user branches
          if (this.companyIds.length > 0) {
            let index = this.companyIds.findIndex(r => r === res.data.companyId);
            if (index !== -1)
              this.companyIds.splice(index, 1);

            let _companyUserList: any = [];
            if (this.companyIds.length > 0) {
              this.companyIds.forEach(e => {
                let cu = {
                  userId: res.data.id,
                  companyId: e
                };
                _companyUserList.push(cu);
              });
            }
            if (_companyUserList.length > 0)
              this.updateUserBranches(_companyUserList);
          }
          //update user roles
          if (this.userRoleIds.length > 0) {
            let _userRoleList: any = [];
            if (this.userRoleIds.length > 0) {
              this.userRoleIds.forEach(e => {
                let ur = {
                  userId: res.data.id,
                  roleId: e
                };
                _userRoleList.push(ur);
              });
            }
            if (_userRoleList.length > 0)
              this.updateUserRoles(_userRoleList);
          }
          this.userService.userRefresh(true);
          this.sharedHeaderService.refreshCompanyDropdownList.emit(true);
        }
        else {
          snack = new SnackBar(SnackBarType.success, "Error on update", "", 2000);
          this.uIService.openSnackBar(snack);
        }
      },
      () => { console.error(); this.closeDialog(); });
  }

  addUserBranches(userBranchesList) {
    this.userService.addUserBranches(userBranchesList).subscribe(
      res => {
        if (res.isSuccsess) { this.userService.refershUpdateUser.emit(true); }
        () => { console.error(); this.userService.refershUpdateUser.emit(true); }
      });
  }

  updateUserBranches(userBranchesList) {
    this.userService.updateUserBranches(0, userBranchesList).subscribe(
      res => {
        if (res.isSuccsess) { this.userService.refershUpdateUser.emit(true); }
        () => { console.error(); this.userService.refershUpdateUser.emit(true); }
      });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  onCheckEmailDomain() {
    if (this._userModal.email) {
      var domain = this._userModal.email.split("@")[1];
      this.emailDomainValidation = true;
    }
  }

  addUserRoles(userRoleList) {
    this.userService.addUserRoles(userRoleList).subscribe(
      res => {
        if (res.isSuccsess) { this.userService.refershUpdateUser.emit(true); }
        () => { console.error(); this.userService.refershUpdateUser.emit(true); }
      });
  }

  updateUserRoles(userRoleList) {
    this.userService.updateUserRoles(0, userRoleList).subscribe(
      res => {
        if (res.isSuccsess) { this.userService.refershUpdateUser.emit(true); }
        () => { console.error(); this.userService.refershUpdateUser.emit(true); }
      });
  }

  changeCompany() {
    if (this._userModal.companyId) {
      this._apiService.get(API.company.listAll)
        .subscribe(response => {
          if (response && response.data) {
            this._subCompanyList = response.data;
            let index1 = this._subCompanyList.findIndex(s => s.id == this._userModal.companyId);
            if (index1 !== -1) {
              this._subCompanyList.splice(index1, 1);
            }
          }
        }, err => {
          console.error(err)
        }, () => { });
    }
  }
}
