import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ClientService } from 'src/app/client/services/client.service';
import { InvoiceModel } from 'src/app/inventory/entities/inventoryInvoice';
import { InventoryService } from 'src/app/inventory/service/inventoryService';
import { SnackBar, SnackBarType } from 'src/app/shared/components/snackbar/entities/snackbar';
import { FormatMatDatepicker } from 'src/app/shared/format-datepicker';
import { CommonDropdownService } from 'src/app/shared/services/common.dropdown.service';
import { DialogDataForModalView, UIService } from 'src/app/shared/services/ui.service';
import { ValidationService } from 'src/app/shared/services/validation.service';

@Component({
  selector: 'app-edit-invoice',
  templateUrl: './edit-invoice.component.html',
  styleUrls: ['./edit-invoice.component.scss']
})
export class EditInvoiceComponent implements OnInit {

  toggle = true;
  editInvoiceFormGroup: FormGroup;
  invoiceModel: InvoiceModel; 
  invoiceModelUpdate: InvoiceModel; 
  inventoryId: number = 0;
  isDocumentRequired = false;
  inventoryInvoiceList: InvoiceModel[] = [];
  isExistLoad = false;
  delInvoiceId:number=0;

  constructor(public dialogRef: MatDialogRef<EditInvoiceComponent>,
    @Inject(MAT_DIALOG_DATA) public modalViewData: DialogDataForModalView,
    private formBuilder: FormBuilder,
    private clientService: ClientService,
    private inventoryService: InventoryService,
    private commonUIService: UIService,
    private formatDatepicker: FormatMatDatepicker,
    private validationService: ValidationService,
    public commonDropdownService: CommonDropdownService) 
    {     
      this.invoiceModel = new InvoiceModel(); 
      let dataToEdit = modalViewData.data.modalData;
      this.invoiceModel.id = dataToEdit.id; 
      this.invoiceModel.inventoryId = dataToEdit.inventoryId;     
    }

  ngOnInit() 
  {   
    this.initeditInvoiceFormGroup();
    this.getInventoryInvoiceDetailsById(this.invoiceModel.id);
  }

  initeditInvoiceFormGroup() {
    this.editInvoiceFormGroup = this.formBuilder.group({
      documentName: ['', [Validators.required, this.validationService.noWhitespaceValidator]],
      date: ['', [Validators.required]],
      file: [null]
    });
  }

  getInventoryInvoiceDetailsById(id)
  { 
    this.inventoryService.getInventoryInvoiceDetailsById(id).subscribe(res => {      
      if (res.isSuccsess) 
      {      
        this.invoiceModel.id = res.data.id;
        this.invoiceModel.inventoryId = res.data.inventoryId;
        this.invoiceModel.documentName = res.data.documentName;
        this.invoiceModel.date = res.data.date;
        this.invoiceModel.file = null;
        this.invoiceModel.fileName = res.data.fileName;
        this.invoiceModel.generatedFileName = res.data.generatedFileName;
        this.invoiceModel.documentType = res.data.documentType;
        this.invoiceModel.insertDate = res.data.insertDate;      

      }
      else
      {
        var snack = new SnackBar(SnackBarType.error, res.message, "", 2000);
        this.commonUIService.openSnackBar(snack);
      }
    },error=>{      
      console.log(error);
      var snack = new SnackBar(SnackBarType.error, "An unexpected error occurred", "", 2000);
      this.commonUIService.openSnackBar(snack);
    });
  }

  submit(invoiceModel: InvoiceModel)
  {    
    if (this.editInvoiceFormGroup.invalid) {
      return;
    }
    invoiceModel.documentType = 'Invoice';
    this.invoiceModelUpdate = Object.assign({}, invoiceModel);
    this.invoiceModelUpdate.dateStr = invoiceModel.date ? this.formatDatepicker.ConvertDateFormatForSave(invoiceModel.date) : invoiceModel.date;
    this.invoiceModelUpdate.date = invoiceModel.date;
    this.updateInventoryInvoice();    
  }

  updateInventoryInvoice()
  {
    if (this.invoiceModelUpdate) {
      if (this.modalViewData.data.id) {
        this.invoiceModelUpdate.id = this.modalViewData.data.id;
        this.invoiceModelUpdate.inventoryId =  this.invoiceModel.inventoryId;
        if (this.invoiceModelUpdate.fileList && this.invoiceModelUpdate.fileList.length > 0) {
          this.invoiceModelUpdate.fileName = (this.invoiceModelUpdate.fileList[0]).name;
          this.invoiceModelUpdate.extension = ((this.invoiceModelUpdate.fileList[0]).name).split('.')[(((this.invoiceModelUpdate.fileList[0]).name).split('.')).length - 1];
        }

        this.inventoryService.updateInventoryInvoice(this.invoiceModelUpdate).subscribe(res => {
          this.inventoryService.newDocument.emit(res);
          if (res) {
            const formData: FormData = new FormData();
            if(this.invoiceModel.fileList && this.invoiceModel.fileList.length>0)
            {
              formData.append('file', this.invoiceModel.fileList[0], res.data.generatedFileName);
            }
           else
           {
              formData.append('file', null);
           }         
            formData.append('inventoryId', res.data.inventoryId.toString());
            formData.append('fileId', res.data.id.toString());
            this.uploadInventoryDocument(formData);
          }
        });
        this.closeDialog();
      }
    }
  }

  uploadInventoryDocument(formData: FormData) {   
    this.clientService.uploadDocuments(formData).subscribe(
      res => {
        if (+res == 200 || +(res.statusCode) == 200) {
          const snack = new SnackBar(SnackBarType.success, 'Successfully updated', '', 4000);
          this.commonUIService.openSnackBar(snack);
          this.closeDialog();
          this.inventoryService.refreshInventorInvoice.emit(true);
        } else {
          const snack = new SnackBar(SnackBarType.success, 'Error on update', '', 4000);
          this.commonUIService.openSnackBar(snack);
        }
      },
      () => { console.error(); });
  }

  public onChooseInventoryInvoice(event: any): void {
    if (event) {
      let files: any = event.target.files;
      this.invoiceModel.fileList = files;
      this.toggle = true;
      this.isDocumentRequired = false;
    } else {
    }
  }

  closeDialogBox(): void {
    this.inventoryService.cancelInventoryInvoice.emit(true);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

}
