import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SnackBar, SnackBarType } from 'src/app/shared/components/snackbar/entities/snackbar';
import { AlertModel } from 'src/app/shared/entities/alertModel';
import { InventoryMetaData } from 'src/app/shared/entities/inventoryMetadata';
import { InventoryMetadataType } from 'src/app/shared/enum/inventoryMetadataType';
import { AlertService } from 'src/app/shared/services/alert.service';
import { APIService } from 'src/app/shared/services/api.service';
import { UIService } from 'src/app/shared/services/ui.service';
import { InventoryMaintenanceService } from '../service/inventory-maintenance.service';
import { AddEditLaptopscreensizeComponent } from './add-edit-laptopscreensize/add-edit-laptopscreensize.component';

@Component({
  selector: 'app-laptop-screen-size',
  templateUrl: './laptop-screen-size.component.html',
  styleUrls: ['./laptop-screen-size.component.scss']
})
export class LaptopScreenSizeComponent implements OnInit {

  
  dataList: InventoryMetaData[] = [];
  updateInventoryMetadata: InventoryMetaData;
  private subscriber: Subscription;
  isLoading: boolean = false;
  _idSelectedInventoryMetadata: number;

  constructor(private uIService: UIService, private _apiService: APIService, private _inventoryMaintenanceService: InventoryMaintenanceService,
    private alertService: AlertService) { }

  ngOnInit() {
    this.updateInventoryMetadata = new InventoryMetaData();
    this.LoadDataList();
    this.subscribeAlertConfirmEvents()
    this._inventoryMaintenanceService.subjectLaptopScreenSizeTypeRefresh.subscribe(isStatusRefesh => {
      if (isStatusRefesh) {
        this.LoadDataList();
      }
    });
  }

  subscribeAlertConfirmEvents() {
    this.subscriber = this.alertService.referenceDelete
      .subscribe(data => {
        if (data === "Delete ActionType" && this._idSelectedInventoryMetadata) {
          this.deleteActionType(this._idSelectedInventoryMetadata);
          console.log(this._idSelectedInventoryMetadata);
        }
      });
  }

  deleteActionType(conditionId) {
    this._inventoryMaintenanceService.deleteInventoryMetadata(conditionId).subscribe(
      res => {
        if (res.isSuccsess) {
          var snack = new SnackBar(SnackBarType.success, res.message, "", 2000);
          this.uIService.openSnackBar(snack);
          if (res.data) this.LoadDataList();
        }
      });
  }

  addInventoryMetadata() {
    this.uIService.openDialog(AddEditLaptopscreensizeComponent, null);
  }

  editInventoryMetadata(type) {
    const inventoryData = Object.assign({}, type);
    const dialogViewData = this.uIService.setDataForModalDialog(null, null, null, inventoryData, null, null);
    this.uIService.openDialog(AddEditLaptopscreensizeComponent, dialogViewData);
  }

  private LoadDataList() {
    this.isLoading = true;
    let url = this._apiService.getEndPoint("inventoryMetaData.getInventoryMetadataByType", [["{type}", InventoryMetadataType.laptopScreenSize], ["{isOnlyActive}", false]]);
    this._apiService.get(url).subscribe(response => {
      if (response) {
        if (response.data) {
          this.dataList = response.data;
        }
      }
      this.isLoading = false;
    },
      err => {
        console.error(err);
      }, () => {
      });
  }

  activeDeactive(event, inventoryMetadata: InventoryMetaData) {
    inventoryMetadata.status = event.checked;
    this._inventoryMaintenanceService.updateInventoryMetadata(inventoryMetadata.id, inventoryMetadata).subscribe(
      (res: { isSuccsess: any; }) => {
        if (res.isSuccsess) {
          this._inventoryMaintenanceService.laptopScreenSizeTypeRefresh(true);
          var snack = new SnackBar(SnackBarType.success, "Successfully updated", "", 2000);
          this.uIService.openSnackBar(snack);
        }
        else {
          snack = new SnackBar(SnackBarType.success, "Error on update", "", 2000);
          this.uIService.openSnackBar(snack);
        }
      },
      () => { console.error(); });
  }

  openAlertDelete(id) {
    let deleteData = new AlertModel();
    deleteData.title = "Delete";
    deleteData.msg = "Are you sure to delete this record?";
    deleteData.id = id;
    deleteData.reference = "Delete ActionType";
    this._idSelectedInventoryMetadata = id;
    this.uIService.openAlert(deleteData);
  }
  
  ngOnDestroy(): void {
    this.subscriber.unsubscribe();
  }


}
