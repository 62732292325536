export class Users {
  id: number;
  empId: number;
  firstName: string;
  middleName: string;
  lastName: string;
  dob: any;
  nic: any;
  userName: string;
  email: string;
  password: string;
  imageName: string;
  imageUrl: string;
  insertDate: any;
  insertUser: string;
  updateDate: any;
  updateUser: string;
  status: boolean;
  idGoogle: string;
  name: string;
  authToken: string;
  idToken: string;
  provider: string;
  roleName: string;
  roleId: number;
  companies:string;
  companyUser:any;
  companiesID: number;
  companyId: number;
  companyName: string;
  company: any;
  seeAllBranches: boolean;
  userRole: any;
  userRoleName: string;
  mainBranch: string;
  role: any;
  emailSignature: string;

  companyNames: any = [];
  userRoles: any = [];

  constructor() {
    this.seeAllBranches = false;
  }
}
export class UserViewModal {
  userName: string;
  password: string;
}
