import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatSelectChange, MAT_DIALOG_DATA } from '@angular/material';
import { DialogDataForModalView, UIService } from 'src/app/shared/services/ui.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonDropdownService } from 'src/app/shared/services/common.dropdown.service';
import { FormatMatDatepicker } from 'src/app/shared/format-datepicker';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { InventoryService } from 'src/app/inventory/service/inventoryService';
import { SnackBar, SnackBarType } from 'src/app/shared/components/snackbar/entities/snackbar';
import { InventoryUserModel } from '../../../../entities/inventoryUser';
import { isNumber } from 'util';
import { Subscription } from 'rxjs';
import { SimmModels } from '../../../../entities/simm';

@Component({
  selector: 'add-simm-user',
  templateUrl: './add-simm-user.component.html',
})

export class AddSimmUserComponent implements OnInit {
  simmId: number = 0;
  simmModel: SimmModels;
  simmModelForSave: SimmModels;
  inventorySimmForm: FormGroup;
  subscriber: Subscription;
  selectedUser:any;

  constructor(public dialogRef: MatDialogRef<AddSimmUserComponent>,
    @Inject(MAT_DIALOG_DATA) public modalViewData: DialogDataForModalView,
    private _formBuilder: FormBuilder,
    public commonDropdownService: CommonDropdownService,
    private formatDatepicker: FormatMatDatepicker,
    private validationService: ValidationService,
    private inventoryService: InventoryService,
    private commonUIService: UIService) {
    this.simmModel = new SimmModels();
    this.simmModelForSave = new SimmModels();
  }

  ngOnInit() {
    this.commonDropdownService.LoadUserList();
    this.initFormGroup();
  }

  initFormGroup() {
    this.inventorySimmForm = this._formBuilder.group({
      user: ['', [Validators.required]]
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  submit(model: SimmModels) {  
    if (this.inventorySimmForm.invalid) {
      return;
    }
    this.simmModelForSave = Object.assign({}, model);
    this.simmModelForSave.id = this.modalViewData.data.modalData.id;
    this.simmModelForSave.allocatedData = this.modalViewData.data.modalData.allocatedData;
    this.simmModelForSave.number = this.modalViewData.data.modalData.number;
    this.simmModelForSave.status = this.modalViewData.data.modalData.status;
    this.simmModelForSave.user = this.selectedUser.text;
    this.simmModelForSave.userId = this.selectedUser.value;    
    this.saveSimmUser()
  }

  saveSimmUser() {
    if (this.simmModelForSave) {
      this.inventoryService.updateSimm(this.simmModelForSave.id, this.simmModelForSave).subscribe(res => {
        if (res.isSuccsess) {
          if (res.message == "Sucessfully updated") {
            this.inventorySimmForm.disable();
            this.closeDialog();
            this.commonUIService.closeMask();
            this.inventoryService.refreshLoadSimmList.emit(res.data);
            this.inventoryService.refreshSimmUpdate.emit({ status: true, simmId: res.data.id });
          }
          let snack = new SnackBar(SnackBarType.success, res.message, '', 2000);
          this.commonUIService.openSnackBar(snack);
        }
      });
    }
  }

  selectedValue(event: MatSelectChange) {  
    this.selectedUser = {
      value: event.value,
      text: event.source.triggerValue
    }; 
  }
}
