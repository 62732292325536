import { Component, OnInit } from '@angular/core';
import { CommonDropdownService } from 'src/app/shared/services/common.dropdown.service';
import { UIService } from 'src/app/shared/services/ui.service';
import { Employee } from '../../entities/empoyee';
import { HrmService } from '../../service/hrm.service';
import { APIService } from 'src/app/shared/services/api.service';
import API from 'src/app/shared/services/api.routes.json'
import moment from 'moment';
import { FilterEmployeeComponent } from '../filter-employee/filter-employee.component';
import { AddEmployeeComponent } from '../add-employee/add-employee.component';
import { AuthAppService } from '../../../shared/services/auth-app.service';
import { FilterEmployeeModel } from '../../entities/filter-employee-model';
import { PageEvent } from '@angular/material';

@Component({
  selector: 'app-hrm-list',
  templateUrl: './hrm-list.component.html',
  styleUrls: ['./hrm-list.component.scss']
})
export class HRMListComponent implements OnInit {

  employeelist: Employee[] = [];
  notiCount: number = 0;
  newEmpList: Employee[] = [];
  isTab: number = 0;
  idSelectedEmployee: number = 0;
  isLoading: boolean = false;
  searchEmployeeInputField: string = null;
  //filterEmployeeModel: FilterEmployeeModel;


  constructor(public commonDropdownService: CommonDropdownService,
    public commonUIservice: UIService,
    public _apiService: APIService,
    public hrmService: HrmService,
    public commonUIService: UIService,
    private authAppService: AuthAppService) {
    this.hrmService.filterEmployeeModel = new FilterEmployeeModel;
  }

  ngOnInit() {
    this.hrmService.filterEmployeeModel.page = 1;
    this.hrmService.filterEmployeeModel.pageSize = 15;
    this.newEmpList = [];
    this.listAllEmployees()
    this.subscribeEmployeeFilter()
    this.subscribeEmployeeFilterClear();
    this.subscribeRefreshEmployeeList();
    this.subscribeSelectedEmployee();
    this.subscribeListAllEmployees();
  }

  public setConfigView() {
    this.idSelectedEmployee = 0;
  }

  public getPaginatorData(event: any) {
    this.hrmService.filterEmployeeModel.page = 1;
    this.hrmService.filterEmployeeModel.pageSize = event;
    if (this.hrmService.isMoreFilterActive)
      this.filterEmployee();
    else if (this.searchEmployeeInputField)
      this.searchEmployeeByText();
    else
      this.listAllEmployees();
  }

  public viewPreviousPage(event: PageEvent) {
    if (this.hrmService.filterEmployeeModel.page !== 1) {
      this.hrmService.filterEmployeeModel.page--;

      if (this.hrmService.isMoreFilterActive)
        this.filterEmployee();
      else if (this.searchEmployeeInputField)
        this.searchEmployeeByText();
      else
        this.listAllEmployees();
    }
  }

  public viewNextPage(event: PageEvent) {
    if (this.hrmService.highValue !== this.hrmService.totalCount) {
      this.hrmService.filterEmployeeModel.page++;

      if (this.hrmService.isMoreFilterActive)
        this.filterEmployee();
      else if (this.searchEmployeeInputField)
        this.searchEmployeeByText();
      else
        this.listAllEmployees();
    }
  }

  filterEmployee() {
    var value = localStorage.getItem('filterStorageModel');
    if (value) {
      var obj = JSON.parse(value);
      obj.pageSize = this.hrmService.filterEmployeeModel.pageSize
      obj.page = this.hrmService.filterEmployeeModel.page
      this.hrmService.filterEmployee(obj).subscribe(
        res => {
          if (res) {
            if (res.data) {
              this.hrmService.refreshEmloyeeList.emit(res.data);
            }
          }
        },
        err => {
        });

    }
  }

  public listAllEmployees(text: string = null) {
    this.isLoading = true;
    if (text != null) {
      this.hrmService.isEmployeeSelected = false;
      this.idSelectedEmployee = 0;
    }
    this.isLoading = true;
    this._apiService.get(API.employee.ListAllEmployees)

    this.hrmService.listAllEmployees(this.hrmService.filterEmployeeModel).subscribe(response => {
      if (response && response.data != null) {
        this.employeelist = response.data.list;

        this.hrmService.totalCount = response.data.totalCount;
        this.hrmService.lowValue = (this.hrmService.filterEmployeeModel.page * this.hrmService.filterEmployeeModel.pageSize) - (this.hrmService.filterEmployeeModel.pageSize - 1);
        this.hrmService.highValue = this.employeelist.length === this.hrmService.filterEmployeeModel.pageSize ?
          (this.hrmService.filterEmployeeModel.page * this.hrmService.filterEmployeeModel.pageSize)
          : ((this.hrmService.filterEmployeeModel.page - 1) * this.hrmService.filterEmployeeModel.pageSize) + this.employeelist.length;

        for (let x = 0; x < this.employeelist.length; x++) {
          if (this.employeelist[x].dob) {
            let _todayDate = new Date().toISOString().split("T")[0];
            let _dob = this.employeelist[x].dob.split("T")[0];
            let month = moment(_todayDate).diff(moment(_dob), 'month', true);
            if (month >= 12) {
              let _year = (month - (month % 12)) / 12;
              this.employeelist[x].employeeAge = _year;
            }
            else {
              this.employeelist[x].employeeAge = null;
            }
          }
        }
        this.employeelist.forEach((employee: any) => {
          if (employee.prolfileImage && employee.profilePath != null && employee.profilePath != undefined) {
            employee.prolfileImage = 'data:image/png;base64,' + employee.prolfileImage;
          }
          let _todayDate = new Date().toISOString().split("T")[0];
          if (employee.jointDate) {
            let _joinDate = employee.jointDate.split("T")[0];
            let month = moment(_todayDate).diff(moment(_joinDate), 'month', true);
            if (month < 1) {
              let days = moment(_todayDate).diff(moment(_joinDate), 'day', true);
              employee.days = days;
            }
            else {
              let days = moment(_todayDate).diff(moment(_joinDate), 'day', true);
              let daysDiff = moment(_todayDate).date() - moment(_joinDate).date();

              if (daysDiff > 0) {
                employee.days = daysDiff;
              }
              else {
                let previousMonthLastDay = moment(_todayDate).subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
                let startOfMonth = moment().clone().endOf('month').format('YYYY-MM-DD');

                let daysDiffPreviousMonth = moment(previousMonthLastDay).date() - moment(_joinDate).date();
                let daysDiffCurrentMonth = moment(startOfMonth).diff(moment(_todayDate), 'day', true);
                employee.days = daysDiffPreviousMonth + daysDiffCurrentMonth;
              }
            }

            if (month >= 12) {
              let _year = (month - (month % 12)) / 12;
              employee.year = _year;
              let _month = (month % 12).toString();
              employee.month = parseInt(_month);
            }
            else {
              let _year = (month - (month % 12)) / 12;
              employee.year = _year;
              let _month = (month % 12).toString();
              employee.month = parseInt(_month);
            }
          }
          var currentDate = new Date();
          var eventStartTime = new Date();
          var eventEndTime = new Date(employee.jointDate);
          var diff = eventStartTime.valueOf() - eventEndTime.valueOf();
          var diff = Math.ceil(diff / (1000 * 3600 * 24)) / 30;
          employee.workingMonth = Math.round(diff);
          this.newEmpList = [];
          this.notiCount = 0;
          employee.totalClaim = 0;
          if (diff >= 12) {
            employee.completedOneYear = true;
            employee.entitleAmount = 50000;
            let insuranceList = employee.employeeInsurance
            insuranceList.forEach(element => {
              let amount = parseInt(element.insuranceAmount);
              employee.totalClaim = amount + employee.totalClaim;
              if (employee.totalClaim > 0) {
                employee.total = employee.entitleAmount - employee.totalClaim;
              }
            });
          } else {
            employee.completedOneYear = false;
          }
        })
        this.isLoading = false;
      }
    }, err => {
      console.error(err)
      this.isLoading = false;
    }, () => { this.isLoading = false; });
  }

  changeTab(event) {
    this.isTab = event.index;
  }

  selectEmployee(empObj, event) {
    if (empObj) {
      let employee = Object.assign({}, empObj);
      if (employee.prolfileImage == null || employee.prolfileImage == undefined) {
        this.hrmService.getEmployeeById(employee.id).subscribe(res => {
          if (res && res.data) {
            if (res.data.dob) {
              let _todayDate = new Date().toISOString().split("T")[0];
              let _dob = res.data.dob.split("T")[0];
              let month = moment(_todayDate).diff(moment(_dob), 'month', true);
              if (month >= 12) {
                let _year = (month - (month % 12)) / 12;
                res.data.employeeAge = _year;
              }
              else {
                res.data.employeeAge = null;
              }
            }
            if (res.data.jointDate != null) {
              var eventStartTime = new Date();
              var eventEndTime = new Date(res.data.jointDate);
              var diff = eventStartTime.valueOf() - eventEndTime.valueOf();
              var diff = Math.ceil(diff / (1000 * 3600 * 24)) / 30;
              res.data.workingMonth = Math.round(diff);
              this.newEmpList = [];
              this.notiCount = 0;
              res.data.totalClaim = 0;
              if (diff >= 12) {
                res.data.completedOneYear = true;
                res.data.entitleAmount = 50000;
                let insuranceList = res.data.employeeInsurance
                insuranceList.forEach(element => {
                  let amount = parseInt(element.insuranceAmount);
                  res.data.totalClaim = amount + employee.totalClaim;
                  if (res.data.totalClaim > 0) {
                    res.data.total = res.data.entitleAmount - res.data.totalClaim;
                  }
                });
              } else {
                res.data.completedOneYear = false;
              }
            }
            if (res.data.prolfileImage && res.data.profilePath != null && res.data.profilePath != undefined) {
              res.data.prolfileImage = 'data:image/png;base64,' + res.data.prolfileImage;
            }
            this.idSelectedEmployee = res.data.id;
            this.hrmService.refreshEmployee.emit({ employeeObject: res.data });
          }
        });
      } else {
        this.idSelectedEmployee = employee.id;
        this.hrmService.refreshEmployee.emit({ employeeObject: employee });
      }
    }
  }

  openFilterEmployeePopup() {
    this.commonUIservice.openDialog(FilterEmployeeComponent, null)
  }

  subscribeEmployeeFilter() {
    this.hrmService.refreshEmloyeeList.subscribe(res => {
      if (res) {
        this.isTab == 0;

        this.employeelist = res.list;

        this.hrmService.totalCount = res.totalCount;
        this.hrmService.lowValue = (this.hrmService.filterEmployeeModel.page * this.hrmService.filterEmployeeModel.pageSize) - (this.hrmService.filterEmployeeModel.pageSize - 1);
        this.hrmService.highValue = this.employeelist.length === this.hrmService.filterEmployeeModel.pageSize ?
          (this.hrmService.filterEmployeeModel.page * this.hrmService.filterEmployeeModel.pageSize)
          : ((this.hrmService.filterEmployeeModel.page - 1) * this.hrmService.filterEmployeeModel.pageSize) + this.employeelist.length;

        for (let x = 0; x < this.employeelist.length; x++) {
          if (this.employeelist[x].dob) {
            let _todayDate = new Date().toISOString().split("T")[0];
            let _dob = this.employeelist[x].dob.split("T")[0];
            let month = moment(_todayDate).diff(moment(_dob), 'month', true);
            if (month >= 12) {
              let _year = (month - (month % 12)) / 12;
              this.employeelist[x].employeeAge = _year;
            }
            else {
              this.employeelist[x].employeeAge = null;
            }
          }
          this.employeelist[x].employeeAge = null;
        }


        this.employeelist.forEach((employee: any) => {
          if (employee.prolfileImage && employee.profilePath != null && employee.profilePath != undefined) {
            employee.prolfileImage = 'data:image/png;base64,' + employee.prolfileImage;
          }
          if (employee.jointDate) {
            let _todayDate = new Date().toISOString().split("T")[0];
            let _joinDate = employee.jointDate.split("T")[0];
            let month = moment(_todayDate).diff(moment(_joinDate), 'month', true);
            if (month < 1) {
              let days = moment(_todayDate).diff(moment(_joinDate), 'day', true);
              employee.days = days;
            }

            if (month >= 12) {
              let _year = (month - (month % 12)) / 12;
              employee.year = _year;
              let _month = (month % 12).toString();
              employee.month = parseInt(_month);
            }
            else {
              let _year = (month - (month % 12)) / 12;
              employee.year = _year;
              let _month = (month % 12).toString();
              employee.month = parseInt(_month);
            }
          }
          var currentDate = new Date();
          var eventStartTime = new Date();
          var eventEndTime = new Date(employee.jointDate);
          var diff = eventStartTime.valueOf() - eventEndTime.valueOf();
          var diff = Math.ceil(diff / (1000 * 3600 * 24)) / 30;
          employee.workingMonth = Math.round(diff);
          this.newEmpList = [];
          this.notiCount = 0;
          employee.totalClaim = 0;
          if (diff >= 12) {
            employee.completedOneYear = true;
            employee.entitleAmount = 50000;
            let insuranceList = employee.employeeInsurance
            insuranceList.forEach(element => {
              let amount = parseInt(element.insuranceAmount);
              employee.totalClaim = amount + employee.totalClaim;
              if (employee.totalClaim > 0) {
                employee.total = employee.entitleAmount - employee.totalClaim;
              }
            });
          } else {
            employee.completedOneYear = false;
          }
        })
      } else {
        this.employeelist = null;
      }
    }
    )
  }

  subscribeEmployeeFilterClear() {
    this.hrmService.clearFilter.subscribe(res => {
      if (res == true) {
        this.listAllEmployees()
        this.newEmpList = [];
        this.hrmService.isMoreFilterActive = false;
      }
    });
  }

  openAddEmployeePopup() {
    this.hrmService.isEmployeeEdit = false;
    const dialogViewData = this.commonUIService.setDataForModalDialog(null, null, null, null, null, null);
    this.commonUIService.openDialog(AddEmployeeComponent, dialogViewData)
  }

  subscribeRefreshEmployeeList() {
    this.hrmService.refershListEmloyee.subscribe(res => {
      if (res) {
        this.listAllEmployees();
      }
    });
  }

  subscribeSelectedEmployee() {
    this.hrmService.selectedEmployee.subscribe(data => {
      if (data) {
        this.selectEmployee(data, event);
      }
    });
  }

  subscribeListAllEmployees() {
    this.authAppService.listAllEmployees.subscribe(data => {
      this.listAllEmployees("fromHeader");
    });
  }

  searchEmployeeByText() {
    if (this.searchEmployeeInputField != null) {
      this.isLoading = true;
      this.hrmService.filterEmployeeModel.searchText = this.searchEmployeeInputField;
      this.hrmService.filterEmployeeModel.page = 1;
      this.hrmService.filterEmployee(this.hrmService.filterEmployeeModel).subscribe(
        res => {
          if (res && res.data) {
            this.employeelist = res.data.list;

            this.hrmService.totalCount = res.data.totalCount;
            this.hrmService.lowValue = (this.hrmService.filterEmployeeModel.page * this.hrmService.filterEmployeeModel.pageSize) - (this.hrmService.filterEmployeeModel.pageSize - 1);
            this.hrmService.highValue = this.employeelist.length === this.hrmService.filterEmployeeModel.pageSize ?
              (this.hrmService.filterEmployeeModel.page * this.hrmService.filterEmployeeModel.pageSize)
              : ((this.hrmService.filterEmployeeModel.page - 1) * this.hrmService.filterEmployeeModel.pageSize) + this.employeelist.length;

            for (let x = 0; x < this.employeelist.length; x++) {
              if (this.employeelist[x].dob) {
                let _todayDate = new Date().toISOString().split("T")[0];
                let _dob = this.employeelist[x].dob.split("T")[0];
                let month = moment(_todayDate).diff(moment(_dob), 'month', true);
                if (month >= 12) {
                  let _year = (month - (month % 12)) / 12;
                  this.employeelist[x].employeeAge = _year;
                }
                else {
                  this.employeelist[x].employeeAge = null;
                }
              }
              this.employeelist[x].employeeAge = null;
            }

            this.employeelist.forEach((employee: any) => {
              if (employee.prolfileImage && employee.profilePath != null && employee.profilePath != undefined) {
                employee.prolfileImage = 'data:image/png;base64,' + employee.prolfileImage;
              }
              if (employee.jointDate) {
                let _todayDate = new Date().toISOString().split("T")[0];
                let _joinDate = employee.jointDate.split("T")[0];
                let month = moment(_todayDate).diff(moment(_joinDate), 'month', true);
                if (month < 1) {
                  let days = moment(_todayDate).diff(moment(_joinDate), 'day', true);
                  employee.days = days;
                }

                if (month >= 12) {
                  let _year = (month - (month % 12)) / 12;
                  employee.year = _year;
                  let _month = (month % 12).toString();
                  employee.month = parseInt(_month);
                }
                else {
                  let _year = (month - (month % 12)) / 12;
                  employee.year = _year;
                  let _month = (month % 12).toString();
                  employee.month = parseInt(_month);
                }
              }
              var currentDate = new Date();
              var eventStartTime = new Date();
              var eventEndTime = new Date(employee.jointDate);
              var diff = eventStartTime.valueOf() - eventEndTime.valueOf();
              var diff = Math.ceil(diff / (1000 * 3600 * 24)) / 30;
              employee.workingMonth = Math.round(diff);
              this.newEmpList = [];
              this.notiCount = 0;
              employee.totalClaim = 0;
              if (diff >= 12) {
                employee.completedOneYear = true;
                employee.entitleAmount = 50000;
                let insuranceList = employee.employeeInsurance
                insuranceList.forEach(element => {
                  let amount = parseInt(element.insuranceAmount);
                  employee.totalClaim = amount + employee.totalClaim;
                  if (employee.totalClaim > 0) {
                    employee.total = employee.entitleAmount - employee.totalClaim;
                  }
                });
              } else {
                employee.completedOneYear = false;
              }
            })
            this.isLoading = false;
          }
        },
        err => {
          this.isLoading = false;
        });
    }
  }
}

