import { Component, OnInit } from '@angular/core';
import { CommonDropdownService } from 'src/app/shared/services/common.dropdown.service';
import { UIService } from 'src/app/shared/services/ui.service';
import { APIService } from 'src/app/shared/services/api.service';
import API from 'src/app/shared/services/api.routes.json'
import moment from 'moment';
import { AuthAppService } from '../../../shared/services/auth-app.service';
import { SnackBar, SnackBarType } from '../../../shared/components/snackbar/entities/snackbar';
import { ClientService } from '../../../client/services/client.service';
import { Router } from '@angular/router';
import { EstimationService } from '../../service/estimation.service';
import { FilterEstimationModel } from '../../entities/filterEstimationModel';

@Component({
  selector: 'estimation-list',
  templateUrl: './estimation-list.component.html',
  styleUrls: ['./estimation-list.component.scss']
})

export class EstimationListComponent implements OnInit {

  isLoading: boolean = false;
  estimationList: any[] = [];
  idSelectedClient: number = 0;
  filterEstimationModel: FilterEstimationModel = new FilterEstimationModel();
  loggedInUser: any;
  selectedClientStatus: string = '';
  urlData: any;
  estimationcount: any = 0;

  constructor(public commonDropdownService: CommonDropdownService,
    public commonUIservice: UIService,
    public _apiService: APIService,
    public clientService: ClientService,
    public estimationService: EstimationService,
    private authAppService: AuthAppService,
    public router: Router) {
    this.loggedInUser = this.authAppService.getLoggedInUser();
    this.clientService.isMoreFilterActiveForTask = false;
  }

  ngOnInit() {
    this.getEstimationList();
    this.subscribeUpdateClient();
    this.subscribeRefreshEstimationList();
    this.urlData = this.router.url;
  }

  getEstimationList() {
    this.isLoading = true;
    this.clientService.listAllEstimations().subscribe(res => {
      if (res && res.data) {
        this.estimationList = res.data;
        this.estimationList.forEach(r => {
          r.estimationPhase.forEach(p => {
            if (p.userName) {
              var myStr = p.userName;
              var matches = myStr.match(/\b(\w)/g);
              var x = matches.join('');
              p.initials = x;
            }
            p.initials = null;
          });
        });
        this.isLoading = false;
      }
      else {
        const snack = new SnackBar(SnackBarType.error, 'Error on list', '', 2000);
        this.commonUIservice.openSnackBar(snack);
        this.isLoading = false;
      }
    },
      err => {
        console.error();
        const snack = new SnackBar(SnackBarType.error, 'Error on list', '', 2000);
        this.commonUIservice.openSnackBar(snack);
        this.isLoading = false;
      });
  }

  subscribeRefreshEstimationList() {
    this.clientService.refreshListForEstimationTab.subscribe(res => {
      if (res) {
        this.estimationList = res;
      } else {
        this.estimationList = null;
      }
    })
  }

  public selectProjectEstimation(estimation) {
    const except = $(event.target).hasClass('except');
    if (!except) {
      if (estimation.clientId) {
        this.idSelectedClient = estimation.id;
        estimation.isSelectedItem = true;
        estimation.url = this.urlData;       
        this.clientService.selectedClient.emit(estimation);
      }
    }
  }

  subscribeUpdateClient() {
    this.clientService.refreshUpdateClient.subscribe(res => {
      if (res == true) {
        this.getEstimationList();
      }
    });
  }

  filterEstimation(event) {
    this.isLoading = true;
    if (this.filterEstimationModel.estimationDescription == "" || this.filterEstimationModel.estimationDescription == undefined)
      this.filterEstimationModel.estimationDescription = null;

    this.clientService.filterEstimations(this.filterEstimationModel).subscribe(res => {
      if (res && res.data) {
        this.estimationList = res.data;
        this.estimationList.forEach(r => {
          r.estimationPhase.forEach(p => {
            var myStr = p.userName;
            var matches = myStr.match(/\b(\w)/g);
            var x = matches.join('');
            p.initials = x;
          });
        });
        this.isLoading = false;
      }
      else {
        const snack = new SnackBar(SnackBarType.error, 'Error on list', '', 2000);
        this.commonUIservice.openSnackBar(snack);
        this.isLoading = false;
      }
    },
      err => {
        console.error();
        const snack = new SnackBar(SnackBarType.error, 'Error on list', '', 2000);
        this.commonUIservice.openSnackBar(snack);
        this.isLoading = false;
      });
  }
}

