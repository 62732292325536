import { Component, OnInit } from '@angular/core';
import { MaintenanceService } from '../service/maintenance.service';
import { ReferalSourceModel } from 'src/app/shared/entities/referalSource';
import { APIService } from 'src/app/shared/services/api.service';
import API from '../../shared/services/api.routes.json';
import { AlertModel } from 'src/app/shared/entities/alertModel';
import { UIService } from 'src/app/shared/services/ui.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { SnackBar, SnackBarType } from 'src/app/shared/components/snackbar/entities/snackbar';
import { AddEditReferalsourceComponent } from './add-edit-referalsource/add-edit-referalsource.component';

@Component({
  selector: 'app-referal-source',
  templateUrl: './referal-source.component.html',
  styleUrls: ['./referal-source.component.scss']
})
export class ReferalSourceComponent implements OnInit {
  referalSourceList: ReferalSourceModel[] = [];
  _idSelectedReferalSource: number;
  updateReferalSource: ReferalSourceModel;
  isLoading: boolean = false;

  constructor(
    private maintanceService: MaintenanceService,
    private _apiService: APIService,
    private uIService: UIService,
    private alertService: AlertService,
    public maintenanceService: MaintenanceService
  ) {
    this.referalSourceList = [];

  }

  ngOnInit() {
    this.updateReferalSource = new ReferalSourceModel();
    this.loadReferalSource();
    this.loadReferalSourceAfterDelete();
    this.maintenanceService.subjectSourcerefresh.subscribe(isRefesh => {
      if (isRefesh) {
        this.loadReferalSource();
      }
    });

  }

  loadReferalSourceAfterDelete() {

    this.alertService.referenceDelete.subscribe(data => {
      if (data == "Delete ReferenSource") {
        this.deleteReferalSourceConform(this._idSelectedReferalSource);
      }
    });
  }



  loadReferalSource() {
    this.isLoading = true;
    this._apiService.get(API.referralSource.GetAllReferralSource).subscribe(response => {
      if (response) {
        if (response.data) {
          this.referalSourceList = response.data;
        }
      }
      this.isLoading = false;
    });
  }

  deleteReferalSource(id) {
    let deleteData = new AlertModel();
    deleteData.title = "Delete";
    deleteData.msg = "Are you sure to delete this record?";
    deleteData.id = id;
    deleteData.reference = "Delete ReferenSource";
    this._idSelectedReferalSource = id;
    this.uIService.openAlert(deleteData);

  }

  deleteReferalSourceConform(id) {
    this.maintanceService.deleteReferalSource(id).subscribe(res => {
      if (res.isSuccsess) {
        var snack = new SnackBar(SnackBarType.success, res.message, "", 2000);
        this.uIService.openSnackBar(snack);
        this.loadReferalSource();
      }
      else {
        var snack = new SnackBar(SnackBarType.error, "Error", "", 2000);
        this.uIService.openSnackBar(snack);
      }

    });

  }

  addReferalSource() {
    this.uIService.openDialog(AddEditReferalsourceComponent, null)
  }

  editReferalSource(list) {
    const projectState = Object.assign({}, list);
    const dialogViewData = this.uIService.setDataForModalDialog(null, null, null, projectState, null, null);
    this.uIService.openDialog(AddEditReferalsourceComponent, dialogViewData);
  }
  activeDeactive(event, model) {
    model.active = event.checked;
    this.maintenanceService.updateReferalSource(model.id, model).subscribe(
      (res: { isSuccsess: any; }) => {
        if (res.isSuccsess) {
          var snack = new SnackBar(SnackBarType.success, "Successfully updated", "", 2000);
          this.uIService.openSnackBar(snack);
          this.maintenanceService.referalRefresh(true);
        }
        else {
          snack = new SnackBar(SnackBarType.success, "Error on update", "", 2000);
          this.uIService.openSnackBar(snack);
        }
      },
      () => { console.error(); });
  }
}
