import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'hrm',
    templateUrl: './hrm.component.html',
    styleUrls: ['./hrm.component.scss']
})

  export class HRMComponent {
  }
