import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { DialogDataForModalView, UIService } from 'src/app/shared/services/ui.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { TaskTypeModel } from 'src/app/shared/entities/taskTypeModel';
import { SnackBar, SnackBarType } from 'src/app/shared/components/snackbar/entities/snackbar';
import { MaintenanceService } from '../../service/maintenance.service';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { PositionModel } from 'src/app/shared/entities/positionModel';
@Component({
  selector: 'app-add-editposition',
  templateUrl: './add-editposition.component.html',
  styleUrls: ['./add-editposition.component.scss']
})

export class AddEditpositionComponent implements OnInit {

  positionFromGroup: FormGroup;
  isEdit: boolean;
  positionType: PositionModel;
  isDisabledPosition = false;

  constructor(
    private maintatanceService: MaintenanceService,
    private _formBuilder: FormBuilder,
    private uIService: UIService,
    private validationService: ValidationService,
    public dialogRef: MatDialogRef<AddEditpositionComponent>,
    @Inject(MAT_DIALOG_DATA) public taskTypeData: DialogDataForModalView, ) {
    this.positionType = new PositionModel();
  }

  ngOnInit() {
    this.checkDataForEdit();
    this.formvalidate();
  }

  formvalidate() {
    this.positionFromGroup = this._formBuilder.group({
      descriptiontype: ['', [Validators.required, this.validationService.noWhitespaceValidator]]
    })
  }

  checkDataForEdit() {
    if (this.taskTypeData.data) {
      this.positionType = Object.assign({}, this.taskTypeData.data).modalData;
      this.isEdit = true;
    }
  }

  submit(model: { id: any; active:boolean }) {

    if (this.positionFromGroup.invalid) {
      this.isDisabledPosition = false;
      return;
    }
    else if (this.isEdit) {
      this.maintatanceService.updatePostion(model.id, model).subscribe(res => {

        if (res.isSuccsess) {
          let snack = new SnackBar(SnackBarType.success, "Successfully updated", "", 2000);
          this.uIService.openSnackBar(snack);
          this.maintatanceService.PositionTypeRefresh(true);
          this.closeDialog();
        }
        else {
          let snack = new SnackBar(SnackBarType.success, res.message, "", 2000);
          this.uIService.openSnackBar(snack);
          this.closeDialog();
        }
      });
    }
    else {
      this.isDisabledPosition = true;
      model.active  = true;
      this.maintatanceService.SavePosition(model).subscribe(res => {

        if(res.isSuccsess)
        {
          let snack = new SnackBar(SnackBarType.success, "Successfully saved", "", 2000);
          this.uIService.openSnackBar(snack);
          this.closeDialog();
          this.maintatanceService.PositionTypeRefresh(true);

        }
        else {
          let snack = new SnackBar(SnackBarType.success, res.message, "", 2000);
          this.uIService.openSnackBar(snack);
          this.closeDialog();
        }
      });
    }

  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}

