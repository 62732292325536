import { Component, OnInit } from '@angular/core';
import { APIService } from 'src/app/shared/services/api.service';
import API from '../../shared/services/api.routes.json';
import { AlertModel } from 'src/app/shared/entities/alertModel';
import { UIService } from 'src/app/shared/services/ui.service';
import { Subscription } from 'rxjs';
import { AlertService } from 'src/app/shared/services/alert.service';
import { SnackBar, SnackBarType } from 'src/app/shared/components/snackbar/entities/snackbar';
import { MaintenanceService } from '../service/maintenance.service';
import { AddEditpositionComponent } from './add-editposition/add-editposition.component';
import { PositionModel } from 'src/app/shared/entities/positionModel';

@Component({
  selector: 'app-position',
  templateUrl: './position.component.html',
  styleUrls: ['./position.component.scss']
})

export class PositionComponent implements OnInit {
  _positionList: PositionModel[] = [];
  positionList: PositionModel[] = [];
  updatePosition: PositionModel;
  _idSelectedPosition: number;
  private subscriber: Subscription;
  isLoading: boolean = false;

  constructor(
    private _apiService: APIService, private uIService: UIService, private alertService: AlertService, private maintananceService: MaintenanceService) {
    this._positionList = [];
  }

  ngOnInit() {
    this.updatePosition = new PositionModel();
    this.loadPositionList();
    this.SubscribePositionList();
    this.maintananceService.subjectPositionTypeRefresh.subscribe(istrue => {

      if (istrue) {
        this.loadPositionList();
      }
    })
  }

  SubscribePositionList() {
    this.subscriber = this.alertService.referenceDelete.subscribe(data => {
      if (data == "Delete Position") {
        this.deletePositionConform(this._idSelectedPosition)
      }
    });
  }

  loadPositionList() {
    this.isLoading = true;
    this._apiService.get(API.position.getAllPosition).subscribe(response => {
      if (response) {
        if (response.data) {
          this.positionList = response.data;
          this._positionList = this.positionList.sort((a, b) => a.description.localeCompare(b.description));
        }
      }
      this.isLoading = false;
    });
  }

  deletePosition(id) {
    let deleteData = new AlertModel();
    deleteData.title = "Delete";
    deleteData.msg = "Are you sure to delete this record?";
    deleteData.id = id;
    deleteData.reference = "Delete Position";
    this._idSelectedPosition = id;
    this.uIService.openAlert(deleteData);

  }

  addPosition() {
    this.uIService.openDialog(AddEditpositionComponent, null);
  }

  editPosition(data) {
    const setdataPosition = Object.assign({}, data);
    const dialogViewData = this.uIService.setDataForModalDialog(null, null, null, setdataPosition, null, null);
    this.uIService.openDialog(AddEditpositionComponent, dialogViewData);
  }

  deletePositionConform(id) {
    this.maintananceService.deletePosition(id).subscribe(res => {
      if (res.isSuccsess) {
        var snack = new SnackBar(SnackBarType.success, res.message, "", 2000);
        this.uIService.openSnackBar(snack);
        if (res.data) this.loadPositionList();
      }
      else {
        var snack = new SnackBar(SnackBarType.error, "Error", "", 2000);
        this.uIService.openSnackBar(snack);
      }
    });
  }
  activeDeactive(event, model) {
    model.active = event.checked;
    this.maintananceService.updatePostion(model.id, model).subscribe(
      (res: { isSuccsess: any; }) => {
        if (res.isSuccsess) {
          var snack = new SnackBar(SnackBarType.success, "Successfully updated", "", 2000);
          this.uIService.openSnackBar(snack);
          this.maintananceService.PositionTypeRefresh(true);
        }
        else {
          snack = new SnackBar(SnackBarType.success, "Error on update", "", 2000);
          this.uIService.openSnackBar(snack);
        }
      },
      () => { console.error(); });
  }
}

