import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UIService, DialogDataForModalView } from 'src/app/shared/services/ui.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SnackBar, SnackBarType } from 'src/app/shared/components/snackbar/entities/snackbar';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/auth/login/login.service';
import { Users } from 'src/app/auth/entities/user';
import { CommonDropdownService } from '../../../shared/services/common.dropdown.service';
import moment from 'moment';
import { SharedHeaderService } from '../../../shared/services/shared.header.services';
import { isNumber } from 'util';
import { FormatMatDatepicker } from '../../../shared/format-datepicker';
import { ProductService } from '../../service/product.service';
import { ProductPack } from '../../entities/productPack';

@Component({
  selector: 'app-add-edit-product-pack',
  templateUrl: './add-edit-product-pack.component.html',
  styleUrls: ['./add-edit-product-pack.component.scss']
})

export class AddEditProductPackComponent implements OnInit {

  isEdit: boolean = false;
  productPackFormGroup: FormGroup;
  _productPackModal: ProductPack;
  isDisabledProductPack: boolean = false;

  constructor(
    private _formBuilder: FormBuilder,
    public commonUIService: UIService,
    private uIService: UIService,
    private route: Router,
    private loginServic: LoginService,
    private validationSerivce: ValidationService,
    public sharedHeaderService: SharedHeaderService,
    private formatDatepicker: FormatMatDatepicker,
    public productService: ProductService,
    private commonDropdownService: CommonDropdownService,
    public dialogRef: MatDialogRef<AddEditProductPackComponent>,
    @Inject(MAT_DIALOG_DATA) public modalViewData: DialogDataForModalView) {
    this._productPackModal = new ProductPack();
  }

  ngOnInit() {
    this.initFormGroup();
    this.loadProductPackDataForEditView();   
  }

  initFormGroup() {
    this.productPackFormGroup = this._formBuilder.group({
      packName: [''],
      subscriptionType: [''],
      oneTimePrice: [''],
      subscriptionPrice: [''],
      active: ['']
    });
  }

  loadProductPackDataForEditView(): void { 
    if (this.modalViewData.data.id) {
     this.productService.getProductPackById(this.modalViewData.data.id).subscribe(result => {
        this._productPackModal = Object.assign({}, result.data);  
        this.setProductPackDataForEditView();
      },error=>{
        console.log(error);
      });     
    } else {
      this._productPackModal.productId = this.modalViewData.data.modalData.id;  
      this.setProductPackDataForEditView();
    }
    
  }

  setProductPackDataForEditView(): void {    
    if (this.modalViewData.data.id) {    
      this._productPackModal.productId =  this._productPackModal.productId;
      this._productPackModal.subscriptionType = this._productPackModal.subscriptionType!=null?this._productPackModal.subscriptionType.toString():'';
      this.isEdit = true;
    } else {     
      this.isEdit = false;
    }
  }

  async submit(productPack) {
    if (this.productPackFormGroup.invalid) {
      this.isDisabledProductPack = false;
      return;
    }
    let productPackObj = Object.assign({}, productPack);
    if (productPackObj.subscriptionPrice == null || productPackObj.subscriptionPrice == undefined) {
      productPackObj.subscriptionType = "0";
    }
    if (this.isEdit) {
      this.updateProductPack(productPack.id, productPackObj);
    } else {
      this.addProductPack(productPackObj);
    }
  }

  addProductPack(productPackObj) {
    this.isDisabledProductPack = true;
    this.productService.saveProductPackData(productPackObj).subscribe(
      res => {
        if (res.isSuccsess) {
          this.refreshProductPackList(productPackObj.productId);
          this.closeDialog();          
          this.productService.refreshProductPack.emit(true);
        }
      },
      () => {
        console.error(); this.closeDialog();
      });
  }

  updateProductPack(productPackId, productPackObj) {   
    this.productService.updateProductPack(productPackId, productPackObj).subscribe(
      res => {       
        if (res.isSuccsess) {         
          this.refreshProductPackList(productPackObj.productId);
          this.closeDialog();
          this.productService.refreshProductPack.emit(true);    

        }
      },
      () => {
        console.error();
        this.closeDialog();
      });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  activeCheckEvent(event) {
    this._productPackModal.active = event.checked;
  }

  refreshProductPackList(productId) {  
    this.productService.getProductList().subscribe(res => {
      if (res && res.data) {
        this.productService.productList = [];
        this.productService.activeProductList = [];
        this.productService.inActiveProductList = [];
        if (this.productService.isActiveProduct) {        
          this.productService.activeProductList = res.data.filter(x => x.active != null && x.active == this.productService.isActiveProduct);
          this.productService.productList = this.productService.activeProductList;
        }
        if (!this.productService.isActiveProduct) {      
          this.productService.inActiveProductList = res.data.filter(x => x.active == null || x.active == this.productService.isActiveProduct);
          this.productService.productList = this.productService.inActiveProductList;
        }
      }
      else {
        const snack = new SnackBar(SnackBarType.error, 'Error on list', '', 2000);
        this.commonUIService.openSnackBar(snack);
        this.productService.productList = [];
        this.productService.activeProductList = [];
        this.productService.inActiveProductList = [];
      }

      if (this.productService.productList.length > 0) {
        let products = this.productService.productList.find(x => x.id == productId);
        if (products.productPack) {
          this.productService.productPackList = products.productPack;
          if (this.productService.isActiveProductPack) {
            this.productService.activeProductPackList = this.productService.productPackList.filter(x => x.active != null && x.active == this.productService.isActiveProductPack);
            this.productService.productPackList = this.productService.activeProductPackList;
          }
          if (!this.productService.isActiveProductPack) {
            this.productService.inActiveProductPackList = this.productService.productPackList.filter(x => x.active == null || x.active == this.productService.isActiveProductPack);
            this.productService.productPackList = this.productService.inActiveProductPackList;
          }
        }
        else {
          this.productService.productPackList = [];
          this.productService.activeProductPackList = [];
          this.productService.inActiveProductPackList = [];
        }

        const snack = new SnackBar(SnackBarType.success, 'Successfully updated', '', 2000);
        this.commonUIService.openSnackBar(snack); 
      }
    },
      err => { 
        
        console.error(err);
        const snack = new SnackBar(SnackBarType.error, 'Error occurred', '', 2000);
        this.commonUIService.openSnackBar(snack); 
      }, () => { });
  }


}
