import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { regionModel } from 'src/app/shared/entities/regionModel';
import { MaintenanceService } from '../../service/maintenance.service';
import { SnackBar, SnackBarType } from 'src/app/shared/components/snackbar/entities/snackbar';
import { UIService, DialogDataForModalView } from 'src/app/shared/services/ui.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { empty } from 'rxjs';

@Component({
  selector: 'app-add-edit-region',
  templateUrl: './add-edit-region.component.html',
  styleUrls: ['./add-edit-region.component.scss']
})
export class AddEditRegionComponent implements OnInit {

  _regionType: regionModel;
  regionFromGroup: FormGroup;
  isEdit: boolean = false;
  isDisabledRegion = false;

  constructor(private _formBuilder: FormBuilder,
    private maintatanceService: MaintenanceService,
    private uIService: UIService,
    private validationService: ValidationService,
    public dialogRef: MatDialogRef<AddEditRegionComponent>,
    @Inject(MAT_DIALOG_DATA) public regionTypeData: DialogDataForModalView) { }

  ngOnInit() {
    this.formvalidate();
    this._regionType = new regionModel();
    this.editRegionView();
  }

  editRegionView() {
    if (this.regionTypeData.data) {
      this._regionType = Object.assign({}, this.regionTypeData.data).modalData;
      this.isEdit = true;
    }
  }

  formvalidate() {
    this.regionFromGroup = this._formBuilder.group({
      regiontype: ['', [Validators.required, this.validationService.noWhitespaceValidator]]
    })
  }

  async submit(regionData) {
    if (this.regionFromGroup.invalid) {
      this.isDisabledRegion = false;
      return;
    }
    else if (!this.isEdit) {
      this.isDisabledRegion = true;
      this.maintatanceService.saveRegionType(regionData).subscribe(res => {

        if (res.message == "Successfully saved") {
          var snack = new SnackBar(SnackBarType.success, res.message, "", 2000);
          this.uIService.openSnackBar(snack);
          this.closeDialog();
          this.maintatanceService.RegionTypeRefresh(true);
        }
        else (res.message == "Error on save")
        {
          var snack = new SnackBar(SnackBarType.success, res.message, "", 2000);
          this.uIService.openSnackBar(snack);
          this.closeDialog();
        }
      });
    }
    else if (this.isEdit) {
      this.maintatanceService.updateRegionType(regionData).subscribe(res => {

        if (res.message == "Successfully updated") {
          var snack = new SnackBar(SnackBarType.success, "Successfully updated", "", 2000);
          this.uIService.openSnackBar(snack);
          this.closeDialog();
          this.maintatanceService.RegionTypeRefresh(true);
        }
        else (res.message == "Error on update")
        {
          var snack = new SnackBar(SnackBarType.success, res.message, "", 2000);
          this.uIService.openSnackBar(snack);
          this.closeDialog();
        }
      });
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
  // onKey(event: any) { 
  //  
  //   if(this.nullValue)
  //   {
  //     event.target.target='';
  //     this.nullValue=false;
  //   }
  // } 
