import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { APIService } from 'src/app/shared/services/api.service';
import { HrmService } from 'src/app/hrm/service/hrm.service';
import { UIService, DialogDataForModalView } from 'src/app/shared/services/ui.service';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormatMatDatepicker } from 'src/app/shared/format-datepicker';
import { CommonDropdownService } from 'src/app/shared/services/common.dropdown.service';
import { Subject } from 'rxjs';
import { EmpInsurance } from 'src/app/hrm/entities/EmpInsurance';
import { SnackBarType, SnackBar } from 'src/app/shared/components/snackbar/entities/snackbar';
import { HrmMetaData } from 'src/app/shared/entities/hrmMetadataModel';
import { HrmMetaDataEnum } from 'src/app/shared/enum/hrmMetadataType';

@Component({
  selector: 'app-add-hrm-insurance',
  templateUrl: './add-hrm-insurance.component.html',
  styleUrls: ['./add-hrm-insurance.component.scss']
})
export class AddHrmInsuranceComponent implements OnInit {
  empInsuranceFormGroup: FormGroup;
  private _unsubscribeAll: Subject<any>;
  _employeeInsuranceModal: EmpInsurance;
  isFileRequired = false;
  toggle = true;
  _empInsuranceForSave: EmpInsurance;
  isAmountExeed: boolean = false;
  isInsuranceTypeRequired: boolean = false;

  insuranceList: HrmMetaData[] = [];

  constructor(
    private _apiService: APIService,
    private _formBuilder: FormBuilder,
    private hrmService: HrmService,
    private commonUIService: UIService,
    private validationService: ValidationService,
    public dialogRef: MatDialogRef<AddHrmInsuranceComponent>,
    @Inject(MAT_DIALOG_DATA) public modalViewData: DialogDataForModalView,
    private formatDatepicker: FormatMatDatepicker,
    public commonDropdownService: CommonDropdownService
  ) {
    this._employeeInsuranceModal = new EmpInsurance();
    this._empInsuranceForSave = new EmpInsurance();
  }

  ngOnInit() {
    this.initInsuranceFormGroup();
    this.getInsuranceTypeDetails()
  }

  initInsuranceFormGroup() {
    this.empInsuranceFormGroup = this._formBuilder.group({
      date: ['', [Validators.required]],
      description: [''],
      file: [null, [Validators.required]],
      insuranceAmount: ['', [Validators.required]],
      insuranceTypeId: ['', [Validators.required]]
    });
    if (this.modalViewData.data.modalData) {
      this.setValuesForEdit();
    }
  }

  getInsuranceTypeDetails() {
    let url = this._apiService.getEndPoint("hrmMetaData.getHrmMetadataByType", [["{type}", HrmMetaDataEnum.Insurance], ["{isOnlyActive}", true]]);

    this._apiService.get(url).subscribe(response => {
      if (response) {
        if (response.data) {
          this.insuranceList = response.data;
        }
      }
    });
  }

  setValuesForEdit() {
    this._employeeInsuranceModal = this.modalViewData.data.modalData;
    if (this.hrmService.isEmployeeInsuranceEdit == true)
      this._employeeInsuranceModal.insuranceTypeId = this.modalViewData.data.modalData.insuranceTypeId.toString();
  }

  async submit(employeeInsuranceModal: EmpInsurance) {
    let valid = this.empInsuranceFormGroup.get('file').touched && this.empInsuranceFormGroup.get('file').valid;
    this.isFileRequired = !valid;
    this.toggle = valid;

    if (this.empInsuranceFormGroup.invalid) {
      return;
    }
    this._empInsuranceForSave = Object.assign({}, employeeInsuranceModal);
    this._empInsuranceForSave.dateStr = employeeInsuranceModal.date ? this.formatDatepicker.ConvertDateFormatForSave(employeeInsuranceModal.date) : employeeInsuranceModal.date;
    this.onSaveInsuranceDetails();
  }



  async onSaveInsuranceDetails() {
    if (this._empInsuranceForSave) {
      if (this.modalViewData.data.id) {
        this._empInsuranceForSave.employeeId = this.modalViewData.data.id;
        if (this._empInsuranceForSave.fileList.length > 0) {
          this._empInsuranceForSave.fileName = (this._empInsuranceForSave.fileList[0]).name;
          this._empInsuranceForSave.extension = ((this._empInsuranceForSave.fileList[0]).name).split('.')[(((this._empInsuranceForSave.fileList[0]).name).split('.')).length - 1];
          this.hrmService.saveEmployeeInsurance(this._empInsuranceForSave).subscribe(res => {
            if (res && res.data) {
              const formData: FormData = new FormData();
              formData.append('file', this._employeeInsuranceModal.fileList[0], res.data.generatedFileName);
              formData.append('employeeId', res.data.employeeId.toString());
              formData.append('fileId', res.data.id.toString());
              formData.append('letterTypeId', "0");
              formData.append('empInsuranceId', res.data.id.toString());
              this.uploadEmpInsuranceFile(formData);
            }
          });
          this.closeDialog();
        }
      }
    }
  }

  uploadEmpInsuranceFile(formData: FormData) {
    this.hrmService.uploadEmpInsuranceFile(formData).subscribe(
      res => {
        if (+res == 200 || +(res.statusCode) == 200) {
          const snack = new SnackBar(SnackBarType.success, 'Successfully saved', '', 4000);
          this.commonUIService.openSnackBar(snack);
          this.hrmService.refreshEmployeeInsurance.emit(res);
          this.closeDialog();
        } else {
          const snack = new SnackBar(SnackBarType.success, 'Error on save', '', 4000);
          this.commonUIService.openSnackBar(snack);
        }
      },
      () => { console.error(); this.closeDialog(); });
  }

  onChooseFile(event: any): void {
    if (event) {
      let files: any = event.target.files;
      this._employeeInsuranceModal.fileList = files;
      this.toggle = true;
      this.isFileRequired = false;
    }
  }

  onCheckAmount() {
    if (this._employeeInsuranceModal.insuranceAmount) {
      if (this._employeeInsuranceModal.insuranceTypeId) {
        let insuranceValue = parseFloat(this.insuranceList.find(x => x.id === this._employeeInsuranceModal.insuranceTypeId).value);
        this.isInsuranceTypeRequired = false;
        let insuAmt = +this._employeeInsuranceModal.insuranceAmount;
        let totalOfThisCategory = 0;
        this.modalViewData.data.modalData.insuranceList.forEach((item) => {
          if(item.insuranceTypeId === this._employeeInsuranceModal.insuranceTypeId)
          {
            totalOfThisCategory = totalOfThisCategory + item.insuranceAmount;
          }
        });
        let total = totalOfThisCategory + insuAmt;
        this.isAmountExeed = (total > insuranceValue);
      } else {
        this.isInsuranceTypeRequired = true;
      }
    } else {
      this.isAmountExeed = false;
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  selectInsuranceType() {
    if (this._employeeInsuranceModal.insuranceTypeId) {
      this.isInsuranceTypeRequired = false;
      this.onCheckAmount();
    } else {
      if (this._employeeInsuranceModal.insuranceAmount) {
        this.isInsuranceTypeRequired = true;
        this.onCheckAmount();
      }
      else
        this.isInsuranceTypeRequired = false;
    }
  }

  closeDialogBox() {
    this.hrmService.cancelInsurance.emit(true);
  }
}
