import { Component, OnInit, Input, OnChanges, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { InventoryModel } from 'src/app/inventory/entities/inventoryModel';
import { UIService } from 'src/app/shared/services/ui.service';
import { CommonDropdownService } from 'src/app/shared/services/common.dropdown.service';
import { FormatMatDatepicker } from 'src/app/shared/format-datepicker';
import { InventoryService } from 'src/app/inventory/service/inventoryService';
import { startWith, map } from 'rxjs/operators';
import { SnackBar, SnackBarType } from 'src/app/shared/components/snackbar/entities/snackbar';
import { AlertModel } from '../../../../shared/entities/alertModel';
import { AlertService } from '../../../../shared/services/alert.service';
import { AddInventoryUserComponent } from '../inventory-user/add-inventory-user/add-inventory-user.component';

@Component({
  selector: 'app-monitor-detail',
  templateUrl: './monitor-detail.component.html',
  styleUrls: ['./monitor-detail.component.scss']
})
export class MonitorDetailComponent implements OnInit, OnChanges, OnDestroy {

  options: string[] = ['One', 'Two', 'Three'];
  filteredOptions: Observable<string[]>;
  showEditSave: boolean = false;
  myControl = new FormControl();
  @Input() inventoryDetailFormGroup: FormGroup;
  @Input() inventoryModel: InventoryModel;
  saveInventoryUser: boolean = false;
  subscriber: Subscription;
  subscriberForAlertConfirm: Subscription;

  constructor(public commonDropdownService: CommonDropdownService,
    public commonUIService: UIService,
    public formatDatepicker: FormatMatDatepicker,
    public alertService: AlertService,
    public inventoryService: InventoryService) {
    this.inventoryModel = new InventoryModel();
    this.inventoryModel.isEdit = false;
    this.inventoryModel.state = 0;
    this.subscribeAlertConfirmEventsForMonitor();
  }

  ngOnInit() {
    this.filteredOptions = this.inventoryDetailFormGroup.get('name').valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
    this.commonDropdownService.LoadInventoryDropdownList();
    this.commonDropdownService.LoadProviderList();
    this.commonDropdownService.LoadCurrencyList();
    this.commonDropdownService.LoadUserList();
    this.subscribeAlertCloseEventsForMonitor();
    this.inventoryDetailFormGroup.reset();

    if (this.inventoryDetailFormGroup) {
      this.inventoryDetailFormGroup.get('type').markAsUntouched();
      this.inventoryDetailFormGroup.get('code').markAsUntouched();
      this.inventoryDetailFormGroup.get('date').markAsUntouched();
    }
  }

  ngOnChanges() {
    if (this.inventoryDetailFormGroup) {
      this.inventoryDetailFormGroup.get('type').markAsUntouched();
      this.inventoryDetailFormGroup.get('code').markAsUntouched();
      this.inventoryDetailFormGroup.get('date').markAsUntouched();
    }
  }

  ngOnDestroy() {
    this.subscriberForAlertConfirm.unsubscribe();
  }

  private _filter(value: string): string[] {
    return this.options.filter(option => option.toLowerCase());
  }

  changeInventoryStatusForMonitors(event, inventoryModel) {
    if (event.value == "1") {
      this.inventoryModel.checkIsInuse = true;
      this.inventoryModel.state = 1;
      let alterData = new AlertModel();
      alterData.title = 'Warning';
      alterData.msg = 'Are you sure you want to change the status?';
      alterData.id = inventoryModel.id;
      alterData.reference = '1';
      alterData.selectedTabIndex = 2;
      this.commonUIService.openAlert(alterData);
    } else if (event.value == "0") {
      this.inventoryModel.checkIsInuse = false;
      this.inventoryModel.state = 0;
      this.inventoryModel.name = null;
      this.inventoryModel.currentUser = null;
      let alterData = new AlertModel();
      alterData.title = 'Warning';
      alterData.msg = 'Are you sure you want to change the status?';
      alterData.id = inventoryModel.id;
      alterData.reference = '0';
      alterData.selectedTabIndex = 2;
      this.commonUIService.openAlert(alterData);
    }
    else if (event.value == "2") {
      this.inventoryModel.checkIsInuse = false;
      this.inventoryModel.state = 2;
      this.inventoryModel.name = null;
      this.inventoryModel.currentUser = null;
      let alterData = new AlertModel();
      alterData.title = 'Warning';
      alterData.msg = 'Are you sure you want to change the status?';
      alterData.id = inventoryModel.id;
      alterData.reference = '2';
      alterData.selectedTabIndex = 2;
      this.commonUIService.openAlert(alterData);
    }
    else if (event.value == "3") {
      this.inventoryModel.checkIsInuse = false;
      this.inventoryModel.state = 3;
      this.inventoryModel.name = null;
      this.inventoryModel.currentUser = null;
      let alterData = new AlertModel();
      alterData.title = 'Warning';
      alterData.msg = 'Are you sure you want to change the status?';
      alterData.id = inventoryModel.id;
      alterData.reference = '3';
      alterData.selectedTabIndex = 2;
      this.commonUIService.openAlert(alterData);
    }
  }

  public editMonitor() {
    if (!this.inventoryModel.showEditSave) {
      this.inventoryModel.showEditSave = true;
    }
    if (this.inventoryDetailFormGroup.disabled) {
      this.inventoryDetailFormGroup.enable();
    }
  }

  saveMonitor(model, statusChangeString = null) {
    if (this.inventoryModel.showEditSave == false && statusChangeString == 'status_change_monitor') {

    } else {
      if (this.inventoryModel.isEdit == false) {
        this.inventoryDetailFormGroup.get('type').markAsTouched();
        this.inventoryDetailFormGroup.get('code').markAsTouched();
        this.inventoryDetailFormGroup.get('date').markAsTouched();
        this.inventoryDetailFormGroup.get('type').patchValue("MONITOR");

        var typeValue = this.inventoryDetailFormGroup.get('type').value;
        var codeValue = this.inventoryDetailFormGroup.get('code').value;
        var dateValue = this.inventoryDetailFormGroup.get('date').value;
        if (this.inventoryDetailFormGroup.invalid || typeValue == undefined || typeValue == null || codeValue == undefined || codeValue == null || dateValue == undefined || dateValue == null) {
          this.inventoryDetailFormGroup.enable();
          return
        }
        else {
          let invetoryObj = Object.assign({}, model);
          if (invetoryObj.name == undefined && invetoryObj.name != null) {
            invetoryObj.currentUser = null;
            invetoryObj.state = 0;
          }
          if (invetoryObj.currentUser != invetoryObj.name) {
            invetoryObj.currentUser = invetoryObj.name;
            invetoryObj.state = 1;
          } else {
            invetoryObj.currentUser = invetoryObj.name;
            if (![1, 2, 3].includes(invetoryObj.state)) {
              invetoryObj.state = 0;
            }
          }

          if (invetoryObj.currentUser == null) {
            if (![1, 2, 3].includes(invetoryObj.state)) {
              invetoryObj.state = 0;
            }
          }

          invetoryObj.dateStr = invetoryObj.date ? this.formatDatepicker.ConvertDateFormatForSave(invetoryObj.date) : invetoryObj.date;
          invetoryObj.state = invetoryObj.state ? Number(invetoryObj.state) : 0;
          invetoryObj.screenSize = invetoryObj.screenSize ? Number(invetoryObj.screenSize) : null;
          if (invetoryObj.id && invetoryObj.id > 0 && invetoryObj.isEdit) {
            this.inventoryService.submitUpdateInventory(invetoryObj, 2, this.saveInventoryUser);
          }
          else {
            if (invetoryObj.checkIsInuse == false || invetoryObj.checkIsInuse == undefined)
              invetoryObj.state = 0;
            else
              invetoryObj.state = 1;

            if (invetoryObj.name != undefined || invetoryObj.checkIsInuse != null) {
              invetoryObj.state = 1;
              invetoryObj.currentUser = invetoryObj.name;
            }

            this.inventoryService.submitSaveInventory(invetoryObj, 2);
          }
        }
      } else {
        if (!!this.inventoryDetailFormGroup && this.inventoryDetailFormGroup.dirty) {
          this.inventoryDetailFormGroup.get('type').markAsTouched();
          this.inventoryDetailFormGroup.get('code').markAsTouched();
          this.inventoryDetailFormGroup.get('date').markAsTouched();
          this.inventoryDetailFormGroup.get('type').patchValue("MONITOR");

          var typeValue = this.inventoryDetailFormGroup.get('type').value;
          var codeValue = this.inventoryDetailFormGroup.get('code').value;
          var dateValue = this.inventoryDetailFormGroup.get('date').value;
          if (this.inventoryDetailFormGroup.invalid || typeValue == undefined || typeValue == null || codeValue == undefined || codeValue == null || dateValue == undefined || dateValue == null) {
            this.inventoryDetailFormGroup.enable();
            return
          }
          else {
            let invetoryObj = Object.assign({}, model);
            if (invetoryObj.name == undefined && invetoryObj.name != null) {
              invetoryObj.currentUser = null;
              invetoryObj.state = 0;
            }
            if (invetoryObj.currentUser != invetoryObj.name) {
              invetoryObj.currentUser = invetoryObj.name;
              invetoryObj.state = 1;
            } else {
              invetoryObj.currentUser = invetoryObj.name;
              if (![1, 2, 3].includes(invetoryObj.state)) {
                invetoryObj.state = 0;
              }
            }

            if (invetoryObj.currentUser == null) {
              if (![1, 2, 3].includes(invetoryObj.state)) {
                invetoryObj.state = 0;
              }
            }

            invetoryObj.dateStr = invetoryObj.date ? this.formatDatepicker.ConvertDateFormatForSave(invetoryObj.date) : invetoryObj.date;
            invetoryObj.state = invetoryObj.state ? Number(invetoryObj.state) : 0;
            invetoryObj.screenSize = invetoryObj.screenSize ? Number(invetoryObj.screenSize) : null;
            if (invetoryObj.id && invetoryObj.id > 0 && invetoryObj.isEdit) {
              this.inventoryService.submitUpdateInventory(invetoryObj, 2, this.saveInventoryUser);
            }
            else {
              if (invetoryObj.checkIsInuse == false || invetoryObj.checkIsInuse == undefined)
                invetoryObj.state = 0;
              else
                invetoryObj.state = 1;

              if (invetoryObj.name != undefined || invetoryObj.checkIsInuse != null) {
                invetoryObj.state = 1;
                invetoryObj.currentUser = invetoryObj.name;
              }

              this.inventoryService.submitSaveInventory(invetoryObj, 2);
            }
          }
        } else {
          const snack = new SnackBar(SnackBarType.success, 'No data entered for update', '', 4000);
          this.commonUIService.openSnackBar(snack);
          return;
        }
      }
    }
  }

  changeUserName(inventoryModel) {
    if (this.inventoryModel.currentUser != inventoryModel.name || this.inventoryModel.currentUser == null) {
      this.saveInventoryUser = true;
    } else {
      this.saveInventoryUser = false;
    }
  }

  subscribeAlertConfirmEventsForMonitor() {
    this.subscriberForAlertConfirm = this.alertService.referenceDelete.subscribe(data => {
      if (data.reference == '1' && data.selectedTabIndex == 2) {
        this.onOpenAssignMonitorsForUsersPopup();
      } else if (data.reference === '0' && data.selectedTabIndex == 2) {
        this.inventoryModel.checkIsInuse = false;
        this.inventoryModel.state = 0;
        this.inventoryModel.name = null;
        this.inventoryModel.currentUser = null;
        this.saveMonitor(this.inventoryModel, 'status_change_monitor');
      } else if (data.reference === '2' && data.selectedTabIndex == 2) {
        this.inventoryModel.checkIsInuse = false;
        this.inventoryModel.state = 2;
        this.inventoryModel.name = null;
        this.inventoryModel.currentUser = null;
        this.saveMonitor(this.inventoryModel, 'status_change_monitor');
      } else if (data.reference === '3' && data.selectedTabIndex == 2) {
        this.inventoryModel.checkIsInuse = false;
        this.inventoryModel.state = 3;
        this.inventoryModel.name = null;
        this.inventoryModel.currentUser = null;
        this.saveMonitor(this.inventoryModel, 'status_change_monitor');
      }
    });
  }

  onOpenAssignMonitorsForUsersPopup() {
    const dialogViewData = this.commonUIService.setDataForModalDialog(null, null, null, this.inventoryModel, null, null);
    this.commonUIService.openDialog(AddInventoryUserComponent, dialogViewData)
  }

  subscribeAlertCloseEventsForMonitor() {
    this.subscriber = this.alertService.referenceClose.subscribe(data => {
      if (data === true) {
        this.inventoryService.refreshInventor.emit({ list: this.inventoryModel, tabName: "" });
      }
    });
  }
}
