import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDatepicker } from '@angular/material';
import { DialogDataForModalView, UIService } from 'src/app/shared/services/ui.service';
import { isNumber } from 'util';
import { CommonDropdownService } from 'src/app/shared/services/common.dropdown.service';
import { ClientService } from 'src/app/client/services/client.service';
import { FormatMatDatepicker } from 'src/app/shared/format-datepicker';
import { SnackBarType, SnackBar } from 'src/app/shared/components/snackbar/entities/snackbar';
import { ProjectTask } from 'src/app/client/entities/projectTask';
import { ProjectViewService } from '../../../services/project-view.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-edit-project-task',
  templateUrl: './add-edit-project-task.component.html',
  styleUrls: ['./add-edit-project-task.component.scss']
})
export class AddEditProjectTaskComponent implements OnInit {

  projectTaskFormGroup: FormGroup;
  projectTaskModal: any;
  isEdit: boolean = false;
  _projectTaskModalForSave: any;
  isDisabledTask = false;
  minDate: Date = new Date();
  _taskCreationType: string = "";
  selectedDateId: number = 0;
  @ViewChild('picker', { read: null, static: true }) picker: MatDatepicker<Date>;
  _taskTypeListForIcons: any = [];
  _selectedTaskTypeId: any;
  _selectedTaskTypeName: string;
  isTypeRequired: boolean = false;
  @ViewChild('description', { static: false }) descriptionField: ElementRef;

  constructor(private _formBuilder: FormBuilder,
    public commonDropdownService: CommonDropdownService,
    private clientService: ClientService,
    private projectViewService: ProjectViewService,
    private formatDatepicker: FormatMatDatepicker,
    public dialogRef: MatDialogRef<AddEditProjectTaskComponent>,
    public commonUIService: UIService,
    public router: Router,
    @Inject(MAT_DIALOG_DATA) public modalViewData: DialogDataForModalView) {
    this.projectTaskModal = new ProjectTask();
  }

  ngOnInit() {
    this.LoadTaskTypeListForIcon();
    this.initProjectTaskFormGroup();
    this.commonDropdownService.LoadUserList();
    this.commonUIService.loadTaskDateLabels();
    this.setClientTaskDataForEditView();
  }

  // Reactive Form
  initProjectTaskFormGroup() {
    this.projectTaskFormGroup = this._formBuilder.group({
      taskDate: ['', [Validators.required]],
      userId: ['', [Validators.required]],
      status: [''],
      taskTypeId: ['', [Validators.required]],
      taskTime: [''],
      description: [''],
      taskTypeName: ['', [Validators.required]]
    });
  }

  setClientTaskDataForEditView(): void {
    if (this.modalViewData.data) {
      this.projectTaskModal = Object.assign({}, this.modalViewData.data.modalData);
      this._selectedTaskTypeId = this.projectTaskModal.taskTypeId;
      if (isNumber(this.modalViewData.data.id)) {
        this.isEdit = false;
        this._taskCreationType = this.projectTaskModal ? this.projectTaskModal.text : null;
        this._selectedTaskTypeName = this.projectTaskModal.taskTypeName ? this.projectTaskModal.taskTypeName : null;
      } else {
        this.isEdit = true;
        this._selectedTaskTypeName = this.projectTaskModal.taskType.taskTypeName;
      }
    }
  }

  async submit(projectTaskModal) {
    if (this.projectTaskFormGroup.invalid) {
      this.isDisabledTask = false;
      this.isTypeRequired = !!(this.projectTaskFormGroup.get('taskTypeId').errors
        && this.projectTaskFormGroup.get('taskTypeId').errors.required && this.projectTaskFormGroup.get('taskTypeName').errors.required);
      return;
    }
    let projectTaskModalObj = Object.assign({}, projectTaskModal);
    projectTaskModalObj.taskDateStr = projectTaskModalObj.taskDate
      ? this.formatDatepicker.ConvertDateFormatForSave(projectTaskModalObj.taskDate) : projectTaskModalObj.taskDate;

    if (this.isEdit)
      this.updateProjectTask(projectTaskModal.id, projectTaskModalObj);
    else
      this.SaveProjectTask(projectTaskModalObj).then();
  }

  async SaveProjectTask(projectTaskModalObj) {
    this.isDisabledTask = true;
    this.BindToModel(projectTaskModalObj);
    this.clientService.isLoading_task = true;
    this.clientService.SaveProjectTask(this._projectTaskModalForSave).subscribe(
      res => {
        if (res.isSuccsess) {
          const snack = new SnackBar(SnackBarType.success, 'Successfully saved', '', 2000);
          this.commonUIService.openSnackBar(snack);
          this.closeDialog();
          let urlData = this.router.url;
          if (urlData == '/task') {
            this.clientService.clientProjectTaskUpdate.emit(res.data);
            this.projectViewService.refreshProjectTask.emit(res.data);
            this.projectViewService.refreshProject.emit(res.data);
          }
          if (urlData == '/client') {
            this.projectViewService.refreshProjectTask.emit(res.data);           
          }
        }
      },
      () => {
        console.error(); this.closeDialog();
      });
  }

  updateProjectTask(projectTaskID, projectTaskObj) {
    this.BindToModel(projectTaskObj);
    this.clientService.updateProjectTask(projectTaskID, this._projectTaskModalForSave).subscribe(
      res => {
        if (res.isSuccsess) {
          const snack = new SnackBar(SnackBarType.success, 'Successfully updated', '', 2000);
          this.commonUIService.openSnackBar(snack);
          this.closeDialog();
          let urlData = this.router.url;
          if (urlData == '/task') {
            this.clientService.clientProjectTaskUpdate.emit(res.data);
            this.projectViewService.refreshProjectTask.emit(res.data);
            this.projectViewService.refreshProject.emit(res.data);
          }
          if (urlData == '/client') {
            this.projectViewService.refreshProjectTask.emit(res.data);
            this.projectViewService.refreshProject.emit(res.data);
          }
        }
      },
      () => { console.error(); this.closeDialog(); });
  }

  BindToModel(projectTaskModalObj) {
    this._projectTaskModalForSave = Object.assign({}, null);
    this._projectTaskModalForSave = new ProjectTask();
    this._projectTaskModalForSave.projectId = this.isEdit ? projectTaskModalObj.projectId : this.modalViewData.data.id;
    this._projectTaskModalForSave.status = false;
    this._projectTaskModalForSave.taskName = null;
    this._projectTaskModalForSave.taskDate = projectTaskModalObj.taskDate;
    this._projectTaskModalForSave.taskDateStr = projectTaskModalObj.taskDateStr;
    this._projectTaskModalForSave.userId = projectTaskModalObj.userId;
    this._projectTaskModalForSave.taskTypeId = projectTaskModalObj.taskTypeId;
    this._projectTaskModalForSave.taskType = null;
    this._projectTaskModalForSave.description = projectTaskModalObj.description;
    this._projectTaskModalForSave.taskTime = null;
    this._projectTaskModalForSave.taskTimeStr = projectTaskModalObj.taskTimeStr;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  setDateValueToPicker(item) {
    this.selectedDateId = item.id;
    const [day, month, year] = item.date.split("/");
    this.projectTaskModal.taskDate = new Date(year, month - 1, day);
  }

  LoadTaskTypeListForIcon() {
    this.commonDropdownService.LoadTaskTypeListForHeader().subscribe(data => {
      if (data) {
        this._taskTypeListForIcons = data;
        if (this._taskTypeListForIcons.length > 0) {
          this._taskTypeListForIcons.forEach(ele => {
            ele.taskTypeName = ele.taskTypeName.trim();
          });
        }
      }
    });
  }

  selectTaskType(taskType) {
    this.projectTaskModal.taskTypeId = taskType.id;
    this._selectedTaskTypeId = taskType.id;
    this._selectedTaskTypeName = taskType.taskTypeName;
    this.isTypeRequired = false;
    setTimeout(() => {
      this.descriptionField.nativeElement.focus();
    }, 100);
  }
}
