import { Component, OnInit, Input, ViewChild, ChangeDetectorRef, ElementRef } from '@angular/core';
import { Project } from '../../entities/project';
import { CommonDropdownService } from 'src/app/shared/services/common.dropdown.service';
import { ClientService } from '../../services/client.service';
import { UIService } from 'src/app/shared/services/ui.service';
import { SnackBar, SnackBarType } from 'src/app/shared/components/snackbar/entities/snackbar';
import { Subscription, empty } from 'rxjs';
import { AddEditProjectComponent } from '../add-edit-project/add-edit-project.component';
import { AddEditProjectTaskComponent } from './add-edit-project-task/add-edit-project-task.component';
import { AlertModel } from 'src/app/shared/entities/alertModel';
import { AlertService } from 'src/app/shared/services/alert.service';
import { AddEditProjectActionComponent } from './add-edit-project-action/add-edit-project-action.component';
import { ProjectAction } from '../../entities/projectAction';
import { ProjectDocuments } from '../../entities/projectDocument';
import API from '../../../shared/services/api.routes.json';
import { AddEditProjectDocumentComponent } from './add-edit-project-document/add-edit-project-document.component';
import { Users } from 'src/app/auth/entities/user';
import { FormatTimeForDisplay, FormatMatDatepicker } from 'src/app/shared/format-datepicker';
import { ProjectTask } from '../../entities/projectTask';
import { AuthAppService } from 'src/app/shared/services/auth-app.service';
import moment from 'moment';
import { MatDialog } from '@angular/material';
import { DocumentService } from '../../services/document.service';
import { ProjectViewService } from '../../services/project-view.service';
import { AddEstimationPhaseComponent } from './add-estimation-phase/add-estimation-phase.component';
import { AddEstimationComponent } from './add-estimation/add-estimation.component';
import { Estimation } from '../../entities/estimation';

@Component({
  selector: 'app-project-view-data',
  templateUrl: './project-view-data.component.html',
  styleUrls: ['./project-view-data.component.scss']
})
export class ProjectViewDataComponent implements OnInit {

  @ViewChild('matTabGroup', { static: false }) matTabGroup: any;
  @Input() currentProjectId: any;

  public PDF = '../../assets/images/config/pdf.png';
  public PNG = '../../assets/images/config/png.png';
  public JPG = '../../assets/images/config/jpg.png';
  public JPEG = '../../assets/images/config/jpg.png';
  public XLS = '../../assets/images/config/xls.png';
  public DOC = '../../assets/images/config/doc.png';

  isLadingProjectDocument: boolean = false;
  isLoading_project: boolean = true;
  isLoading_task: boolean = false;
  isLoading_action: boolean = false;
  isLoading_estimation: boolean = false;
  projectName: any;
  selectedProjectStatusStyle: any;
  projectStatusIdModel: any;
  selectedProject: Project;
  private subscriber: Subscription;
  _taskList: any[];
  idCurrentProjectDocument: any = 0;
  showAddDocumentOut: boolean = false;
  projectActionList: any[];
  idCurrentProjectTask: number = 0;
  idCurrentProjectAction: number = 0;
  idCurrentProjectEstimation: number = 0;
  projectDocuments: ProjectDocuments[] = [];
  document: any = [];
  _actionTypeListForHeader: any = [];
  minDate: Date = new Date();
  pickedDate: Date;
  eventValue: any;
  @Input() detectChangeId: any;
  @ViewChild('commentElement', { read: ElementRef, static: true }) commentElement: ElementRef<HTMLInputElement>;
  _loggedinUser: Users = new Users();
  _taskTypeListForHeader: any = [];
  _taskTypeIdForAutomatic: number = 0;
  selectedTaskId: number = 0;
  _actionTypeIdForAutomatic: number = 0;
  typeGlobal: string;
  closeReasonIdForModel: any;
  isClosedReasonVisible: boolean = false;
  isCloseReasonRequired: boolean = false;
  _projectStatusValueGlobal: any;
  selectedDocumentTabIndex: number = 0;
  selectedDocumentTypeId: number = 0;
  characterLength: any = null;
  projectEstimationList: any[];

  Followup = [
    { value: `${this.commonUIService.getDateValueForTask('One week')}`, label: 'One week', parent: 'Followup' },
    { value: `${this.commonUIService.getDateValueForTask('Two weeks')}`, label: 'Two weeks', parent: 'Followup' },
    { value: `${this.commonUIService.getDateValueForTask('Three weeks')}`, label: 'Three weeks', parent: 'Followup' },
    { value: `${this.commonUIService.getDateValueForTask('One month')}`, label: 'One month', parent: 'Followup' },
    { value: 'Other', label: 'Other', parent: 'Followup' },
  ];

  Reminder = [
    { value: `${this.commonUIService.getDateValueForTask('One month')}`, label: 'One month', parent: 'Reminder' },
    { value: `${this.commonUIService.getDateValueForTask('Two months')}`, label: 'Two months', parent: 'Reminder' },
    { value: `${this.commonUIService.getDateValueForTask('Three months')}`, label: 'Three months', parent: 'Reminder' },
    { value: `${this.commonUIService.getDateValueForTask('Six months')}`, label: 'Six months', parent: 'Reminder' },
    { value: 'Other', label: 'Other', parent: 'Reminder' },
  ];

  Claim = [
    { value: `${this.commonUIService.getDateValueForTask('Tomorrow')}`, label: 'Tomorrow', parent: 'Claim' },
    { value: `${this.commonUIService.getDateValueForTask('Day after tomorrow')}`, label: 'Day after tomorrow', parent: 'Claim' },
    { value: `${this.commonUIService.getDateValueForTask('One week')}`, label: 'One week', parent: 'Claim' },
    { value: `${this.commonUIService.getDateValueForTask('Two weeks')}`, label: 'Two weeks', parent: 'Claim' },
    { value: `${this.commonUIService.getDateValueForTask('Three weeks')}`, label: 'Three weeks', parent: 'Claim' },
    { value: `${this.commonUIService.getDateValueForTask('One month')}`, label: 'One month', parent: 'Claim' },
    { value: 'Other', label: 'Other', parent: 'Claim' },
  ];

  constructor(
    public commonDropdownService: CommonDropdownService,
    public clientService: ClientService,
    private documentService: DocumentService,
    private projectViewService: ProjectViewService,
    public commonUIService: UIService,
    public cdRef: ChangeDetectorRef,
    public alertService: AlertService,
    public dialog: MatDialog,
    private formatTimeForDisplay: FormatTimeForDisplay,
    private formatDatepicker: FormatMatDatepicker,
    private authAppService: AuthAppService
  ) {
    this.selectedProject = new Project();
    this.projectActionList = [];
    this.projectEstimationList = [];
  }

  ngOnInit() {
    this.clientService.isDifferentClientSelected = false; 
    this.subscribeAlertConfirmEvents();
    this.subscribeProjectTaskRefresh();
    this.subscribeProjectActionFresh();
    this.subscribeInactiveProjectRefresh();
    this.projectDocumentSubscribe();
    this.getLoggedinUser();
    this.commonUIService.loadPostponeDateList();
    this.LoadTaskTypeListForHeader();
    this.LoadActionTypeListForHeader();
    this.commonDropdownService.LoadCloseReasonList();
    this.cancelProjectDocument();
    this.newDocumentCreate();
    this.subscribeRefreshEstimationList();
    this.documentService.refreshProjectDocument.emit(this.clientService.selectedProjectId);
    this.subscribeRefreshProject();
  }

  ngOnChanges() {
    this.getProjectById(this.clientService.selectedProjectId).then();
  }

  ngAfterViewChecked() {
  }

  ngOnDestroy() {
    this.subscriber.unsubscribe();
    this.cdRef.detectChanges = empty;
  }

  getLoggedinUser() {
    this._loggedinUser = this.authAppService.getLoggedInUser();
  }

  subscribeProjectTaskRefresh() {
    this.projectViewService.refreshProjectTask.subscribe(data => {     
      if (!data) { return; }
      this.loadToDoProjectTaskList(this.clientService.selectedProjectId).then();
    })
  }

  projectDocumentSubscribe() { 
    this.documentService.refreshProjectDocument.subscribe(res => {
      this.loadProjectDocument(this.clientService.selectedProjectId);
    });
  }

  subscribeProjectActionFresh() {
    this.projectViewService.refreshProjectAction.subscribe(data => {
      if (!data) { return; }
      this.loadProjectActionList(this.clientService.selectedProjectId);
    })
  }

  loadProjectDocument(currentProjectIdNo) { 
    this.isLadingProjectDocument = true;
    this._loggedinUser = this.authAppService.getLoggedInUser();
    currentProjectIdNo = parseInt(currentProjectIdNo);
    this.clientService.getProjectById(currentProjectIdNo, this._loggedinUser.id).subscribe(data => {   
      let _docList = data.data.projectDocuments ? data.data.projectDocuments : null;
      if ( _docList && _docList.length > 0) {
        _docList.forEach(document => {
          document.extension = document.fileName.split('.')[(document.fileName.split('.')).length - 1];
          document.link = API.baseUrl + API.projectDocuments.getProjectDocument + document.id + '/' + document.generatedFileName;
          if (document.url) {
            if (document.extension === 'pdf')
              document.url = document.url.toString().includes('data:application/pdf;base64,') ? document.url : 'data:application/pdf;base64,' + document.url;
            if (document.extension === 'png')
              document.url = document.url.toString().includes('data:image/png;base64,') ? document.url : 'data:image/png;base64,' + document.url;
            if (document.extension === 'jpg')
              document.url = document.url.toString().includes('data:image/jpg;base64,') ? document.url : 'data:image/jpg;base64,' + document.url;
            if (document.extension === 'jpeg')
              document.url = document.url.toString().includes('data:image/jpeg;base64,') ? document.url : 'data:image/jpeg;base64,' + document.url;
            if (document.extension === 'docx')
              document.url = document.url.toString().includes('https://docs.google.com/gview?url=') ? document.url : 'https://docs.google.com/gview?url=' + document.link + '&embedded=true';
            if (document.extension === 'xlsx' || document.extension === 'xls')
              document.url = document.url.toString().includes('https://docs.google.com/gview?url=') ? document.url : 'https://docs.google.com/gview?url=' + document.link + '&embedded=true';
          }
        });
        this.showAddDocumentOut = true;
        this.selectedProject.isProjectDocumentList = true;
      }
      else {
        this.selectedProject.isProjectDocumentList = false;
        this.showAddDocumentOut = false;
      }
      this.document = _docList;
      this.isLadingProjectDocument = false;
    });
  }

  subscribeInactiveProjectRefresh() {
    this.projectViewService.refreshInactiveProject.subscribe(data => {     
      if (!data) { return; }
      this.getProjectById(data).then();
      this.clientService.selectedProjectId = data;
    })
  }

  subscribeAlertConfirmEvents() {
    this.subscriber = this.alertService.referenceDelete
      .subscribe(data => {

        if (data == 'Delete Project Task') {
          this.deleteProjectTask(this.idCurrentProjectTask);
        }
        else if (data == 'Delete Project Action') {
          this.deleteProjectAction(this.idCurrentProjectAction).then();
        }
        else if (data == 'Delete Project') {
          this.deleteProject(this.clientService.selectedProjectId);
        }
        else if (data == 'Delete Project Document') {
          this.openDeleteProjectDocument(this.idCurrentProjectDocument);
        }
        else if (data == 'Delete Project Estimation') {
          this.deleteProjectEstimation(this.idCurrentProjectEstimation).then();
        }
      });
  }

  async getProjectById(projectId) {   
    this.isLoading_project = true;
    this.isLadingProjectDocument = true;
    this._loggedinUser = this.authAppService.getLoggedInUser();
    projectId = parseInt(projectId);
    this.clientService.getProjectById(projectId, this._loggedinUser.id).subscribe(
      res => {
        if (res) {         
          this.selectedProject = res.data;
          if (this.selectedProject.projectDescription)
            this.characterLength = this.selectedProject.projectDescription.length;
          if (this.selectedProject.estimation) {
            this.projectEstimationList = this.selectedProject.estimation.length > 0 ? this.selectedProject.estimation.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()) : this.selectedProject.estimation;
            this.projectEstimationList.forEach(es => {
              if (es.userName && es.userName != null) {
                let myStr = es.userName;
                let matches = myStr.match(/\b(\w)/g);
                es.initials = matches.join('');
                es.initials = es.initials.substring(0, 2);
              }
              if (es.state == 7 || es.state == 8 || es.state == 9 || es.state == 10) {
                es.flag = false;
              } else {
                es.flag = true;
              } es.estimationPhase.forEach(esp => {
                if (esp.userName && esp.userName != null) {
                  let myStr = esp.userName;
                  let matches = myStr.match(/\b(\w)/g);
                  esp.initials = matches.join('');
                  esp.initials = esp.initials.substring(0, 2);
                }
                if (esp.contactName && esp.contactName != null) {
                  let myStr = esp.contactName;
                  let matches = myStr.match(/\b(\w)/g);
                  esp.initialsContacts = matches.join('');
                  esp.initialsContacts = esp.initialsContacts.substring(0, 2);
                }
              });
            });
            if (this.projectEstimationList.length > 0) {
              this.projectEstimationList.forEach(element => {
                element.estimationPhase.forEach(phase => {
                  phase.estimationDocument.forEach(document => {
                    document.extension = document.fileName.split('.')[(document.fileName.split('.')).length - 1];
                    document.link = API.baseUrl + API.estimationDocument.getEstimationDocument + document.id + '/' + document.generatedFileName;
                    if (document.url) {
                      if (document.extension === 'pdf')
                        document.url = document.url.toString().includes('data:application/pdf;base64,') ? document.url : 'data:application/pdf;base64,' + document.url;
                      if (document.extension === 'png')
                        document.url = document.url.toString().includes('data:image/png;base64,') ? document.url : 'data:image/png;base64,' + document.url;
                      if (document.extension === 'jpg')
                        document.url = document.url.toString().includes('data:image/jpg;base64,') ? document.url : 'data:image/jpg;base64,' + document.url;
                      if (document.extension === 'jpeg')
                        document.url = document.url.toString().includes('data:image/jpeg;base64,') ? document.url : 'data:image/jpeg;base64,' + document.url;
                      if (document.extension === 'docx')
                        document.url = document.url.toString().includes('https://docs.google.com/gview?url=') ? document.url : 'https://docs.google.com/gview?url=' + document.link + '&embedded=true';
                      if (document.extension === 'xlsx' || document.extension === 'xls')
                        document.url = document.url.toString().includes('https://docs.google.com/gview?url=') ? document.url : 'https://docs.google.com/gview?url=' + document.link + '&embedded=true';
                    }
                  });
                });
              });
            }
          } else { this.projectEstimationList = []; }
          this.projectActionList = this.selectedProject.actionProjects;
          this.projectActionList.forEach(act => {
            if (act.author && act.author != null) {
              let myStr = act.author;
              let matches = myStr.match(/\b(\w)/g);
              act.initials = matches.join('');
              act.initials = act.initials.substring(0, 2);
            }
          });
          this.selectedProject.tasks.forEach(r => {
            let myStr = r.taskUser;
            let matches = myStr.match(/\b(\w)/g);
            r.initials = matches.join('');
            r.initials = r.initials.substring(0, 2);
          });
          if (this.selectedProject.tasks.length > 0) {
            this.selectedProject.tasks = this.selectedProject.tasks.filter(x => x.status == 0).sort((a, b) => new Date(b.taskDate).getTime() - new Date(a.taskDate).getTime());
          }
          this.formatTaskTimeForDisplay(this.selectedProject.tasks);
          this.selectedProject.isTaskList = this.selectedProject.tasks.length > 0;
          this.selectedProject.isActionList = this.selectedProject.actionProjects.length > 0;
          this.selectedProject.isProjectDocumentList = this.selectedProject.projectDocuments.length > 0;
          this.setProjectStatusForDropDown(this.selectedProject);
          this.setCloseReasonForModel(this.selectedProject);
          this.projectName = this.selectedProject.projectName[0].toUpperCase() + this.selectedProject.projectName.slice(1);
          let _docList = this.selectedProject.projectDocuments;

          if (_docList.length > 0) {
            _docList.forEach(document => {
              document.extension = document.fileName.split('.')[(document.fileName.split('.')).length - 1];
              document.link = API.baseUrl + API.projectDocuments.getProjectDocument + document.id + '/' + document.generatedFileName;
              if (document.url) {
                if (document.extension === 'pdf')
                  document.url = document.url.toString().includes('data:application/pdf;base64,') ? document.url : 'data:application/pdf;base64,' + document.url;
                if (document.extension === 'png')
                  document.url = document.url.toString().includes('data:image/png;base64,') ? document.url : 'data:image/png;base64,' + document.url;
                if (document.extension === 'jpg')
                  document.url = document.url.toString().includes('data:image/jpg;base64,') ? document.url : 'data:image/jpg;base64,' + document.url;
                if (document.extension === 'jpeg')
                  document.url = document.url.toString().includes('data:image/jpeg;base64,') ? document.url : 'data:image/jpeg;base64,' + document.url;
                if (document.extension === 'docx')
                  document.url = document.url.toString().includes('https://docs.google.com/gview?url=') ? document.url : 'https://docs.google.com/gview?url=' + document.link + '&embedded=true';
                if (document.extension === 'xlsx' || document.extension === 'xls')
                  document.url = document.url.toString().includes('https://docs.google.com/gview?url=') ? document.url : 'https://docs.google.com/gview?url=' + document.link + '&embedded=true';
              }
            });
            this.showAddDocumentOut = true;
            this.selectedProject.isProjectDocumentList = true;
          }
          else {
            this.selectedProject.isProjectDocumentList = false;
            this.showAddDocumentOut = false;
          }
          this.document = _docList;
          this.isLadingProjectDocument = false;
        }
        this.isLadingProjectDocument = false;
      },
      () => { console.error(); });     
    this.isLoading_project = false;
  }

  private formatTaskTimeForDisplay(list) {
    if (list.length > 0)
    {
      list.forEach(ele => {
        try {
          ele.taskTimeStr = ele.taskTime ? this.formatTimeForDisplay.ConvertTimeFormatForDisplay(ele.taskTime) : null;
        }
        catch(error){
        console.log("formatTaskTimeForDisplay:"+error);
        }
      });
    } 
     
  }

  private formatEstimationTimeForDisplay(list) {
    if (list.length > 0)
      list.forEach(ele => {
        ele.estimationHoursStr = ele.estimationHours ? this.formatTimeForDisplay.ConvertTimeFormatForDisplay(ele.estimationHours) : null;
        ele.maxEstimationHoursStr = ele.maxEstimationHours ? this.formatTimeForDisplay.ConvertTimeFormatForDisplay(ele.maxEstimationHours) : null;
        ele.budgetHoursStr = ele.budgetHours ? this.formatTimeForDisplay.ConvertTimeFormatForDisplay(ele.budgetHours) : null;
        ele.maxBudgetHoursStr = ele.maxBudgetHours ? this.formatTimeForDisplay.ConvertTimeFormatForDisplay(ele.maxBudgetHours) : null;
        ele.estimationPhase.forEach(ele => {
          ele.hoursStr = ele.hours ? this.formatTimeForDisplay.ConvertTimeFormatForDisplay(ele.hours) : null;
          ele.maxHoursStr = ele.maxHours ? this.formatTimeForDisplay.ConvertTimeFormatForDisplay(ele.maxHours) : null;
          if (ele.estimationOption.length > 0) {
            ele.estimationOption.forEach(ele => {
              ele.hoursStr = ele.hours ? this.formatTimeForDisplay.ConvertTimeFormatForDisplay(ele.hours) : null;
              ele.maxHoursStr = ele.maxHours ? this.formatTimeForDisplay.ConvertTimeFormatForDisplay(ele.maxHours) : null;
            });
          }
        });
      });
  }

  setProjectStatusForDropDown(project) {
    this.projectStatusIdModel = project.projectStatusId;
    this._projectStatusValueGlobal = project.projectStatusValue;
    this.setProjectStatusStyle(this.projectStatusIdModel);
  }

  setCloseReasonForModel(project) {
    this.isClosedReasonVisible = project.projectStatusValue === '0' && project.closeReason && project.closeReasonId;
    this.closeReasonIdForModel = project.closeReasonId ? project.closeReasonId.toString() : null;
    this.isCloseReasonRequired = this.closeReasonIdForModel === null;
  }

  setProjectStatusStyle(id) {
    let projectStatusList = this.commonDropdownService._projectStatusList;
    projectStatusList.forEach(ele => {
      if (ele.id.toString() == id) {
        this.selectedProjectStatusStyle = {
          'background': ele.color,
          'color': '#ffffff !important'
        };
        this._projectStatusValueGlobal = ele.status;
      }
    });
  }

  openDeleteProjectAlert(id) {
    let deleteData = new AlertModel();
    deleteData.title = 'Delete';
    deleteData.msg = 'Are you sure to delete this project?';
    deleteData.id = id;
    deleteData.reference = 'Delete Project';
    this.clientService.selectedProjectId = id;
    this.commonUIService.openAlert(deleteData);
  }

  OpenEditActionPopup(action) {
    const dialogViewData = this.commonUIService.setDataForModalDialog(null, null, "actionProject", action, null, null);
    this.commonUIService.openDialog(AddEditProjectActionComponent, dialogViewData);
  }

  deleteProject(id) {
    
    this.clientService.deleteProject(id).subscribe(res => {
      if (res.isSuccsess) {      
        this.projectViewService.refreshDeleteProject.emit(true);
        this.clientService.refresPrject=true;
        this.clientService.isProjectDeletion =true;
        let snack = new SnackBar(SnackBarType.success, res.message, '', 2000);
        this.commonUIService.openSnackBar(snack);
        this.clientService.selectedProjectId = this.getNextProjectId(id);
      }
      else {
        let snack = new SnackBar(SnackBarType.success, res.message, '', 2000);
        this.commonUIService.openSnackBar(snack);
      }
    }
    );
  }

  projectStatusChange(event, statusId) {
    this.setProjectStatusStyle(statusId);
    if (this._projectStatusValueGlobal == 0) {
      this.isClosedReasonVisible = true;
      this.selectedProject.projectStatusId = statusId > 0 ? statusId : this.selectedProject.projectStatusId;
      this.isCloseReasonRequired = this.closeReasonIdForModel === null;
      if (this.isCloseReasonRequired) return;
    }
    else {
      this.isClosedReasonVisible = false;
      this.isCloseReasonRequired = false;
      this.selectedProject.projectStatusId = statusId > 0 ? statusId : this.selectedProject.projectStatusId;
      this.selectedProject.closeReasonId = null;
      this.selectedProject.closeReasonText = null;
      this.updateProjectStatus(this.selectedProject.id, this.selectedProject);
    }
  }

  setCloseReasonForUpdate(event) {
    if (event) {
      this.closeReasonIdForModel = event.value ? event.value : null;
      this.isCloseReasonRequired = this.closeReasonIdForModel === null;
      if (this.isCloseReasonRequired) { return; }
      else {
        this.selectedProject.closeReasonId = Number(this.closeReasonIdForModel);
        this.updateProjectStatus(this.selectedProject.id, this.selectedProject);
      }
    }
  }

  updateProjectStatus(projectId, projectObj) {
    projectObj = this.setProjectDataForUpdateProject(projectObj);
    this.clientService.isProjectDeletion = false;
    this.clientService.updateProject(projectId, projectObj).subscribe(
      updateResult => {
        if (updateResult.isSuccsess) {    

          this.clientService.isInactiveProjectStatusType(projectObj.projectStatusId).subscribe(projStatusRes=>
          {  
            let snack = new SnackBar(SnackBarType.success, 'Updated successfully ', '', 2000);
            this.commonUIService.openSnackBar(snack);  

            console.log(projStatusRes.message);//Required to determine whether the selected project status is of inactive type
            this.clientService.isToInActive=projStatusRes.data;
            this.projectViewService.refreshProject.emit(updateResult.data);
            this.clientService.refresPrject=true;           
          },error=>
          {
            console.log(error);
            let snack = new SnackBar(SnackBarType.success, "An error occurred while updating the project status.", "", 2000);
            this.commonUIService.openSnackBar(snack);           
          }); 
               
        }
        else {
          let snack = new SnackBar(SnackBarType.success, 'Errors on update', '', 2000);
          this.commonUIService.openSnackBar(snack);
        }
      },
      () => { console.error(); });
  }

  setProjectDataForUpdateProject(projectObj) {
    let project = Object.assign({}, projectObj);
    project.actionProjects = null;
    project.tasks = null;
    project.employeeProjects = null;
    return project;
  }

  openEditProjectPopup(project) {

    project.projectStatusId = this.projectStatusIdModel;
    const dialogViewData = this.commonUIService.setDataForModalDialog(null, null, null, project, null, null);
    this.commonUIService.openDialog(AddEditProjectComponent, dialogViewData);
  }

  //#region project task
  openAddProjectTaskPopup(projectId) {
    let task = {
      projectId: projectId,
      userId: this._loggedinUser ? this._loggedinUser.id : null
    };
    const dialogViewData = this.commonUIService.setDataForModalDialog(projectId, null, null, task, null, null);
    this.commonUIService.openDialog(AddEditProjectTaskComponent, dialogViewData);
  }

  async loadToDoProjectTaskList(id) {   
    this.clientService.isLoading_task = true;
    this._taskList = [];
    id = parseInt(id);
    this.clientService.loadToDoProjectTaskList(id).subscribe(
      res => {      
        if (res) {
          this._taskList = res.data;
          this.formatTaskTimeForDisplay(this._taskList);
          this.selectedProject.tasks = this._taskList;
          if (this.selectedProject.tasks) {
            let xList = this.selectedProject.tasks.length > 0 ? this.selectedProject.tasks.filter(x => x.status == 0).sort((a, b) => new Date(b.taskDate).getTime() - new Date(a.taskDate).getTime()) : this.selectedProject.tasks;
            if (this.clientService.clientActiveProjectList) {
              this.clientService.clientActiveProjectList.forEach(ele => {
                if (ele.id == parseInt(this.clientService.selectedProjectId)) {
                  ele.projectTaskCount = xList.length;
                }
              });
            }
            this.selectedProject.tasks.forEach(r => {
              const myStr = r.taskUser;
              const matches = myStr.match(/\b(\w)/g);
              r.initials = matches.join('');
              r.initials = r.initials.substring(0, 2);
            });
            this.selectedProject.isTaskList = this._taskList.length > 0;
            this.clientService.isLoading_task = false;
          } else {
            this.selectedProject.isTaskList = this._taskList.length > 0;
            this.clientService.isLoading_task = false;
          }
        }
        this.clientService.isLoading_task = false;
      },
      err => { console.error(); this.clientService.isLoading_task = true; });

  }

  OpenEditProjectPopup(projecTask) {
    const dialogViewData = this.commonUIService.setDataForModalDialog(null, null, null, projecTask, null, null);
    this.commonUIService.openDialog(AddEditProjectTaskComponent, dialogViewData);
  }

  deleteProjectTask(projectTaskId) {
    this.clientService.isLoading_task  = true;
    this.clientService.deleteProjectTask(projectTaskId).subscribe(
      res => {
        if (res.isSuccsess) {
          const snack = new SnackBar(SnackBarType.success, 'Project task successfully deleted', '', 2000);
          this.commonUIService.openSnackBar(snack);
          //this.clientService._isLoading_task = false;
          this.projectViewService.refreshDeleteProjectTask.emit(true);
          this.clientService.clientProjectTaskUpdate.emit(null);
          this.loadToDoProjectTaskList(this.clientService.selectedProjectId).then();
        }
        this.clientService.isLoading_task  = false;
      }
    )
  }

  changeProjectTaskStatus(task) {
    this.commonUIService.openMask();
    this.clientService.changeProjectTaskStatus(task.id, task.status).subscribe(
      res => {
        if (res.data) {
          const snack = new SnackBar(SnackBarType.success, 'Task done', '', 3000);
          this.commonUIService.openSnackBar(snack);
          this.OpenActionAfterMarkedTask(task);
          this.projectViewService.refreshDeleteProjectTask.emit(true);
          this.clientService.clientProjectTaskUpdate.emit(null);
          this.loadToDoProjectTaskList(this.clientService.selectedProjectId).then();         
        } else {
          const snack = new SnackBar(SnackBarType.error, 'Error on state update', '', 4000);
          this.commonUIService.openSnackBar(snack);
        }
        this.commonUIService.closeMask();
      });
  }

  OpenActionAfterMarkedTask(task) {
    let actionSelected = this._actionTypeListForHeader.filter(m => m.actionType1.trim().toLowerCase() === task.taskType.taskTypeName.trim().toLowerCase()).length > 0
      ? this._actionTypeListForHeader.filter(m => m.actionType1.trim().toLowerCase() === task.taskType.taskTypeName.trim().toLowerCase())
      : this._actionTypeListForHeader.filter(m => m.actionType1.trim().toLowerCase() === "contract");

    if (actionSelected.length > 0) {
      let action = {
        id: actionSelected[0].id,
        actionType1: task.taskType.taskTypeName
      };
      let taskType = {
        id: task.taskTypeId,
        taskTypeName: task.taskType.taskTypeName
      };
      this.OpenAutomaticProjectActionPopup(task.projectId, action, taskType, true, task.id);
    }
    else {
      const snack = new SnackBar(SnackBarType.success, 'Action type not exist for marked task', '', 4000);
      this.commonUIService.openSnackBar(snack);
    }
  }
  //#endregion Project Task

  //#region Project Action
  OpenAutomaticProjectActionPopup(projectId, actionType, taskType, nextAfterDone: boolean, doneTaskId: number) {
    this.showNoTaskPopup();
    let today = moment();
    let date = moment(today);
    let action = {
      projectId: projectId,
      actionTypeId: actionType.id,
      actionDate: date,
      text: "Automatic",
      userId: this._loggedinUser ? this._loggedinUser.id : null,
      author: this._loggedinUser ? this._loggedinUser.name : null,
      actionTypeName: actionType.actionType1,
      doneTaskId: doneTaskId,
      nextTaskTypeId: taskType && !nextAfterDone ? taskType.id : 0,
      nextTaskTypeName: taskType ? taskType.taskTypeName : null,
      loggedUserId: this._loggedinUser ? this._loggedinUser.id : null
    };
    if ((actionType.actionType1 != "Follow-up" && actionType.actionType1 != "Reminder" && actionType.actionType1 != "Claim" && actionType.actionType1 != "Contract" && !nextAfterDone) || nextAfterDone) {
      this._taskTypeIdForAutomatic = 0;
      const dialogViewData = this.commonUIService.setDataForModalDialog(projectId, null, 'actionProject', action, null, null);
      this.commonUIService.openDialog(AddEditProjectActionComponent, dialogViewData);
    }
    else {
      this._actionTypeIdForAutomatic = actionType.id;
      this.SaveAutomaticProjectAction(projectId, date, actionType);
    }
  }

  OpenAddProjectActionPopup(project) {
    this.showNoTaskPopup();
    this.typeGlobal = project.type;
    let today = moment();
    let date = moment(today);
    let action = {
      projectId: project.id,
      actionDate: date,
      userId: this._loggedinUser ? this._loggedinUser.id : null,
      author: this._loggedinUser ? this._loggedinUser.name : null,
      loggedUserId: this._loggedinUser ? this._loggedinUser.id : null
    };
    const dialogViewData = this.commonUIService.setDataForModalDialog(project.id, null, 'actionProject', action, null, null);
    this.commonUIService.openDialog(AddEditProjectActionComponent, dialogViewData);
  }

  SaveAutomaticProjectAction(projectId, date, actionType) {
    let actionModalForSave = new ProjectAction();
    actionModalForSave.projectId = projectId;
    actionModalForSave.actionTypeId = this._actionTypeIdForAutomatic;
    actionModalForSave.userId = this._loggedinUser ? this._loggedinUser.id : null;
    actionModalForSave.status = false;
    actionModalForSave.actionName = null;
    actionModalForSave.type = actionType.actionType1;
    actionModalForSave.who = this._loggedinUser ? this._loggedinUser.name : null;
    actionModalForSave.actionDate = date;
    actionModalForSave.comment = null;
    actionModalForSave.answer = null;
    actionModalForSave.author = this._loggedinUser ? this._loggedinUser.name : null;

    this.clientService.SaveProjectAction(actionModalForSave).subscribe(
      res => {
        if (res.isSuccsess) {
          const snack = new SnackBar(SnackBarType.success, 'Successfully saved', '', 4000);
          this.commonUIService.openSnackBar(snack);
          this.projectViewService.refreshProjectAction.emit(res.data);
        }
        else {
          const snack = new SnackBar(SnackBarType.error, 'Error on save', '', 4000);
          this.commonUIService.openSnackBar(snack);
        }
      },
      () => { console.error(); });
  }

  async loadProjectActionList(projectId) {
    this.isLoading_action = true;
    projectId = parseInt(projectId);
    this.clientService.loadProjectActionList(projectId).subscribe(
      res => {
        if (res) {
          this.projectActionList = res.data;
          this.projectActionList.forEach(act => {
            if (act.author && act.author != null) {
              const myStr = act.author;
              const matches = myStr.match(/\b(\w)/g);
              act.initials = matches.join('');
              act.initials = act.initials.substring(0, 2);
            }
          });
          this.selectedProject.isActionList = this.projectActionList.length > 0;
          this.isLoading_action = false;
        }
        this.isLoading_action = false;
      },
      err => { console.error(); this.isLoading_action = false; });
  }

  async loadProjectEstimationList(projectId) {   
    this.isLoading_estimation = true;
    this.clientService.loadProjectEstimationList(projectId).subscribe(
      res => {
        if (res && res.data) {          
          this.projectEstimationList = res.data.length > 0 ? res.data.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()) : res.data;
          this.projectEstimationList.forEach(es => {
            if (es.userName && es.userName != null) {             
              let myStr = es.userName;
              let matches = myStr.match(/\b(\w)/g);
              es.initials = matches.join('');
              es.initials = es.initials.substring(0, 2);
            }
            if (es.state == 7 || es.state == 8 || es.state == 9 || es.state == 10) {
              es.flag = false;
            } else {
              es.flag = true;
            }
            es.estimationPhase.forEach(esp => {           
              if (esp.userName && esp.userName != null) {           
                let myStr = esp.userName;
                let matches = myStr.match(/\b(\w)/g);
                esp.initials = matches.join('');
                esp.initials = esp.initials.substring(0, 2);
              }
              if (esp.contactName && esp.contactName != null) {
                let myStr = esp.contactName;
                let matches = myStr.match(/\b(\w)/g);
                esp.initialsContacts = matches.join('');
                esp.initialsContacts = esp.initialsContacts.substring(0, 2);
              }
            });
          });
          if (this.projectEstimationList.length > 0) {
            this.projectEstimationList.forEach(element => {
              element.estimationPhase.forEach(phase => {
                phase.estimationDocument.forEach(document => {
                  document.extension = document.fileName.split('.')[(document.fileName.split('.')).length - 1];
                  document.link = API.baseUrl + API.estimationDocument.getEstimationDocument + document.id + '/' + document.generatedFileName;
                  if (document.url) {
                    if (document.extension === 'pdf')
                      document.url = document.url.toString().includes('data:application/pdf;base64,') ? document.url : 'data:application/pdf;base64,' + document.url;
                    if (document.extension === 'png')
                      document.url = document.url.toString().includes('data:image/png;base64,') ? document.url : 'data:image/png;base64,' + document.url;
                    if (document.extension === 'jpg')
                      document.url = document.url.toString().includes('data:image/jpg;base64,') ? document.url : 'data:image/jpg;base64,' + document.url;
                    if (document.extension === 'jpeg')
                      document.url = document.url.toString().includes('data:image/jpeg;base64,') ? document.url : 'data:image/jpeg;base64,' + document.url;
                    if (document.extension === 'docx')
                      document.url = document.url.toString().includes('https://docs.google.com/gview?url=') ? document.url : 'https://docs.google.com/gview?url=' + document.link + '&embedded=true';
                    if (document.extension === 'xlsx' || document.extension === 'xls')
                      document.url = document.url.toString().includes('https://docs.google.com/gview?url=') ? document.url : 'https://docs.google.com/gview?url=' + document.link + '&embedded=true';
                  }
                });
              });
            });
          }
          this.isLoading_estimation = false;
        } else {
          this.projectEstimationList = [];
          this.isLoading_estimation = false;
        }
      },
      err => { console.error(); this.isLoading_estimation = false; });
  }

  showComment() {
    if (this.projectActionList.length > 0) {
      this.projectActionList = this.projectActionList.length > 0 ? this.projectActionList.sort((a, b) => new Date(b.actionDate).getTime() - new Date(a.actionDate).getTime()) : this.projectActionList;
      this.projectActionList.forEach(act => {
        act.visible = act.visible == true;
        const className = '.comment-expandable' + act.id;
        const commentRef = document.querySelector(className);
        if (commentRef.clientHeight >= 48) {
          act.isShow = true;
        }
      });
    }
  }

  openDeleteProjectTaskAlert(id) { 
    const deleteData = new AlertModel();
    deleteData.title = 'Delete';
    deleteData.msg = 'Are you sure to delete this record?';
    deleteData.id = id;
    deleteData.reference = 'Delete Project Task';
    this.idCurrentProjectTask = id;
    this.commonUIService.openAlert(deleteData);
  }

  openDeleteProjectActionAlert(id) {
    const deleteData = new AlertModel();
    deleteData.title = 'Delete';
    deleteData.msg = 'Are you sure to delete this record?';
    deleteData.id = id;
    deleteData.reference = 'Delete Project Action';
    this.idCurrentProjectAction = id;
    this.commonUIService.openAlert(deleteData);
  }

  openDeleteProjectEstimationAlert(id) {
    const deleteData = new AlertModel();
    deleteData.title = 'Delete';
    deleteData.msg = 'Are you sure to delete this record?';
    deleteData.id = id;
    deleteData.reference = 'Delete Project Estimation';
    this.idCurrentProjectEstimation = id;
    this.commonUIService.openAlert(deleteData);
  }

  async deleteProjectAction(projectActionId) {
    this.clientService.deleteProjectAction(projectActionId).subscribe(
      res => {
        if (res.isSuccsess) {
          const snack = new SnackBar(SnackBarType.success, 'Project Action successfully deleted', '', 2000);
          this.commonUIService.openSnackBar(snack);
          this.projectViewService.refreshProjectAction.emit(res.data);
        }
      }
    )
  }

  async deleteProjectEstimation(projectEstimationId) {
    this.clientService.deleteEstimation(projectEstimationId).subscribe(
      res => {
        if (res.isSuccsess) {
          const snack = new SnackBar(SnackBarType.success, 'Project Estimation successfully deleted', '', 2000);
          this.commonUIService.openSnackBar(snack);
          if (this.clientService.selectedProjectId)
            this.loadProjectEstimationList(this.clientService.selectedProjectId);
        }
      }
    )
  }
  //#endregion

  openDeleteProjectDocument(id) {
    this.clientService.deleteProjectDocument(id).subscribe(res => {
      if (!res.isSuccsess) { return; }
      const snack = new SnackBar(SnackBarType.success, res.message, '', 2000);
      this.commonUIService.openSnackBar(snack);
      this.loadProjectDocument(this.clientService.selectedProjectId);
    });
  }

  projectDocumentSelect(event) {
    if (event) {
      const matTabGroupLength = this.matTabGroup._tabs.length;
      const _lengthForGetLastIndex = (matTabGroupLength - 1);
      const _selectedIndex = event.index;
      this.selectedDocumentTabIndex = _selectedIndex;
      const _selectedTab = event.tab._viewContainerRef.element.nativeElement;
      if (_selectedIndex === _lengthForGetLastIndex) {
        this.matTabGroup._tabHeader._inkBar.hide();
      }
      else {
        this.matTabGroup._tabHeader._inkBar.show();
        this.eventValue = event;
      }
      this.idCurrentProjectDocument = _selectedTab.id;
    } else {
      this.idCurrentProjectDocument = this.idCurrentProjectDocument;
    }
  }

  openAddDocument(id) {
    this.isLadingProjectDocument = true;
    const dialogViewData = this.commonUIService.setDataForModalDialog(id, null, this.selectedDocumentTabIndex, null, null, null);
    this.commonUIService.openDialog(AddEditProjectDocumentComponent, dialogViewData);
  }

  openDeleteDocumentAlert(documentId) {
    this.idCurrentProjectDocument = documentId;
    const deleteData = new AlertModel();
    deleteData.title = 'Delete';
    deleteData.msg = 'Are you sure to delete this record?';
    deleteData.id = documentId;
    deleteData.type = 'deleteProjectDocument';
    deleteData.reference = 'Delete Project Document';
    this.commonUIService.openAlert(deleteData);
  }

  //#region automatic task
  LoadTaskTypeListForHeader() {
    this.commonDropdownService.LoadTaskTypeListForHeader().subscribe(data => {
      this._taskTypeListForHeader = data;
      this._taskTypeListForHeader.forEach(ele => {
        ele.taskTypeName = ele.taskTypeName.trim();
      });
    });
  }

  getDataForMenu(requested) {
    requested = requested && requested.includes('-') ? requested.split('-')[0] + requested.split('-')[1] : requested;
    return { data: this[requested] };
  }

  OpenAutomaticTaskPopupForPopup(projectId, taskType, nextAfterDone) {
    let task = {
      projectId: projectId,
      taskTypeId: taskType.id,
      text: "Automatic",
      userId: this._loggedinUser ? this._loggedinUser.id : null,
      taskTypeName: taskType.taskTypeName
    }
    if ((taskType.taskTypeName != "Follow-up" && taskType.taskTypeName != "Reminder" && taskType.taskTypeName != "Claim" && !nextAfterDone) || nextAfterDone) {
      this._taskTypeIdForAutomatic = 0;
      const dialogViewData = this.commonUIService.setDataForModalDialog(projectId, null, 'projectTask', task, null, null);
      this.commonUIService.openDialog(AddEditProjectTaskComponent, dialogViewData);
    }
    else {
      this._taskTypeIdForAutomatic = taskType.id;
    }

  }

  openAutomaticProjectTaskForDropdown(projectId, item) {
    let task = {
      projectId: projectId,
      taskTypeId: this._taskTypeIdForAutomatic,
      text: "Automatic",
      userId: this._loggedinUser ? this._loggedinUser.id : null,
      taskTypeName: item.parent
    };
    if (item.label === 'Other') {
      const dialogViewData = this.commonUIService.setDataForModalDialog(projectId, null, 'projectTask', task, null, null);
      this.commonUIService.openDialog(AddEditProjectTaskComponent, dialogViewData);
    }
    else {
      let date = item.value;
      this.SaveAutomaticProjectTask(projectId, date);
    }
  }

  SaveAutomaticProjectTask(projectId, date) {
    let taskModalForSave = new ProjectTask();
    taskModalForSave.projectId = projectId;
    taskModalForSave.taskTypeId = this._taskTypeIdForAutomatic;
    taskModalForSave.userId = this._loggedinUser ? this._loggedinUser.id : null;
    taskModalForSave.status = false;
    taskModalForSave.taskName = null;
    taskModalForSave.taskDate = null;
    taskModalForSave.taskDateStr = date;
    taskModalForSave.taskType = null;
    taskModalForSave.description = null;
    taskModalForSave.taskTime = null;
    this.clientService.SaveProjectTask(taskModalForSave).subscribe(
      res => {
        if (res.isSuccsess) {
          const snack = new SnackBar(SnackBarType.success, 'Successfully saved', '', 4000);
          this.commonUIService.openSnackBar(snack);
          this.projectViewService.refreshDeleteProjectTask.emit(true);
          this.clientService.clientProjectTaskUpdate.emit(null);
          this.loadToDoProjectTaskList(this.clientService.selectedProjectId).then();
        }
        else {
          const snack = new SnackBar(SnackBarType.error, 'Error on save', '', 4000);
          this.commonUIService.openSnackBar(snack);
        }
      },
      () => { console.error(); });
  }

  menuOpenedPostpone(task) {
    this.selectedTaskId = task.id;
  }

  menuClosedPostpone() {
    this.selectedTaskId = 0;
  }
  //#endregion

  changeProjectTaskDate(id, date) {
    let re = /\//gi;
    let result = date.replace(re, "-");
    this.clientService.changeProjectTaskDate(id, result).subscribe(
      res => {
        if (res.data === true) {
          const snack = new SnackBar(SnackBarType.success, 'Task date updated', '', 4000);
          this.commonUIService.openSnackBar(snack);
          this.clientService.clientProjectTaskUpdate.emit(id);
          this.loadToDoProjectTaskList(this.clientService.selectedProjectId).then();
        }
      });
  }

  onDateChanged(id, event) {
    this.pickedDate = event.value;
    const pickedDateStr = this.pickedDate ? this.formatDatepicker.ConvertDateFormatForSave(this.pickedDate) : this.pickedDate;
    this.changeProjectTaskDate(id, pickedDateStr);
  }

  LoadActionTypeListForHeader() {
    this.commonDropdownService.LoadActionTypeListForHeader().subscribe(data => {
      if (!data || data.length <= 0) { return; }
      this._actionTypeListForHeader = data;
      this._actionTypeListForHeader.forEach(ele => {
        ele.actionType1 = ele.actionType1.trim();
      });
    });
  }

  showNoTaskPopup() {
    if (!this.selectedProject.isTaskList) {
      const snack = new SnackBar(SnackBarType.success, 'No tasks for this project', '', 5000);
      this.commonUIService.openSnackBar(snack);
    }
  }

  cancelProjectDocument() {
    this.clientService.cancelProjectDocument.subscribe(data => {
      this.isLadingProjectDocument = false;
      if (this.matTabGroup && this.matTabGroup.selectedIndex != undefined)
        this.matTabGroup.selectedIndex = data;
      this.selectedDocumentTypeId = data;
    })
  }

  newDocumentCreate() {
    this.clientService.newProjectDocument.subscribe(res => {
      var p;
      let x = res.documentTypeId;
      for (var i = 0; i < this.document.length; i++) {
        if (this.document[i].documentTypeId == x) {
          p = i;
          if (this.matTabGroup && this.matTabGroup.selectedIndex != undefined)
            this.matTabGroup.selectedIndex = i;
        }
      }
    })
  }

  toggleEstimationPhaseList(estimation: Estimation) {
    estimation.flag = !estimation.flag;
  }

  openAddEstimationPopup(project) {
    let estimation = {
      projectId: project.id,
      loggedUser: this._loggedinUser ? this._loggedinUser.name : null
    };
    const dialogViewData = this.commonUIService.setDataForModalDialog(null, null, null, estimation, project.id, null);
    this.commonUIService.openDialog(AddEstimationComponent, dialogViewData);
  }

  openEditEstimationPopup(estimation) {
    const dialogViewData = this.commonUIService.setDataForModalDialog(estimation.id, null, null, estimation, estimation.projectId, null);
    this.commonUIService.openDialog(AddEstimationComponent, dialogViewData);
  }

  openAddEstimationPhasePopup(estimation) {
    const dialogViewData = this.commonUIService.setDataForModalDialog(null, null, 'add-phase', estimation, estimation.projectId, null);
    this.commonUIService.openDialog(AddEstimationPhaseComponent, dialogViewData);
  }

  openEditEstimationPhasePopup(estimation, estimationPhase) {
    const dialogViewData = this.commonUIService.setDataForModalDialog(estimationPhase.id, null, 'edit-phase', estimationPhase, estimation.projectId, null);
    this.commonUIService.openDialog(AddEstimationPhaseComponent, dialogViewData);
  }

  subscribeRefreshEstimationList() {  
    this.projectViewService.refreshEstimationList.subscribe(data => {
      if (!data) { return; }
      this.loadProjectEstimationList(this.clientService.selectedProjectId);
    })
  }

  deleteEstimationPhasePopup(estimationPhase) {
    this.clientService.deleteEstimationPhase(estimationPhase.id).subscribe(
      res => {
        if (res.isSuccsess) {
          const snack = new SnackBar(SnackBarType.success, res.message, '', 2000);
          this.commonUIService.openSnackBar(snack);
          this.commonUIService.closeMask();
          this.loadProjectEstimationList(this.clientService.selectedProjectId);
        }
        else {
          this.commonUIService.closeMask();
          const snack = new SnackBar(SnackBarType.success, res.message, '', 2000);
          this.commonUIService.openSnackBar(snack);
        }
      }
    )
  }

  
  async subscribeRefreshProject() {
    this.projectViewService.refreshProject.subscribe(data => {     
      if (data) {   
        this.clientService.selectedProjectId = data.projectId?data.projectId: data.id;
        this.getProjectById(this.clientService.selectedProjectId);     
        this.cdRef.detectChanges();
      }
    })
  }

  getNextProjectId(id)
  {
    let activeProjList = this.clientService.clientActiveProjectList;
    //NOTE : The next index is same as the current index(After deletion, the next element index becomes the current deleted item's index)
    let nextProjIndex = activeProjList.length > 0 ? activeProjList.findIndex( x => parseInt(x.id) == parseInt(id)):-1; 
    this.clientService.nextActiveProjectIndex = nextProjIndex<=(activeProjList.length-1)?nextProjIndex :-1;   
    let nextProjectId:number =0;
    try 
    {
      nextProjectId = this.clientService.nextActiveProjectIndex>=0?activeProjList[this.clientService.nextActiveProjectIndex+1].id : 0;
    } catch (error) 
    { //To handle array out index error
      console.log(error);  
      nextProjectId = 0;   
    }
    return nextProjectId;
  }
}
