import { Component, OnInit } from '@angular/core';
import { UIService } from '../../shared/services/ui.service';
import { MaintenanceService } from '../service/maintenance.service';
import { AlertService } from '../../shared/services/alert.service';
import { APIService } from '../../shared/services/api.service';
import API from '../../shared/services/api.routes.json';
import { ActionTypeModel } from '../../shared/entities/actionTypeModel';
import { Subscription, Subject } from 'rxjs';
import { AlertModel } from '../../shared/entities/alertModel';
import { SnackBar, SnackBarType } from '../../shared/components/snackbar/entities/snackbar';
import { AddEditActiontypeComponent } from './add-edit-actiontype/add-edit-actiontype.component';

@Component({
  selector: 'app-action-type',
  templateUrl: './action-type.component.html',
  styleUrls: ['./action-type.component.scss']
})

export class ActionTypeComponent implements OnInit {
  _actionTypeList: ActionTypeModel[] = [];
  actionTypeList: ActionTypeModel[] = [];
  updateAction:ActionTypeModel;
  _idSelectedActionType: number;
  private subscriber: Subscription;
  private _unsubscribeAll: Subject<any>;
  isLoading: boolean = false;

  constructor(private uIService: UIService,
    private maintananceService: MaintenanceService,
    private _apiService: APIService,
    private alertService: AlertService) {
    this._actionTypeList = [];
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this.updateAction=new ActionTypeModel();
    this.LoadActionTypeList();
    this.subscribeAlertConfirmEvents();
    this.maintananceService.subjectActionTypeRefresh.subscribe(isStatusRefesh => {
      if (isStatusRefesh) {
        this.LoadActionTypeList();
      }
    });
  }

  subscribeAlertConfirmEvents() {
    this.subscriber = this.alertService.referenceDelete
      .subscribe(data => {
        if (data === "Delete ActionType" && this._idSelectedActionType) {
          this.deleteActionType(this._idSelectedActionType);
          console.log(this._idSelectedActionType);
        }
      });
  }

  addActionType() {
    this.uIService.openDialog(AddEditActiontypeComponent, null);
  }

  private LoadActionTypeList() {
    this.isLoading = true;
    this._apiService.get(API.actionType.GetAllActionType).subscribe(response => {
      if (response) {
        if (response.data) {
          this.actionTypeList = response.data;
          this._actionTypeList = this.actionTypeList.sort((a, b) => a.actionType1.localeCompare(b.actionType1));
        }
      }
      this.isLoading = false;
    },
      err => {
        console.error(err);
      }, () => {
      });
  }

  editActionType(type) {
    const actionTypeState = Object.assign({}, type);
    const dialogViewData = this.uIService.setDataForModalDialog(null, null, null, actionTypeState, null, null);
    this.uIService.openDialog(AddEditActiontypeComponent, dialogViewData);
  }

  openAlertDeleteActionType(id) {
    let deleteData = new AlertModel();
    deleteData.title = "Delete";
    deleteData.msg = "Are you sure to delete this record?";
    deleteData.id = id;
    deleteData.reference = "Delete ActionType";
    this._idSelectedActionType = id;
    this.uIService.openAlert(deleteData);
  }

  deleteActionType(typeId) {
    this.maintananceService.deleteActionType(typeId).subscribe(
      res => {
        if (res.isSuccsess) {
          var snack = new SnackBar(SnackBarType.success, res.message, "", 2000);
          this.uIService.openSnackBar(snack);
          if (res.data) this.LoadActionTypeList();
        }


      });
  }
  
  activeDeactive(event,type)
  {
    type.active = event.checked;
    this.maintananceService.updateActionType(type.id,type).subscribe(
      (res: { isSuccsess: any; }) => {
        if (res.isSuccsess) {
          this.maintananceService.actionTypeRefresh(true);
          var snack = new SnackBar(SnackBarType.success, "Successfully updated", "", 2000);
          this.uIService.openSnackBar(snack);
        }
        else {
          snack = new SnackBar(SnackBarType.success, "Error on update", "", 2000);
          this.uIService.openSnackBar(snack);
        }
      },
      () => { console.error(); });
  }

  ngOnDestroy(): void {
    this.subscriber.unsubscribe();
  }
}
