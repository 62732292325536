import { Component, OnInit, Inject } from '@angular/core';
import { UIService, DialogDataForModalView } from 'src/app/shared/services/ui.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SnackBar, SnackBarType } from 'src/app/shared/components/snackbar/entities/snackbar';
import { SharedHeaderService } from '../../../shared/services/shared.header.services';
import { ProductService } from '../../service/product.service';
import { Product } from '../../entities/product';
import { AddEditClientProductComponent } from '../add-edit-client-product/add-edit-client-product.component';
import { ClientService } from 'src/app/client/services/client.service';

@Component({
  selector: 'app-view-product-list',
  templateUrl: './view-product-list.component.html',
  styleUrls: ['./view-product-list.component.scss']
})

export class ViewProductListComponent implements OnInit {

  _productListForAdd: Product[] = [];
  _currentClientId : any;

  constructor(
    public commonUIService: UIService,
    public sharedHeaderService: SharedHeaderService,
    public productService: ProductService,
    private clientService :ClientService,
    public dialogRef: MatDialogRef<ViewProductListComponent>,
    @Inject(MAT_DIALOG_DATA) public modalViewData: DialogDataForModalView) {
    this._currentClientId = modalViewData.data.modalData.clientId;
  }

  ngOnInit() {
    this.loadProductList();
    this.subscribeRefreshClientProductPopup();
  }

  loadProductList() {
    this.productService.getProductList().subscribe(res => {     
      if (res && res.data) {
        this._productListForAdd = [];    
        let unassignedProducts = [];   
        unassignedProducts = res.data.filter(x => x.clientProduct == null || x.clientProduct.length == 0);        
        //Step 1 : Add all unassigned products
        for(var i=0;i<unassignedProducts.length;i++)
        {
          this._productListForAdd.push(unassignedProducts[i]);
        }     

        //Step 2 : Filter out all 'already-assigned' products for the selected client
        let assignedProductsFiltered = this.filterProductsByClientId(parseInt(this._currentClientId),res.data);
        for(var i=0;i<assignedProductsFiltered.length;i++)
        {
          this._productListForAdd.push(assignedProductsFiltered[i]);
        } 

        //Sort by Product name
        this._productListForAdd = this._productListForAdd.length>0?this._productListForAdd.sort((a, b) => a.productName.localeCompare(b.productName)):this._productListForAdd;
      }
      else {
        const snack = new SnackBar(SnackBarType.error, 'Error on list', '', 2000);
        this.commonUIService.openSnackBar(snack);
        this._productListForAdd = [];
      }
    },
      err => { console.error(err); }, () => { });
  }

  openAddClientProductPopup(product) {
    const dialogViewData = this.commonUIService.setDataForModalDialog(null, null, null, product, null, null);
    this.commonUIService.openDialog(AddEditClientProductComponent, dialogViewData)
  }

  filterProductsByClientId(clientId : number,productList:any)
  {
     let filteredList = [];
     for(var i=0;i<productList.length;i++)
     {
       let productElem = productList[i];   
       
       if(productElem.clientProduct && productElem.clientProduct.length>0)
       {
        let clientProductList = productElem.clientProduct;
        let productMappedCount :number = 0;

        for(var j=0;j<clientProductList.length;j++)
        {         
          if(clientProductList[j].clientId==clientId)
          {
            productMappedCount++;
          }
        }         
        //Add to list only if the product has no mapped record to the current client Id
        if(productMappedCount==0)
        {
          filteredList.push(productElem);
        }
       }      
     } 
     //Handle duplicate products
     filteredList = filteredList.length>0? filteredList.filter((v,i,a)=>a.findIndex(t=>(t.id === v.id))===i) : filteredList;
     return filteredList;
  }

  subscribeRefreshClientProductPopup() {
    this.clientService.refreshMappedProducts.subscribe(data => {   
      if (!data) { return; }
      this.loadProductList();
    });
  }
}
