import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DialogDataForModalView, UIService } from 'src/app/shared/services/ui.service';
import { CommonDropdownService } from 'src/app/shared/services/common.dropdown.service';
import { FormatMatDatepicker } from 'src/app/shared/format-datepicker';
import { APIService } from 'src/app/shared/services/api.service';
import API from 'src/app/shared/services/api.routes.json';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { HrmService } from '../../../../service/hrm.service';
import { AddEditEmployeeLetterComponent } from './add-edit-employee-letter/add-edit-employee-letter.component';
import { AlertModel } from '../../../../../shared/entities/alertModel';
import { Subscription } from 'rxjs';
import { AlertService } from '../../../../../shared/services/alert.service';
import { SnackBar, SnackBarType } from '../../../../../shared/components/snackbar/entities/snackbar';

@Component({
  selector: 'letter-document-view',
  templateUrl: './letter-document-view.component.html',
  styleUrls: ['./letter-document-view.component.scss']
})
export class LetterDocumentViewComponent implements OnInit {
  // config 
  public PDF = '../../assets/images/config/pdf.png';
  public PNG = '../../assets/images/config/png.png';
  public JPG = '../../assets/images/config/jpg.png';
  public JPEG = '../../assets/images/config/jpg.png';
  public XLS = '../../assets/images/config/xls.png';
  public DOC = '../../assets/images/config/doc.png';

  @ViewChild('matTabGroup', { static: false }) matTabGroup: any;
  subscriber: Subscription;
  files: File[] = [];
  employeeLetterTypeList: any = [];
  eventValue: any;
  selectedLetterTypeId: number = 0;
  permanentLetterTypeList: any = [];
  resignationLetterTypeList: any = [];
  letterTypeList: any = [];
  isLoadingPermanentLetter: boolean = false;
  isLoadingResignationLetter: boolean = false;
  isLoadingLetterList: boolean = false;
  idCurrentEmployeeLetterId: number = 0;
  isLoading: boolean = true;
  isLoading_PermanentLetter: boolean = false;
  isLoading_ResignationLetter: boolean = false;

  constructor(private _apiService: APIService,
    private _formBuilder: FormBuilder,
    private hrmService: HrmService,
    private commonUIService: UIService,
    private validationService: ValidationService,
    public dialogRef: MatDialogRef<LetterDocumentViewComponent>,
    @Inject(MAT_DIALOG_DATA) public modalViewData: DialogDataForModalView,
    private formatDatepicker: FormatMatDatepicker,
    public commonDropdownService: CommonDropdownService,
    public alertService: AlertService) {
  }

  ngOnInit() {
    if (this.modalViewData.data.id)
      this.loadEmployeeLetterList(this.modalViewData.data.id);
    this.subscribeRefreshEmployeeLetterDocumentList();
    this.subscribeAlertConfirmEvents();
    this.subscribeCancelLetter();
  }

  async loadEmployeeLetterList(employeeId) {
    this.hrmService.loadEmployeeLetterList(employeeId).subscribe(
      res => {
        if (res) {
          let _letterList = res.data;
          if (_letterList != null) {
            _letterList.forEach(element => {
              element.employeeLetterModelList.forEach(letter => {
                if (letter.fileName) {
                  letter.extension = letter.fileName.split('.')[(letter.fileName.split('.')).length - 1];
                }
                letter.link = API.baseUrl + API.employeeLetters.getEmployeeLetters + letter.id + '/' + letter.generatedFileName;
                if (letter.url) {
                  let l = letter.url.toString();
                  if (letter.extension === 'pdf')
                    l = l.includes('data:application/pdf;base64,') ? l : 'data:application/pdf;base64,' + l;
                  if (letter.extension === 'png')
                    l = l.includes('data:image/png;base64,') ? l : 'data:image/png;base64,' + l;
                  if (letter.extension === 'jpg')
                    l = l.includes('data:image/jpg;base64,') ? l : 'data:image/jpg;base64,' + l;
                  if (letter.extension === 'jpeg')
                    l = l.includes('data:image/jpeg;base64,') ? l : 'data:image/jpeg;base64,' + l;
                  if (letter.extension === 'docx')
                    l = l.includes('https://docs.google.com/gview?url=') ? l : 'https://docs.google.com/gview?url=' + letter.link + '&embedded=true';
                  if (letter.extension === 'xlsx' || letter.extension === 'xls')
                    l = l.includes('https://docs.google.com/gview?url=') ? l : 'https://docs.google.com/gview?url=' + letter.link + '&embedded=true';
                }
              });
            });
            this.isLoading = false;
          }
          this.employeeLetterTypeList = [];
          this.permanentLetterTypeList = [];
          this.resignationLetterTypeList = [];
          this.letterTypeList = [];
          this.employeeLetterTypeList = _letterList;
          this.isLoading_PermanentLetter = false;
          this.isLoading_ResignationLetter = false;
          this.permanentLetterTypeList.push(this.employeeLetterTypeList[3]);
          this.resignationLetterTypeList.push(this.employeeLetterTypeList[4]);
          this.letterTypeList.push(this.employeeLetterTypeList[0], this.employeeLetterTypeList[1], this.employeeLetterTypeList[2], this.employeeLetterTypeList[5]);
          this.isLoadingPermanentLetter = this.permanentLetterTypeList[0].employeeLetterModelList.length > 0 ? true : false;
          this.isLoadingResignationLetter = this.resignationLetterTypeList[0].employeeLetterModelList.length > 0 ? true : false;
        }
      },
      err => { console.error(); this.isLoading = true; });
  }

  openAddEmployeeLetterPopup(type: any) {
    if (type.letterTypeId == 4) {
      this.isLoadingPermanentLetter = true;
      this.isLoading_PermanentLetter = true;
    } else if (type.letterTypeId == 5) {
      this.isLoadingResignationLetter = true;
      this.isLoading_ResignationLetter = true;
    }
    const dialogViewData = this.commonUIService.setDataForModalDialog(this.modalViewData.data.id, null, type.letterTypeId, null, null, null);
    this.commonUIService.openDialog(AddEditEmployeeLetterComponent, dialogViewData);
  }

  documentSelect(event) {
    if (event) {
      const matTabGroupLength = this.matTabGroup._tabs.length;
      const _lenthForGetLastIndex = (matTabGroupLength - 1);
      const _selectedIndex = event.index;
      const _selectedTab = event.tab._viewContainerRef.element.nativeElement;
      if (_selectedIndex === _lenthForGetLastIndex) {
        this.matTabGroup._tabHeader._inkBar.hide();
      }
      else {
        this.matTabGroup._tabHeader._inkBar.show();
        this.eventValue = event;
      }
      this.selectedLetterTypeId = _selectedTab.id;
    } else {
      this.selectedLetterTypeId = this.selectedLetterTypeId;
    }
  }

  openDeleteLetterDocumentAlert(employeeLetterId) {
    this.idCurrentEmployeeLetterId = employeeLetterId;
    const deleteData = new AlertModel();
    deleteData.title = 'Delete';
    deleteData.msg = 'Are you sure to delete this record?';
    deleteData.id = employeeLetterId;
    deleteData.type = 'deleteLetterDocument';
    deleteData.reference = 'Delete Letter Document';
    this.commonUIService.openAlert(deleteData);
  }

  subscribeAlertConfirmEvents() {
    this.subscriber = this.alertService.referenceDelete.subscribe(data => {
      if (data === 'Delete Letter Document') {
        this.deleteEmployeeLetterDocument(this.idCurrentEmployeeLetterId);
      }
    });
  }

  deleteEmployeeLetterDocument(employeeLetterId) {
    this.hrmService.deleteEmployeeLetter(employeeLetterId).subscribe(
      res => {
        if (res.isSuccsess) {
          const snack = new SnackBar(SnackBarType.success, 'Successfully deleted', '', 2000);
          this.commonUIService.openSnackBar(snack);
          this.loadEmployeeLetterList(this.modalViewData.data.id);
        }
      }
    )
  }

  subscribeRefreshEmployeeLetterDocumentList() {
    this.hrmService.refreshEmployeeLetterList.subscribe(data => {
      if (data.letterTypeId == 4) {
        this.isLoading_PermanentLetter = false;
      } else if (data.letterTypeId == 5) {
        this.isLoading_ResignationLetter = false;
      }
      this.loadEmployeeLetterList(this.modalViewData.data.id);
    });
  }

  subscribeCancelLetter() {
    this.hrmService.cancelLetter.subscribe(data => {
      if (data == 4) {
        this.isLoadingPermanentLetter = false;
        this.isLoading_PermanentLetter = false;
      } else if (data == 5) {
        this.isLoadingResignationLetter = false;
        this.isLoading_ResignationLetter = false;
      }
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
