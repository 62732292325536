import { Component, OnInit } from '@angular/core';
import API from '../../../shared/services/api.routes.json';
import { Subscription, Subject } from 'rxjs';
import { SharedLinkedInService } from '../../service/shared.linkedin.service';
import { Users } from '../../../auth/entities/user';
import { LoginService } from '../../../auth/login/login.service';
import { AddEditLinkedInComponent } from './add-edit-linkedin/add-edit-linkedin.component';
import { CommonDropdownService } from '../../../shared/services/common.dropdown.service';
import { UIService } from '../../../shared/services/ui.service';
import { APIService } from '../../../shared/services/api.service';
import { AlertService } from '../../../shared/services/alert.service';
import { LinkedIn } from '../../entities/linkedin';
import { LinkedInService } from '../../service/linkedin.service';
import { SnackBar, SnackBarType } from '../../../shared/components/snackbar/entities/snackbar';
import { AlertModel } from '../../../shared/entities/alertModel';
import { SharedHeaderService } from 'src/app/shared/services/shared.header.services';
import { LinkedInFilterModel } from '../../entities/linkedInFilterModel';
import { LinkedinReasonPopupComponent } from './linkedin-reason-popup/linkedin-reason-popup.component';
import { FormatMatDatepicker } from 'src/app/shared/format-datepicker';
import { LinkdinModel } from 'src/app/shared/entities/linkedinModel';
import { PageEvent } from '@angular/material';
import { AuthAppService } from '../../../shared/services/auth-app.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-view-linkedin',
  templateUrl: './view-linkedin.component.html',
  styleUrls: ['./view-linkedin.component.scss']
})
export class ViewLinkedInComponent implements OnInit {
  _linkedInList: LinkedIn[] = [];
  linkedInList: LinkedIn[] = [];
  _idSelectedLinkedIn: number;
  today_date: string;
  selectedValue: string;
  filterAciveDeactive = false;
  globalCompanyId: number = null;
  globalCompanyName: string = null;
  currentusers: Users;
  selectedCompanyId?: number = null;
  private subscriberCompanyId: Subscription;
  private subscriberReasonPoup: Subscription;
  private _unsubscribeAll: Subject<any>;
  isLoading: boolean = true;
  filterObject: LinkedInFilterModel = new LinkedInFilterModel();
  //sortingOption: string = 'name_asc';
  sortingField: string = 'name';
  isEntryDateAsc: boolean = false;
  isNameAsc: boolean = true;
  isStateAsc: boolean = false;
  isReminderAsc: boolean = false;
  searchText: string = null;
  stateText: string = 'Leads';
  userText: string = null;
  pickedDate: Date;
  objForUpdateState: LinkedIn;
  private subscriber: Subscription;
  totalRecords: number = null;
  lowValue: number = 0;
  highValue: number = 15;
  pageIndex: number = 0;
  totalCount: number = 0;
  _linkedInTempleteList: any[] = [];
  type: string = 'Standard';
  language: string = 'ESP';
  permissionsList: any[] = [];
  _statusList: any[] = [];
  _linkedInTempleteListForCopy: any[] = [];

  constructor(private commonUIService: UIService,
    private linkedInService: LinkedInService,
    private _apiService: APIService,
    private loginServic: LoginService,
    private sharedLinkedInService: SharedLinkedInService,
    private alertService: AlertService,
    private sharedHeaderService: SharedHeaderService,
    public commonDropdownService: CommonDropdownService,
    private authAppService: AuthAppService,
    private router: Router,
    private formatDatepicker: FormatMatDatepicker) {
    this._linkedInList = [];
    this._unsubscribeAll = new Subject();
    this.objForUpdateState = new LinkedIn();
    this.filterObject.companyId = this.sharedHeaderService.isMainBranch ? null : this.globalCompanyId;
    this.filterObject.sortField = !this.filterObject.sortField ? "name" : this.filterObject.sortField;
    this.filterObject.sortType = !this.filterObject.sortType ? "asc" : this.filterObject.sortType;
  }

  ngOnInit() {
    this.totalRecords = null;
    this.type = 'Standard';
    this.language = 'ESP';
    this.getCurrentUser();
    this.commonDropdownService.LoadStatusList('LinkedIn');
    this.filterObject.state = 'Leads';
    this.LoadLinkedInList(this.filterObject);
    this.commonUIService.loadReminderDateList()
    this.subscribeEmitters();
    this.subscribeLinkedInRefresh();
    this.subscribeAlertConfirmEvents();
    this.loadLinkedInTempletesList();
    this.permissionsList = this.authAppService.getLoggedInPermission();
    this.loadStatusList('LinkedIn');
  }
  subscribeLinkedInRefresh() {
    this.linkedInService.subjectLinkedInRefresh.subscribe(res => {
      if (res == true) {
        this.LoadLinkedInList(this.filterObject);
      }
    });
  }

  public loadStatusList(type: string) {
    const url = this._apiService.getEndPoint("status.listAll", [["{type}", type]]);
    this._apiService.get(url)
      .subscribe(response => {
        this._statusList = response.data;
        let index1 = this._statusList.findIndex(s => s.text === '1x');
        if (index1 !== -1) {
          this._statusList.splice(index1, 1);
        }
        let index2 = this._statusList.findIndex(s => s.text === '2x');
        if (index2 !== -1) {
          this._statusList.splice(index2, 1);
        }
        let index3 = this._statusList.findIndex(s => s.text === '3x');
        if (index3 !== -1) {
          this._statusList.splice(index3, 1);
        }
      }, err => {
        console.error(err)
      }, () => { });
  }

  async getCurrentUser() {
    let jsonString = await this.loginServic.getSessionAppUser();
    this.currentusers = jsonString ? JSON.parse(jsonString) : "";
    if (this.currentusers.imageUrl == undefined) {
      this.currentusers.imageUrl = "assets/images/profile.jpg";
    }
    this.globalCompanyId = !this.sharedHeaderService.selectedCompanyId ? this.currentusers.company.id : this.sharedHeaderService.selectedCompanyId;
    this.globalCompanyName = !this.sharedHeaderService.selectedCompanyName ? this.currentusers.company.name : this.sharedHeaderService.selectedCompanyName;
  }

  subscribeEmitters() {
    this.subscriberCompanyId = this.sharedLinkedInService.passCompanyIdToLinkedIn.subscribe(data => {
      if (data) {
        this.globalCompanyId = data.companyId;
        this.sharedLinkedInService.linkedInCompanyId = data.companyId;
        this.filterObject.companyId = this.globalCompanyId > 0 && data.isLoad && this.sharedHeaderService.isMainBranch ? null : this.globalCompanyId;
        this.LoadLinkedInList(this.filterObject);
      }
    });
    this.subscriberReasonPoup = this.linkedInService.linkedInCloseReasonEmit.subscribe(data => {
      if (data && this.objForUpdateState) {
        this.objForUpdateState.comments = !this.objForUpdateState.comments ? data : this.objForUpdateState.comments.concat('\n', data);
        this.updateLinkedIn(this.objForUpdateState);
      }
    })
  }

  private LoadLinkedInList(filterObject: LinkedInFilterModel) {
    this.isLoading = true;
    this.linkedInService.getLinkedInList(filterObject).subscribe(res => {
      this.totalRecords = null;
      if (res && res.isSuccsess && res.data) {
        this.linkedInService.commonLinkedList = res.data;
        this._linkedInList = res.data;
        this._linkedInList.forEach(act => {
          act.showMore = false;
          if (act.comments)
            act.characterLength = act.comments.length;
          else
            act.characterLength = null;
        });


        this.totalRecords = res.data.length;
        this._linkedInList.forEach(ele => {
          if (ele.state == 'NO' || ele.state == 'OK') {
            ele.isReminderDropdown = false;
          } else {
            ele.isReminderDropdown = true;
          }

          ele.initials = this.commonUIService.getInitials(ele.userName);
          if (ele.statusNavigation) {
            ele.statusNavigation.idString = ele.statusNavigation.id.toString();
          }
        });
        this.isLoading = false;
      }
      else {
        const snack = new SnackBar(SnackBarType.error, 'Error on list', '', 2000);
        this.commonUIService.openSnackBar(snack);
        this.isLoading = false;
        this.totalRecords = null;
      }
    },
      err => { console.error(err); this.isLoading = false; }, () => { });
  }

  sortingList(field: string) {
    let type: string = null;
    switch (field) {
      case "entryDate":
        this.isEntryDateAsc = !this.isEntryDateAsc;
        type = this.isEntryDateAsc ? 'asc' : 'desc';
        this.sortingField = 'entryDate';
        this.isNameAsc = false;
        this.isStateAsc = false;
        this.isReminderAsc = false;
        break;
      case "name":
        this.isNameAsc = !this.isNameAsc;
        type = this.isNameAsc ? 'asc' : 'desc';
        this.sortingField = 'name';
        this.isEntryDateAsc = false;
        this.isStateAsc = false;
        this.isReminderAsc = false;
        break;
      case "state":
        this.isStateAsc = !this.isStateAsc;
        type = this.isStateAsc ? 'asc' : 'desc';
        this.sortingField = 'state';
        this.isEntryDateAsc = false;
        this.isNameAsc = false;
        this.isReminderAsc = false;
        break;
      case "reminder":
        this.isReminderAsc = !this.isReminderAsc;
        type = this.isReminderAsc ? 'asc' : 'desc';
        this.sortingField = 'reminder';
        this.isEntryDateAsc = false;
        this.isNameAsc = false;
        this.isStateAsc = false;
        break;
    }
    this.filterObject.sortField = field;
    this.filterObject.sortType = type;
    this.LoadLinkedInList(this.filterObject);
  }

  swipeLinkedInUser(obj: LinkedIn) {
    let userName = obj.userName.toLowerCase();
    if (userName.includes('miriam') || userName.includes('oscar') || userName.includes('rikasa') || userName.includes('piyumali')) {
      obj.updatedCatagory = "swapUser";
      this.updateLinkedIn(obj);
    }
  }

  changeLinkedInState(obj: LinkedIn, isAutomatic: boolean, event) {
    this.objForUpdateState = Object.assign({}, obj);
    let statusList = this.commonDropdownService._statusList;
    this.objForUpdateState.updatedCatagory = 'stateChange';
    if (isAutomatic && obj && obj.statusNavigation) {
      const statusId = this.setLinkedInStateForAutomaticSelect(obj.statusNavigation.text, statusList);
      this.objForUpdateState.statusId = statusId ? statusId : this.objForUpdateState.statusId;
      if (statusId) this.updateLinkedIn(this.objForUpdateState);
    }
    if (!isAutomatic && obj && obj.statusNavigation && event) {
      this.objForUpdateState.statusId = Number(event.currentTarget.id); //Number(event.value);
      let statusText = statusList.filter(m => m.id === this.objForUpdateState.statusId)[0].text;
      if (statusText === "NO") this.OpenLinkedInReasonPopup('ViewComment');
      else this.updateLinkedIn(this.objForUpdateState);
    }
  }


  changeReminderDate(item, userObj) {
    let objForUpdateState = Object.assign({}, userObj);
    objForUpdateState.reminderDate = this.linkedInService.setDateValueToPicker(item)
    objForUpdateState.reminderDateStr = objForUpdateState.reminderDate ? this.formatDatepicker.ConvertDateFormatForSave(objForUpdateState.reminderDate) : objForUpdateState.reminderDate;
    objForUpdateState.updatedCatagory = "reminderChange";
    this.updateLinkedIn(objForUpdateState);
  }

  onDateChanged(userObj, event) {
    let objForUpdateState = Object.assign({}, userObj);
    this.pickedDate = event.value;
    objForUpdateState.reminderDateStr = this.pickedDate ? this.formatDatepicker.ConvertDateFormatForSave(this.pickedDate) : this.pickedDate;
    objForUpdateState.updatedCatagory = "reminderChange";
    objForUpdateState.reminderDate = this.pickedDate;
    this.updateLinkedIn(objForUpdateState);
  }

  private setLinkedInStateForAutomaticSelect(text: string, statusList) {
    let statusId: number = null;
    switch (text) {
      case "1":
        statusId = statusList.filter(m => m.text === "2")[0].id;
        break;
      case "1S":
        statusId = statusList.filter(m => m.text === "2S")[0].id;
        break;
      case "1W":
        statusId = statusList.filter(m => m.text === "2W")[0].id;
        break;
      case "1E":
        statusId = statusList.filter(m => m.text === "2E")[0].id;
        break;
      case "2":
        statusId = statusList.filter(m => m.text === "3")[0].id;
        break;
      case "2S":
        statusId = statusList.filter(m => m.text === "3")[0].id;
        break;
      case "2W":
        statusId = statusList.filter(m => m.text === "3")[0].id;
        break;
      case "2E":
        statusId = statusList.filter(m => m.text === "3")[0].id;
        break;
      case "3":
        statusId = statusList.filter(m => m.text === "STANDBY")[0].id;
        break;
      case "STANDBY":
        statusId = statusList.filter(m => m.text === "B1")[0].id;
        break;
      case "B1":
        statusId = statusList.filter(m => m.text === "B2")[0].id;
        break;
      case "B2":
        statusId = statusList.filter(m => m.text === "STANDBY 2")[0].id;
        break;
    }
    return statusId;
  }
  private OpenLinkedInReasonPopup(data) {
    const dialogViewData = this.commonUIService.setDataForModalDialog(null, null, data, null, null, null);
    this.commonUIService.openDialog(LinkedinReasonPopupComponent, dialogViewData);
  }

  changeLinkedInCheck(obj: LinkedIn, event) {
    let objForUpdateIsLinkedIn = Object.assign({}, obj);
    objForUpdateIsLinkedIn.isLinkedIn = event.checked;
    objForUpdateIsLinkedIn.updatedCatagory = "markLinkedIn";
    this.updateLinkedIn(objForUpdateIsLinkedIn);
  }


  private updateLinkedIn(obj: LinkedIn) {
    this.commonUIService.openMask();
    this.linkedInService.updateLinkedIn(obj).subscribe(res => {
      if (res && res.isSuccsess && res.data) {
        if (this.sortingField == 'state' || this.sortingField == 'reminder') this.LoadLinkedInList(this.filterObject);
        this.resetUpdatedRow(res.data);
        const snack = new SnackBar(SnackBarType.success, 'Successfully updated', '', 2000);
        this.commonUIService.openSnackBar(snack);
        this.commonUIService.closeMask();
      }
      else {
        const snack = new SnackBar(SnackBarType.error, 'Error on update', '', 2000);
        this.commonUIService.openSnackBar(snack);
        this.commonUIService.closeMask();
      }
    },
      err => { console.error(err); this.commonUIService.closeMask(); }, () => { });
  }

  private resetUpdatedRow(obj: LinkedIn) {
    this._linkedInList.forEach((ele: LinkedIn) => {
      if (ele.id == obj.id) {
        ele.user = obj.user;
        ele.userName = obj.userName;
        ele.linkedInUserImageUrl = obj.linkedInUserImageUrl;
        ele.initials = this.commonUIService.getInitials(obj.userName);
        ele.statusId = obj.statusId;
        ele.state = obj.state;
        ele.language = obj.language;
        ele.statusNavigation = obj.statusNavigation;
        ele.color = obj.color;
        if (ele.statusNavigation && obj.statusNavigation) {
          ele.statusNavigation.idString = obj.statusNavigation.id.toString();
        }
        ele.isLinkedIn = obj.isLinkedIn;
        if (obj.reminderDate == null) {
          if (obj.state == 'NO' || obj.state == 'OK') {
            ele.isReminderDropdown = false;
          }
          else {
            ele.isReminderDropdown = true;
          }
        }
        else {
          ele.isReminderDropdown = true;
        }

        ele.reminderDate = obj.reminderDate;
        ele.comments = obj.comments;
      }
    });
  }
  filterLinkedInByState(event) {
    if (event && event.value != null && event.value != undefined) {
      this.filterObject.state = event.value;
      this.setValueToFilterControl();
      this.commonUIService.delay(() => { this.LoadLinkedInList(this.filterObject); }, 1000);
    }
  }
  filterLinkedInByText(event) {
    if (event && event.target.value != null && event.target.value != undefined) {
      this.filterObject.searchText = event.target.value;
      this.setValueToFilterControl();
      this.commonUIService.delay(() => { this.LoadLinkedInList(this.filterObject); }, 1000);
    }
  }
  filterLinkedInByUser(event) {
    if (event && event.value != null && event.value != undefined) {
      this.filterObject.user = event.value;
      this.setValueToFilterControl();
      this.commonUIService.delay(() => { this.LoadLinkedInList(this.filterObject); }, 1000);
    }
  }
  setValueToFilterControl() {
    this.searchText = this.filterObject.searchText;
    this.stateText = this.filterObject.state;
    this.userText = this.filterObject.user;
  }
  refershFilter() {
    this.filterObject.searchText = null;
    this.filterObject.state = null;
    this.filterObject.user = null;
    if (this.searchText || this.stateText || this.userText) {
      this.setValueToFilterControl();
      this.commonUIService.delay(() => { this.LoadLinkedInList(this.filterObject); }, 1000);
    }
  }
  unsubscribeEmitters() {
    this.subscriberCompanyId.unsubscribe();
    this.subscriberReasonPoup.unsubscribe();
  }

  subscribeAlertConfirmEvents() {
    this.subscriber = this.alertService.referenceDelete
      .subscribe(data => {
        if (data === "Delete LinkedIn" && this._idSelectedLinkedIn) {
          this.deleteLinkedIn(this._idSelectedLinkedIn);
        }
      });
  }

  openAddLinkedInPopup() {
    this.linkedInService.isLinkedInAdd = true;
    this.commonUIService.openDialog(AddEditLinkedInComponent, null);
  }

  ngOnDestroy(): void {
    this.unsubscribeEmitters();
  }

  openEditLinkedInPopup(linkedInObj) {
    this.linkedInService.isLinkedInAdd = false;
    let linkedInforEdit = Object.assign({}, linkedInObj);
    const dialogViewData = this.commonUIService.setDataForModalDialog(null, null, null, linkedInforEdit, null, null);
    this.commonUIService.openDialog(AddEditLinkedInComponent, dialogViewData);
  }


  openAlertDeleteLinkedIn(id) {
    let deleteData = new AlertModel();
    deleteData.title = "Delete";
    deleteData.msg = "Are you sure to delete this record?";
    deleteData.id = id;
    deleteData.reference = "Delete LinkedIn";
    this._idSelectedLinkedIn = id;
    this.commonUIService.openAlert(deleteData);
  }

  deleteLinkedIn(linkedinId) {
    this.linkedInService.deleteLinkedIn(linkedinId).subscribe(
      res => {
        if (res.isSuccsess) {
          var snack = new SnackBar(SnackBarType.success, res.message, "", 2000);
          this.commonUIService.openSnackBar(snack);
          this.LoadLinkedInList(this.filterObject);
        }
      });
  }

  loadLinkedInTempletesList() {    
    this.linkedInService.getAllTempleteList().subscribe(res => {
      if (res && res.data) {
        this._linkedInTempleteList = [];
        this._linkedInTempleteListForCopy = res.data.filter(x => x.category.category != null && x.category.category.toLowerCase().trim() == 'linkedin'
          && x.subcategory.subcategory != null && x.subcategory.subcategory.toLowerCase().trim() == 'standard' || x.subcategory.subcategory.toLowerCase().trim() == 'invitation');
          

        this._linkedInTempleteList = res.data.filter(x => x.category.category != null && x.category.category.toLowerCase().trim() == 'linkedin'
          && x.subcategory.subcategory != null && x.subcategory.subcategory.toLowerCase().trim() == this.type.toLowerCase().trim());   
                    
          const url = this._apiService.getEndPoint("status.listAll", [["{type}", 'LinkedIn']]);
          this._apiService.get(url)
            .subscribe(response => {
              this._statusList = response.data;
              let index1 = this._statusList.findIndex(s => s.text === '1x');
              if (index1 !== -1) {
                this._statusList.splice(index1, 1);
              }
              let index2 = this._statusList.findIndex(s => s.text === '2x');
              if (index2 !== -1) {
                this._statusList.splice(index2, 1);
              }
              let index3 = this._statusList.findIndex(s => s.text === '3x');
              if (index3 !== -1) {
                this._statusList.splice(index3, 1);
              }
              let st = this._statusList;
              if (this._linkedInTempleteList.length > 0 && st.length > 0) {
                for (var i = 0; i < this._linkedInTempleteList.length; i++) {
                  let status = st.filter((k) => k.text.toLowerCase().trim() == this._linkedInTempleteList[i].name.toLowerCase().trim());
                  if (status.length > 0) {
                    if (status[0].text == this._linkedInTempleteList[i].name)
                      this._linkedInTempleteList[i].colorStyles = st.filter((k) => k.text.toLowerCase().trim() == this._linkedInTempleteList[i].name.toLowerCase().trim())[0].color;
                    else
                      this._linkedInTempleteList[i].colorStyles = '#FFFFFF';
                  }
                  else
                    this._linkedInTempleteList[i].colorStyles = '#FFFFFF';
                }
              }
            }, err => {
              console.error(err)
            }, () => { });       
       
      }
      else {
        const snack = new SnackBar(SnackBarType.error, 'Error on list', '', 2000);
        this.commonUIService.openSnackBar(snack);
        this._linkedInTempleteList = [];
      }
    },
      err => { console.error(err); }, () => { });
  }

  filterLinkedInTempletesListByType(type: string) {
   
    if (type != null || type != undefined) {
      this.type = type;
      this.loadLinkedInTempletesList();
    }
  }

  copyToClipBoardTemplete(template, val: string) {
    if (this.language) {
      if (this.language == 'ESP') {
        val = template.esptext;
      } else if (this.language == 'CAT') {
        val = template.cattext;
      }
      else if (this.language == 'ENG') {
        val = template.engtext;
      }
    }
    if (val) {
      const selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = val;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);
    } else {
      const selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = ' ';
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);
    }
  }

  copyToClipBoardLanguage(val: string) {
    if (val) {
      const selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = val;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);
    } else {
      const selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = ' ';
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);
    }
  }

  changeLanguage(obj: LinkedIn, language: string) {
    if (language) {
      if (language == 'ESP') {
        language = 'CAT';
        obj.language = language;
        this.updateLinkedInObj(obj.id, obj);
      }
      else if (language == 'CAT') {
        language = 'ENG';
        obj.language = language;
        this.updateLinkedInObj(obj.id, obj);
      }
      else if (language == 'ENG') {
        language = 'ESP';
        obj.language = language;
        this.updateLinkedInObj(obj.id, obj);
      }
    }
  }

  updateLinkedInObj(id, obj) {
    this.linkedInService.updateLinkedInObj(id, obj).subscribe(
      (res: { isSuccsess: any; }) => {
        if (res.isSuccsess) {
          this.LoadLinkedInList(this.filterObject);
        }
      },
      () => { console.error(); });
  }

  public getDataForLinkedIn(event: PageEvent): PageEvent {
    this.lowValue = event.pageIndex * event.pageSize;
    this.highValue = this.lowValue + event.pageSize;
    return event;
  }

  changeFilterLanguage(language: string) {
    if (language) {
      this.language = language;
    }
  }

  copySelectedItem(copyText, language) {
    let copyValue: string = null;
    if (copyText) {
      if (this._linkedInTempleteListForCopy.length > 0) {
        let templete = this._linkedInTempleteListForCopy.filter(k => k.name != null && k.name.toLowerCase().trim() == copyText.toLowerCase().trim());
        if (templete.length > 0) {
          if (language) {
            if (language == 'ESP') {
              if (templete[0].esptext)
                copyValue = templete[0].esptext;
              else
                copyValue = null;
            } else if (language == 'CAT') {
              if (templete[0].cattext)
                copyValue = templete[0].cattext;
              else
                copyValue = null;
            } else if (language == 'ENG') {
              if (templete[0].engtext)
                copyValue = templete[0].engtext;
              else
                copyValue = null;
            }
            if (copyValue) {
              const selBox = document.createElement('textarea');
              selBox.style.position = 'fixed';
              selBox.style.left = '0';
              selBox.style.top = '0';
              selBox.style.opacity = '0';
              selBox.value = copyValue;
              document.body.appendChild(selBox);
              selBox.focus();
              selBox.select();
              document.execCommand('copy');
              document.body.removeChild(selBox);
            } else {
              const selBox = document.createElement('textarea');
              selBox.style.position = 'fixed';
              selBox.style.left = '0';
              selBox.style.top = '0';
              selBox.style.opacity = '0';
              selBox.value = '  ';
              document.body.appendChild(selBox);
              selBox.focus();
              selBox.select();
              document.execCommand('copy');
              document.body.removeChild(selBox);
            }
          } else {
            const selBox = document.createElement('textarea');
            selBox.style.position = 'fixed';
            selBox.style.left = '0';
            selBox.style.top = '0';
            selBox.style.opacity = '0';
            selBox.value = '  ';
            document.body.appendChild(selBox);
            selBox.focus();
            selBox.select();
            document.execCommand('copy');
            document.body.removeChild(selBox);
          }
        } else {
          const selBox = document.createElement('textarea');
          selBox.style.position = 'fixed';
          selBox.style.left = '0';
          selBox.style.top = '0';
          selBox.style.opacity = '0';
          selBox.value = '  ';
          document.body.appendChild(selBox);
          selBox.focus();
          selBox.select();
          document.execCommand('copy');
          document.body.removeChild(selBox);
        }
      }
    }
  }
}
