import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DialogDataForModalView, UIService } from 'src/app/shared/services/ui.service';
import { CommonDropdownService } from 'src/app/shared/services/common.dropdown.service';
import { isNumber } from 'util';
import { SnackBarType, SnackBar } from 'src/app/shared/components/snackbar/entities/snackbar';
import { FormatMatDatepicker } from 'src/app/shared/format-datepicker';
import { APIService } from 'src/app/shared/services/api.service';
import API from 'src/app/shared/services/api.routes.json';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { EmployeeDocument } from '../../../../entities/empoyeeDocument';
import { Employee } from '../../../../entities/empoyee';
import { HrmService } from '../../../../service/hrm.service';
import { AlertModel } from 'src/app/shared/entities/alertModel';
import { AlertService } from 'src/app/shared/services/alert.service';
import { AddPersonalDocumentComponent } from './add-personal-document/add-personal-document.component';
declare const JSON;

@Component({
  selector: 'personal-document-view',
  templateUrl: './personal-document-view.component.html',
  styleUrls: ['./personal-document-view.component.scss']
})
export class PersonalDocumentViewComponent implements OnInit {

  personalDocumentFormGroup: FormGroup;
  _personalEmpDocumentModal: EmployeeDocument;
  _personalEmpDocumentForSave: EmployeeDocument;
  personalDocList: any[] = [];

  employeeDocumentGroupList: any = [];
  resumeDocumentList: any = [];
  nicList: any = [];
  birthsertificateDocumentList: any = [];
  EduDocList: any = [];
  priorDocList: any = [];
  commonGroupId: number;

  isLoading: boolean = true;
  isLoading_ResumeDocument: boolean = false;
  isLoading_NicAgreement: boolean = false;
  isLoading_BirthCertDocument: boolean = false;
  isLoading_education: boolean = false;
  isLoading_Prior: boolean = false;
  idCurrentClientDocument: number = 0;
  subscriber: Subscription;

  isLoadingNicDocument: boolean = false;
  isLoadingResumeDocument: boolean = false;
  isLoadingBirthCertDocument: boolean = false;
  isLoadingeducation: boolean = false;
  isLoadingPrior: boolean = false;


  public PDF = '../../assets/images/config/pdf.png';
  public PNG = '../../assets/images/config/png.png';
  public JPG = '../../assets/images/config/jpg.png';
  public JPEG = '../../assets/images/config/jpg.png';
  public XLS = '../../assets/images/config/xls.png';
  public DOC = '../../assets/images/config/doc.png';

  constructor(private _apiService: APIService,
    private _formBuilder: FormBuilder,
    private hrmService: HrmService,
    private commonUIService: UIService,
    private validationService: ValidationService,
    public dialogRef: MatDialogRef<PersonalDocumentViewComponent>,
    @Inject(MAT_DIALOG_DATA) public modalViewData: DialogDataForModalView,
    private formatDatepicker: FormatMatDatepicker,
    public commonDropdownService: CommonDropdownService,
    public alertService: AlertService) {

    this._personalEmpDocumentModal = new EmployeeDocument();
    this._personalEmpDocumentForSave = new EmployeeDocument();

  }

  ngOnInit() {
    if (this.modalViewData.data.id) {
      this.loadEmployeeDocumentList(this.modalViewData.data.id);
    }

    this.subscribeAlertConfirmEvents();
    this.subscribeCancelDocument();
    this.subscribeRefreshPersonalEmployeeDocumentList();
  }

  subscribeRefreshPersonalEmployeeDocumentList() {
    this.hrmService.refreshPersonalEmployeeDocumentList.subscribe(data => {
      this.loadEmployeeDocumentList(this.modalViewData.data.id);
    });
  }

  openDeleteDocumentAlert(personalDocId, docGroupId) {
    this.idCurrentClientDocument = personalDocId;
    const deleteData = new AlertModel();
    deleteData.title = 'Delete';
    deleteData.msg = 'Are you sure to delete this record?';
    deleteData.id = personalDocId;
    deleteData.type = 'deleteDocument';
    deleteData.reference = 'Delete Document';
    this.commonGroupId = docGroupId;
    this.commonUIService.openAlert(deleteData);
  }

  deleteEmployeePersonalDocument(clientDocumentId) {

    if (this.commonGroupId == 6) {
      this.isLoading_NicAgreement = true;
    } else if (this.commonGroupId == 7) {
      this.isLoading_BirthCertDocument = true;
    } else if (this.commonGroupId == 8) {
      this.isLoading_ResumeDocument = true;
    } else if (this.commonGroupId == 9) {
      this.isLoading_Prior = true;
    } else if (this.commonGroupId == 10) {
      this.isLoading_education = true;
    }
    this.hrmService.deleteEmployeePersonalDocument(clientDocumentId).subscribe(
      res => {
        if (res.isSuccsess) {
          const snack = new SnackBar(SnackBarType.success, 'Employee document successfully deleted', '', 2000);
          this.commonUIService.openSnackBar(snack);
          this.loadEmployeeDocumentList(this.modalViewData.data.id);
        }
      }
    )
  }

  subscribeAlertConfirmEvents() {
    this.subscriber = this.alertService.referenceDelete.subscribe(data => {
      if (data === 'Delete Document') {
        this.deleteEmployeePersonalDocument(this.idCurrentClientDocument);
      }
    });
  }


  async loadEmployeeDocumentList(employeeId) {
    this.isLoading_education = true;
    this.hrmService.getDocumentListByEmployeeIdAndDocumentTypeId(employeeId, 8).subscribe(
      res => {
        if (res) {
          let _personalDocumentList = res.data;
          if (_personalDocumentList != null) {
            _personalDocumentList.forEach(element => {
              element.employeeDocumentModelList.forEach(personalDocument => {
                if (personalDocument.fileName) {
                  personalDocument.extension = personalDocument.fileName.split('.')[(personalDocument.fileName.split('.')).length - 1];
                }
                personalDocument.link = API.baseUrl + API.employeeDocument.getEmployeeDocument + personalDocument.id + '/' + personalDocument.generatedFileName;
                if (personalDocument.url) {
                  let c = personalDocument.url.toString();
                  if (personalDocument.extension === 'pdf')
                    c = c.includes('data:application/pdf;base64,') ? c : 'data:application/pdf;base64,' + c;
                  if (personalDocument.extension === 'png')
                    c = c.includes('data:image/png;base64,') ? c : 'data:image/png;base64,' + c;
                  if (personalDocument.extension === 'jpg')
                    c = c.includes('data:image/jpg;base64,') ? c : 'data:image/jpg;base64,' + c;
                  if (personalDocument.extension === 'jpeg')
                    c = c.includes('data:image/jpeg;base64,') ? c : 'data:image/jpeg;base64,' + c;
                  if (personalDocument.extension === 'docx')
                    c = c.includes('https://docs.google.com/gview?url=') ? c : 'https://docs.google.com/gview?url=' + personalDocument.link + '&embedded=true';
                  if (personalDocument.extension === 'xlsx' || personalDocument.extension === 'xls')
                    c = c.includes('https://docs.google.com/gview?url=') ? c : 'https://docs.google.com/gview?url=' + personalDocument.link + '&embedded=true';
                }
              });
            });
            this.isLoading = false;
          }

          this.employeeDocumentGroupList = [];
          this.resumeDocumentList = [];
          this.nicList = [];
          this.birthsertificateDocumentList = [];
          this.EduDocList = [];
          this.priorDocList = [];
          this.employeeDocumentGroupList = _personalDocumentList;
          this.nicList.push(this.employeeDocumentGroupList[0]);
          this.birthsertificateDocumentList.push(this.employeeDocumentGroupList[1]);
          this.resumeDocumentList.push(this.employeeDocumentGroupList[2]);
          this.priorDocList.push(this.employeeDocumentGroupList[3]);
          this.EduDocList.push(this.employeeDocumentGroupList[4]);
          this.isLoading_NicAgreement = false;
          this.isLoading_BirthCertDocument = false;
          this.isLoading_ResumeDocument = false;
          this.isLoading_education = false;
          this.isLoading_Prior = false;

          this.isLoadingNicDocument = this.nicList[0].employeeDocumentModelList.length > 0 ? true : false;
          this.isLoadingBirthCertDocument = this.birthsertificateDocumentList[0].employeeDocumentModelList.length > 0 ? true : false;
          this.isLoadingResumeDocument = this.resumeDocumentList[0].employeeDocumentModelList.length > 0 ? true : false;
          this.isLoadingeducation = this.EduDocList[0].employeeDocumentModelList.length > 0 ? true : false;
          this.isLoadingPrior = this.priorDocList[0].employeeDocumentModelList.length > 0 ? true : false;
        }
      },
      err => {
        console.error(); this.isLoading = true;
      });
  }

  subscribeCancelDocument() {
    this.hrmService.cancelDocument.subscribe(data => {
      if (data == 6) {
        this.isLoadingNicDocument = false;
        this.isLoading_NicAgreement = false;
      } else if (data == 7) {
        this.isLoadingBirthCertDocument = false;
        this.isLoading_BirthCertDocument = false;
      } else if (data == 8) {
        this.isLoadingResumeDocument = false;
        this.isLoading_ResumeDocument = false;
      } else if (data == 9) {
        this.isLoadingPrior = false;
        this.isLoading_Prior = false;
      } else if (data == 10) {
        this.isLoadingeducation = false;
        this.isLoading_education = false;
      }
    });
  }

  openAddEmployeeDocumentPopup(documentGroupId) {

    if (documentGroupId == 6) {
      this.isLoadingNicDocument = true;
      this.isLoading_NicAgreement = true;
    } else if (documentGroupId == 7) {
      this.isLoadingBirthCertDocument = true;
      this.isLoading_BirthCertDocument = true;
    } else if (documentGroupId == 8) {
      this.isLoadingResumeDocument = true;
      this.isLoading_ResumeDocument = true;
    } else if (documentGroupId == 9) {
      this.isLoadingPrior = true;
      this.isLoading_Prior = true;
    } else if (documentGroupId == 10) {
      this.isLoadingeducation = true;
      this.isLoading_education = true;
    }
    const dialogViewData = this.commonUIService.setDataForModalDialog(this.modalViewData.data.id, null, documentGroupId, null, null, null);
    this.commonUIService.openDialog(AddPersonalDocumentComponent, dialogViewData);
  }

  async submit(personalEmpDocumentModel: EmployeeDocument) {
    this.personalDocumentFormGroup.get('file').markAsTouched();
    if (this.personalDocumentFormGroup.invalid) {
      return;
    }
    this._personalEmpDocumentForSave = Object.assign({}, personalEmpDocumentModel);
    this.onSaveClientDocument();
  }

  async onSaveClientDocument() {
    if (this._personalEmpDocumentForSave) {
      if (this.modalViewData.data.id) {
        this._personalEmpDocumentForSave.employeeId = this.modalViewData.data.id;
        if (this._personalEmpDocumentForSave.fileList.length > 0) {
          this._personalEmpDocumentForSave.fileName = (this._personalEmpDocumentForSave.fileList[0]).name;
          this._personalEmpDocumentForSave.extension = ((this._personalEmpDocumentForSave.fileList[0]).name).split('.')[(((this._personalEmpDocumentForSave.fileList[0]).name).split('.')).length - 1];
          this.hrmService.addEmployeeDocument(this._personalEmpDocumentForSave).subscribe(res => {
            if (res) {
              const formData: FormData = new FormData();
              formData.append('file', this._personalEmpDocumentModal.fileList[0], res.data.generatedFileName);
              formData.append('employeeId', res.data.employeeId.toString());
              formData.append('fileId', res.data.id.toString());
              formData.append('letterTypeId', "0");
              this.uploadEmployeeDocument(formData);
            }
          });
          this.closeDialog();
        }
      }
    }
  }

  uploadEmployeeDocument(formData: FormData) {
    this.hrmService.uploadEmployeeDocument(formData).subscribe(
      res => {
        if (res == 200) {
          const snack = new SnackBar(SnackBarType.success, 'Successfully saved', '', 4000);
          this.commonUIService.openSnackBar(snack);
          this.closeDialog();
        } else {
          const snack = new SnackBar(SnackBarType.success, 'Error on save', '', 4000);
          this.commonUIService.openSnackBar(snack);
        }
      },
      () => { console.error(); this.closeDialog(); });
  }





  closeDialog(): void {
    this.dialogRef.close();
  }


}
