import { Injectable, Output, EventEmitter } from '@angular/core';
import { APIService } from 'src/app/shared/services/api.service';
import { BehaviorSubject } from 'rxjs';
import { ClientDocument } from 'src/app/client/entities/clientDocument';
import { InvoiceModel } from 'src/app/inventory/entities/inventoryInvoice';
import { InventoryModel } from 'src/app/inventory/entities/inventoryModel';
import { RefereshModal } from 'src/app/client/entities/referesh-modal';
import { SimmModels } from '../entities/simm';
import { ProvidersModel } from '../entities/providers';
import { SnackBar, SnackBarType } from 'src/app/shared/components/snackbar/entities/snackbar';
import { UIService } from 'src/app/shared/services/ui.service';
import { InventoryRepair } from '../entities/inventoryRepair';
import { InventoryIncidenceModel } from '../entities/inventoryIncidence';
import { InventoryUserModel } from '../entities/inventoryUser';
import { Furniture } from '../entities/furniture';
import { FloorFurniture } from '../entities/floorFurniture';
import { FurnitureModel } from '../entities/furnitureModel';
import { InventoryUserComponent } from '../components/inventory-detail/inventory-user/inventory-user.component';
import { InventoryFilterModel } from '../entities/inventoryFIlterModel';
const behaviorSubjectForRefreshEvents = new BehaviorSubject<RefereshModal>(new RefereshModal());

@Injectable({
  providedIn: 'root'
})
export class InventoryService {
  @Output() refershListInventor: EventEmitter<{ id: any, index: number }> = new EventEmitter<{ id: any, index: number }>();
  @Output() refreshInventor: EventEmitter<{ list: any, tabName: any }> = new EventEmitter<{ list: any, tabName: any }>();
  @Output() refreshInventorInvoice: EventEmitter<any> = new EventEmitter<any>();
  @Output() refreshInvetoryType: EventEmitter<any> = new EventEmitter<any>();
  @Output() newDocument: EventEmitter<any> = new EventEmitter<any>();
  @Output() refreshSimm: EventEmitter<any> = new EventEmitter<any>();
  @Output() refreshSimmUpdate: EventEmitter<{ status: any, simmId: number }> = new EventEmitter<{ status: any, simmId: number }>();
  @Output() refreshProviders: EventEmitter<any> = new EventEmitter<any>();
  @Output() refreshProvidersUpdate: EventEmitter<{ status: any, providerId: number }> = new EventEmitter<{ status: any, providerId: number }>();
  @Output() addInventoryEmitter: EventEmitter<any> = new EventEmitter<{ isEdit: boolean, selectedTabIndex: number }>();
  @Output() refreshInventoryRepair: EventEmitter<any> = new EventEmitter<any>();
  @Output() refreshInventoryIncidence: EventEmitter<any> = new EventEmitter<any>();
  @Output() refreshInventoryUser: EventEmitter<any> = new EventEmitter<any>();
  @Output() loadInventoryDetails: EventEmitter<any> = new EventEmitter<any>();
  @Output() refreshLaptopList: EventEmitter<any> = new EventEmitter<any>();
  @Output() clearLaptopList: EventEmitter<any> = new EventEmitter<any>();
  @Output() refreshLoadKPIStatistics: EventEmitter<any> = new EventEmitter<any>();
  @Output() loadFurnitureDetails: EventEmitter<any> = new EventEmitter<any>();
  @Output() refreshFurniture: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancelInventoryInvoice: EventEmitter<any> = new EventEmitter<any>();
  @Output() refreshLoadSimmList: EventEmitter<any> = new EventEmitter<any>();

  public slectedInventoryIndex: number = 0;
  public isInventorySelected: boolean = false;
  public showEditSave: boolean;
  isMoreFilterActive: boolean;

  public inventoryFilterModel: InventoryFilterModel;
  public lowValue: number = 0;
  public highValue: number = 15;
  public pageIndex: number = 0;
  public totalCount: number = 0;

  private _refreshEventsSource = behaviorSubjectForRefreshEvents;
  public refreshEvents = this._refreshEventsSource.asObservable();
  _inventoryUser: InventoryUserModel;

  constructor(private apiservice: APIService,
    public commonUIService: UIService) {
    this._inventoryUser = new InventoryUserModel();
  }


  getInventoryList(type) {
    let url = this.apiservice.getEndPoint("Inventory.GetByType", [["{type}", type]]);
    return this.apiservice.get(url);
  }

  InventoryGetById(Id) {
    let url = this.apiservice.getEndPoint("Inventory.GetById", [["{Id}", Id]]);
    return this.apiservice.get(url);
  }

  listAllInventoryInvoice() {
    let url = this.apiservice.getEndPoint("InventoryInvoice.ListAll", null);
    return this.apiservice.get(url);
  }

  deleteInventoryInvoice(id:number)
  {   
    let url = this.apiservice.getEndPoint("InventoryInvoice.DeleteInventoryInvoice",[["{Id}", id]]);
    return this.apiservice.delete(url,id.toString());
  }

  getInventoryInvoiceDetailsById(id:number)
  {
    let url = this.apiservice.getEndPoint("InventoryInvoice.GetInventoryInvoiceDetailsById", null);
    url = `${url}/${id}`
    return this.apiservice.get(url);
  }

 
  ListExistingInvoiceForAttachInvoice(inventoryId: string) {
    let url = this.apiservice.getEndPoint("InventoryInvoice.ExistingInvoiceListForAttachInvoice", [["{inventoryId}", inventoryId]]);
    return this.apiservice.get(url);
  }

  saveInventoryInvoice(inventoryInvoiceObj: InvoiceModel) {
    let url = this.apiservice.getEndPoint("InventoryInvoice.AddInventoryInvoice", null);
    return this.apiservice.post(url, inventoryInvoiceObj, true);
  }

  updateInventoryInvoice(inventoryInvoiceObj: InvoiceModel) {
    let url = this.apiservice.getEndPoint("InventoryInvoice.UpdateInventoryInvoice", null);
    return this.apiservice.patch(url, inventoryInvoiceObj, true);
  }

  InventoryInvoiceGetById(Id) {
    let url = this.apiservice.getEndPoint("InventoryInvoice.GetByInventoryId", [["{id}", Id]]);
    return this.apiservice.get(url);
  }

  copyExistingInventoryInvoice(inventoryInvoiceObj: InvoiceModel) {
    let url = this.apiservice.getEndPoint("InventoryInvoice.AddExistingInventoryInvoice", null);
    return this.apiservice.post(url, inventoryInvoiceObj, true);
  }

  saveInventory(InventoryObj) {
    InventoryObj.screenSize = parseInt(InventoryObj.screenSize);
    InventoryObj.cost = parseInt(InventoryObj.cost);
    let url = this.apiservice.getEndPoint("Inventory.AddInventory", null);
    return this.apiservice.post(url, InventoryObj, true);
  }

  updateInventory(inventoryId, InventoryObj) {

    InventoryObj.screenSize = parseInt(InventoryObj.screenSize);
    let url = this.apiservice.getEndPoint("Inventory.UpdateInventory", [["{id}", inventoryId]]);
    return this.apiservice.put(url, InventoryObj, true);
  }

  getBySimmNumberUser(user, number) {
    let url = this.apiservice.getEndPoint("Simm.getBySimmNumberUser", [["{user}", user], ["{number}", number]]);
    return this.apiservice.get(url);
  }

  getInventoryLaptopListBySearch(type, name) {
    let url = this.apiservice.getEndPoint("Inventory.getInventoryLaptopListBySearch", [["{type}", type], ["{name}", name]]);
    return this.apiservice.get(url);
  }

  getBySimmId(id) {
    let url = this.apiservice.getEndPoint("Simm.getById", [["{id}", id]]);
    return this.apiservice.get(url);
  }

  updateSimm(simmId, SimmObj: SimmModels) {
    if (SimmObj.number)
      SimmObj.number = +SimmObj.number;
    let url = this.apiservice.getEndPoint("Simm.updateSimm", [["{id}", simmId]]);
    return this.apiservice.put(url, SimmObj, true);
  }

  getByProviderId(id) {
    let url = this.apiservice.getEndPoint("provider.getById", [["{id}", id]]);
    return this.apiservice.get(url);
  }

  updateProvider(simmId, ProviderObj: ProvidersModel) {
    let url = this.apiservice.getEndPoint("provider.updateProvider", [["{id}", simmId]]);
    return this.apiservice.put(url, ProviderObj, true);
  }

  saveSimm(obj: any) {
    if (obj.number)
      obj.number = +obj.number;
    let url = this.apiservice.getEndPoint("Simm.addSimm", null);
    return this.apiservice.post(url, obj, true);
  }

  saveProvider(obj: any) {
    let url = this.apiservice.getEndPoint("provider.provider", null);
    return this.apiservice.post(url, obj, true);
  }

  getCountryById(id) {
    let url = this.apiservice.getEndPoint("country.getById", [["{id}", id]]);
    return this.apiservice.get(url);
  }

  submitSaveInventory(model: InventoryModel, index) {
    this.slectedInventoryIndex = index;
    let id = null;
    this.saveInventory(model).subscribe(res => {
      if (res.isSuccsess) {
        if (res.data) {
          id = res.data.id;
          this.showEditSave = false;
          if (res.data.name != null) {
            let modelObj = new InventoryUserModel();
            modelObj.fromDate = new Date().toISOString();
            modelObj.inventoryId = res.data.id;
            modelObj.user = res.data.name;
            this.saveInventoryUser(modelObj).subscribe(res => {
              if (res && res.isSuccsess) {
                this.refreshInventoryUser.emit(true);
              }
              else { }
            });;
          }
        }
        const snack = new SnackBar(SnackBarType.success, res.message, '', 4000);
        this.commonUIService.openSnackBar(snack);
        this.refershListInventor.emit({ id: id, index: this.slectedInventoryIndex });
        this.refreshInventor.emit({ list: res.data, tabName: "" });
        this.refreshLoadKPIStatistics.emit(this.slectedInventoryIndex);
      }
      else {
        const snack = new SnackBar(SnackBarType.error, res.message, '', 4000);
        this.commonUIService.openSnackBar(snack);
      }
    });
  }

  submitUpdateInventory(model: InventoryModel, index, saveInventoryUser) {
    this.slectedInventoryIndex = index;
    let id = null;
    if (model.inventoryUser) {
      let _inventoryUserList = model.inventoryUser.sort((a, b) => new Date(b.fromDate).getTime() - new Date(a.fromDate).getTime());
      this._inventoryUser = _inventoryUserList[0];
    }
    this.updateInventory(model.id, model).subscribe(res => {
      if (res.isSuccsess) {
        if (res.data) {
          id = res.data.id;
          this.showEditSave = false;
        }
        if (saveInventoryUser == true) {
          if (res.data.name != null) {
            let modelObj = new InventoryUserModel();
            modelObj.fromDate = new Date().toISOString();
            modelObj.inventoryId = res.data.id;
            modelObj.user = res.data.name;
            this.saveInventoryUser(modelObj).subscribe(res => {
              if (res && res.isSuccsess) {
                this.refreshInventoryUser.emit(true);
              }
              else { }
            });
          }
        }
        if (res.data.state == 0) {
          if (this._inventoryUser) {
            this.updateInventoryUser(this._inventoryUser).subscribe(res => {
              if (res && res.isSuccsess) {
                this.refreshInventoryUser.emit(true);
              }
              else { }
            });;
          }
        }
        const snack = new SnackBar(SnackBarType.success, res.message, '', 4000);
        this.commonUIService.openSnackBar(snack);
        this.refershListInventor.emit({ id: id, index: this.slectedInventoryIndex });
        this.refreshInventor.emit({ list: res.data, tabName: "" });
        this.refreshLoadKPIStatistics.emit(this.slectedInventoryIndex);
      } else {
        const snack = new SnackBar(SnackBarType.error, res.message, '', 4000);
        this.commonUIService.openSnackBar(snack);
      }
    })
  }

  public getInventoryRepairListByInventoryId(inventoryId) {
    let url = this.apiservice.getEndPoint("inventoryRepairs.getListByInventoryId", [["{id}", inventoryId]]);
    return this.apiservice.get(url);
  }

  saveInventoryRepair(modelObj: InventoryRepair) {
    let url = this.apiservice.getEndPoint("inventoryRepairs.saveInventoryRepair", null);
    return this.apiservice.post(url, modelObj, true);
  }

  saveInventoryIncidence(modelObj: InventoryIncidenceModel) {
    let url = this.apiservice.getEndPoint("inventoryIncidence.saveInventoryIncidence", null);
    return this.apiservice.post(url, modelObj, true);
  }

  saveInventoryUser(modelObj: InventoryUserModel) {
    let url = this.apiservice.getEndPoint("InventoryUser.saveInventoryUser", null);
    return this.apiservice.post(url, modelObj, true);
  }

  filterLaptopDetails(state, brand, cpuType, ramType, crrentStorageType) {
    let url = this.apiservice.getEndPoint("Inventory.FilterLaptops", [["{State}", state], ["{Brand}", brand], ["{CPUType}", cpuType], ["{RAMType}", ramType], ["{CurrentStorageType}", crrentStorageType],]);
    return this.apiservice.get(url);
  }

  getKPIStatistics() {
    let url = this.apiservice.getEndPoint("Inventory.GetKPIStatistics", null);
    return this.apiservice.get(url);
  }

  listAllFurnitures() {
    let url = this.apiservice.getEndPoint("furniture.listAllFurnitures", null);
    return this.apiservice.get(url);
  }

  saveFurniture(furnitureObj) {
    let url = this.apiservice.getEndPoint("furniture.addFurniture", null);
    return this.apiservice.post(url, furnitureObj, true);
  }

  saveFloorFurniture(floorFurnitureObj) {
    let url = this.apiservice.getEndPoint("floorFurniture.addFloorFurniture", null);
    return this.apiservice.post(url, floorFurnitureObj, true);
  }

  updateFloorFurniture(floorFurnitureId, floorFurnitureObj) {
    let url = this.apiservice.getEndPoint("floorFurniture.updateFloorFurniture", [["{id}", floorFurnitureId]]);
    return this.apiservice.put(url, floorFurnitureObj, true);
  }

  updateInventoryUser(modelObj: InventoryUserModel) {
    let url = this.apiservice.getEndPoint("InventoryUser.updateInventoryUser", [["{id}", modelObj.id]]);
    return this.apiservice.put(url, modelObj, true);
  }
  
}
