import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { UIService } from 'src/app/shared/services/ui.service';
import { AttachInvoiceComponent } from './attach-invoice/attach-invoice.component';
import { InventoryService } from 'src/app/inventory/service/inventoryService';
import { AlertModel } from 'src/app/shared/entities/alertModel';
import { Subscription } from 'rxjs';
import { AlertService } from 'src/app/shared/services/alert.service';
import { SnackBar, SnackBarType } from 'src/app/shared/components/snackbar/entities/snackbar';
import { EditInvoiceComponent } from './edit-invoice/edit-invoice.component';
import { InvoiceModel } from 'src/app/inventory/entities/inventoryInvoice';


@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss']
})
export class InvoicesComponent implements OnInit {
  @ViewChild('matTabGroup', { static: false }) matTabGroup: any;
  @Input() selectInventory: any;
  document: any = [];
  inventroyId: number = null;
  isLoading: boolean = false;
  showAddDocumentOut: boolean = false;
  public PDF = '../../assets/images/config/pdf.png';
  public PNG = '../../assets/images/config/png.png';
  public JPG = '../../assets/images/config/jpg.png';
  public JPEG = '../../assets/images/config/jpg.png';
  public XLS = '../../assets/images/config/xls.png';
  public DOC = '../../assets/images/config/doc.png';
  delInvoiceId :number =0;
  private subscriber: Subscription;

  constructor(private commonUIService: UIService, public inventoryService: InventoryService, private alertService: AlertService) { }

  ngOnInit() {
    this.refreshAddInventory();
    this.subscribeCancelInventoryInvoice();
    this.subscribeAlertConfirmInvoiceDelete();
  }

  ngOnChanges() {
    this.loadInventoryInvoice(this.selectInventory);
  }

  openAttachInvoicePopup() {    
    const dialogViewData = this.commonUIService.setDataForModalDialog(this.selectInventory, null, null, null, null, null);
    this.commonUIService.openDialog(AttachInvoiceComponent, dialogViewData);
  }

  invoiceDocumentSelect(event) {
    if (event) {
      const matTabGroupLength = this.matTabGroup._tabs.length;
      const _lenthForGetLastIndex = (matTabGroupLength - 1);
      const _selectedIndex = event.index;
      const _selectedTab = event.tab._viewContainerRef.element.nativeElement;
      if (_selectedIndex === _lenthForGetLastIndex) {
        this.matTabGroup._tabHeader._inkBar.hide();
      }
      else {
        this.matTabGroup._tabHeader._inkBar.show();
      }
    }
  }

  refreshAddInventory() {
    this.inventoryService.refreshInventorInvoice.subscribe(res => {
      if (res == true) {
        this.isLoading = false;
        this.loadInventoryInvoice(this.selectInventory);
      }
    })
  }

  loadInventoryInvoice(id) {
    this.showAddDocumentOut = false;
    this.inventoryService.InventoryInvoiceGetById(id).subscribe(data => {
      this.isLoading = true;
      let _docList = data.data;
      if (_docList.length > 0) {
        this.showAddDocumentOut = true;
        _docList.forEach(element => {
          element.inventoryInvoice.forEach(document => {
            document.extension = document.fileName.split('.')[(document.fileName.split('.')).length - 1];
            if (document.url) {
              if (document.extension === 'pdf')
                document.url = document.url.toString().includes('data:application/pdf;base64,') ? document.url : 'data:application/pdf;base64,' + document.url;
              if (document.extension === 'png')
                document.url = document.url.toString().includes('data:image/png;base64,') ? document.url : 'data:image/png;base64,' + document.url;
              if (document.extension === 'jpg')
                document.url = document.url.toString().includes('data:image/jpg;base64,') ? document.url : 'data:image/jpg;base64,' + document.url;
              if (document.extension === 'jpeg')
                document.url = document.url.toString().includes('data:image/jpeg;base64,') ? document.url : 'data:image/jpeg;base64,' + document.url;
              if (document.extension === 'docx')
                document.url = document.url.toString().includes('https://docs.google.com/gview?url=') ? document.url : 'https://docs.google.com/gview?url=' + document.link + '&embedded=true';
              if (document.extension === 'xlsx' || document.extension === 'xls')
                document.url = document.url.toString().includes('https://docs.google.com/gview?url=') ? document.url : 'https://docs.google.com/gview?url=' + document.link + '&embedded=true';
            }
          });
        });
      }
      this.document = _docList;
      this.isLoading = false;
    });
  }

  subscribeCancelInventoryInvoice() {
    this.inventoryService.cancelInventoryInvoice.subscribe(data => {
      if (data == true)
        this.isLoading = false;
    });
  }

    //#region "Edit/Delete invoices"

    openDeleteInvoiceAlert(invoiceId) {
      this.delInvoiceId = invoiceId;
      const deleteData = new AlertModel();
      deleteData.title = 'Delete';
      deleteData.msg = 'Are you sure to delete this record?';
      deleteData.id = invoiceId;
      deleteData.type = 'deleteInvoice';
      deleteData.reference = 'Delete Invoice';
      let result = this.commonUIService.openAlert(deleteData);    
    } 
  


  subscribeAlertConfirmInvoiceDelete() {   
    this.subscriber = this.alertService.referenceDelete.subscribe(data => {     
      console.log(`this.alertService.referenceDelete data:${data}`);
      if (data && data.reference == "Delete Invoice") {
        this.deleteInvoice(this.delInvoiceId);
      }
    });
  }

  deleteInvoice(id){
    this.inventoryService.deleteInventoryInvoice(id).subscribe(res => {
      if (res.isSuccsess) 
      {
        var snack = new SnackBar(SnackBarType.success, res.message, "", 2000);
        this.commonUIService.openSnackBar(snack);
        this.loadInventoryInvoice(this.selectInventory);
      }
      else
      {
        var snack = new SnackBar(SnackBarType.error, res.message, "", 2000);
        this.commonUIService.openSnackBar(snack);
      }
    },error=>
    {
      console.log(error);
      var snack = new SnackBar(SnackBarType.error, "An unexpected error occurred", "", 2000);
      this.commonUIService.openSnackBar(snack);
    });
  }  
  //#endregion

  //#region "Edit invoice"

  openEditInvoice(invoiceId:number) 
  {
    let invoiceModel = new InvoiceModel(); 
    invoiceModel.id = invoiceId;
    invoiceModel.inventoryId = this.selectInventory;
    const dialogViewData = this.commonUIService.setDataForModalDialog(invoiceId, null, null, invoiceModel, null, null);
    this.commonUIService.openDialog(EditInvoiceComponent, dialogViewData);
  }

  //#endregion
}
