import { ProductPack } from './productPack';
import { ClientProduct } from './clientProduct';

export class Product {
  id: any;
  productName: string;
  main: boolean;
  active: boolean;

  productPack: ProductPack[];
  clientProduct: ClientProduct[];

  constructor() {
    this.active = false;
  }
}
