import { Component, OnInit } from '@angular/core';
import { APIService } from '../../shared/services/api.service';
import { UIService } from '../../shared/services/ui.service';
import { MaintenanceService } from '../service/maintenance.service';
import { AlertService } from '../../shared/services/alert.service';
import { ClientStatusModel } from '../../shared/entities/clientStatusModel';
import API from '../../shared/services/api.routes.json';
import { Subscription } from 'rxjs';
import { AlertModel } from '../../shared/entities/alertModel';
import { SnackBar, SnackBarType } from '../../shared/components/snackbar/entities/snackbar';
import { AddEditClientStatusComponent } from './add-edit-client-status/add-edit-client-status.component';

@Component({
  selector: 'app-client-status',
  templateUrl: './client-status.component.html',
  styleUrls: ['./client-status.component.scss']
})

export class ClientStatusComponent implements OnInit {
  ClientStatusList: ClientStatusModel[] = [];
  _clientStatusList: ClientStatusModel[] = [];
  updateClientStatus:ClientStatusModel;
  private subscriber: Subscription;
  _idSelectedClientStatus: number;
  isLoading: boolean = false;

  constructor(private _apiService: APIService,
    private uIService: UIService,
    private maintenanceService: MaintenanceService,
    private alertService: AlertService) {
    this.ClientStatusList = [];
  }

  ngOnInit() {
    this.updateClientStatus=new ClientStatusModel();
    this.LoadClientStatusList();
    this.maintenanceService.subjectClientStatusRefersh.subscribe(isRefesh => {
      if (isRefesh) {
        this.LoadClientStatusList();
      }
    });
    this.subscribeAlertConfirmEvents();
  }

  subscribeAlertConfirmEvents() {
    this.subscriber = this.alertService.referenceDelete
      .subscribe(data => {
        if (data && data === "Delete Clientstatus") {
          this.deleteClientStatus(this._idSelectedClientStatus);
        }
      });
  }

  private LoadClientStatusList() {
    this.isLoading = true;
    this._apiService.get(API.clientStatus.listAll).subscribe(response => {
      if (response) {
        if (response.data) {
          this._clientStatusList = response.data;
          this.ClientStatusList = this._clientStatusList.sort((a, b) => a.clientState.localeCompare(b.clientState));
        }
      }
      this.isLoading = false;
    }, err => {
      console.error(err);
    }, () => {
    });
  }

  addClientStatus() {
    this.uIService.openDialog(AddEditClientStatusComponent, null)
  }

  editClientStatus(tech) {
    const technology = Object.assign({}, tech);
    const dialogViewData = this.uIService.setDataForModalDialog(null, null, null, technology, null, null);
    this.uIService.openDialog(AddEditClientStatusComponent, dialogViewData);
  }

  openAlertDeleteClientStatus(id) {
    let deleteData = new AlertModel();
    deleteData.title = "Delete";
    deleteData.msg = "Are you sure to delete this record?";
    deleteData.id = id;
    deleteData.reference = "Delete Clientstatus";
    this._idSelectedClientStatus = id;
    this.uIService.openAlert(deleteData);
  }
  activeDeactive(event,model)
  {
    model.active = event.checked;
    this.maintenanceService.updateClientStatus(model.id,model).subscribe(
      (res: { isSuccsess: any; }) => {
        if (res.isSuccsess) {
          var snack = new SnackBar(SnackBarType.success, "Successfully updated", "", 2000);
          this.uIService.openSnackBar(snack);
          this.maintenanceService.ClientStatusRefersh(true);

        }
        else {
          snack = new SnackBar(SnackBarType.success, "Error on update", "", 2000);
          this.uIService.openSnackBar(snack);
        }
      },
      () => { console.error(); });
  }
  deleteClientStatus(id) {
    this.maintenanceService.deleteClientStatus(id).subscribe(
      res => {
        if (res.isSuccsess) {
          this.LoadClientStatusList();
          let snack = new SnackBar(SnackBarType.success, res.message, "", 2000);
          this.uIService.openSnackBar(snack);
        }

        else {
          let snack = new SnackBar(SnackBarType.error, "Error", "", 2000);
          this.uIService.openSnackBar(snack);
        }
      }
    )
  }
}
