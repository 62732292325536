import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AlertService } from '../../services/alert.service';
import { Inject, Component, OnInit } from '@angular/core';
import { DialogDataForAlertView } from '../../services/ui.service';
import { Subject } from 'rxjs';
import { InventoryService } from '../../../inventory/service/inventoryService';

@Component({
  selector: 'app-alert-popup',
  templateUrl: './alert-popup.component.html',
  styleUrls: ['./alert-popup.component.scss']
})
export class AlertPopupComponent implements OnInit {
  _alertModel: any;
  protected _onDestroy = new Subject<void>();
  _alertType: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public modalViewData: DialogDataForAlertView,
    public dialogRef: MatDialogRef<AlertPopupComponent>,
    public alertService: AlertService) { }

  ngOnInit() {
    this._alertModel = this.modalViewData.data;
    this._alertType = this._alertModel ? this._alertModel.reference : null;
  }

  clickConfirmAlert(_alertModel) {   
    if (_alertModel) {
      if (_alertModel.selectedTabIndex != null) {
        this.closeDialog();
        this.alertService.referenceDelete.emit(_alertModel);
      }
      else if (_alertModel.alertType === "Confirm") {
        this.dialogRef.close();
        this._alertModel.isCancel = false;
        this.alertService.referenceConfirm.emit(_alertModel)
      }
      else if (_alertModel.reference === "ChangeStatusOfSimm" || _alertModel.reference === "DontChangeStatusOfSimm") {
        this.dialogRef.close();
        this._alertModel.isCancel = false;
        this.alertService.referenceConfirmForSimm.emit(_alertModel)
      }
      else if (_alertModel.reference === "Delete Invoice" || _alertModel.reference === "Delete Invoice") {
        this.dialogRef.close();
        this._alertModel.isCancel = false;
        this.alertService.referenceDelete.emit(_alertModel)
      }
      else {
        this.closeDialog();
        this.alertService.referenceDelete.emit(_alertModel.reference)
      }
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
    if (this._alertModel.alertType === "Confirm" && this._alertType === 'TaskDone') {
      this._alertModel.isCancel = true;
      this.alertService.referenceConfirm.emit(this._alertModel);
    } else if (this._alertModel.alertType === 'Simm') {
      this.alertService.referenceRefreshSimm.emit(this._alertModel.id);
    }
    else {
      this.alertService.referenceClose.emit(true);
    }
  }
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
