import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UIService, DialogDataForModalView } from 'src/app/shared/services/ui.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SnackBar, SnackBarType } from 'src/app/shared/components/snackbar/entities/snackbar';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { LinkedIn } from 'src/app/linkedin/entities/linkedin';
import { LinkedInService } from 'src/app/linkedin/service/linkedin.service';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/auth/login/login.service';
import { Users } from 'src/app/auth/entities/user';
import moment, { lang } from 'moment';
import { AuthAppService } from 'src/app/shared/services/auth-app.service';
import { FormatMatDatepicker } from 'src/app/shared/format-datepicker';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { SharedLinkedInService } from 'src/app/linkedin/service/shared.linkedin.service';
import { APIService } from '../../../shared/services/api.service';
import { CommonDropdownService } from '../../../shared/services/common.dropdown.service';
import { Template } from '../../entities/template';
const nameTextChanged = new Subject<string>();
const companyTextChanged = new Subject<string>();
const cityTextChanged = new Subject<string>();

@Component({
  selector: 'add-edit-templete',
  templateUrl: './add-edit-templete.component.html',
  styleUrls: ['./add-edit-templete.component.scss']
})

export class AddEditTempleteComponent implements OnInit {

  templeteFormGroup: FormGroup;
  _templateModal: Template;

  constructor(
    private sharedLinkedInService: SharedLinkedInService,
    private formatDatepicker: FormatMatDatepicker,
    private _formBuilder: FormBuilder,
    private linkedinService: LinkedInService,
    public commonUIService: UIService,
    private uIService: UIService,
    private route: Router,
    private loginServic: LoginService,
    private validationService: ValidationService,
    private _apiService: APIService,
    private commonDropdownService: CommonDropdownService,
    public dialogRef: MatDialogRef<AddEditTempleteComponent>,
    @Inject(MAT_DIALOG_DATA) public modelData: DialogDataForModalView,
    private authAppService: AuthAppService) {
    this._templateModal = new Template();
  }

  ngOnInit() {
    this.initFormGroup();
  }

  initFormGroup() {
    this.templeteFormGroup = this._formBuilder.group({
      name: ['', [Validators.required, this.validationService.noWhitespaceValidator]],
      espsubject: [''],
      esptext: [''],
      catsubject: [''],
      cattext: [''],
      engsubject: [''],
      engtext: [''],
      main: ['']
    })
  }

  submit(model: Template) {
    if (model) {
      let templateModel = Object.assign({}, model);
      templateModel.categoryId = this.modelData.data.modalData.categoryId;
      templateModel.subcategoryId = this.modelData.data.modalData.id;
      if (this.templeteFormGroup.invalid) {
        this.templeteFormGroup.enable();
        return
      } else {
        this.addTemplete(templateModel)
      }
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  addTemplete(templateObj) {
    this.linkedinService.saveTempleteData(templateObj).subscribe(
      (res) => {
        if (res.isSuccsess) {
          var snack = new SnackBar(SnackBarType.success, "Successfully saved", "", 2000);
          this.commonUIService.openSnackBar(snack);
          this.linkedinService.refreshCategoryList.emit(true);
          this.closeDialog();
        }
        else {
          snack = new SnackBar(SnackBarType.success, "Error on save", "", 2000);
          this.commonUIService.openSnackBar(snack);
        }
      },
      () => { console.error(); });
  }

  templeteMainCheckEvent(event) {
    this._templateModal.main = event.checked;
  }
}
