import { Component, OnInit } from '@angular/core';
import { APIService } from 'src/app/shared/services/api.service';
import { UIService } from 'src/app/shared/services/ui.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { MaintenanceService } from '../service/maintenance.service';
import API from '../../shared/services/api.routes.json';
import { departmentModel } from 'src/app/shared/entities/departmentModel';
import { SnackBar, SnackBarType } from 'src/app/shared/components/snackbar/entities/snackbar';
import { AddEditDepartmentComponent } from './add-edit-department/add-edit-department.component';
import { AddEditpositionComponent } from '../position/add-editposition/add-editposition.component';
import { MatDialogRef } from '@angular/material';
import { AlertModel } from 'src/app/shared/entities/alertModel';

@Component({
  selector: 'app-department',
  templateUrl: './department.component.html',
  styleUrls: ['./department.component.scss']
})
export class DepartmentComponent implements OnInit {

  isLoading: boolean = false;
  _departmentList: departmentModel[] = [];
  departmentList: departmentModel[] = [];
  _idSelectedDepartment: number;
  subscriber: any;

  constructor(
    private _apiService: APIService, 
    private uIService: UIService,
    private alertService: AlertService, 
    private maintananceService: MaintenanceService
     ) { }

  ngOnInit() {
    this.loadDepartmentList();
    this.SubscribeDepartmentList();
    this.maintananceService.subjectDepartmentTypeRefresh.subscribe(istrue => {
      if (istrue) {
        this.loadDepartmentList();
      }
    })
  }

  editDepartment(data) {
    const setdataDept = Object.assign({}, data);
    const dialogViewData = this.uIService.setDataForModalDialog(null, null, null, setdataDept, null, null);
    this.uIService.openDialog(AddEditDepartmentComponent, dialogViewData);
  }
  
  loadDepartmentList() {
    this.isLoading = true;
    this._apiService.get(API.department.getAllDepartment).subscribe(response => {
      if (response) {
        if (response.data) {
          this.departmentList = response.data;
         // this.departmentList = this.departmentList.sort((a, b) => a.name.localeCompare(b.name));
        }
      }
      this.isLoading = false;
    });
  }

  
  addDepartment() {
    this.uIService.openDialog(AddEditDepartmentComponent, null);
  }

  activeDeactive(event, model) {
    model.status = event.checked;
    this.maintananceService.updateDepartment(model.id, model).subscribe(
      (res: { isSuccsess: any; }) => {
        if (res.isSuccsess) {
          var snack = new SnackBar(SnackBarType.success, "Successfully updated", "", 2000);
          this.uIService.openSnackBar(snack);
          this.maintananceService.DepartmentTypeRefresh(true);
        }
        else {
          snack = new SnackBar(SnackBarType.success, "Error on update", "", 2000);
          this.uIService.openSnackBar(snack);
        }
      },
      () => { console.error(); });
  }
  
  openDeleteAlert(id) {
    let deleteData = new AlertModel();
    deleteData.title = "Delete";
    deleteData.msg = "Are you sure to delete this record?";
    deleteData.id = id;
    deleteData.reference = "Delete Department";
    this._idSelectedDepartment = id;
    this.uIService.openAlert(deleteData);
  }

  SubscribeDepartmentList() {
    this.subscriber = this.alertService.referenceDelete.subscribe(data => {
      if (data == "Delete Department") {
        this.deleteDepartment(this._idSelectedDepartment)
      }
    });
  }
  
  deleteDepartment(id) {
    this.maintananceService.deleteDepartment(id).subscribe(res => {
      if (res.isSuccsess) {
        var snack = new SnackBar(SnackBarType.success, res.message, "", 2000);
        this.uIService.openSnackBar(snack);
        if (res.data) this.loadDepartmentList();
      }
      else {
        var snack = new SnackBar(SnackBarType.error, "Error", "", 2000);
        this.uIService.openSnackBar(snack);
      }
    });
  }
}