import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DialogDataForModalView, UIService } from 'src/app/shared/services/ui.service';
import { CommonDropdownService } from 'src/app/shared/services/common.dropdown.service';
import { isNumber } from 'util';
import { SnackBarType, SnackBar } from 'src/app/shared/components/snackbar/entities/snackbar';
import { FormatMatDatepicker } from 'src/app/shared/format-datepicker';
import { APIService } from 'src/app/shared/services/api.service';
import API from 'src/app/shared/services/api.routes.json';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { Employee } from '../../../../../entities/empoyee';
import { EmpLetters } from '../../../../../entities/empoyeeLetters';
import { HrmService } from '../../../../../service/hrm.service';
declare const JSON;

@Component({
  selector: 'add-edit-employee-letter',
  templateUrl: './add-edit-employee-letter.component.html',
  styleUrls: ['./add-edit-employee-letter.component.scss']
})
export class AddEditEmployeeLetterComponent implements OnInit, OnDestroy {

  employeeLetterFormGroup: FormGroup;
  _employeeLetterModal: EmpLetters;
  _employeeLetterForSave: EmpLetters;
  selectedEmployee: Employee;
  letterTypeList: any = [];
  isChecked = true;
  toggle = true;
  isLetterRequired = false;
  isLoading: boolean = false;
  private _unsubscribeAll: Subject<any>;

  constructor(private _apiService: APIService,
    private _formBuilder: FormBuilder,
    private hrmService: HrmService,
    private commonUIService: UIService,
    private validationService: ValidationService,
    public dialogRef: MatDialogRef<AddEditEmployeeLetterComponent>,
    @Inject(MAT_DIALOG_DATA) public modalViewData: DialogDataForModalView,
    private formatDatepicker: FormatMatDatepicker,
    public commonDropdownService: CommonDropdownService) {
    this._unsubscribeAll = new Subject();
    this._employeeLetterModal = new EmpLetters();
    this._employeeLetterForSave = new EmpLetters();
  }

  ngOnInit() {
    this.initEmployeeLetterFormGroup();
    this.selectedEmployee;
    this.commonDropdownService.LoadLetterTypeList();
  }

  initEmployeeLetterFormGroup() {
    this.employeeLetterFormGroup = this._formBuilder.group({
      date: ['', [Validators.required]],
      file: [null, [Validators.required]]
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  onChooseEmployeeLetter(event: any): void {
    if (event) {
      let files: any = event.target.files;
      this._employeeLetterModal.fileList = files;
      this.toggle = true;
      this.isLetterRequired = false;
    }
  }

  async submit(employeeLetterModal: EmpLetters) {
    let valid = this.employeeLetterFormGroup.get('file').touched && this.employeeLetterFormGroup.get('file').valid;
    this.isLetterRequired = !valid;
    this.toggle = valid;  
    if (this.employeeLetterFormGroup.invalid) {
      return;
    }
    this._employeeLetterForSave = Object.assign({}, employeeLetterModal);
    this._employeeLetterForSave.dateStr = employeeLetterModal.date ? this.formatDatepicker.ConvertDateFormatForSave(employeeLetterModal.date) : employeeLetterModal.date;
    this.onSaveEmployeeLetter();
  }

  async onSaveEmployeeLetter() {
    if (this._employeeLetterForSave) {
      if (this.modalViewData.data.id) {
        this._employeeLetterForSave.employeeId = this.modalViewData.data.id;
        this._employeeLetterForSave.letterTypeId = this.modalViewData.data.type;
        if (this._employeeLetterForSave.fileList.length > 0) {
          this._employeeLetterForSave.fileName = (this._employeeLetterForSave.fileList[0]).name;
          this._employeeLetterForSave.extension = ((this._employeeLetterForSave.fileList[0]).name).split('.')[(((this._employeeLetterForSave.fileList[0]).name).split('.')).length - 1];
         this.isLoading = true;
          this.hrmService.saveEmployeeLetter(this._employeeLetterForSave).subscribe(res => {
            this.hrmService.newLetter.emit(res);
            if (res != null && res.data) {
              
              const formData: FormData = new FormData();
              formData.append('file', this._employeeLetterModal.fileList[0], res.data.generatedFileName);
              formData.append('employeeId', res.data.employeeId.toString());
              formData.append('fileId', res.data.id.toString());
              formData.append('letterTypeId', res.data.letterTypeId.toString());
              this.uploadEmployeeLetter(formData);
            }
            
          });
          
        }
      }
    }
  }

  uploadEmployeeLetter(formData: FormData) {
    this.hrmService.uploadEmployeeLetter(formData).subscribe(
      res => {
        if (+res == 200 || +(res.statusCode) == 200) {
          this.isLoading = false;
          const snack = new SnackBar(SnackBarType.success, 'Successfully saved', '', 4000);
          this.commonUIService.openSnackBar(snack);
          this.closeDialog();
          this.hrmService.refreshEmployeeLetterList.emit({ employeeId: this.modalViewData.data.id, letterTypeId: this.modalViewData.data.type });
        } else {
          this.isLoading = false;
          const snack = new SnackBar(SnackBarType.success, 'Error on save', '', 4000);
          this.commonUIService.openSnackBar(snack);
        }
      },
      () => { console.error(); this.closeDialog(); });
  }

  closeDialogBox() {
    this.hrmService.cancelLetter.emit(this.modalViewData.data.type);
  }
}
