import { Component, OnInit, Input, Inject } from '@angular/core';
import { CommonDropdownService } from 'src/app/shared/services/common.dropdown.service';
import { UIService, DialogDataForModalView } from 'src/app/shared/services/ui.service';
import { APIService } from 'src/app/shared/services/api.service';
import { HrmService } from '../../../service/hrm.service';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ValidationService } from '../../../../shared/services/validation.service';
import { FormatMatDatepicker, FormatTimeForDisplay } from '../../../../shared/format-datepicker';
import { Employee } from '../../../entities/empoyee';
import moment from 'moment';
import { AddEditEmployeeLeaveComponent } from './hrm-calender/add-edit-employee-leave/add-edit-employee-leave.component';
import { AddHrmInsuranceComponent } from './hrm-insuarence/add-hrm-insurance/add-hrm-insurance.component';
import { EmpInsurance } from '../../../entities/EmpInsurance';
import API from 'src/app/shared/services/api.routes.json';
import { AlertModel } from '../../../../shared/entities/alertModel';
import { Subscription } from 'rxjs';
import { AlertService } from '../../../../shared/services/alert.service';
import { EmployeeLeave } from '../../../entities/empoyeeLeave';
import { SnackBar, SnackBarType } from '../../../../shared/components/snackbar/entities/snackbar';
import { HrmMetaDataEnum } from 'src/app/shared/enum/hrmMetadataType';
import { HrmMetaData } from 'src/app/shared/entities/hrmMetadataModel';

@Component({
  selector: 'hrm-center-view',
  templateUrl: './hrm-center-view.component.html',
  styleUrls: ['./hrm-center-view.component.scss']
})
export class HRMCenterViewComponent implements OnInit {

  public PDF = '../../assets/images/config/pdf.png';
  public PNG = '../../assets/images/config/png.png';
  public JPG = '../../assets/images/config/jpg.png';
  public JPEG = '../../assets/images/config/jpg.png';
  public XLS = '../../assets/images/config/xls.png';
  public DOC = '../../assets/images/config/doc.png';

  @Input() employeeModel: Employee;
  employeeObject: Employee;
  leaveList: any[] = [];
  _empInsuranceList: EmpInsurance[] = [];
  employeeLeaveTypeList: any = [];
  subEmployeeLeaveTypeList: any = [];
  isLoading = false;
  hours_s: any = null;
  minutes_s: any = null;
  seconds_s: any = null;
  a: any = null;
  title: string = null;
  viewLeave: boolean = false;
  viewCalender: boolean = false;
  viewAttendanceCalendar: boolean = false;
  isShowInsuarence: boolean = false;
  isShowLeave: boolean = false;
  totalClaim: any;
  total: any;
  entitleAmount: any;
  getClaim: boolean = false;
  firstDay: any;
  lastDay: any;
  todayDate = new Date();
  subscriber: Subscription;
  employeeLeaveId: number = null;
  isClickLeaveButton: boolean = true;
  isClickCalenderButton: boolean = false;
  isClickAttendanceButton: boolean = false;
  uitlizedHospitalizationAmount: number = null;
  uitlizedOPDAmount: number = null;
  payableInsuarenceAmount: number = null;
  empInsuranceForSave: EmpInsurance;
  isLoadingInsuranceList = false;
  currentMonth: number = null;
  currentDate = new Date();
  employeeInsuranceList: EmpInsurance[] = [];
  fileList: any;
  showUpload: boolean = false;
  today: any;
  insuranceTypeList: HrmMetaData[];
  subInsuranceTypeList: any = [];
  leaveTypeList: HrmMetaData[];

  constructor(public commonDropdownService: CommonDropdownService,
    public commonUIService: UIService,
    public _apiService: APIService,
    public hrmService: HrmService,
    public dialog: MatDialog,
    public fb: FormBuilder,
    public formatDatepicker: FormatMatDatepicker,
    private formatTimeForDisplay: FormatTimeForDisplay,
    public alertService: AlertService) { this.empInsuranceForSave = new EmpInsurance(); }

  ngOnInit() {
  }

  ngOnChanges() {
    if (this.hrmService.isShowLeaveCard == true && this.hrmService.isShowInsuarenceCard == false) {
      this.isShowLeave = true;
      this.isShowInsuarence = false;
    }
    if (this.hrmService.isShowLeaveCard == false && this.hrmService.isShowInsuarenceCard == true) {
      this.isShowInsuarence = true;
      this.isShowLeave = false;
    }
    if (this.employeeModel.completedOneYear == true) {
      this.hrmService.isCompletedOneYear = true;
    } else {
      this.hrmService.isCompletedOneYear = false;
    }
    this.employeeObject = this.employeeModel;
    this.employeeModel = this.employeeModel;
    this.getInsuranceTypeDetails()
    this.loadEmployeeLeaveByEmployeeId();
    this.loadEmpInsuranceList();
    this.getEmployeeLeaveTypeListByEmployeeId();
    this.subscribeDeleteLeave();
    this.subscribeEmployeeInsuranceDetails();
    this.viewLeave = true;
    this.viewCalender = false;
    this.viewAttendanceCalendar = false;
    this.isClickLeaveButton = true;
    this.isClickCalenderButton = false;
    this.isClickAttendanceButton = false;
    this.isLoading = true;
    this.title = "Leave list";
    this.uitlizedHospitalizationAmount = null;
    this.uitlizedOPDAmount = null;
    const startOfMonth = moment().startOf('month').format('dd MMMM yyyy');
    const endOfMonth = moment().endOf('month').format('dd MMMM yyyy');
    this.firstDay = new Date(this.todayDate.getFullYear(), this.todayDate.getMonth(), 1);
    this.firstDay = this.firstDay.toISOString('MMM d, y');
    this.lastDay = new Date(this.todayDate.getFullYear(), this.todayDate.getMonth() + 1, 0);
    this.currentMonth = this.todayDate.getMonth() + 1;
    this.subscribeCancelInsurance();
    this.subscribeRefreshLeaves();
    this.today = new Date().toISOString();
  }

  getInsuranceTypeDetails() {
    let url = this._apiService.getEndPoint("hrmMetaData.list", [["{isOnlyActive}", true]]);

    this._apiService.get(url).subscribe(response => {
      if (response) {
        if (response.data) {
          this.insuranceTypeList = response.data.insurance;
          let payable = new HrmMetaData();
          payable.description = 'Payable';
          this.insuranceTypeList.push(payable);

          this.subInsuranceTypeList = [];
          let tempList = [];

          if (this.insuranceTypeList.length > 3) {
            for (var i = 0; i < this.insuranceTypeList.length; i++) {
              tempList.push(this.insuranceTypeList[i]);
              if ((i+1)%3 === 0) {
                this.subInsuranceTypeList.push(tempList);
                tempList = [];
              }

              if (this.insuranceTypeList.length === (i + 1) && tempList.length > 0) {
                this.subInsuranceTypeList.push(tempList);
              }
            }
          } else {
            this.subInsuranceTypeList.push(this.insuranceTypeList);
          }


          this.leaveTypeList = response.data.leave;
        }
      }
    });
  }

  loadEmployeeLeaveByEmployeeId() {
    if (this.employeeModel.id > 0) {
      this.hrmService.getEmployeeLeaveByEmployeeId(this.employeeModel.id).subscribe(res => {
        if (res && res.data) {
          this.leaveList = res.data;
          this.formatLeaveTimeForDisplay(this.leaveList);
          this.firstDay = new Date(this.todayDate.getFullYear(), this.todayDate.getMonth(), 1);
          this.lastDay = new Date(this.todayDate.getFullYear(), this.todayDate.getMonth() + 1, 0);
          this.leaveList.forEach(element => {
            if (element.startDate && element.endDate) {
              const oneDay = 24 * 60 * 60 * 1000;
              var _startDate = new Date(element.startDate);
              var _endDate = new Date(element.endDate);
              const diffDays = Math.round(Math.abs((+_startDate - +_endDate) / oneDay));
              element.leaveCount = diffDays;
            }
          });
          this.leaveList;
          this.isLoading = false;
        } else {
          this.leaveList = [];
          this.isLoading = false;
        }
      });
    } else {
      this.leaveList = [];
      this.isLoading = false;
    }
  }

  private formatLeaveTimeForDisplay(list) {
    if (list.length > 0)
      list.forEach(ele => {
        ele.startTimeStr = ele.startTime ? this.formatTimeForDisplay.ConvertTimeFormatForDisplay(ele.startTime) : null;
        ele.endTimeStr = ele.endTime ? this.formatTimeForDisplay.ConvertTimeFormatForDisplay(ele.endTime) : null;
      });
  }

  getEmployeeLeaveTypeListByEmployeeId() {
    if (this.employeeModel.id > 0) {
      this.hrmService.getEmployeeLeaveTypeListByEmployeeId(this.employeeModel.id).subscribe(
        res => {
          if (res) {
            this.subEmployeeLeaveTypeList = [];
            this.employeeLeaveTypeList = res.data;
            let tempList = [];

            if (this.employeeLeaveTypeList.length > 3) {
              for (var i = 0; i < this.employeeLeaveTypeList.length; i++) {
                tempList.push(this.employeeLeaveTypeList[i]);
                if ((i+1)%3 === 0) {
                  this.subEmployeeLeaveTypeList.push(tempList);
                  tempList = [];
                }

                if (this.employeeLeaveTypeList.length === (i + 1) && tempList.length > 0) {
                  this.subEmployeeLeaveTypeList.push(tempList);
                }
              }
            } else {
              this.subEmployeeLeaveTypeList.push(this.employeeLeaveTypeList);
            }
          } else
            this.employeeLeaveTypeList = [];
        },
        err => { console.error(); });
    } else {
      this.employeeLeaveTypeList = [];
    }
  }

  viewLeaveList() {
    this.title = "Leave list";
    this.viewLeave = true;
    this.viewCalender = false;
    this.viewAttendanceCalendar = false;
    this.loadEmployeeLeaveByEmployeeId();
    this.getEmployeeLeaveTypeListByEmployeeId();
    this.isClickLeaveButton = true;
    this.isClickCalenderButton = false;
    this.isClickAttendanceButton = false;
    this.showUpload = false;
  }

  viewLeaveCalender() {
    this.title = "Leave calendar";
    this.viewCalender = true;
    this.viewLeave = false;
    this.viewAttendanceCalendar = false;
    this.isClickLeaveButton = false;
    this.isClickCalenderButton = true;
    this.isClickAttendanceButton = false;
    this.showUpload = false;
  }

  viewAttendanceCalender() {
    this.title = "Attendance calendar";
    this.viewAttendanceCalendar = true;
    this.viewCalender = false;
    this.viewLeave = false;
    this.isClickLeaveButton = false;
    this.isClickCalenderButton = false;
    this.isClickAttendanceButton = true;
    this.showUpload = true;
  }

  openEditLeavePopup(employeeLeave) {
    if (employeeLeave.id > 0) {
      let empLeaveObj = Object.assign({}, employeeLeave);
      empLeaveObj.type = this.employeeLeaveTypeList;
      const dialogViewData = this.commonUIService.setDataForModalDialog(employeeLeave.employeeId, null, this.employeeLeaveTypeList, empLeaveObj, null, null);
      this.commonUIService.openDialog(AddEditEmployeeLeaveComponent, dialogViewData);
    }
  }

  openAddInsurancePopup(employeeId) {
    this.isLoadingInsuranceList = true;
    let _modalData = new EmpInsurance();
    _modalData.totalClaimForHospitalization = this.uitlizedHospitalizationAmount;
    _modalData.totalClaimForOPD = this.uitlizedOPDAmount;
    _modalData.insuranceList = this.employeeInsuranceList;
    this.hrmService.isEmployeeInsuranceEdit = false;
    const dialogViewData = this.commonUIService.setDataForModalDialog(employeeId, null, null, _modalData, null, null);
    this.commonUIService.openDialog(AddHrmInsuranceComponent, dialogViewData);
  }

  getInsuranceTotalByType(typeId: number): any {
    let totalOfThisCategory = 0;
    this.employeeInsuranceList.forEach((item) => {
      if (item.insuranceTypeId === typeId) {
        totalOfThisCategory = totalOfThisCategory + item.insuranceAmount;
      }
    });
    return totalOfThisCategory;
  }

  loadEmpInsuranceList() {
    this.isLoadingInsuranceList = true;
    if (this.employeeModel.id > 0) {
      this.hrmService.getEmpInsuranceById(this.employeeModel.id).subscribe(
        res => {
          if (res) {

            this.uitlizedHospitalizationAmount = null;
            this.uitlizedOPDAmount = null;
            this.payableInsuarenceAmount = null;
            this._empInsuranceList = res.data;
            if (this._empInsuranceList.length > 0) {
              this._empInsuranceList.forEach(e => {
                if (e.insuranceTypeId == 1)
                  this.uitlizedHospitalizationAmount = e.uitlizedHospitalizationAmount ? e.uitlizedHospitalizationAmount : null;
                else if (e.insuranceTypeId == 2)
                  this.uitlizedOPDAmount = e.uitlizedOPDAmount ? e.uitlizedOPDAmount : null;

                this.payableInsuarenceAmount = e.payableInsuarenceAmount;
              });
            }
            this.isLoadingInsuranceList = false;
            let insuranceList = this._empInsuranceList;
            if (insuranceList.length > 0) {
              this.employeeObject.totalClaim = 0;
              insuranceList.forEach(element => {
                if (element.fileName != null) {
                  element.extension = element.fileName.split('.')[(element.fileName.split('.')).length - 1];
                  element.link = API.baseUrl + API.clientDocument.getClientDocument + element.id + '/' + element.generatedFileName;
                  if (element.url) {
                    let l = element.url.toString();
                    if (element.extension === 'pdf')
                      l = l.toString().includes('data:application/pdf;base64,') ? l : 'data:application/pdf;base64,' + l;
                    if (element.extension === 'png')
                      l = l.toString().includes('data:image/png;base64,') ? l : 'data:image/png;base64,' + l;
                    if (element.extension === 'jpg')
                      l = l.toString().includes('data:image/jpg;base64,') ? l : 'data:image/jpg;base64,' + l;
                    if (element.extension === 'jpeg')
                      l = l.toString().includes('data:image/jpeg;base64,') ? l : 'data:image/jpeg;base64,' + l;
                    if (element.extension === 'docx')
                      l = l.toString().includes('https://docs.google.com/gview?url=') ? l : 'https://docs.google.com/gview?url=' + element.link + '&embedded=true';
                    if (element.extension === 'xlsx' || element.extension === 'xls')
                      l = l.toString().includes('https://docs.google.com/gview?url=') ? l : 'https://docs.google.com/gview?url=' + element.link + '&embedded=true';
                  }
                }
                let amount = element.insuranceAmount;
                this.employeeObject.totalClaim = amount + this.employeeObject.totalClaim;
              });
              this.employeeObject.entitleAmount = 50000;
              if (this.employeeObject.totalClaim > 0) {
                this.employeeObject.total = this.employeeObject.entitleAmount - this.employeeObject.totalClaim;
              }
              this.employeeInsuranceList = insuranceList;
              this._empInsuranceList = this.employeeInsuranceList;
              this._empInsuranceList = this._empInsuranceList.length > 0 ? this._empInsuranceList.filter(x => new Date(x.date).getMonth() + 1 == this.currentMonth).sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()) : this._empInsuranceList;

            } else {
              this.employeeInsuranceList = [];
              this._empInsuranceList = [];
            }
            if (this.entitleAmount > this.totalClaim) {
              this.total = this.entitleAmount - this.totalClaim;
            } else {
              this.getClaim = true;
            }
          }
        },
        err => { console.error(); });
    } else {
      this.leaveList = [];
      this.isLoadingInsuranceList = false;
    }
  }

  openDeleteLeavePopup(leave: EmployeeLeave) {
    this.employeeLeaveId = leave.id;
    let deleteData = new AlertModel();
    deleteData.title = 'Delete';
    deleteData.msg = 'Are you sure to delete this leave?';
    deleteData.id = leave.id;
    deleteData.reference = 'Delete Leave';
    this.commonUIService.openAlert(deleteData);
  }

  subscribeDeleteLeave() {
    this.subscriber = this.alertService.referenceDelete.subscribe(data => {
      if (data === 'Delete Leave') {
        if (this.employeeLeaveId > 0 || this.employeeLeaveId != null) {
          this.deleteLeave(this.employeeLeaveId);
        }
      }
    });
  }

  deleteLeave(employeeLeaveId) {
    this.hrmService.deleteLeave(employeeLeaveId).subscribe(
      res => {
        if (res.isSuccsess) {
          const snack = new SnackBar(SnackBarType.success, 'Leave successfully deleted', '', 2000);
          this.commonUIService.openSnackBar(snack);
          this.loadEmployeeLeaveByEmployeeId();
          this.getEmployeeLeaveTypeListByEmployeeId();
        }
      }
    )
  }

  changeInsuarencePayable(insurance, event) {
    if (event && event.checked == true) {
      insurance.isPayable = true;
      this.empInsuranceForSave = Object.assign({}, insurance);
      this.hrmService.updateEmployeeInsurance(this.empInsuranceForSave.id, this.empInsuranceForSave).subscribe(res => {
        if (res && res.isSuccsess) {
          const snack = new SnackBar(SnackBarType.success, 'Successfully updated', '', 4000);
          this.commonUIService.openSnackBar(snack);
          this.loadEmpInsuranceList();
        }
        else {
          const snack = new SnackBar(SnackBarType.success, 'Error on update', '', 4000);
          this.commonUIService.openSnackBar(snack);
        }
      });
    }
  }

  subscribeEmployeeInsuranceDetails() {
    this.hrmService.refreshEmployeeInsurance.subscribe(data => {
      if (!data) { return; }
      this.loadEmpInsuranceList();
    });
  }

  subscribeCancelInsurance() {
    this.hrmService.cancelInsurance.subscribe(data => {
      if (data == true)
        this.isLoadingInsuranceList = false;
    });
  }

  changeCurrentMonth(key) {
    if (key) {
      if (key == '-') {
        this.currentMonth = this.currentMonth - 1;
        this.currentDate.setMonth(this.currentDate.getMonth() - 1);
        this.firstDay = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), 1);
        this.lastDay = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1, 0);
        this._empInsuranceList = this.employeeInsuranceList.length > 0 ? this.employeeInsuranceList.filter(x => new Date(x.date).getMonth() + 1 == this.currentMonth).sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()) : this.employeeInsuranceList;
      } else if (key == '+') {
        this.currentMonth = this.currentMonth + 1;
        this.currentDate.setMonth(this.currentDate.getMonth() + 1);
        this.firstDay = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), 1);
        this.lastDay = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1, 0);
        this._empInsuranceList = this.employeeInsuranceList.length > 0 ? this.employeeInsuranceList.filter(x => new Date(x.date).getMonth() + 1 == this.currentMonth).sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()) : this.employeeInsuranceList;
      }
    }
  }

  openEditInsurancePopup(employeeInsurance) {
    if (employeeInsurance.id > 0) {
      this.hrmService.isEmployeeInsuranceEdit = true;
      let empInsuranceObj = Object.assign({}, employeeInsurance);
      const dialogViewData = this.commonUIService.setDataForModalDialog(employeeInsurance.id, null, null, empInsuranceObj, null, null);
      this.commonUIService.openDialog(AddHrmInsuranceComponent, dialogViewData);
    }
  }

  subscribeRefreshLeaves() {
    this.hrmService.refreshLeaveList.subscribe(data => {
      if (!data) { return; }
      this.loadEmployeeLeaveByEmployeeId();
      this.getEmployeeLeaveTypeListByEmployeeId();
    });
  }

  onChooseEmployeeAttendanceSheet(event: any): void {
    if (event) {
      this.fileList = event.target.files;
      if (this.fileList)
        this.uploadEmployeeAttendanceSheet();
    }
  }

  uploadEmployeeAttendanceSheet() {
    const formData: FormData = new FormData();
    formData.append('file', this.fileList[0]);
    this.hrmService.uploadEmployeAttendanceSheet(formData).subscribe(
      res => {
        if (res && res.isSuccsess) {
          const snack = new SnackBar(SnackBarType.success, 'Employee attendance successfully uploaded', '', 4000);
          this.commonUIService.openSnackBar(snack);
          this.hrmService.refershEmloyeeAttendanceCalender.emit(this.employeeModel.id);
        } else {
          const snack = new SnackBar(SnackBarType.error, 'Error on employee attendance sheet upload', '', 4000);
          this.commonUIService.openSnackBar(snack);
        }
      },
      () => { console.error(); });
  }
}
