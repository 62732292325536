import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { SharedModule } from '../shared/shared.module';
import { ColorPickerModule } from 'ngx-color-picker';
import { AngularMaterialModule } from '../angular-material.module';
import { CommonModule } from '@angular/common';
import { HrmMaintenanceComponent } from './hrm-maintenance.component';
import { LeaveComponent } from './leave/leave.component';
import { InsuranceComponent } from './insurance/insurance.component';
import { AddEditInsuranceComponent } from './insurance/add-edit-insurance/add-edit-insurance.component';
import { AddEditLeaveComponent } from './leave/add-edit-leave/add-edit-leave.component';

@NgModule({
  declarations: [
    HrmMaintenanceComponent,
    LeaveComponent,
    InsuranceComponent,
    AddEditInsuranceComponent,
    AddEditLeaveComponent
  ],
  imports: [
    RouterModule,
    SharedModule,
    ColorPickerModule,
    AngularMaterialModule,
    CommonModule,
    BrowserModule
  ],
  entryComponents: [
    HrmMaintenanceComponent,
    AddEditInsuranceComponent,
    AddEditLeaveComponent
  ]
})

export class HrmMaintenanceModule {

}
