import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { ClientStatusModel } from 'src/app/shared/entities/clientStatusModel';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSelect, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Subject } from 'rxjs';
import { CommonDropdownService } from 'src/app/shared/services/common.dropdown.service';
import { DialogDataForModalView, UIService } from 'src/app/shared/services/ui.service';
import { MaintenanceService } from '../../service/maintenance.service';
import { SnackBar, SnackBarType } from 'src/app/shared/components/snackbar/entities/snackbar';
import { ValidationService } from 'src/app/shared/services/validation.service';

@Component({
  selector: 'app-add-edit-client-status',
  templateUrl: './add-edit-client-status.component.html',
  styleUrls: ['./add-edit-client-status.component.scss']
})
export class AddEditClientStatusComponent implements OnInit {
  clientStatusModel: ClientStatusModel;
  clientStatusFormGroup: FormGroup;
  isEdit: boolean = false;
  devStatusList: any = [];
  lengthClientStatus: number = 0;
  isDisabledClientStatus = false;

  @ViewChild('singleSelect', { static: false }) singleSelect: MatSelect;

  protected _onDestroy = new Subject<void>();

  constructor(
    private commonDropdownService: CommonDropdownService,
    private _formBuilder: FormBuilder,
    private validationService: ValidationService,
    @Inject(MAT_DIALOG_DATA) public clientStatusData: DialogDataForModalView,
    private maintenanceService: MaintenanceService,
    private uIService: UIService,
    public dialogRef: MatDialogRef<AddEditClientStatusComponent>,
  ) {
    this.clientStatusModel = new ClientStatusModel();
  }

  ngOnInit() {
    this.initFormGroup();
    this.commonDropdownService.LoadReferralSourceList();
    this.commonDropdownService.LoadClientStatusList();
    this.setClientDataForEditView();
  }

  initFormGroup() {
    this.clientStatusFormGroup = this._formBuilder.group({
      clientStatus: ['', [Validators.required, this.validationService.noWhitespaceValidator]],
      color: ['', Validators.required],

    });
  }

  setClientDataForEditView() {
    if (this.clientStatusData.data) {
      this.clientStatusModel = Object.assign({}, this.clientStatusData.data).modalData;
      this.isEdit = true;
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  async submit(model) {

    if (this.clientStatusFormGroup.invalid) {
      this.isDisabledClientStatus = false;
      return;
    }

    else if (this.isEdit) {
      this.updateClientStatus(model.id, model);
    }
    else {
      this.lengthClientStatus = this.commonDropdownService._activeClientStatusList.length;
      model.orders = this.lengthClientStatus;
      model.active = true;
      this.saveClientStatus(model);
    }

  }

  saveClientStatus(obj) {
    this.isDisabledClientStatus = true;
    this.maintenanceService.SaveClientStatus(obj).subscribe(
      res => {
          if (res.isSuccsess) {
            var snack = new SnackBar(SnackBarType.success, "Successfully saved", "", 2000);
              this.uIService.openSnackBar(snack);
              this.closeDialog();
              this.maintenanceService.ClientStatusRefersh(true);
          }
          else{
            var snack = new SnackBar(SnackBarType.success, "Error on save", "", 2000);
              this.uIService.openSnackBar(snack);
          }
      },
      err => { console.error(); this.closeDialog(); });

  }

  updateClientStatus(id, obj) {

    this.maintenanceService.updateClientStatus(id, obj).subscribe(
      res => {
        if (res.isSuccsess) {
          var snack = new SnackBar(SnackBarType.success, "Successfully updated", "", 2000);
          this.uIService.openSnackBar(snack);
          this.closeDialog();
          this.maintenanceService.ClientStatusRefersh(true);
        }
        else {
          snack = new SnackBar(SnackBarType.success, "Error on update", "", 2000);
          this.uIService.openSnackBar(snack);
        }
      },
      err => { console.error(); this.closeDialog(); });
  }
}
