import { Injectable, Output, EventEmitter } from '@angular/core';
import { APIService } from 'src/app/shared/services/api.service';
import { UIService } from 'src/app/shared/services/ui.service';
import { EmployeeDocument } from '../entities/empoyeeDocument';
import { FilterEmployeeModel } from '../entities/filter-employee-model';
import { designationModel } from 'src/app/shared/entities/designationModel';
import { SnackBar, SnackBarType } from '../../shared/components/snackbar/entities/snackbar';
import { Employee } from '../entities/empoyee';
import { EmpBankDetails } from '../entities/EmpBankDetails';
import { EmpLetters } from '../entities/empoyeeLetters';
import { RefereshModal } from '../../client/entities/referesh-modal';
import { EmpInsurance } from '../entities/EmpInsurance';
import { EmployeeLeave } from '../entities/empoyeeLeave';


@Injectable({
  providedIn: 'root'
})

export class HrmService {

  @Output() refreshEmployee: EventEmitter<{ employeeObject: any }> = new EventEmitter<{ employeeObject: any }>();
  @Output() refreshEmployeeInsurance: EventEmitter<any> = new EventEmitter<any>();
  @Output() newDocument: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancelDocument: EventEmitter<any> = new EventEmitter<any>();
  @Output() refreshEmloyeeList: EventEmitter<any> = new EventEmitter<any>();
  @Output() clearFilter: EventEmitter<any> = new EventEmitter<any>();
  @Output() refershListEmloyee: EventEmitter<{ id: any }> = new EventEmitter<{ id: any }>();
  @Output() newLetter: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancelLetter: EventEmitter<any> = new EventEmitter<any>();
  @Output() refreshCompanyEmployeeDocumentList: EventEmitter<{ employeeId: any, documentGroupId: any }> = new EventEmitter<{ employeeId: any, documentGroupId: any }>();
  @Output() refreshSalaryEmployeeDocumentList: EventEmitter<any> = new EventEmitter<any>();
  @Output() refreshEmployeeLetterList: EventEmitter<{ employeeId: any, letterTypeId: any }> = new EventEmitter<{ employeeId: any, letterTypeId: any }>();
  @Output() cancelLeave: EventEmitter<any> = new EventEmitter<any>();
  @Output() refershEmloyeeLeaveCalender: EventEmitter<any> = new EventEmitter<any>();
  @Output() refreshPersonalEmployeeDocumentList: EventEmitter<{ employeeId: any, documentGroupId: any }> = new EventEmitter<{ employeeId: any, documentGroupId: any }>();
  @Output() selectedEmployee: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancelInsurance: EventEmitter<any> = new EventEmitter<any>();
  @Output() refershEmloyeeAttendanceCalender: EventEmitter<any> = new EventEmitter<any>();
  @Output() refreshLeaveList: EventEmitter<any> = new EventEmitter<any>();

  isEmployeeSelected: boolean = false;
  isMoreFilterActive: boolean;
  public showEditSave: boolean;
  isCompletedOneYear: boolean = false;
  isEmployeeEdit: boolean = true;
  isShowInsuarenceCard: boolean = false;
  isShowLeaveCard: boolean = true;
  isEmployeeLeaveEdit: boolean = true;
  isEmployeeInsuranceEdit: boolean = true;
  
  public filterEmployeeModel: FilterEmployeeModel;
  public lowValue: number = 0;
  public highValue: number = 15;
  public pageIndex: number = 0;
  public totalCount: number = 0;


  constructor(
    private apiservice: APIService,
    public commonUIService: UIService
  ) { }


  listAllEmployees(FilterObject: FilterEmployeeModel) {
    let url = this.apiservice.getEndPoint("employee.ListAllEmployees", null);
    return this.apiservice.post(url,FilterObject,true);
  }

  employeeConfirmLetterStateByid(employeeId) {
    let url = this.apiservice.getEndPoint("employeeLetters.employeeConfirmLetterStateByid", [["{employeeId}", employeeId]]);
    return this.apiservice.get(url);
  }

  getEmployeeDocumentListByEmployeeId(employeeId) {
    let url = this.apiservice.getEndPoint("employeeDocument.getListByEmployeeId", [["{employeeId}", employeeId]])
    return this.apiservice.get(url);
  }

  GetBankDetailByEmpId(employeeId) {
    let url = this.apiservice.getEndPoint("employeeBankDetail.getBankDetailByEmpId", [["{id}", employeeId]])
    return this.apiservice.get(url);
  }

  getEmpInsuranceById(employeeId) {
    let url = this.apiservice.getEndPoint("employeeInsurance.getEmpInsuranceById", [["{employeeId}", employeeId]])
    return this.apiservice.get(url);
  }

  saveEmployeeBankDetails(addEmployeeBankDetailObj: EmpBankDetails) {
    let url = this.apiservice.getEndPoint("employeeBankDetail.addEmployeeBankDetail", null);
    return this.apiservice.post(url, addEmployeeBankDetailObj, true);
  }

  saveEmployeeDocument(employeeDocumentObj: EmployeeDocument) {
    let url = this.apiservice.getEndPoint("employeeDocument.addEmployeeDocument", null);
    return this.apiservice.post(url, employeeDocumentObj, true);
  }

  uploadEmployeeDocument(formData) {
    let url = this.apiservice.getEndPoint("fileUpload.upload", null);
    return this.apiservice.upload(url, formData);
  }

  loadEmployeeDocumentList(employeeId) {
    let url = this.apiservice.getEndPoint("employeeDocument.getEmployeeDocumentListByEmployeeId", [["{employeeId}", employeeId]]);
    return this.apiservice.get(url);
  }

  deleteEmployeeDocument(id) {
    let url = this.apiservice.getEndPoint("employeeDocument.employeeDocument", [["{id}", id]]);
    return this.apiservice.delete(url, id);
  }

  filterEmployee(FilterObject: FilterEmployeeModel) {
    let url = this.apiservice.getEndPoint("employee.filterEmployeeObject", null);
    return this.apiservice.post(url, FilterObject, true);
  }

  addEmployee(employeeObj: Employee) {
    let url = this.apiservice.getEndPoint("employee.addEmployee", null);
    return this.apiservice.post(url, employeeObj, true);
  }

  updateEmployee(employeeId, EmployeeObj) {
    let url = this.apiservice.getEndPoint("employee.updateEmployee", [["{id}", employeeId]]);
    return this.apiservice.put(url, EmployeeObj, true);
  }

  uploadEmployeeProfileImage(formData) {
    let url = this.apiservice.getEndPoint("fileUpload.uploadDocuments", null);
    return this.apiservice.upload(url, formData);
  }

  saveEmployeeLetter(employeeLetterObj: EmpLetters) {
    let url = this.apiservice.getEndPoint("employeeLetters.addEmployeeLetters", null);
    return this.apiservice.post(url, employeeLetterObj, true);
  }

  saveEmployeeInsurance(employeeInsuranceObj: EmpInsurance) {
    employeeInsuranceObj.insuranceTypeId = +employeeInsuranceObj.insuranceTypeId;
    employeeInsuranceObj.insuranceAmount = +employeeInsuranceObj.insuranceAmount;
    let url = this.apiservice.getEndPoint("employeeInsurance.saveEmployeeInsurance", null);
    return this.apiservice.post(url, employeeInsuranceObj, true);
  }

  updateEmployeeInsurance(id, employeeInsuranceObj: EmpInsurance) {
    employeeInsuranceObj.insuranceTypeId = +employeeInsuranceObj.insuranceTypeId;
    employeeInsuranceObj.insuranceAmount = +employeeInsuranceObj.insuranceAmount;
    let url = this.apiservice.getEndPoint("employeeInsurance.updateEmployeeInsurance", [["{id}", id]]);
    return this.apiservice.put(url, employeeInsuranceObj, true);
  }

  uploadEmployeeLetter(formData) {
    let url = this.apiservice.getEndPoint("fileUpload.upload", null);
    return this.apiservice.upload(url, formData);
  }

  uploadEmpInsuranceFile(formData) {
    let url = this.apiservice.getEndPoint("fileUpload.upload", null);
    return this.apiservice.upload(url, formData);
  }

  deleteEmployeeLetter(id) {
    let url = this.apiservice.getEndPoint("employeeLetters.employeeLetters", [["{id}", id]]);
    return this.apiservice.delete(url, id);
  }

  loadEmployeeLetterList(employeeId) {
    let url = this.apiservice.getEndPoint("employeeLetters.getEmployeeLetterListByEmployeeId", [["{employeeId}", employeeId]]);
    return this.apiservice.get(url);
  }

  getDocumentListByEmployeeIdAndDocumentTypeId(employeeId, documentTypeId) {
    let url = this.apiservice.getEndPoint("employeeDocument.getDocumentListByEmployeeIdAndDocumentTypeId", [["{employeeId}", employeeId], ["{documentTypeId}", documentTypeId]])
    return this.apiservice.get(url);
  }

  addEmployeeDocument(documentObj: EmployeeDocument) {
    let url = this.apiservice.getEndPoint("employeeDocument.addEmployeeDocument", null);
    return this.apiservice.post(url, documentObj, true);
  }

  deleteEmployeePersonalDocument(id) {
    let url = this.apiservice.getEndPoint("employeeDocument.employeeDocument", [["{id}", id]]);
    return this.apiservice.delete(url, id);
  }

  getEmployeeDocumentListByEmployeeIdAndDocumentTypeId(employeeId, documentTypeId) {
    let url = this.apiservice.getEndPoint("employeeDocument.getEmployeeDocumentListByEmployeeIdAndDocumentTypeId", [["{employeeId}", employeeId], ["{documentTypeId}", documentTypeId]])
    return this.apiservice.get(url);
  }

  saveEmployeeLeave(employeeLeaveObj: EmployeeLeave) {
    employeeLeaveObj.leaveTypeId = +employeeLeaveObj.leaveTypeId;
    let url = this.apiservice.getEndPoint("employeeLeave.saveEmployeeLeave", null);
    return this.apiservice.post(url, employeeLeaveObj, true);
  }

  getEmployeeLeaveTypeListByEmployeeId(employeeId) {
    let url = this.apiservice.getEndPoint("employeeLeave.getEmployeeLeaveTypeListByEmployeeId", [["{employeeId}", employeeId]]);
    return this.apiservice.get(url);
  }

  getEmployeeLeaveEventListByEmployeeId(employeeId) {
    let url = this.apiservice.getEndPoint("employeeLeave.getEmployeeLeaveEventListByEmployeeId", [["{employeeId}", employeeId]]);
    return this.apiservice.get(url);
  }

  getEmployeeLeaveByEmployeeId(employeeId) {
    let url = this.apiservice.getEndPoint("employeeLeave.getEmployeeLeaveByEmployeeId", [["{employeeId}", employeeId]]);
    return this.apiservice.get(url);
  }

  getEmployeeById(employeeId) {
    let url = this.apiservice.getEndPoint("employee.getEmployeeById", [["{id}", employeeId]]);
    return this.apiservice.get(url);
  }

  deleteLeave(id) {
    let url = this.apiservice.getEndPoint("employeeLeave.employeeLeave", [["{id}", id]]);
    return this.apiservice.delete(url, id);
  }

  getEmployeeAttendanceEventListByEmployeeId(employeeId) {
    let url = this.apiservice.getEndPoint("employeeAttendance.getEmployeeAttendanceEventListByEmployeeId", [["{employeeId}", employeeId]]);
    return this.apiservice.get(url);
  }
  
  uploadEmployeAttendanceSheet(formData) {    
    let url = this.apiservice.getEndPoint("fileUpload.importAttendance", null);
    return this.apiservice.upload(url, formData);
  }
} 
