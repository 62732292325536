import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, ValidatorFn } from '@angular/forms';
import { empty } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ValidationService {

constructor() { }

public noWhitespaceValidator(control: FormControl) 
{
   const isWhitespace = (control.value || '').trim().length === 0;
   const isValid = !isWhitespace;
   return  isValid ? null : { 'whitespace': true };
}

public nullValueSave(arry,objectPass)
{
   var x=arry.length;
   for(var i=0;i<x;i++)
   {
     var y=arry[i];   
     if(objectPass[y]!=null)  {
           if( !objectPass[y].trim().length)
           {
            objectPass[y]=null;
           }
   }}
   return objectPass
}  

checkLimit(min: number, max: number): ValidatorFn {
   return (c: AbstractControl): { [key: string]: boolean } | null => {    
       if (c.value && (isNaN(c.value) || c.value.length < min)) {
           return { 'minlength': true };
       }
       if (c.value && (isNaN(c.value) || c.value.length > max)) {
         return { 'maxlength': true };
     }
       return null;
   };
}
}
