import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { TechnologiesModel } from 'src/app/shared/entities/technologiesModel';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { MatSelect, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { CommonDropdownService } from 'src/app/shared/services/common.dropdown.service';
import { DialogDataForModalView, UIService } from 'src/app/shared/services/ui.service';
import { MaintenanceService } from '../../service/maintenance.service';
import { takeUntil, take } from 'rxjs/operators';
import { SnackBar, SnackBarType } from 'src/app/shared/components/snackbar/entities/snackbar';
import { ValidationService } from 'src/app/shared/services/validation.service';

@Component({
  selector: 'app-add-edit-technologies',
  templateUrl: './add-edit-technologies.component.html',
  styleUrls: ['./add-edit-technologies.component.scss']
})
export class AddEditTechnologiesComponent implements OnInit {

  technologyModel: TechnologiesModel;
  technologyFormGroup: FormGroup;
  isEdit: boolean = false;
  devIconList: any = [];
  isDisabledTechnologies = false;

  /** control for the MatSelect filter keyword */
  public iconFilterCtrl: FormControl = new FormControl();

  /** list of icons filtered by search keyword */
  public filteredDevIconList: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  @ViewChild('singleSelect', { static: false }) singleSelect: MatSelect;

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  constructor(
    private commonDropdownService: CommonDropdownService,
    private _formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public technologyData: DialogDataForModalView,
    private maintenanceService: MaintenanceService,
    private uIService: UIService,
    private validationService: ValidationService,
    public dialogRef: MatDialogRef<AddEditTechnologiesComponent>,
  ) {
    this.technologyModel = new TechnologiesModel();
  }

  ngOnInit() {
    this.initFormGroup();
    this.commonDropdownService.LoadReferralSourceList();
    this.loadDevicoList();
    this.setClientDataForEditView();
  }

  initFormGroup() {
    this.technologyFormGroup = this._formBuilder.group({
      name: ['', [Validators.required, this.validationService.noWhitespaceValidator]],
      color: ['', Validators.required],
      icons: [''],
    });
  }

  loadDevicoList() {
    this.commonDropdownService.LoadDeviconsList().subscribe(list => {
      list.forEach(ele => {
        let Devicon: any = [];
        Devicon.label = ele.name;
        Devicon.plain = "devicon-" + ele.name + "-" + ele.versions.font[0];
        Devicon.wordmark = "devicon-" + ele.name + "-" + ele.versions.font[1];
        this.devIconList.push(Devicon);
      });

      // load the initial icon list
      this.filteredDevIconList.next(this.devIconList.slice());

      // listen for search field value changes
      this.iconFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterIcons();
        });
    });
  }

  setClientDataForEditView() {
    if (this.technologyData.data) {
      this.technologyModel = Object.assign({}, this.technologyData.data).modalData;
      this.isEdit = true;
    }
  }

  ngAfterViewInit() {
  }

  async submit(model) {
    if (this.technologyFormGroup.invalid) {
      this.isDisabledTechnologies = false;
      return;
    }
    model.icons = model.icons ? model.icons : "devicon-slack-plain";
    if (this.isEdit) this.updateTechnology(model.id, model);
    else this.saveTechnology(model);
  }

  saveTechnology(obj) {
    this.isDisabledTechnologies = true;
    obj.active = true;
    this.maintenanceService.SaveTechnology(obj).subscribe(
      res => {
        if (res.isSuccsess) {
          var snack = new SnackBar(SnackBarType.success, "Successfully saved", "", 2000);
          this.uIService.openSnackBar(snack);
          this.closeDialog();
          this.maintenanceService.technologyRefersh(true);
        } else {
          snack = new SnackBar(SnackBarType.success, "Error on save", "", 2000);
          this.uIService.openSnackBar(snack);
        }
      },
      err => { console.error(); this.closeDialog(); });
  }

  updateTechnology(id, obj) {
    this.maintenanceService.updateTechnology(id, obj).subscribe(
      res => {
        if (res.isSuccsess) {
          var snack = new SnackBar(SnackBarType.success, "Successfully updated", "", 2000);
          this.uIService.openSnackBar(snack);
          this.closeDialog();
          this.maintenanceService.technologyRefersh(true);
        } else {
          snack = new SnackBar(SnackBarType.success, "Error on update", "", 2000);
          this.uIService.openSnackBar(snack);
        }
      },
      err => { console.error(); this.closeDialog(); });
  }

  /**
   * Sets the initial value after the filteredDevIconList are loaded initially
   */
  protected setInitialValue() {
    this.filteredDevIconList
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        this.singleSelect.compareWith = (a: any, b: any) => a && b && a.id === b.id;
      });
  }

  protected filterIcons() {
    if (!this.devIconList) {
      return;
    }
    // get the search keyword
    let search = this.iconFilterCtrl.value;
    if (!search) {
      this.filteredDevIconList.next(this.devIconList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredDevIconList.next(
      this.devIconList.filter(icon => icon.plain.toLowerCase().indexOf(search) > -1)
    );
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
