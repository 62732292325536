import { Component, OnInit, Input } from '@angular/core';
import { UIService } from 'src/app/shared/services/ui.service';
import { CommonDropdownService } from 'src/app/shared/services/common.dropdown.service';
import { FormatMatDatepicker } from 'src/app/shared/format-datepicker';
import { InventoryService } from 'src/app/inventory/service/inventoryService';
import { FloorFurniture } from '../../entities/floorFurniture';
import { FloorFurnitureModel } from '../../entities/furnitureModel';
import { SnackBar, SnackBarType } from '../../../shared/components/snackbar/entities/snackbar';
import { AddFurnitureComponent } from './add-furniture/add-furniture.component';

@Component({
  selector: 'app-furniture-detail',
  templateUrl: './furniture-detail.component.html',
  styleUrls: ['./furniture-detail.component.scss']
})

export class FurnitureDetailComponent implements OnInit {
  isEdit: boolean = false;
  floorList: any[];
  oddfurnitureCategoryList: any[];
  evenfurnitureCategoryList: any[];
  _floorFurnitureForSave: FloorFurnitureModel;

  constructor(public commonDropdownService: CommonDropdownService, public commonUIService: UIService,
    public formatDatepicker: FormatMatDatepicker,
    public inventoryService: InventoryService) {
    this._floorFurnitureForSave = new FloorFurnitureModel();
  }

  ngOnInit() {
    this.subscribeLoadFurnitureDetails();
    this.subscribeRefreshFurniture();
    this.listAllFurnitures();
  }

  listAllFurnitures() {
    this.inventoryService.listAllFurnitures().subscribe(
      response => {
        if (response) {
          if (response.data) {
            this.floorList = response.data;
          }
        }
      },
      err => {
        console.error(err);
      }, () => {
      });
  }

  subscribeLoadFurnitureDetails() {
    this.inventoryService.loadFurnitureDetails.subscribe(res => {
      if (res.data) {
        this.floorList = res.data;
      }
    })
  }

  subscribeRefreshFurniture() {
    this.inventoryService.refreshFurniture.subscribe(res => {
      if (res) {
        this.listAllFurnitures();
      }
    })
  }

  openFurnitureAddPopup() {
    this.commonUIService.openDialog(AddFurnitureComponent, null)
  }

  editFurniture() {
    this.isEdit = true;
  }

  updateFurniture(furnitureModel, categoryModel, floorModel) {
    if (furnitureModel != null && categoryModel != null && floorModel != null) {
      let floorFrnitureObj = new FloorFurnitureModel();
      floorFrnitureObj.floorId = floorModel.id.toString();
      floorFrnitureObj.furnitureId = furnitureModel.id;
      floorFrnitureObj.furnitureCount = furnitureModel.furnitureCount;
      this.inventoryService.updateFloorFurniture(furnitureModel.floorFurnitureId, floorFrnitureObj).subscribe(res => {
        if (res.isSuccsess) {
          var snack = new SnackBar(SnackBarType.success, res.message, '', 2000);
          this.commonUIService.openSnackBar(snack);
          if (res.message == "Sucessfully updated") {
            this.inventoryService.refreshFurniture.emit(true);
          }
        }
      });
    }    
  }
}
