import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UIService, DialogDataForModalView } from 'src/app/shared/services/ui.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SnackBar, SnackBarType } from 'src/app/shared/components/snackbar/entities/snackbar';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/auth/login/login.service';
import { Users } from 'src/app/auth/entities/user';
import { CommonDropdownService } from '../../../shared/services/common.dropdown.service';
import moment from 'moment';
import { SharedHeaderService } from '../../../shared/services/shared.header.services';
import { ClientPack } from '../../entities/clientPack';
import { isNumber } from 'util';
import { FormatMatDatepicker } from '../../../shared/format-datepicker';
import { ProductService } from '../../service/product.service';

@Component({
  selector: 'app-add-edit-client-pack',
  templateUrl: './add-edit-client-pack.component.html',
  styleUrls: ['./add-edit-client-pack.component.scss']
})

export class AddEditClientPackComponent implements OnInit {

  isEdit: boolean = false;
  clientPackFormGroup: FormGroup;
  _clientPackModal: ClientPack;
  isDisabledClientPack: boolean = false;

  constructor(
    private _formBuilder: FormBuilder,
    public commonUIService: UIService,
    private uIService: UIService,
    private route: Router,
    private loginServic: LoginService,
    private validationSerivce: ValidationService,
    public sharedHeaderService: SharedHeaderService,
    private commonDropdownService: CommonDropdownService,
    private formatDatepicker: FormatMatDatepicker,
    public productService: ProductService,
    public dialogRef: MatDialogRef<AddEditClientPackComponent>,
    @Inject(MAT_DIALOG_DATA) public modalViewData: DialogDataForModalView) {
  }

  ngOnInit() {
    this.initFormGroup();
    this.setClientPackDataForEditView();
  }

  initFormGroup() {
    this.clientPackFormGroup = this._formBuilder.group({
      state: [''],
      contractDate: ['', [Validators.required]],
      expirationDate: [''],
      renewalDate: [''],
      cancelDate: [''],
      pack: ['', [Validators.required, this.validationSerivce.noWhitespaceValidator]],
      details: [''],
      subscriptionType: [''],
      oneTimePrice: [''],
      subscriptionPrice: [''],
      billed: ['']
    });
  }

  setClientPackDataForEditView(): void {
    if (this.modalViewData.data) {
      this._clientPackModal = Object.assign({}, this.modalViewData.data.modalData);
      if (isNumber(this.modalViewData.data.id)) {
        this.isEdit = true;
      } else {
        this._clientPackModal.clientProductId = this.modalViewData.data.modalData.clientProductId;
        this.isEdit = false;
      }
    }
  }

  async submit(clientPack) {
    if (this.clientPackFormGroup.invalid) {
      this.isDisabledClientPack = false;
      return;
    }
    let clientPackObj = Object.assign({}, clientPack);
    clientPackObj.contractDateStr = clientPackObj.contractDate ? this.formatDatepicker.ConvertDateFormatForSave(clientPackObj.contractDate) : clientPackObj.contractDate;
    clientPackObj.expirationDateStr = clientPackObj.expirationDate ? this.formatDatepicker.ConvertDateFormatForSave(clientPackObj.expirationDate) : clientPackObj.expirationDate;
    clientPackObj.renewalDateStr = clientPackObj.renewalDate ? this.formatDatepicker.ConvertDateFormatForSave(clientPackObj.renewalDate) : clientPackObj.renewalDate;
    clientPackObj.cancelDateStr = clientPackObj.cancelDate ? this.formatDatepicker.ConvertDateFormatForSave(clientPackObj.cancelDate) : clientPackObj.cancelDate;
    if (this.isEdit) {
      this.updateClientPack(clientPack.id, clientPackObj);
    } else {
      this.addClientPack(clientPackObj);
    }
  }

  addClientPack(clientPackObj) {
    this.isDisabledClientPack = true;
    this.productService.saveClientPackData(clientPackObj).subscribe(
      res => {
        if (res.isSuccsess) {
          this.closeDialog();
          const snack = new SnackBar(SnackBarType.success, 'Successfully saved', '', 2000);
          this.commonUIService.openSnackBar(snack);
        }
      },
      () => {
        console.error(); this.closeDialog();
      });
  }

  updateClientPack(clientPackId, clientPackObj) {
    this.productService.updateClientPack(clientPackId, clientPackObj).subscribe(
      res => {
        if (res.isSuccsess) {
          const snack = new SnackBar(SnackBarType.success, 'Successfully updated', '', 2000);
          this.commonUIService.openSnackBar(snack);
          this.closeDialog();
        }
      },
      () => {
        console.error();
        this.closeDialog();
      });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  billedCheckEvent(event) {
    this._clientPackModal.billed = event.checked;
  }

  setExpirationDate(text) {
    if (text) {
      if (text == '1m' && this._clientPackModal.contractDate != null || this._clientPackModal.renewalDate != null) {
        if (this._clientPackModal.contractDate != null) {
          let _expirationDate = this._clientPackModal.contractDate;
          this._clientPackModal.expirationDate = _expirationDate.setMonth(_expirationDate.getMonth() - 1);
        } else if (this._clientPackModal.renewalDate != null) {
          let _expirationDate = this._clientPackModal.contractDate;
          this._clientPackModal.expirationDate = _expirationDate.setMonth(_expirationDate.getMonth() - 1);
        }
      } else if (text == '3m' && this._clientPackModal.contractDate != null || this._clientPackModal.renewalDate != null) {
        if (this._clientPackModal.contractDate != null) {
          let _expirationDate = this._clientPackModal.contractDate;
          this._clientPackModal.expirationDate = _expirationDate.setMonth(_expirationDate.getMonth() - 3);
        } else if (this._clientPackModal.renewalDate != null) {
          let _expirationDate = this._clientPackModal.contractDate;
          this._clientPackModal.expirationDate = _expirationDate.setMonth(_expirationDate.getMonth() - 3);
        }
      } else if (text == '6m' && this._clientPackModal.contractDate != null || this._clientPackModal.renewalDate != null) {
        if (this._clientPackModal.contractDate != null) {
          let _expirationDate = this._clientPackModal.contractDate;
          this._clientPackModal.expirationDate = _expirationDate.setMonth(_expirationDate.getMonth() - 6);
        } else if (this._clientPackModal.renewalDate != null) {
          let _expirationDate = this._clientPackModal.contractDate;
          this._clientPackModal.expirationDate = _expirationDate.setMonth(_expirationDate.getMonth() - 6);
        }
      } else if (text == '1y' && this._clientPackModal.contractDate != null || this._clientPackModal.renewalDate != null) {
        if (this._clientPackModal.contractDate != null) {
          let _expirationDate = this._clientPackModal.contractDate;
          this._clientPackModal.expirationDate = _expirationDate.setMonth(_expirationDate.getMonth() - 12);
        } else if (this._clientPackModal.renewalDate != null) {
          let _expirationDate = this._clientPackModal.contractDate;
          this._clientPackModal.expirationDate = _expirationDate.setMonth(_expirationDate.getMonth() - 12);
        }
      }
    }
  }
}
