import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthAppService } from './services/auth-app.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authAppService: AuthAppService) {
  }

  async canActivate(route: ActivatedRouteSnapshot) {
    // check not logged in
    const token = await this.authAppService.authenticated();
    const authenticated = token && token != null;
    if (!authenticated) {
      this.router.navigate(['/']).then();
      return false;
    }

    // check permissions
    const isPermit = this.getPermissionAccess(route.routeConfig.path);
    if (!isPermit) {
      this.router.navigate(['/no-permission']).then();
      return false;
    }

    return true;
  }

  private getPermissionAccess(path) {
    let isPermit: boolean = false;
    const permissionIds = this.authAppService.getLoggedInPermission();
    if(!(permissionIds && permissionIds.length > 0)) return isPermit;

    switch (path) {
      case "client":
        isPermit = permissionIds.includes('2000');
        break;
      case "linkedin":
        isPermit = permissionIds.includes("2800");
        break;
      case "maintenance": case "task": case "estimation": case "users": case "permits": case "inventory": case "no-permission": case "company": case "hrm": case "module": case "crm-document-type":
        case "linkedin-templete" : case "client-product" : isPermit = true;
        break;
    }
    return isPermit;
  }
}

@Injectable({
  providedIn: 'root'
})
export class AuthRedirect implements CanActivate {
  constructor(private router: Router, private authAppService: AuthAppService) {
  }

  async canActivate(route: ActivatedRouteSnapshot) {
    const r = route.routeConfig.path;
    const token = await this.authAppService.authenticated();
    if (token && (r == '/' || r == '')) {
      this.router.navigate(['/module']).then();
      return false;
    }
    return true;
  }
}
