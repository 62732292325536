import { Component, OnInit, ElementRef, Inject, ViewChild, ChangeDetectorRef } from '@angular/core';
import { CommonDropdownService } from 'src/app/shared/services/common.dropdown.service';
import { UIService, DialogDataForModalView } from 'src/app/shared/services/ui.service';
import { APIService } from 'src/app/shared/services/api.service';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { FormatMatDatepicker, FormatTimeForDisplay } from 'src/app/shared/format-datepicker';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { Subject } from 'rxjs';
import { SnackBar, SnackBarType } from '../../../../shared/components/snackbar/entities/snackbar';
import { Router } from '@angular/router';
import { ClientService } from '../../../services/client.service';
import { isNumber } from 'util';
import { ProjectViewService } from '../../../services/project-view.service';
import { ClientViewService } from '../../../services/client-view.service';
import moment from 'moment';
import { EstimationOption } from '../../../entities/estimationOption';

@Component({
  selector: 'add-estimation-option',
  templateUrl: './add-estimation-option.component.html',
  styleUrls: ['./add-estimation-option.component.scss']
})

export class AddEstimationOptionComponent implements OnInit {

  isDisabledEstimationOption = true;
  estimationOptionFormGroup: FormGroup;
  isEdit: boolean = false;
  private _unsubscribeAll: Subject<any>;
  estimationOptionModal: EstimationOption;
  isMaximumHoursHigher: boolean = false;
  isMaximumBudgetHigher: boolean = false;
  invalidMaximumHours: string = null;
  invalidMaximumBudget: string = null;

  constructor(public commonDropdownService: CommonDropdownService,
    public commonUIService: UIService,
    public _apiService: APIService,
    public dialog: MatDialog,
    public fb: FormBuilder,
    public formatDatepicker: FormatMatDatepicker,
    private validationService: ValidationService,
    private clientService: ClientService,
    private projectViewService: ProjectViewService,
    public clientViewService: ClientViewService,
    private _formBuilder: FormBuilder,
    public router: Router,
    private formatTimeForDisplay: FormatTimeForDisplay,
    public dialogRef: MatDialogRef<AddEstimationOptionComponent>,
    @Inject(MAT_DIALOG_DATA) public currentconpanyData: DialogDataForModalView,
    @Inject(MAT_DIALOG_DATA) public modalViewData: DialogDataForModalView) {
    this._unsubscribeAll = new Subject();
    this.estimationOptionModal = new EstimationOption();
  }

  ngOnInit() {
    this.initEstimationOptionFormGroup();
    this.setEstimationOptionDataForEditView();
  }

  initEstimationOptionFormGroup() {
    this.estimationOptionFormGroup = this._formBuilder.group({
      option: ['', [Validators.required, this.validationService.noWhitespaceValidator]],
      state: ['', [Validators.required]],
      hours: [''],
      maxHours: [''],
      hourFee: [''],
      budget: [''],
      maxBudget: ['']
    });
  }

  setEstimationOptionDataForEditView(): void {
    if (this.modalViewData.data) {
      this.estimationOptionModal = Object.assign({}, this.modalViewData.data.modalData);
      let today = moment();
      let date = moment(today);
      if (isNumber(this.modalViewData.data.id)) {
        this.isEdit = true;
      } else {
        this.isEdit = false;
      }
    }
  }

  async submit(estimationOption) {
    this.isMaximumBudgetHigher = false;
    this.isMaximumHoursHigher = false;
    this.isDisabledEstimationOption = false;
    if (estimationOption.hours != null)
      estimationOption.hours = +estimationOption.hours;
    if (estimationOption.maxHours != null)
      estimationOption.maxHours = +estimationOption.maxHours;
    if (estimationOption.budget != null)
      estimationOption.budget = +estimationOption.budget;
    if (estimationOption.maxBudget != null)
      estimationOption.maxBudget = +estimationOption.maxBudget;
    let estimationOptionObj = Object.assign({}, estimationOption);
    estimationOptionObj.id = 0;
    estimationOptionObj.projectId = this.modalViewData.data.projectId;
    estimationOptionObj.insertUser = this.modalViewData.data.modalData.loggedUser;
    estimationOptionObj.updateUser = this.modalViewData.data.modalData.loggedUser;
    estimationOptionObj.clientId = this.clientViewService.selectedClient.id;
    if (this.estimationOptionFormGroup.invalid) {
      this.isDisabledEstimationOption = true;
      return;
    } else {
      if (estimationOptionObj.hours != null && estimationOptionObj.maxHours != null &&
        estimationOptionObj.hours > estimationOptionObj.maxHours) {
        this.isMaximumHoursHigher = true;
      } else {
        this.isMaximumHoursHigher = false;
      }
      if (estimationOptionObj.budget != null && estimationOptionObj.maxBudget != null &&
        estimationOptionObj.budget > estimationOptionObj.maxBudget) {
        this.isMaximumBudgetHigher = true;
      } else {
        this.isMaximumBudgetHigher = false;
      }
      if (this.isMaximumHoursHigher == true || this.isMaximumBudgetHigher == true) {
        this.isDisabledEstimationOption = true;
        return;
      } else {
        if (this.isEdit) {
          this.closeDialog();
          this.projectViewService.passEstimationOptionData.emit(estimationOptionObj);
        }
        else {
          this.closeDialog();
          this.projectViewService.passEstimationOptionData.emit(estimationOptionObj);
        }
      }
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
