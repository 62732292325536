import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActionTypeModel } from 'src/app/shared/entities/actionTypeModel';
import { MaintenanceService } from '../../service/maintenance.service';
import { UIService, DialogDataForModalView } from 'src/app/shared/services/ui.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SnackBar, SnackBarType } from 'src/app/shared/components/snackbar/entities/snackbar';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { APIService } from '../../../shared/services/api.service';
import API from '../../../shared/services/api.routes.json';

@Component({
  selector: 'app-add-edit-actiontype',
  templateUrl: './add-edit-actiontype.component.html',
  styleUrls: ['./add-edit-actiontype.component.scss']
})

export class AddEditActiontypeComponent implements OnInit {
  actionTypeFormGroup: FormGroup;
  _actionType: ActionTypeModel;
  isEdit: boolean = false;
  isDisabledActionType = false;
  _actionTypeList: any = [];

  constructor(
    private _apiService: APIService,
    private _formBuilder: FormBuilder,
    private maintatanceService: MaintenanceService,
    private uIService: UIService,
    private validationService: ValidationService,
    public dialogRef: MatDialogRef<AddEditActiontypeComponent>,
    @Inject(MAT_DIALOG_DATA) public actionTypeData: DialogDataForModalView) { }

  ngOnInit() {
    this.initFormGroup();
    this._actionType = new ActionTypeModel();
    this.setActionTypeDataForEditView();
  }

  initFormGroup() {
    this.actionTypeFormGroup = this._formBuilder.group({
      actiontype: ['', [Validators.required, this.validationService.noWhitespaceValidator]],
      icon: ['', [Validators.required, this.validationService.noWhitespaceValidator]]
    })
  }

  setActionTypeDataForEditView() {
    if (this.actionTypeData.data) {
      this._actionType = Object.assign({}, this.actionTypeData.data).modalData;
      this.isEdit = true;
    }
  }

  async submit(actionType: { id: any; }) {
    if (this.actionTypeFormGroup.invalid) {
      this.isDisabledActionType = false;
      return;
    }
    if (this.isEdit) this.updateActionType(actionType.id, actionType);
    else
      this.saveActionType(actionType);
  }

  saveActionType(actionType: { id: any; }) {
    console.log(actionType);
    this.isDisabledActionType = true;
    this.maintatanceService.saveActionType(actionType).subscribe(
      (res: { isSuccsess: any; }) => {
        if (res.isSuccsess) {
          var snack = new SnackBar(SnackBarType.success, "Successfully saved", "", 2000);
          this.uIService.openSnackBar(snack);
          this.closeDialog();
          this.maintatanceService.actionTypeRefresh(true);
        }
        else {
          snack = new SnackBar(SnackBarType.success, "Error on save", "", 2000);
          this.uIService.openSnackBar(snack);
        }
      },
      () => { console.error(); this.closeDialog(); });
  }

  updateActionType(id: any, obj: { id: any; }) {
    this.maintatanceService.updateActionType(id, obj).subscribe(
      (res: { isSuccsess: any; }) => {
        if (res.isSuccsess) {
          var snack = new SnackBar(SnackBarType.success, "Successfully updated", "", 2000);
          this.uIService.openSnackBar(snack);
          this.closeDialog();
          this.maintatanceService.actionTypeRefresh(true);
        }
        else {
          snack = new SnackBar(SnackBarType.success, "Error on update", "", 2000);
          this.uIService.openSnackBar(snack);
        }
      },
      () => { console.error(); this.closeDialog(); });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }  
}
