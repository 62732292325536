import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { DialogDataForModalView, UIService } from 'src/app/shared/services/ui.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ClientContact } from 'src/app/client/entities/clientContact';
import { ClientService } from 'src/app/client/services/client.service';
import { isNumber } from 'util';
import { SnackBar, SnackBarType } from 'src/app/shared/components/snackbar/entities/snackbar';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { CommonDropdownService } from 'src/app/shared/services/common.dropdown.service';
import { SendEmailComponent } from '../../../../linkedin/components/view-linkedin/email-popup/email-popup.component';

@Component({
  selector: 'app-add-edit-contact',
  templateUrl: './add-edit-contact.component.html',
  styleUrls: ['./add-edit-contact.component.scss']
})
export class AddEditContactComponent implements OnInit {

  clientContactFormGroup: FormGroup;
  _clientContact: ClientContact;
  isChecked = true;
  _clientContactForSave: ClientContact;
  isEdit: boolean = false;
  buttonDisable: boolean = true;
  lengthNumber1: number = 15;
  lengthNumber2: number = 15;
  lengthWhatsappNumber: number = 15;

  constructor(
    private _formBuilder: FormBuilder,
    private clientService: ClientService,
    private validationService: ValidationService,
    private commonUIService: UIService,
    public commonDropdownService: CommonDropdownService,
    public dialogRef: MatDialogRef<AddEditContactComponent>,
    @Inject(MAT_DIALOG_DATA) public modalViewData: DialogDataForModalView) {
    this._clientContact = new ClientContact();
  }

  ngOnInit() {
    this.contactFormGroup();
    this._clientContactForSave = new ClientContact();
    this.setClientContactDataForEditView();
    this.commonDropdownService.LoadPositionList();
  }

  setClientContactDataForEditView(): void {
    if (this.modalViewData.data) {
      this._clientContact = Object.assign({}, this.modalViewData.data.modalData);
      if (this._clientContact.phoneNumber) {
        this.lengthNumber1 = this._clientContact.phoneNumber.charAt(0) == "+" ? 15 : 9;
      }
      if (this._clientContact.additionalPhoneNumber) {
        this.lengthNumber2 = this._clientContact.additionalPhoneNumber.charAt(0) == "+" ? 15 : 9;
      }
      if (this._clientContact.whatsapp) {
        this.lengthWhatsappNumber = this._clientContact.whatsapp.charAt(0) == "+" ? 15 : 9;
      }
      this.isEdit = !isNumber(this.modalViewData.data.id);
    }
  }

  // Reactive Form
  contactFormGroup() {
    this.clientContactFormGroup = this._formBuilder.group({
      name: ['', [Validators.required, this.validationService.noWhitespaceValidator]],
      positionId: ['', [Validators.required]],
      email: ['', [Validators.email, Validators.required]],
      additionalPhoneNumber: ['', [ this.validationService.checkLimit(6,15)]],
      phoneNumber: ['', [this.validationService.checkLimit(6,15)]],
      whatsapp: ['', [this.validationService.checkLimit(6,15)]],
      skype: [''],
      linkdin: [''],
      status: [true, Validators.requiredTrue]
    });
  }

  onKey(numb, type) {
    if (type === 1) this.lengthNumber1 = numb.charAt(0) == "+" ? 15 : 9;
    if (type === 2) this.lengthNumber2 = numb.charAt(0) == "+" ? 15 : 9;
    if (type === 3) this.lengthWhatsappNumber = numb.charAt(0) == "+" ? 15 : 9;
  }

  async submit(clientContact) {
    this.buttonDisable = false;
    if (this.clientContactFormGroup.invalid) {
      this.buttonDisable = true;
      return;
    }
    if (this.isEdit) {
      this.updateClientContactData(clientContact.id, clientContact);
    } else {
      this.SaveClientContact(clientContact);
    }
  }

  updateClientContactData(clientContactID, clientContactObj) {
    this.clientService.updateClientContact(clientContactID, clientContactObj).subscribe(
      res => {
        if (res.isSuccsess) {
          const snack = new SnackBar(SnackBarType.success, 'Client contact successfully updated', '', 2000);
          this.commonUIService.openSnackBar(snack);
          this.clientService.refreshClientContact.emit(res.data);
          this.closeDialog();
        }
      },
      () => { console.error(); this.closeDialog(); });
  }

  SaveClientContact(clientContactObj) {
    clientContactObj.status = this.isChecked;
    this.BindToModel(clientContactObj);
    this.clientService.SaveClientContact(this._clientContactForSave).subscribe(
      res => {
        if (res.isSuccsess) {
          this.clientService.setRefreshEvents(this.clientService._refreshModal);
          const snack = new SnackBar(SnackBarType.success, 'Client contact successfully saved', '', 2000);
          this.commonUIService.openSnackBar(snack);
          this.clientService.refreshClientContact.emit(res.data);
          this.closeDialog();
        }
      },
      () => { console.error(); this.closeDialog(); });
  }

  BindToModel(clientObj) {
    this._clientContactForSave = new ClientContact();
    this._clientContactForSave.clientId = this.modalViewData.data.id;
    this._clientContactForSave.name = clientObj.name;
    this._clientContactForSave.email = clientObj.email;
    this._clientContactForSave.whatsapp = clientObj.whatsapp;
    this._clientContactForSave.skype = clientObj.skype;
    this._clientContactForSave.linkdin = clientObj.linkdin;
    this._clientContactForSave.positionId = clientObj.positionId;
    this._clientContactForSave.phoneNumber = clientObj.phoneNumber;
    this._clientContactForSave.additionalPhoneNumber = clientObj.additionalPhoneNumber;
    this._clientContactForSave.status = clientObj.status === true ? 1 : 0;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  changeLanguage(language: string) {
    if (language) {
      this._clientContact.language = language;
    }
  }

  copyToClipBoardEmail(val: string) {
    if (val) {
      const selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = val;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);
    } else {
      const selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = ' ';
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);
    }
  }

  onOpenSendEmailPopup() {
    if (this._clientContact.email && this._clientContact.language) {
      let emailModalData = {
        category: 'CMS',
        email: this._clientContact.email,
        language: this._clientContact.language
      };
      const dialogViewData = this.commonUIService.setDataForModalDialog(null, null, null, emailModalData, null, null);
      this.commonUIService.openDialog(SendEmailComponent, dialogViewData);
    } else {
      const snack = new SnackBar(SnackBarType.success, "Email and language should be filled", "", 2000);
      this.commonUIService.openSnackBar(snack);
    }
  }
}
