import {EventEmitter, Injectable, Output} from '@angular/core';
import { EstimationOption } from '../entities/estimationOption';

@Injectable({
  providedIn: 'root'
})
export class ProjectViewService {
  @Output() refreshProjectIndex: EventEmitter<any> = new EventEmitter<any>();
  @Output() refreshProject: EventEmitter<any> = new EventEmitter<any>();
  @Output() refreshProjectTask: EventEmitter<any> = new EventEmitter<any>();
  @Output() refreshProjectAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() refreshDeleteProject: EventEmitter<any> = new EventEmitter<any>();
  @Output() refreshInactiveProject: EventEmitter<any> = new EventEmitter<any>();
  @Output() refreshDeleteProjectTask: EventEmitter<any> = new EventEmitter<any>();
  @Output() refreshEstimationList: EventEmitter<any> = new EventEmitter<any>();
  @Output() passEstimationOptionData: EventEmitter<EstimationOption> = new EventEmitter<EstimationOption>();

  constructor() { }
}
