import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ProjectStatus } from 'src/app/shared/entities/projectStatus';
import { MaintenanceService } from '../../service/maintenance.service';
import { UIService, DialogDataForModalView } from 'src/app/shared/services/ui.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SnackBar, SnackBarType } from 'src/app/shared/components/snackbar/entities/snackbar';
import { ValidationService } from 'src/app/shared/services/validation.service';

@Component({
  selector: 'app-add-edit-projectstatus',
  templateUrl: './add-edit-projectstatus.component.html',
  styleUrls: ['./add-edit-projectstatus.component.scss']
})

export class AddEditProjectstatusComponent implements OnInit {
  projectStatusFormGroup: FormGroup;
  _projectStatus: ProjectStatus;
  isEdit: boolean = false;
  isDisabledProjectStatus = false;

  constructor(
    private _formBuilder: FormBuilder,
    private maintenanceService: MaintenanceService,
    private validationService: ValidationService,
    private uIService: UIService,
    public dialogRef: MatDialogRef<AddEditProjectstatusComponent>,
    @Inject(MAT_DIALOG_DATA) public projectStatausData: DialogDataForModalView
  ) { }

  ngOnInit() {
    this.initFormGroup();
    this._projectStatus = new ProjectStatus();
    this.setProjectStatusDataForEditView();
  }

  initFormGroup() {
    this.projectStatusFormGroup = this._formBuilder.group({
      projectState: ['', [Validators.required, this.validationService.noWhitespaceValidator]],
      color: ['', Validators.required],
      categoryId: ['']
    });
  }

  setProjectStatusDataForEditView() {
    if (this.projectStatausData.data) {
      this._projectStatus = Object.assign({}, this.projectStatausData.data).modalData;
      this.isEdit = true;
    }
  }

  async submit(projectStatus: { id: any; }) {
    if (this.projectStatusFormGroup.invalid) {
      this.isDisabledProjectStatus = false;
      return;
    }
    if (this.isEdit) this.updateProjectStatus(projectStatus.id, projectStatus);
    else
      this.saveTechnology(projectStatus);
  }

  saveTechnology(projectStatus: any) {
    this.isDisabledProjectStatus = true;
    if (projectStatus.categoryId != undefined || projectStatus.categoryId != null) {
      projectStatus.categoryId = +projectStatus.categoryId;
    }
    console.log(projectStatus);
    this.maintenanceService.SaveProjectStatus(projectStatus).subscribe(
      res => {
        if (res.isSuccsess) {
          this.maintenanceService.projectStatusRefresh(true);
          this.closeDialog();
          let snack = new SnackBar(SnackBarType.success, "Successfully saved", "", 2000);
          this.uIService.openSnackBar(snack);
        } else {
          let snack = new SnackBar(SnackBarType.success, "Error on save", "", 2000);
          this.uIService.openSnackBar(snack);
        }
      },
      () => { console.error(); this.closeDialog(); });
  }


  updateProjectStatus(id: any, obj: any) {
    if (obj.categoryId != undefined || obj.categoryId != null) {
      obj.categoryId = +obj.categoryId;
    }
    this.maintenanceService.updateProjectStatus(id, obj).subscribe(
      res => {
        if (res.isSuccsess) {
          this.maintenanceService.projectStatusRefresh(true);
          this.closeDialog();
          let snack = new SnackBar(SnackBarType.success, "Successfully updated", "", 2000);
          this.uIService.openSnackBar(snack);
        } else {
          let snack = new SnackBar(SnackBarType.success, "Error on update", "", 2000);
          this.uIService.openSnackBar(snack);
        }
      },
      err => { console.error(); this.closeDialog(); });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
