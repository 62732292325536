import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { APIService } from '../../shared/services/api.service';
import { UIService } from '../../shared/services/ui.service';
import { AuthAppService } from '../../shared/services/auth-app.service';
import { SnackBar, SnackBarType } from '../../shared/components/snackbar/entities/snackbar';
import { HrmService } from '../../hrm/service/hrm.service';
import { ClientService } from '../../client/services/client.service';

@Component({
  selector: 'module',
  templateUrl: './module.component.html',
  styleUrls: ['./module.component.scss']
})


export class ModuleComponent implements OnInit {
  permissions: any[] = [];
  loggedInUser: any;

  constructor(public dialog: MatDialog,
    private _formBuilder: FormBuilder,
    private router: Router,
    private apiService: APIService,
    private commonUIService: UIService,
    private authAppService: AuthAppService,
    private hrmService: HrmService,
    private clientService: ClientService
  ) { }

  ngOnInit(): void {
    this.permissions = this.authAppService.getLoggedInPermission();
    this.loggedInUser = this.authAppService.getLoggedInUser();
    this.commonUIService.closeMask();
  }

  signInWithCRM() {
    this.commonUIService.openMask();
    this.clientService.filterId = "2";
    if (this.permissions.includes("2000")) {
      let url = this.permissions.includes("2000") ? '/client' : (this.permissions.includes("2800") ? '/linkedin' : '/no-permission');
      this.router.navigate([url]);
    }
    else {
      var snack = new SnackBar(SnackBarType.error, "User has no permission for CRM application", "", 2000);
      this.commonUIService.openSnackBar(snack);
    }
    this.commonUIService.closeMask();
  }

  signInWithHRM() {
    this.commonUIService.openMask();
    if (this.permissions.includes("3000")) {
      this.router.navigate(["/hrm"]);
      this.hrmService.isEmployeeSelected = false;
    }
    else {
      var snack = new SnackBar(SnackBarType.error, "User has no permission for HRM application", "", 2000);
      this.commonUIService.openSnackBar(snack);
    }
    this.commonUIService.closeMask();
  }

  signInWithInventory() {
    this.commonUIService.openMask();
    if (this.permissions.includes("3020")) {
      this.router.navigate(["/inventory"]);
    }
    else {
      var snack = new SnackBar(SnackBarType.error, "User has no permission for Inventory application", "", 2000);
      this.commonUIService.openSnackBar(snack);
    }
    this.commonUIService.closeMask();
  }
}
